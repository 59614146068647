<div [ngSwitch]="uiType">

  <!-- <input #input
         *ngSwitchCase="'plain'"
         [ngClass]="{ 'skipped': isSkipped }"
         [placeholder]="placeholder"
         [type]="inputType"
         [name]="question.key"
         [title]="question.text"
         [(ngModel)]="value"
         (input)="answer()"
         (keypress)="inputKeyPress($event)"
         (focus)="onFocus($event)"
         (blur)="onBlur($event)"> -->

  <!-- <textarea #input
            rows="1"
            *ngSwitchCase="'plain'"
            [ngClass]="{ 'skipped': isSkipped }"
            [placeholder]="placeholder"
            [name]="question.key"
            [title]="question.text"
            [(ngModel)]="value"
            (input)="answer()"
            (keypress)="inputKeyPress($event)"
            (focus)="onFocus($event)"
            (blur)="onBlur($event)"></textarea> -->

  <div contenteditable="true"
       class="contenteditable"
       *ngSwitchCase="'plain'"
       [ngClass]="{ 'skipped': isSkipped }"
       [title]="question.text"
       [(appContenteditableModel)]="value"
       [attr.placeholder]="placeholder"
       (keypress)="inputKeyPress($event)"
       (keyup)="contentEditableKeyUp($event)"
       (focus)="onFocus($event)"
       (blur)="onBlur($event)"></div>

  <input #input
         *ngSwitchCase="'address'"
         [ngClass]="{ 'skipped': isSkipped }"
         [placeholder]="placeholder"
         [type]="inputType"
         [name]="question.key"
         [title]="question.text"
         [(ngModel)]="value"
         (input)="answer()"
         (keypress)="inputKeyPress($event)"
         (focus)="onFocus($event)"
         (blur)="onBlur($event)">

  <input #input
         *ngSwitchCase="'masked'"
         [ngClass]="{ 'skipped': isSkipped }"
         type="text"
         [placeholder]="placeholder"
         [textMask]="mask"
         [name]="question.key"
         [title]="question.text"
         [(ngModel)]="value"
         (input)="answer()"
         (keypress)="inputKeyPress($event)"
         (focus)="onFocus($event)"
         (blur)="onBlur($event)">

  <ng-template [ngSwitchCase]="'autocomplete'">
    <input #input
           type="text"
           [placeholder]="placeholder"
           [ngClass]="{ 'skipped': isSkipped }"
           [name]="question.key"
           [title]="question.text"
           [(ngModel)]="value"
           (input)="completeInput()"
           (keypress)="inputKeyPress($event)"
           (keydown)="inputKeyDown($event)"
           (focus)="onFocus($event)"
           (blur)="onBlur($event)" />

    <app-search-picker [questionId]="question.key"
                       [key]="pickerKey"
                       [value]="value"
                       (pick)="completePick($event)"></app-search-picker>
  </ng-template>

</div>
