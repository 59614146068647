<button (click)="onNameClick()"
        class="filter-name"
        [appShow]="!selected">
  {{ name }}
  <i class="fa"
     aria-hidden="true"
     [ngClass]="{ 'fa-caret-down': !expanded, 'fa-caret-up': expanded }"></i>
</button>

<button (click)="onSelectedClick()"
        [appShow]="selected">
  Filtering by:
  <em>"{{ selected }}"</em>
  <i class="fa fa-times"
     aria-hidden="true"></i>
</button>

<ul [appShow]="expanded">
  <li *ngFor="let option of options"
      (click)="onSelect(option)">
    {{ option }}
  </li>
</ul>
