import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppState, LoginLinkState } from '../../app.models';
import { Store } from '@ngrx/store';
import { LoginLinkRequested } from '../../global.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-update',
  templateUrl: './login-update.component.html',
  styleUrls: ['./login-update.component.sass']
})
export class LoginUpdateComponent implements OnInit, OnDestroy {
  private stateSubscription: Subscription;

  loginState = 'initial' as 'initial' | 'success' | 'not-found';
  emailValue?: string;
  loginLinkState: LoginLinkState;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.stateSubscription = this.store
      .select(s => s.global.loginLink)
      .subscribe(loginLink => {
        this.loginLinkState = loginLink;
        this.loginState = 'initial';
        if (loginLink.receivedResponse) {
          if (loginLink.success) {
            this.loginState = 'success';
          } else {
            this.loginState = 'not-found';
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  login() {
    if (this.emailValue) {
      this.store.dispatch(
        new LoginLinkRequested({
          email: this.emailValue,
          redirectOnSuccess: false
        })
      );
    }
  }
}
