import { yourOrganisationReducer } from './your-organisation/your-organisation.reducer';
import { sharedReducer } from './shared/shared.reducer';
import { AppState } from './app.models';
import { AppActions, AppActionTypes } from './app.actions';
import { opportunitiesReducer } from './opportunities/opportunities.reducer';
import { ActionReducer } from '@ngrx/store';
import { environment } from '../environments/environment';
import { criteriaStateReducer } from './criteria/reducers/criteria.reducer';
import { dataReducer } from './data/data.reducer';
import { globalReducer } from './global.reducer';
import { questionsReducer } from './data/question.reducer';
import { searchPickerReducer } from './shared/search-picker.reducer';
import { reportDownloadReducer } from './shared/report-download/report-download.reducer';
import { criteriaDefineCrunchReducer } from './criteria/criteria-define-crunch/criteria-define-crunch.reducer';
import { surveyPageReducer } from './your-organisation/survey-page.reducer';

export type AppReducers = { [K in keyof AppState]: AppReducer<AppState[K]> };

export const appReducers: AppReducers = {
  yourOrganisation: yourOrganisationReducer,
  shared: sharedReducer,
  opportunities: opportunitiesReducer,
  criteria: criteriaStateReducer,
  data: dataReducer,
  global: globalReducer,
  questions: questionsReducer,
  searchPicker: searchPickerReducer,
  reportDownload: reportDownloadReducer,
  criteriaCrunch: criteriaDefineCrunchReducer,
  surveyPage: surveyPageReducer
};

export function appClearMetaReducer(reducer: AppReducer<any>) {
  return (state: AppState, action: AppActions) => {
    if (action.type === AppActionTypes.APP_CLEAR) {
      state = undefined!;
    }
    return reducer(state, action);
  };
}

export const devReducers = [appClearMetaReducer];
export const prodReducers = [appClearMetaReducer];

export const appMetaReducers: any[] = environment.dev
  ? devReducers
  : prodReducers;

export type AppReducer<T> = (state: T, action: AppActions) => T;
