import { Component, OnInit } from '@angular/core';
import { AppLogger } from "../app-logger";
import { Router } from "@angular/router";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.sass']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private logger: AppLogger, private router: Router) { }

  ngOnInit() {
    this.logger.warn('Page not found:', this.router.url);
  }

}
