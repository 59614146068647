import { Injectable } from '@angular/core';

@Injectable()
export class IntercomService {

  constructor() { }

  update(userId?: string, email?: string) {
    const ic = this.intercom;
    if (ic) {
      const user: any = {};
      if (userId) {
        user.user_id = userId;
      }
      if (email) {
        user.email = email;
      }
      ic("update", user);
    }
  }

  show() {
    const ic = this.intercom;
    if (ic) {
      ic('show');
    }
  }

  private get intercom(): any {
    return (window as any).Intercom;
  }

}
