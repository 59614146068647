<div class="pageheader">
  <h1>Connect with &hellip;</h1>
</div>

<div class="pagecontent">
  <div class="sharebox">
    <h2>
      <strong>{{ state.name }}</strong>
    </h2>

    <p>
      <strong>{{ state.helperType  }}</strong>
      <br> {{ state.description }}
    </p>

    <hr>

    <p>
      <strong>What would you like to get out of this connection?</strong>
      <br> <em class="extra-info">(this will be seen by the supporter)</em>
    </p>

    <div class="field">
      <textarea name="reason"
                id="reason"
                cols="10"
                rows="3"
                [(ngModel)]="reason"></textarea>

      <p class="validation"
         [appShow]="!isValid.reason">
        Please provide what you would like to get out of this connection
      </p>
    </div>


    <div class="info-provided">
      <p><strong>If you decide to connect, we will send {{ state.name }} the following:</strong></p>

      <div class="shared">
        <table>
          <tr *ngFor="let item of state.ourInfo">
            <th>{{ item.title }}</th>
            <td>{{ item.value }}</td>
          </tr>
        </table>

        <p class="fill-heading"
           [appShow]="!!reason"
           *ngIf="state.ourName"><strong>{{ state.ourName }} would like to connect with you for:</strong></p>
        <blockquote [appShow]="!!reason">
          {{ reason }}
        </blockquote>
      </div>
    </div>

    <div class="validation validation-global"
         *ngIf="state.dailyLimitReached">
      You have reached the limit of 5 connections per day.
    </div>

    <div class="actions">
      <button (click)="dismiss()"
              class="action
                    dismiss-action
                    ">Dismiss</button>
      <button (click)="connect()"
              [disabled]="state.dailyLimitReached"
              class="action primary connect-action">Connect</button>
    </div>
  </div>
</div>
