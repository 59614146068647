import { Action } from '@ngrx/store';

export namespace ReportDownloadActionTypes {
  export const PROVIDE_EMAIL: '[Report Download] Provide Email' =
    '[Report Download] Provide Email';
  export const SET_DOWNLOAD_KEY: '[Report Download] Set Download Key' =
    '[Report Download] Set Download Key';
  export const PROVIDE_EMAIL_FAILED: '[Report Download] Provide Email Failed' =
    '[Report Download] Provide Email Failed';
}

export type ReportDownloadActions =
  | SetDownloadKey
  | ProvideEmail
  | ProvideEmailFailed;

export class SetDownloadKey implements Action {
  type = ReportDownloadActionTypes.SET_DOWNLOAD_KEY;
  constructor(public payload: string | null) {}
}

export class ProvideEmail implements Action {
  type = ReportDownloadActionTypes.PROVIDE_EMAIL;
  constructor(public payload: { email: string; reportId: string }) {}
}

export class ProvideEmailFailed implements Action {
  type = ReportDownloadActionTypes.PROVIDE_EMAIL_FAILED;
}
