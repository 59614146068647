import { ICallApiArgs } from '../shared/call-api';
import { ICollectable } from '../../common/core/collection';
import { DataCollectionKey, IDataCollectionTypes } from './data.models';
import {
  LoadCombinedCriteriaSetCallApiArgs,
  SaveCombinedCriteriaSetCallApiArgs,
  DeleteCombinedCriteriaSetCallApiArgs
} from '../criteria/criteria.actions';
import {
  LoadAllResultsCallApiArgs,
  LoadQuestionResponsesCallApiArgs,
  LoadQuestionResponsesLocked,
  LoadQuestionsCallApiArgs,
  LockQuestionIdCallApiArgs,
  SaveQuestionResponseCallApiArgs
} from './question.actions';
import { Injectable } from '@angular/core';
import { QuestionId } from '../../common/questions/question.models';
import { ICombinedCriteriaSet } from '../../common/criteria/criteria.models';

export interface ICollectionConfiguration<T> {
  loadArgs: ICallApiArgs;
  loadMapResponse: (response: any) => ICollectable[];
  saveArgs?: (id: string, data: any) => ICallApiArgs;
  deleteArgs?: (id: string) => ICallApiArgs;
  // batchArgs?: (idData: [string, any][]) => ICallApiArgs;
  hasMany?: { [collection: string]: { arrayKey: string; foreignKey: string } };
}

export type DataStateConfiguration = {
  [P in DataCollectionKey]: ICollectionConfiguration<IDataCollectionTypes[P]>
};

@Injectable()
export class DataConfig {
  private readonly criteriaLoadArgs = new LoadCombinedCriteriaSetCallApiArgs();
  private readonly questionsLoadArgs = new LoadQuestionsCallApiArgs();
  private readonly responseLoadArgs = new LoadQuestionResponsesCallApiArgs();

  readonly configuration: DataStateConfiguration = {
    // criteria: {
    //   loadArgs: this.criteriaLoadArgs,
    //   loadMapResponse: r => this.mapBy(r.criteria, 'id'),
    //   saveArgs: (id, data) => new SaveCriterionCallApiArgs(data),
    //   deleteArgs: id => new RemoveCriterionCallApiArgs(id)
    // },
    // criteriaSets: {
    //   loadArgs: this.criteriaLoadArgs,
    //   loadMapResponse: r => this.mapBy(r.sets, 'id'),
    //   saveArgs: (id, data) => new SaveCriteriaSetCallApiArgs(data),
    //   deleteArgs: id => new RemoveCriteriaSetCallApiArgs(id),
    //   hasMany: {
    //     criteria: { arrayKey: 'criteriaIds', foreignKey: 'criteriaSetId' }
    //   }
    // },
    combinedCriteriaSets: {
      loadArgs: this.criteriaLoadArgs,
      loadMapResponse: (r: { sets: ICombinedCriteriaSet[] }) =>
        r.sets.map(set => ({ ...set, _id: set.set.id })),
      saveArgs: (id, data) => new SaveCombinedCriteriaSetCallApiArgs(data),
      deleteArgs: id => new DeleteCombinedCriteriaSetCallApiArgs(id)
    },
    questions: {
      loadArgs: this.questionsLoadArgs,
      loadMapResponse: r => this.mapBy(r.questions, 'id')
    },
    sections: {
      loadArgs: this.questionsLoadArgs,
      loadMapResponse: r => this.mapBy(r.sections, 'id')
    },
    responses: {
      loadArgs: this.responseLoadArgs,
      loadMapResponse: r => this.mapBy(r.responses, 'questionId'),
      saveArgs: (id, data) =>
        new SaveQuestionResponseCallApiArgs(id as QuestionId, data)
      // batchArgs: (idData) => {
      //   const responsesWithId = idData.map(([id, data]) => ({ ...data, questionId: id }));
      //   return new BatchQuestionResponseCallApiArgs(responsesWithId);
      // }
    },
    oldResponses: {
      loadArgs: this.responseLoadArgs,
      loadMapResponse: r => this.mapBy(r.oldResponses, 'questionKey')
    },
    questionResults: {
      loadArgs: new LoadAllResultsCallApiArgs(),
      loadMapResponse: r => this.mapBy(r, 'questionId')
    },
    lockedQuestionIds: {
      loadArgs: new LoadQuestionResponsesLocked(),
      loadMapResponse: (r: string[]) => r.map(x => ({ _id: x })),
      saveArgs: (id, data) => new LockQuestionIdCallApiArgs(data)
    },
    criteriaSetValidity: {
      loadArgs: this.criteriaLoadArgs,
      loadMapResponse: r => this.mapBy(r.setValidity, 'setId')
    },
    responseUpdates: {
      loadArgs: this.responseLoadArgs, // TODO: fixme
      loadMapResponse: r => this.mapBy(r.updates, 'questionId')
    }
  };

  get keys(): DataCollectionKey[] {
    return Object.keys(this.configuration) as DataCollectionKey[];
  }

  private mapBy<T>(all: any[], idKey: string): (T & ICollectable)[] {
    return all.map(a => ({ ...a, _id: a[idKey] }));
  }
}
