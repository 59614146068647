
import {take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  Resolve
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.models';
import { DataLoaderService } from '../shared/data-loader.service';
import { DataService } from '../data/data.service';

@Injectable()
export class CriteriaDataLoaderService implements Resolve<boolean> {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private loader: DataLoaderService,
    private data: DataService
  ) {}

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
  //   return true;
  // }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.loader
      .load({
        state$: this.store.select(
          s => s.data.combinedCriteriaSets.loading === 'loaded'
        ),
        isLoaded: loaded => loaded,
        triggerLoad: () => {
          this.store.dispatch(this.data.load('combinedCriteriaSets'));
        }
      }).pipe(
      take(1));
  }
}
