import { of as observableOf, iif as observableIif, Observable } from 'rxjs';

import { map, filter, tap, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { UserService } from './users/user.service';
import { Store } from '@ngrx/store';
import { AppState } from './app.models';
import { GlobalLoadRequested } from './global.actions';

@Injectable()
export class AppInitService implements CanActivate {
  constructor(
    private user: UserService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return observableIif(
      () => route.queryParams.auth,
      this.user.auth(route.queryParams.auth),
      observableOf(true)
    ).pipe(
      mergeMap(result => {
        return this.store.select(s => s.global).pipe(
          tap(globalState => {
            if (globalState.loadState === 'unloaded') {
              const lightweightLoad = !!route.data.lightweight;
              this.store.dispatch(new GlobalLoadRequested(lightweightLoad));
            } else if (globalState.isLightweight && !route.data.lightweight) {
              this.store.dispatch(new GlobalLoadRequested(false));
            }
          }),
          filter(globalState => globalState.loadState === 'loaded'),
          map(_ => true)
        );
      })
    );
  }
}
