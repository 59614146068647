import { Component, OnInit, Input } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/compiler/src/core';

@Component({
  selector: 'app-question-status',
  templateUrl: './question-status.component.html',
  styleUrls: ['./question-status.component.sass']
})
export class QuestionStatusComponent implements OnInit {
  @Input() status: 'saving' | 'saved' | 'failed' | 'error' | 'need-update';

  constructor() {}

  ngOnInit() {}
}
