import { OpportunitiesDataLoaderService } from './opportunities-data-loader.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpportunitiesComponent } from './opportunities.component';
import { ConnectionsComponent } from './connections/connections.component';
import { ConnectWithComponent } from './connect-with/connect-with.component';
import { SharedModule } from '../shared/shared.module';
import { OrgLineComponent } from './org-line/org-line.component';
import { ConnectWithPageComponent } from './connect-with-page/connect-with-page.component';
import { ConnectionsPageComponent } from './connections-page/connections-page.component';
import { EffectsModule } from '@ngrx/effects';
import { OpportunitiesEffects } from './opportunities.effects';
import { LeaveFeedbackComponent } from './leave-feedback/leave-feedback.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([OpportunitiesEffects])
  ],
  declarations: [
    OpportunitiesComponent,
    ConnectionsComponent,
    ConnectionsPageComponent,
    ConnectWithPageComponent,
    ConnectWithComponent,
    OrgLineComponent,
    LeaveFeedbackComponent
  ],
  providers: [
    OpportunitiesDataLoaderService
  ],
  exports: [
    OrgLineComponent
  ]
})
export class OpportunitiesModule { }
