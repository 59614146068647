import {
  Question,
  QuestionCondition,
  QuestionShowIf,
  QuestionDefault
} from './question.models';
import * as _ from 'lodash';
import { QuestionVisibilityConditionService } from './question-visibility-condition.service';
import { QuestionResponse } from './response.model';
import Dictionary = _.Dictionary;
import { ResponsesForVisibility } from './question-visibility.util';

export interface IVisibleQuestionsAndResponsesResult {
  questionsByKey: Dictionary<Question>;
  responsesByKey: Dictionary<QuestionResponse>;
}

export class QuestionVisibilityService {
  constructor(private condition: QuestionVisibilityConditionService) {}

  /**
   * Should the current question take on a default value based on the responses given.
   */
  shouldDefault(
    question: Question,
    responsesByKey: ResponsesForVisibility
  ): boolean {
    if (!question.default) {
      return false;
    }

    return this.shouldDefaultSingular(question.default, responsesByKey);
  }

  shouldShowOnly(
    question: Question,
    responsesByKey: ResponsesForVisibility
  ): boolean {
    if (question.showIf) {
      if (Array.isArray(question.showIf)) {
        return question.showIf.some(s =>
          this.shouldShowSingular(s, responsesByKey)
        );
      }
      return this.shouldShowSingular(question.showIf, responsesByKey);
    }

    return true;
  }

  /**
   * Should the current question be shown depending on the responses given.
   */
  shouldShow(
    question: Question,
    responsesByKey: ResponsesForVisibility
  ): boolean {
    let shouldReturn = this.shouldShowOnly(question, responsesByKey);
    if (shouldReturn && question.default) {
      shouldReturn = !this.shouldDefault(question, responsesByKey);
    }
    return shouldReturn;
  }

  /**
   * Returns a list of all the questions which are visible depending on the responses given..
   */
  visibleQuestionsAndResponses(
    questions: Question[],
    responsesByKey: ResponsesForVisibility
  ): IVisibleQuestionsAndResponsesResult {
    // Do extra-passes until we "stabilise", this is because we're removing responses for hidden questions, which may
    // affect questions that have dependencies on those questions.
    while (true) {
      const localResponsesByKey = Object.assign({}, responsesByKey);
      const visibleQuestions: Dictionary<Question> = {};
      const hiddenQuestions: Dictionary<Question> = {};

      for (const question of questions) {
        if (this.shouldShow(question, responsesByKey)) {
          visibleQuestions[question.key] = question;
        } else {
          hiddenQuestions[question.key] = question;
          delete localResponsesByKey[question.key];
        }
      }

      const result = _.values(visibleQuestions);
      if (questions.length === result.length) {
        return {
          questionsByKey: visibleQuestions,
          responsesByKey: localResponsesByKey
        };
      }

      questions = result;
    }
  }

  private everyConditionIsMet(
    responsesByKey: _.Dictionary<QuestionResponse>,
    conditions: QuestionCondition[]
  ): boolean {
    return conditions.every(condition =>
      this.condition.conditionIsMet(responsesByKey, condition)
    );
  }

  private someConditionIsMet(
    responsesByKey: _.Dictionary<QuestionResponse>,
    conditions: QuestionCondition[]
  ): boolean {
    return conditions.some(condition =>
      this.condition.conditionIsMet(responsesByKey, condition)
    );
  }

  private shouldShowSingular(
    showIf: QuestionShowIf,
    responsesByKey: _.Dictionary<QuestionResponse>
  ) {
    let shouldReturn = true;

    if (shouldReturn && showIf.any && showIf.any.length > 0) {
      shouldReturn = this.someConditionIsMet(responsesByKey, showIf.any);
    }
    if (shouldReturn && showIf.all && showIf.all.length > 0) {
      shouldReturn = this.everyConditionIsMet(responsesByKey, showIf.all);
    }
    if (shouldReturn && showIf.notAny && showIf.notAny.length > 0) {
      shouldReturn = !this.someConditionIsMet(responsesByKey, showIf.notAny);
    }
    if (shouldReturn && showIf.notAll && showIf.notAll.length > 0) {
      shouldReturn = !this.everyConditionIsMet(responsesByKey, showIf.notAll);
    }

    return shouldReturn;
  }

  private shouldDefaultSingular(
    def: QuestionDefault,
    responsesByKey: _.Dictionary<QuestionResponse>
  ) {
    let shouldReturn = true;

    if (shouldReturn && def.any && def.any.length > 0) {
      shouldReturn = !this.someConditionIsMet(responsesByKey, def.any);
    }
    if (shouldReturn && def.notAny && def.notAny.length > 0) {
      shouldReturn = this.someConditionIsMet(responsesByKey, def.notAny);
    }
    if (shouldReturn && def.all && def.all.length > 0) {
      shouldReturn = !this.everyConditionIsMet(responsesByKey, def.all);
    }
    if (shouldReturn && def.notAll && def.notAll.length > 0) {
      shouldReturn = this.everyConditionIsMet(responsesByKey, def.notAll);
    }

    return !shouldReturn;
  }
}
