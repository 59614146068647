import { Action } from '@ngrx/store';
import { CallApiActions } from './call-api';

export namespace SharedActionTypes {
  export const SAVING_START: '[Shared] Saving Start' = '[Shared] Saving Start';
  export const SAVING_DONE: '[Shared] Saving Done' = '[Shared] Saving Done';
  export const SAVING_FAILED: '[Shared] Saving Failed' = '[Shared] Saving Failed';

  export const SET_LOAD_REDIRECT: '[Shared] Load Redirect' = '[Shared] Load Redirect';

  export const LOADING_START: '[Shared] Loading Start' = '[Shared] Loading Start';
  export const LOADING_DONE: '[Shared] Loading Done' = '[Shared] Loading Done';
  export const LOADING_FAILED: '[Shared] Loading Failed' = '[Shared] Loading Failed';
}

export class SavingStart implements Action {
  readonly type = SharedActionTypes.SAVING_START;
}

export class SavingDone implements Action {
  readonly type = SharedActionTypes.SAVING_DONE;
}

export class SavingFailed implements Action {
  readonly type = SharedActionTypes.SAVING_FAILED;
  constructor(public payload?: string[]) {}
}

export class SetLoadRedirect implements Action {
  type = SharedActionTypes.SET_LOAD_REDIRECT;
  constructor(public payload: any[]) {}
}

export class LoadingStart implements Action {
  type = SharedActionTypes.LOADING_START;
}

export class LoadingDone implements Action {
  type = SharedActionTypes.LOADING_DONE;
}

export class LoadingFailed implements Action {
  type = SharedActionTypes.LOADING_FAILED;
  constructor(public payload?: string[]) {}
}

export type SharedActions = SavingStart | SavingDone | SavingFailed
  | LoadingStart | LoadingDone | LoadingFailed
  | CallApiActions
  | SetLoadRedirect;
