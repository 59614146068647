<div class="report-download">
  <input class="state"
         id="modal-1"
         type="checkbox"
         checked="checked" />
  <div class="fade-screen"
       (click)="close()">
    <div class="inner"
         (click)="onInnerClick($event)">
      <div class="close"
           for="modal-1"
           (click)="close()"></div>

      <div class="logo">
        <img src="/assets/images/logo-white.svg"
             alt=""
             width="220">
      </div>

      <div class="body">
        <p class="subtitle">We won’t fill your inbox, just your mind with awesome stats</p>

        <p class="prompt"
           *ngIf="!state.downloadKey || state.failedLoad">Enter your email address to download the free report</p>

        <div class="action-line"
             *ngIf="!state.downloadKey || state.failedLoad">
          <input type="text"
                 (keypress)="inputKeyPress($event)"
                 [(ngModel)]="email">
          <button class="action primary"
                  (click)="saveEmail()"
                  [disabled]="!isEmailValidish">
            Download
          </button>
        </div>

        <p class="thank-you"
           *ngIf="(state.isLoading || !!state.downloadKey) && !state.failedLoad">
          Thank you! Your download should start shortly.
        </p>
        <!--

        <h3 *ngIf="showSponsors">Sponsored by</h3>

        <div class="sponsors"
             *ngIf="showSponsors">
          <img id="uts"
               src="/assets/images/report-sponsors/uts-294.png"
               alt="UTS">
               -->
          <!-- <img id="myob"
               src="/assets/images/report-sponsors/myob-trim-308.png"
               alt="MYOB"> -->
        <!--
          <img id="atlassian"
               src="/assets/images/report-sponsors/Atlassian-horizontal-blue@2x-rgb.png"
               alt="atlassian">
          <img id="google"
               src="/assets/images/report-sponsors/google.png"
               alt="Google">
        </div>
        -->
      </div>
    </div>
  </div>
</div>
