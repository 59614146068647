<div class="pageheader">
  <h1>Be Found By Startups</h1>
</div>

<div class="pagecontent"
     *ngIf="state$ | async as state">

  <p>Creating an
    <strong>opportunity profile</strong> allows you to be seen by startups that match
    exactly who you want to connect with.</p>

  <ol>
    <li>Choose the characteristics of the ideal startup you'd like to be found by,
      based on how they have responded to the Startup Muster survey.</li>

    <li>Matching startups will then be shown a snapshot of your offering, and will
      be able to reach out and connect.</li>

    <li>Create and save multiple customer opportunity profiles so you can connect
      with different kinds of startups.</li>
  </ol>

  <p>The more specific your criteria, the more visible you’ll be.</p>

  <ng-container *ngIf="state.initialAdd; else listBlock">
    <!-- <app-criteria-define [id]="null"></app-criteria-define> -->
    <app-criteria-define-crunch [setId]="state.initialAdd"></app-criteria-define-crunch>
  </ng-container>

  <ng-template #listBlock>

    <p>
      Here’s how you’ll appear to startups:
      <app-org-line [viewOnly]="true"
                    [org]="state.org"></app-org-line>
    </p>

    <h2 id="list">List of Opportunity Profiles</h2>

    <div class="add-another">
      <button class="action"
              (click)="onAddNewCriteria()">Add another Opportunity Profile</button>
    </div>

    <div *ngFor="let cs of state.sets"
         class="set"
         [ngClass]="{active: cs.enabled, selected: selected === cs.id}">

      <div class="header">

        <div class="actions"
             (click)="false">
          <a [routerLink]="[cs.id]">Edit</a>

          <a [appShow]="cs.enabled"
             href="javascript:void(0)"
             (click)="onSetDisableClick(cs.id)">Disable</a>
          <a [appShow]="!cs.enabled"
             href="javascript:void(0)"
             (click)="onSetEnableClick(cs.id)">Enable</a>

          <a href="javascript:void(0)"
             (click)="onSetRemoveClick(cs.id)">Remove</a>
        </div>

        <h3>{{ cs.label }}</h3>

        <span class="status status-active">(Active)</span>
        <span class="status status-disabled">(Disabled)</span>

        <span class="status status-invalid"
              *ngIf="state.setValidity[cs.id] === 'tooWide'">
          <br> These criteria are too broad, please edit and make it more specific
          so that we can deliver the best startup matches for you.
        </span>
      </div>

      <!-- <div class="body">
        <app-criteria-set-detail [id]="cs.id"></app-criteria-set-detail>
      </div> -->
      <div class="body">
        <app-criteria-set-detail [id]="cs.id"></app-criteria-set-detail>
      </div>
    </div>

  </ng-template>

</div>
