
import {combineLatest} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { QuestionSelectorsService } from './question-selectors.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.models';
import {
  IAnswersViewState,
  IAnswersViewSection,
  ISurveyStateQuestionResponse,
  ISurveyStateSection
} from './your-organisation.models';
import * as _ from 'lodash';
import * as timm from 'timm';
import {
  QuestionResponseUpdateStatus,
  QuestionResponseUpdateLevel
} from '../../common/questions/response.model';
import { QuestionResponseUpdate } from '../data/question.actions';
import {
  QuestionKey,
  IOptions,
  MultipleSelectionQuestion
} from '../../common/questions/question.models';
import { QuestionIds } from '../../common/questions/constants';

@Injectable()
export class AnswersViewSelectorsService {
  readonly state$: Observable<
    IAnswersViewState
  > = this.questionSelectors
    .nestedSections$(false).pipe(
    combineLatest(
      this.store.select(s => s.yourOrganisation),
      this.store.select(s => s.data.responseUpdates.byId),
      (sections, yourOrg, responseUpdatesById) => {
        const {
          editingQuestionId,
          selectedSectionIds,
          currentUpdateLevel
        } = yourOrg;

        const {
          sections: newSections,
          questionsNeedingUpdates
        } = this.transformSections(
          sections,
          responseUpdatesById,
          currentUpdateLevel
        );

        let editingQuestion: ISurveyStateQuestionResponse | undefined;
        if (editingQuestionId) {
          editingQuestion = _(sections)
            .flatMap(s => s.questionResponses)
            .filter(q => q.question.id === editingQuestionId)
            .first();
        }

        return {
          sections: newSections,
          editingQuestionId,
          editingQuestion,
          selectedSectionIds,
          questionsNeedingUpdates,
          currentUpdateLevel
        };
      }
    ));

  constructor(
    private questionSelectors: QuestionSelectorsService,
    private store: Store<AppState>
  ) {}

  private transformSections(
    sections: ISurveyStateSection[],
    responseUpdatesById: _.Dictionary<QuestionResponseUpdateStatus>,
    currentUpdateLevel: QuestionResponseUpdateLevel | undefined
  ): {
    sections: IAnswersViewSection[];
    questionsNeedingUpdates: ISurveyStateQuestionResponse[];
  } {
    let allQuestionsNeedingUpdate = new Array<ISurveyStateQuestionResponse>();
    const newSections = _.flatMap(sections, s => {
      const {
        responses,
        sectionNeedsUpdate,
        questionsNeedingUpdate
      } = this.transformResponses(
        s.questionResponses,
        responseUpdatesById,
        currentUpdateLevel
      );

      if (responses.length === 0) {
        return [];
      }

      allQuestionsNeedingUpdate = allQuestionsNeedingUpdate.concat(
        questionsNeedingUpdate
      );

      return [
        {
          ...s,
          needsUpdate: sectionNeedsUpdate,
          questionResponses: responses
        }
      ];
    });

    return {
      sections: newSections,
      questionsNeedingUpdates: allQuestionsNeedingUpdate
    };
  }

  private transformResponses(
    responses: ISurveyStateQuestionResponse[],
    responseUpdatesById: _.Dictionary<QuestionResponseUpdateStatus>,
    currentUpdateLevel: QuestionResponseUpdateLevel | undefined
  ): {
    responses: ISurveyStateQuestionResponse[];
    sectionNeedsUpdate: boolean;
    questionsNeedingUpdate: ISurveyStateQuestionResponse[];
  } {
    let sectionNeedsUpdate = false;
    const questionsNeedingUpdate = new Array<ISurveyStateQuestionResponse>();
    const resultResponses = responses
      .filter(qr => !(qr.question.profile && qr.question.profile.exclude))
      .map(qr => {
        if (qr.question.key === QuestionIds.USER_TYPE) {
          // returning is a special option that's only used for the initial
          // survey/login, it's not relevant to the update your org view
          // TODO: consider whether it's better to flag this option in the data
          // somehow, or maybe logins just need to be implemented better as a whole
          const multiSelectQ = qr.question as MultipleSelectionQuestion;
          return timm.setIn(
            qr,
            ['question', 'options'],
            timm.removeAt(
              multiSelectQ.options,
              multiSelectQ.options.findIndex(
                o => o.optionId === 'AUserTypeReturning'
              )
            )
          );
        }
        return qr;
      })
      .map(qr => {
        if ((!qr.status && !qr.isDirty) || !qr.isValid) {
          const update = responseUpdatesById[qr.question.id];
          const needsUpdate =
            (update && update.level === currentUpdateLevel) ||
            (!qr.isValid && qr.status !== 'saving');
          if (needsUpdate) {
            sectionNeedsUpdate = true;
            questionsNeedingUpdate.push(qr);
            return { ...qr, status: 'need-update' as any };
          }
        }
        return qr;
      });

    return {
      sectionNeedsUpdate,
      responses: resultResponses,
      questionsNeedingUpdate
    };
  }
}
