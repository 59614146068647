import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Question, ISkippable } from '../../../common/questions/question.models';
import { QuestionResponse } from '../../../common/questions/response.model';

@Component({
  selector: 'app-skip-button',
  templateUrl: './skip-button.component.html',
  styleUrls: ['./skip-button.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkipButtonComponent implements OnInit, OnChanges {

  @Input() question: Question & ISkippable;
  @Input() response: QuestionResponse;

  @Output() onSkip = new EventEmitter<{}>();

  confirm = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.confirm = false;
  }

  get show() {
    return this.question.skippable
      && (!this.response
      || this.response.type === 'skipped'
      || (this.response.type === 'value' && !this.response.value)
      || (this.response.type === 'selection')
        || (this.response.type === 'choice'));
  }

  get isSkipped() {
    return this.response && this.response.type === 'skipped';
  }

  get buttonText() {
    return this.confirm ? this.question.skipConfirmation : this.question.skippable;
  }

  get showSkipped() {
    return this.isSkipped && (this.question.type === 'MC' || this.question.type === 'MS');
  }

  skip() {
    if (this.question.skipConfirmation && !this.confirm) {
      this.confirm = true;
      return;
    }
    this.onSkip.emit({});
  }

}
