<div class="container"
     [ngClass]="style">
  <button *ngFor="let option of options; trackBy: trackByOptionId"
          class="button"
          [ngClass]="{'selected': isSelected(option.id)}"
          (click)="onSelect(option.id)">
    <i class="badge"
       *ngIf="option.icon"
       aria-hidden="true">{{ option.icon }}</i>
    {{ option.text }}
  </button>
</div>
