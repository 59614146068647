
import {map, combineLatest} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ISearchPickerResult } from '../../common/questions/search-picker.models';
import { AppState } from '../app.models';
import {
  MoveSelection,
  UpdateSearch,
  VisibilityToggle
} from '../shared/search-picker.actions';

// export type SearchPickerSearcher = (value: string) => Observable<ISearchPickerResult[]>;

export interface ISearchPickerViewState {
  results: ISearchPickerResult[];
  selection?: string;
  visible: boolean;
}

@Injectable()
export class SearchPickerService {
  constructor(private store: Store<AppState>) {}

  getState$(questionId: string): Observable<ISearchPickerViewState> {
    return this.store
      .select(s => s.searchPicker.filteredResultsByQuestion).pipe(
      combineLatest(
        this.store.select(s => s.searchPicker.ui),
        (filteredResults, uiStates) => {
          const uiState = uiStates[questionId];
          return {
            results: filteredResults[questionId] || [],
            selection: uiState ? uiState.selection : undefined,
            visible: uiState ? uiState.visible : false
          };
        }
      ));
  }

  selection$(questionId: string): Observable<string | null> {
    return this.store.select(s => s.searchPicker.ui).pipe(map(ui => {
      const uiState = ui[questionId];
      if (uiState && uiState.visible && uiState.selection) {
        return uiState.selection;
      }
      return null;
    }));
  }

  updateSearch(questionId: string, key: string, value: string): void {
    this.store.dispatch(new UpdateSearch({ questionId, key, value }));
  }

  resetSearch(questionId: string, key: string): void {
    this.store.dispatch(new UpdateSearch({ questionId, key, value: '' }));
  }

  moveSelection(questionId: string, direction: 'up' | 'down') {
    this.store.dispatch(new MoveSelection({ questionId, direction }));
  }

  setVisibility(questionId: string, visible: boolean) {
    this.store.dispatch(new VisibilityToggle({ questionId, visible }));
  }
}
