import { Action } from '@ngrx/store';
import {
  QuestionResponse,
  QuestionResponseUpdateLevel
} from '../../common/questions/response.model';
import { ICallApiArgs } from '../shared/call-api';

export namespace YourOrganisationActionTypes {
  // export const LOAD_STATE_REQUESTED: '[Your Org] Load State Requested' = '[Your Org] Load State Requested';
  // export const LOAD_STATE_LOADED: '[Your Org] Load State Loaded' = '[Your Org] Load State Loaded';
  // export const LOAD_STATE_FAILED: '[Your Org] Load State Failed' = '[Your Org] Load State Failed';

  export const EDIT_QUESTION: '[Your Org] Edit Question' =
    '[Your Org] Edit Question';
  export const CANCEL_EDIT_QUESTION: '[Your Org] Cancel Edit Question' =
    '[Your Org] Cancel Edit Question';

  export const GUIDE_INIT: '[Your Org] Guide Init' = '[Your Org] Guide Init';
  export const EDIT_NEXT_QUESTION: '[Your Org] Edit Next Question' =
    '[Your Org] Edit Next Question';
  export const EDIT_PREV_QUESTION: '[Your Org] Edit Prev Question' =
    '[Your Org] Edit Prev Question';

  export const UPDATE_QUESTION_ANSWER: '[Your Org] Update Question Answer' =
    '[Your Org] Update Question Answer';

  export const SELECT_SECTION: '[Your Org] Select Section' =
    '[Your Org] Select Section';

  export const SET_UPDATE_LEVEL: '[Your Org] Set Update Level' =
    '[Your Org] Set Update Level';
}

export namespace YourOrganisationCallApiKeys {
  export const LOAD_STATE: '[Your Org] Load State' = '[Your Org] Load State';
}

export type YourOrganisationActions =
  | EditQuestion
  | CancelEditQuestion
  | EditNextQuestion
  | EditPrevQuestion
  | GuideInit
  | UpdateQuestionAnswer
  | SelectSection
  | SetUpdateLevel;

// export class LoadStateRequested implements Action {
//   type = YourOrganisationActionTypes.LOAD_STATE_REQUESTED;
// }

// export class LoadStateLoaded implements Action {
//   type = YourOrganisationActionTypes.LOAD_STATE_LOADED;
//   constructor(public payload: IYourOrganisationState) {}
// }

// export class LoadStateFailed implements Action {
//   type = YourOrganisationActionTypes.LOAD_STATE_FAILED;
// }

export class LoadStateCallApiArgs implements ICallApiArgs {
  key = YourOrganisationCallApiKeys.LOAD_STATE;
  url = '/your_organisation/';
}

export class EditQuestion implements Action {
  type = YourOrganisationActionTypes.EDIT_QUESTION;
  constructor(public payload: string) {}
}

export class CancelEditQuestion implements Action {
  type = YourOrganisationActionTypes.CANCEL_EDIT_QUESTION;
}

export class EditNextQuestion implements Action {
  type = YourOrganisationActionTypes.EDIT_NEXT_QUESTION;
}

export class EditPrevQuestion implements Action {
  type = YourOrganisationActionTypes.EDIT_PREV_QUESTION;
}

export class GuideInit implements Action {
  type = YourOrganisationActionTypes.GUIDE_INIT;
}

export class UpdateQuestionAnswer implements Action {
  type = YourOrganisationActionTypes.UPDATE_QUESTION_ANSWER;
  constructor(
    public payload: { questionId: string; response: QuestionResponse }
  ) {}
}

export class SelectSection implements Action {
  type = YourOrganisationActionTypes.SELECT_SECTION;
  constructor(public payload: { sectionId: string }) {}
}

export class SetUpdateLevel implements Action {
  type = YourOrganisationActionTypes.SET_UPDATE_LEVEL;
  constructor(public payload: { updateLevel?: QuestionResponseUpdateLevel }) {}
}
