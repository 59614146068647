import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.sass']
})
export class FilterSelectComponent implements OnInit {

  @Input() name: string;
  @Input() options: string[];
  @Input() selected?: string;
  @Output() select = new EventEmitter<string|null>();

  expanded = false;

  constructor() { }

  ngOnInit() {
  }

  onNameClick() {
    this.expanded = !this.expanded;
  }

  onSelectedClick() {
    this.selected = undefined;
    this.select.emit(null);
  }

  onSelect(option: string) {
    this.selected = option;
    this.select.emit(option);
    this.expanded = false;
  }

}
