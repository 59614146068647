import { empty as observableEmpty, of as observableOf } from 'rxjs';

import {
  tap,
  withLatestFrom,
  mergeAll,
  merge,
  map,
  switchMap
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppResponse } from '../../common/core/core.models';
import { AppState } from '../app.models';
import { EffectLoggerService } from '../effect-logger.service';
import {
  GlobalActionTypes,
  GlobalLoaded,
  GlobalLoading,
  GlobalNotLoaded,
  LoginLinkRequested,
  LoginLinkResult,
  GlobalLoadRequested
} from '../global.actions';
import { UserService } from '../users/user.service';

@Injectable()
export class UserEffects {
  @Effect()
  initialLoad = this.actions$.pipe(
    ofType(GlobalActionTypes.LOAD_REQUESTED),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        withLatestFrom(
          this.store.select(s => s.global),
          (action: GlobalLoadRequested, globalState) => {
            if (globalState.loadState !== 'loading') {
              return observableOf(new GlobalLoading()).pipe(
                merge(
                  this.userService
                    .loadInitialState(action.lightweightLoad)
                    .pipe(
                      map(result =>
                        result
                          ? new GlobalLoaded(action.lightweightLoad)
                          : new GlobalNotLoaded()
                      )
                    )
                )
              );
            }
            return observableEmpty();
          }
        ),
        mergeAll()
      )
    )
  );

  @Effect()
  loginLinkRequested$ = this.actions$.pipe(
    ofType(GlobalActionTypes.LOGIN_LINK_REQUESTED),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        switchMap((action: LoginLinkRequested) => {
          return this.http
            .post<AppResponse<{}>>('/auth/request', {
              email: action.payload.email
            })
            .pipe(
              map(response => {
                return new LoginLinkResult({
                  success: response.success,
                  redirectOnSuccess: action.payload.redirectOnSuccess
                });
              })
            );
        })
      )
    )
  );

  @Effect({ dispatch: false })
  loginLinkSucceeded$ = this.actions$.pipe(
    ofType(GlobalActionTypes.LOGIN_LINK_RESULT),
    tap((action: LoginLinkResult) => {
      if (action.payload.redirectOnSuccess) {
        this.router.navigate(['/login', 'requested']);
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private http: HttpClient,
    private router: Router,
    private effectLogger: EffectLoggerService
  ) {}
}
