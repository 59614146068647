
import {combineLatest, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.models';
import { Observable } from 'rxjs';
import { QuestionKey } from '../../common/questions/question.models';
import { QuestionSelectorsService } from './question-selectors.service';
import { ISurveyState } from './your-organisation.models';
import * as lodash from 'lodash';

@Injectable()
export class SurveySelectorsService {
  private returningEmail$ = this.questionSelectors
    .selectQuestionByKey$('QUserTypeReturningEmail' as QuestionKey).pipe(
    combineLatest(this.questionSelectors.responses$, (question, responses) => {
      if (!question) {
        return null;
      }
      const response = responses[question.id];
      if (response && response.type === 'value') {
        return response.value;
      }

      return null;
    }));

  private hasResponse$ = this.store
    .select(s => s.data.responses.ids).pipe(
    map(responseIds => {
      return responseIds.length > 0;
    }));
  private isComplete$ = this.questionSelectors
    .visibleQuestionsResponses$(true).pipe(
    map(qrs => qrs.every(qr => !!qr.response || qr.question.type === 'F')));

  private surveyState$ = this.hasResponse$.pipe(combineLatest(
    this.isComplete$,
    (hasResponse, isComplete) => {
      return {
        hasResponse,
        isComplete
      };
    }
  ));

  private loginLink$ = this.store.select(s => s.global.loginLink);
  private hasPreviousResponses$ = this.store.select(
    s => s.global.hasPreviousResponses
  );
  private fromGlobal$ = this.loginLink$.pipe(combineLatest(
    this.hasPreviousResponses$,
    (loginLink, hasPreviousResponses) => {
      return {
        loginLink,
        hasPreviousResponses
      };
    }
  ));
  private pageState$ = this.store.select(s => s.surveyPage);

  readonly state$: Observable<
    ISurveyState
  > = this.questionSelectors
    .nestedSections$(true).pipe(
    combineLatest(
      this.returningEmail$,
      this.surveyState$,
      this.fromGlobal$,
      this.pageState$,
      (ns, returning, surveyState, fromGlobal, pageState) => {
        return {
          sections: ns,
          isReturning: !!returning,
          returningEmail: returning,
          isComplete: surveyState.isComplete,
          hasStarted: surveyState.hasResponse,
          loginLinkRequestFailed:
            fromGlobal.loginLink.receivedResponse &&
            !fromGlobal.loginLink.success,
          hasPreviousResponses: fromGlobal.hasPreviousResponses,
          progressPercentage: 0, // TODO: remove from models
          page: pageState
        };
      }
    ));

  constructor(
    private store: Store<AppState>,
    private questionSelectors: QuestionSelectorsService
  ) {}
}
