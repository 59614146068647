import { SharedModule } from '../shared/shared.module';
import { AnswerDispatcherService } from './answer-dispatcher.service';
import { AnswersViewComponent } from './answers-view/answers-view.component';
import { MultipleChoiceQuestionComponent } from './multiple-choice-question/multiple-choice-question.component';
import { MultipleSelectionQuestionComponent } from './multiple-selection-question/multiple-selection-question.component';
import { NumberQuestionComponent } from './number-question/number-question.component';
import { QuestionValidatorComponent } from './question-validator/question-validator.component';
import { QuestionComponent } from './question/question.component';
import { SkipButtonComponent } from './skip-button/skip-button.component';
import { TextQuestionComponent } from './text-question/text-question.component';
import { YourOrganisationComponent } from './your-organisation/your-organisation.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SurveyPageComponent } from './survey-page/survey-page.component';
import { QuestionResultsComponent } from './question-results/question-results.component';
import { SurveySelectorsService } from '../your-organisation/survey-selectors.service';
import { QuestionSelectorsService } from '../your-organisation/question-selectors.service';
import { AnswersViewSelectorsService } from '../your-organisation/answers-view-selectors.service';
import { NumberMaskService } from '../your-organisation/number-mask.service';
import { QuestionResultsService } from '../your-organisation/question-results.service';
import { CompareButtonComponent } from './compare-button/compare-button.component';
import { ResponseValueService } from '../your-organisation/response-value.service';
import { YearMonthQuestionComponent } from './year-month-question/year-month-question.component';
import { QuestionStatusComponent } from './question-status/question-status.component';
import { ResponseValidatorService } from '../../common/questions/response-validator.service';
import { AppLogger } from '../app-logger';
import { QuestionVisibilityConditionService } from '../../common/questions/question-visibility-condition.service';
import { QuestionVisibilityService } from '../../common/questions/question-visibility.service';
import { EffectsModule } from '@ngrx/effects';
import { YourOrganisationEffects } from './your-organisation.effects';
import { SurveyUpdateComponent } from './survey-update/survey-update.component';
import { OldResponseComponent } from './old-response/old-response.component';
import { UsersModule } from '../users/users.module';
import { SurveyOfflineComponent } from './survey-offline/survey-offline.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([YourOrganisationEffects]),
    UsersModule
  ],
  declarations: [
    QuestionComponent,
    TextQuestionComponent,
    NumberQuestionComponent,
    MultipleChoiceQuestionComponent,
    MultipleSelectionQuestionComponent,
    QuestionValidatorComponent,
    SkipButtonComponent,
    AnswersViewComponent,
    YourOrganisationComponent,
    SurveyPageComponent,
    QuestionResultsComponent,
    CompareButtonComponent,
    YearMonthQuestionComponent,
    QuestionStatusComponent,
    SurveyUpdateComponent,
    OldResponseComponent,
    SurveyOfflineComponent
  ],
  providers: [
    AnswerDispatcherService,
    SurveySelectorsService,
    QuestionSelectorsService,
    AnswersViewSelectorsService,
    NumberMaskService,
    QuestionResultsService,
    ResponseValueService,
    {
      provide: ResponseValidatorService,
      useClass: ResponseValidatorService,
      deps: [AppLogger]
    },
    {
      provide: QuestionVisibilityConditionService,
      useClass: QuestionVisibilityConditionService,
      deps: []
    },
    {
      provide: QuestionVisibilityService,
      useClass: QuestionVisibilityService,
      deps: [QuestionVisibilityConditionService]
    }
  ],
  exports: [SurveyPageComponent, QuestionComponent]
})
export class YourOrganisationModule {}
