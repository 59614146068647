
import {combineLatest} from 'rxjs/operators';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import {
  IOpportunities,
  IConnectionInfo
} from '../../../common/opportunities.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.models';
import { Subscription } from 'rxjs';
import { OpportunitiesSetFilter } from '../opportunities.actions';
import {
  QuestionOption,
  IOptions
} from '../../../common/questions/question.models';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

export interface IConnectionsCancelPending {
  orgId: string;
}

export interface IConnectionsStateConnectionInfo extends IConnectionInfo {
  rank?: number;
}

export interface IConnectionsState extends IOpportunities {
  opportunities: IConnectionsStateConnectionInfo[];
}

@Component({
  selector: 'app-connections',
  templateUrl: 'connections.component.html',
  styleUrls: ['connections.component.sass']
})
export class ConnectionsComponent implements OnDestroy, OnChanges {
  @Input() state: IConnectionsState;

  showFilter = false;

  allFilterSupporterTypeOptions: string[] = [];
  filterSupporterTypeOptions: string[] = [];
  filterSupporterType?: string;

  private subscription: Subscription;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {
    this.subscription = store
      .select(s => s.data.questions).pipe(
      combineLatest(route.params, (questions, params) => {
        if (questions.loading === 'loaded') {
          const kind = _(questions.byId)
            .values()
            .filter(q => q.key === 'QKindOfHelper')
            .first();
          this.allFilterSupporterTypeOptions = (kind as IOptions).options
            .filter(o => !o.showBox)
            .map(o => o.text)
            .sort();
          this.filterFilterOptions();
          // this.filterSupporterTypeOptions = this.allFilterSupporterTypeOptions;
        }
        this.filterSupporterType = undefined;
        if (params.filter) {
          this.filterSupporterType = params.filter;
        }
      }))
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.state) {
      this.filterFilterOptions();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private filterFilterOptions() {
    if (!this.state) {
      return;
    }
    const existingTypes = _(this.state.opportunities)
      .map(o => o.helperType)
      .uniq()
      .value();
    this.filterSupporterTypeOptions = this.allFilterSupporterTypeOptions.filter(
      o => existingTypes.indexOf(o) > -1
    );
  }

  get hasConnections() {
    return this.state && this.state.connections.length > 0;
  }

  get hasOpportunities() {
    return this.state && this.state.opportunities.length > 0;
  }

  get noResults() {
    return !this.hasConnections && !this.hasOpportunities;
  }

  onFilterClick() {
    this.showFilter = !this.showFilter;
  }

  onFilterSelect() {
    this.showFilter = false;
  }

  onSelectFilterSupporterType(option?: string) {
    // if (!option) {
    //   this.filterSupporterType = undefined;
    // } else {
    //   this.filterSupporterType = option;
    // }
    this.store.dispatch(new OpportunitiesSetFilter(option ? option : null));
    // this.router
  }
}
