import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from '../home/home.component';
import { HomeRedirectService } from '../home/home-redirect.service';
import { YourOrganisationModule } from '../your-organisation/your-organisation.module';

@NgModule({
  imports: [CommonModule, SharedModule, YourOrganisationModule],
  declarations: [HomeComponent],
  providers: [HomeRedirectService]
})
export class HomeModule {}
