<div class="question-area"
     [ngClass]="{ 'old-response': showOldResponse }">

  <p class="helper-text"
     *ngIf="editing && helperText"
     [innerHTML]="helperText"></p>

  <p class="previously-text old-response-non-mobile" style="text-align: left"
     *ngIf="showOldResponse">Your previous response:</p>

  <app-old-response class="old-response-non-mobile"
                    *ngIf="showOldResponse"
                    [state]="state"></app-old-response>

  <button class="transfer-button old-response-non-mobile"
          *ngIf="showOldResponse"
          (click)="transferOldResponse(state)"
          [disabled]="!state.oldResponse || !state.oldResponse.replacementResponse">
    <i class="fa fa-2x fa-arrow-circle-left"
       aria-hidden="true"></i>
  </button>

  <div class="old-response-mobile"
       (click)="transferOldResponse(state)"
       *ngIf="showOldResponse">
    <p class="previously-text">Your previous response:</p>

    <app-old-response [state]="state"></app-old-response>

    <i class="fa fa-lg fa-arrow-circle-down"
       aria-hidden="true"></i>
  </div>

  <ng-template [ngIf]="editing">
    <div [ngSwitch]="questionType"
         class="question">

      <app-text-question *ngSwitchCase="'T'"
                         [question]="state.question"
                         [response]="state.response">
      </app-text-question>

      <app-number-question *ngSwitchCase="'N'"
                           [question]="state.question"
                           [response]="state.response">
      </app-number-question>

      <app-multiple-choice-question *ngSwitchCase="'MC'"
                                    [question]="state.question"
                                    [response]="state.response"
                                    [newOptions]="showOldResponse && state.oldResponse!.newOptionIds || []">
      </app-multiple-choice-question>

      <app-multiple-selection-question *ngSwitchCase="'MS'"
                                       [question]="state.question"
                                       [response]="state.response"
                                       [newOptions]="showOldResponse && state.oldResponse!.newOptionIds || []">
      </app-multiple-selection-question>

      <!--<div *ngSwitchCase="'QFinal'">
      <button class="action primary" (click)="completeSurvey()">Visit My Dashboard</button>
    </div>-->

      <app-year-month-question *ngSwitchCase="'TYearMonth'"
                               [question]="state.question"
                               [response]="state.response">
      </app-year-month-question>

      <app-question-validator [question]="state.question"
                              [response]="state.response"
                              [validationState]="state.isValid"></app-question-validator>

      <app-skip-button [question]="state.question"
                       [response]="state.response"
                       (onSkip)="skip()"></app-skip-button>


    </div>
  </ng-template>

</div>


<div *ngIf="!editing && !hideResponse">
  {{responseValue}}
</div>

<div class="status"
     *ngIf="(showSaving || state.errors) && savingFail">
  <div class="saving"
       *ngIf="showSaving || state.errors"
       [ngClass]="{success: savingSuccess, fail: savingFail}">
    <ng-container [ngSwitch]="state.status">
      <!--<ng-container *ngSwitchCase="'saved'"><i class="fa fa-check-circle" aria-hidden="true"></i></ng-container>-->
      <ng-container *ngSwitchCase="'failed'">
        <ng-container *ngIf="this.state.errors; else failedElse">Failed:</ng-container>
        <ng-template #failedElse>Failed.</ng-template>
      </ng-container>
      <!-- <ng-container *ngSwitchCase="'error'">
        Error.
      </ng-container> -->
      <!--<ng-container *ngSwitchDefault>
        &nbsp;
      </ng-container>-->
    </ng-container>
    <ng-container *ngIf="state.errors">
      <span *ngFor="let error of state.errors">{{error}}</span>
    </ng-container>
  </div>
</div>
