<ng-container *ngIf="state$ | async as state">
  <div class="criterion"
       *ngFor="let c of state.criteria; index as i">

    <h3>
      Criteria #{{ i + 1 }}
    </h3>
    <strong>"{{ c.questionText }}"</strong>
    <br>
    <span>Matching {{ c.operatorText }}</span>
    <ng-container *ngIf="c.maxAgeMonths">
      <br>
      <em>and the startup has updated their answer to this question within the last {{ c.maxAgeMonths }} months</em>
    </ng-container>

  </div>
</ng-container>
