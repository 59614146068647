import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy
} from '@angular/core';
import { IValidatable } from '../../../common/questions/question.models';
import { QuestionResponse } from '../../../common/questions/response.model';
import { ResponseValidationResult } from 'common/questions/response-validator.service';

@Component({
  selector: 'app-question-validator',
  templateUrl: './question-validator.component.html',
  styleUrls: ['./question-validator.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionValidatorComponent implements OnChanges {
  @Input() question: IValidatable;
  @Input() response: QuestionResponse;
  @Input() validationState: ResponseValidationResult;

  private pristine = true;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.pristine) {
      this.pristineCheck(changes, 'response');
      this.pristineCheck(changes, 'validationState');
    }
  }

  get hasResponse() {
    return (
      this.response &&
      ((this.response.type === 'value' && this.response.value) ||
        this.response.type === 'skipped' ||
        (this.response.type === 'choice' && this.response.choice) ||
        (this.response.type === 'selection' &&
          this.response.choices &&
          this.response.choices.length > 0))
    );
  }

  get isValid() {
    return (
      (this.pristine && !this.hasResponse) ||
      !this.validationState ||
      this.validationState.isValid
    );
  }

  private pristineCheck(
    changes: SimpleChanges,
    fieldToCheck: keyof SimpleChanges
  ) {
    const responseChange = changes[fieldToCheck];
    if (responseChange && !responseChange.isFirstChange()) {
      if (responseChange.currentValue !== responseChange.previousValue) {
        this.pristine = false;
      }
    }
  }
}
