import { UpdateQuestionAnswer } from './your-organisation.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Question, QuestionKey } from '../../common/questions/question.models';
import { QuestionResponse } from '../../common/questions/response.model';
import { AppState } from '../app.models';
import { SavingHandlerService } from '../shared/saving-handler.service';
import {
  QuestionResponseUpdate,
  NextQuestion,
  QuestionResponseValid
} from '../data/question.actions';
import { ResponseValidationResult } from 'common/questions/response-validator.service';
// import { QuestionsActionTypes } from '../questions/questions.actions';

@Injectable()
export class AnswerDispatcherService {
  constructor(
    private store: Store<AppState>,
    private savingHandler: SavingHandlerService
  ) {}

  answer(question: Question, response: QuestionResponse) {
    this.store.dispatch(
      new QuestionResponseUpdate({ questionId: question.id, response })
    );
  }

  nextQuestion(fromQuestionKey: QuestionKey) {
    this.store.dispatch(new NextQuestion({ fromQuestionKey }));
  }

  setValidity(question: Question, validity: ResponseValidationResult) {
    this.store.dispatch(new QuestionResponseValid({ [question.id]: validity }));
  }
}
