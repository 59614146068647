import * as timm from 'timm';
import { AppActions } from '../../app.actions';
import { ReportDownloadActionTypes } from '../../shared/report-download/report-download.actions';
import { IReportDownloadState } from '../../shared/report-download/report-download.models';

const defaultState: IReportDownloadState = {
  downloadKey: null,
  requestedReportId: null,
  isLoading: false,
  failedLoad: false
};

export function reportDownloadReducer(
  state: IReportDownloadState = defaultState,
  action: AppActions
) {
  switch (action.type) {
    case ReportDownloadActionTypes.PROVIDE_EMAIL:
      return timm.merge(state, {
        requestedReportId: action.payload.reportId,
        downloadKey: null,
        isLoading: true,
        failedLoad: false
      });
    case ReportDownloadActionTypes.SET_DOWNLOAD_KEY:
      return timm.merge(state, {
        downloadKey: action.payload,
        isLoading: false
      });
    case ReportDownloadActionTypes.PROVIDE_EMAIL_FAILED:
      return timm.merge(state, {
        isLoading: false,
        failedLoad: true
      });
  }
  return state;
}
