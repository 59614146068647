import { Injectable } from '@angular/core';
import { Question } from '../../common/questions/question.models';
import { QuestionResponse } from '../../common/questions/response.model';
import { IQuestionResults } from '../../common/questions/question-results.models';
import { Store } from '@ngrx/store';
import { AppState } from '../app.models';
import { ISurveyStateResult } from '../your-organisation/your-organisation.models';

@Injectable()
export class QuestionResultsService {
  constructor(private store: Store<AppState>) {}

  mapResults(
    question: Question,
    results: IQuestionResults,
    responseMatches: string[]
  ): ISurveyStateResult[] {
    return results.results.map(r => {
      let matchText = r.match;
      switch (question.type) {
        case 'MC':
        case 'MS':
          const option = question.options.find(o => o.optionId === r.match);
          if (option) {
            matchText = option.text;
          }
      }
      return {
        text: matchText,
        percentage: r.percentage,
        selected: responseMatches.indexOf(r.match) > -1
      };
    });
  }

  mapResultsAndResponseMatches(
    question: Question,
    results: IQuestionResults,
    response: QuestionResponse
  ) {
    const responseMatches = this.responseToResultMatches(question, response);
    return this.mapResults(question, results, responseMatches);
  }

  responseToResultMatches(
    question: Question,
    response: QuestionResponse
  ): string[] {
    switch (question.type) {
      case 'N':
        if (response.type === 'value' && question.results) {
          const value = parseInt(response.value, 10);
          const match = question.results.find(r => {
            return (
              r.match.findIndex(([from, to]) => value >= from && value < to) >
              -1
            );
          });
          if (match) {
            return [match.text];
          }
        }
        break;
      case 'MC':
        if (response.type === 'choice') {
          return [response.choice];
        }
        break;
      case 'MS':
        if (response.type === 'selection') {
          return response.choices;
        }
        break;
    }
    return [];
  }
}
