<h2>Create opportunity profile</h2>

<p>
  <label for="profile-name">Name your profile:</label>
  <input class="profile-name"
         type="text"
         name="profile-name"
         [ngModel]="state.name"
         (change)="onChangeProfileName($event)">
</p>

<app-crunch-filter *ngFor="let filter of state.filters; trackBy: filterTrackBy"
                   [filter]="filter"
                   (editQuestion)="onEditQuestion(filter)"
                   (remove)="onRemove(filter)"
                   (changeOperator)="onChangeFilterOperator(filter, $event)"
                   (changeTags)="onChangeFilterTags(filter, $event)"
                   (changeBetweenFrom)="onChangeBetweenFrom(filter, $event)"
                   (changeBetweenTo)="onChangeBetweenTo(filter, $event)"
                   (changeProximity)="onChangeFilterProximity(filter, $event)">
</app-crunch-filter>

<div class="bottom-actions">

  <button class="add-button primary"
          (click)="onClickAdd()">
    <i class="fa fa-plus-circle"
       aria-hidden="true"></i> Add {{ state.filters.length > 0 ? 'another' : ''
    }}
  </button>

  <button class="cancel-button"
          *ngIf="showCancelButton"
          (click)="onClickCancel()">Cancel</button>

  <button class="save-button"
          [disabled]="saveDisabled"
          (click)="onClickSave()">Save</button>

</div>

<i *ngIf="state.savingState === 'saving'"
   class="status-saving fa fa-lg fa-refresh fa-spin"
   aria-hidden="true"></i>

<div class="validation"
     *ngIf="state.validity === 'tooWide'">
  <strong>Your currently selected criteria matches too many startups.</strong> Our
  goal is to provide meaningful connections to startups, and as such we require you
  to continue adding criteria for a more precise match.
  <br> Either continue adding criteria to narrow your search, or go back and adjust
  some of the matching options you have selected previously.
</div>

<div class="validation"
     *ngIf="state.validity === 'tooNarrow'">
  <strong>Your currently selected criteria matches less than 1% of startups.</strong>
  You may wish to consider widening your criteria slightly in order to get more matches.
</div>

<!-- <div *ngFor="let section of editState.questionOptions">
  {{ section.sectionTitle }}
</div> -->

<app-modal-embedded (close)="onCloseEdit()"
                    *ngIf="questionSelect.showing"
                    [noPad]="true">
  <app-staged-select-crunch [optionGroups]="state.sections"
                            [selectedOptionId]="questionSelect.questionId"
                            (selectOptionId)="onSelectQuestionId($event)"></app-staged-select-crunch>
</app-modal-embedded>
