<div style="width: 100%;text-align: center">Loading ...</div>
<!--
<div class="pageheader">
  <h1>Reports</h1>
</div>

<div class="pagecontent">

  <div class="report report-2018"
       (click)="downloadReport('2018')">
    <div class="corner-ribbon-host">

      <img src="/assets/images/reports/2018.jpg"
           width="400"
           alt="Startup Muster 2018 Report">
    </div>
  </div>

  <p>
    Startup Muster provides an annual snapshot of the Australian startup ecosystem. Each
    report is a summary of the key insights and findings from our annual national
    survey of startups, people thinking of founding a startup and businesses that
    support startups. Our aim is to showcase the progress, challenges and opportunities
    of the community to demonstrate and accelerate impact.
  </p>

  <button class="action primary"
          (click)="downloadReport('2018')">Read the report</button>

  <h3>Previous Reports</h3>

  <div class="report-list">



    <div class="report"
         (click)="downloadReport('2015')">
      <img src="/assets/images/reports/2015.jpg"
           width="256"
           alt="2015">
      <span class="nowrap">Startup Muster 2015</span>
    </div>

    <div class="report"
         (click)="downloadReport('2016')">
      <img src="/assets/images/reports/2016.jpg"
           width="256"
           alt="2016">
      <span class="nowrap">Startup Muster 2016</span>
    </div>

    <div class="report"
         (click)="downloadReport('2017')">
      <img src="/assets/images/reports/2017.jpg"
           width="256"
           alt="2017">
      <span class="nowrap">Startup Muster 2017</span>
    </div>

  </div>


  <div>
    GOOGLE UTS MYOB
  </div>

</div>-->
