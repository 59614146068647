import { combineReducers } from '@ngrx/store';
import { AppReducer } from '../app.reducer';
import {
  IDataState,
  IDataCollection,
  LoadingState,
  DataSavingState,
  IDataSavingState,
  IDataDirtyState,
  IDataErrorsState
} from './data.models';
import { ICombinedCriteriaSet } from '../../common/criteria/criteria.models';
import {
  isDataAction,
  DataActions,
  DataActionTypes,
  DataSetItem,
  DataUpdateRelation
} from '../data/data.actions';
import { ICollectable } from '../../common/core/collection';
import * as timm from 'timm';
import * as _ from 'lodash';
import { Question } from '../../common/questions/question.models';
import { Section } from '../../common/questions/section.models';
import {
  QuestionResponse,
  QuestionResponseUpdateStatus,
  OldResponse
} from '../../common/questions/response.model';
import { IQuestionResults } from '../../common/questions/question-results.models';
import { ICriteriaSetValidityResult } from '../../common/criteria/criteria.models';

type DataReducer<T> = (state: T, action: DataActions) => T;

const idsSetItemReducer: AppReducer<string[]> = (
  state,
  action: DataSetItem
) => {
  const id = action.payload.itemId;
  const currentIndex = state.indexOf(id);
  if (action.payload.itemData) {
    if (currentIndex === -1) {
      state = timm.addLast(state, id);
    }
  } else {
    if (currentIndex > -1) {
      state = timm.removeAt(state, currentIndex);
    }
  }
  return state;
};

const idsReducer: AppReducer<string[]> = (state, action) => {
  switch (action.type) {
    case DataActionTypes.DATA_LOADING:
      return [];
    case DataActionTypes.DATA_LOADED:
      return action.payload.data.map((item: any) => item._id);
    case DataActionTypes.DATA_SET_ITEM:
      state = idsSetItemReducer(state, action);
      break;
  }
  return state;
};

const byIdSetItemReducer: AppReducer<_.Dictionary<any>> = (
  state,
  action: DataSetItem
) => {
  const id = action.payload.itemId;
  const data = action.payload.itemData;
  if (data) {
    state = timm.set(state, id, data);
  } else {
    state = timm.omit(state, id);
  }
  return state;
};

const updateRelationIds: AppReducer<string[]> = (
  state,
  action: DataUpdateRelation
) => {
  return state;
};

const updateRelationReducer: AppReducer<any> = (
  state,
  action: DataUpdateRelation
) => {
  const { idArrayKey, childAction, childId } = action.payload;
  const ids = state[idArrayKey];
  const childIndex = ids.indexOf(childId);
  if (childAction === 'adding') {
    if (childIndex === -1) {
      state = timm.set(state, idArrayKey, timm.addLast(ids, childId));
    }
  } else if (childIndex > -1) {
    state = timm.set(state, idArrayKey, timm.removeAt(ids, childIndex));
  }
  return state;
};

const byIdReducer: AppReducer<_.Dictionary<ICollectable>> = (state, action) => {
  switch (action.type) {
    case DataActionTypes.DATA_LOADING:
      return {};
    case DataActionTypes.DATA_LOADED:
      return _.keyBy(
        action.payload.data,
        (thisItem: ICollectable) => thisItem._id
      );
    case DataActionTypes.DATA_UPDATE_RELATION:
      const item = state[action.payload.parentId];
      return timm.set(
        state,
        action.payload.parentId,
        updateRelationReducer(item, action)
      );
    case DataActionTypes.DATA_SET_ITEM:
      return byIdSetItemReducer(state, action);
  }
  return state;
};

const loadingStateReducer: AppReducer<LoadingState> = (
  state = 'unloaded',
  action
) => {
  switch (action.type) {
    case DataActionTypes.DATA_LOADING:
      return 'loading';
    case DataActionTypes.DATA_LOADED:
      return 'loaded';
    case DataActionTypes.DATA_LOAD_FAILED:
      return 'failed';
  }
  return state;
};

const savingStateReducer: AppReducer<DataSavingState> = (state, action) => {
  switch (action.type) {
    case DataActionTypes.DATA_SAVING:
      return 'saving';
    case DataActionTypes.DATA_SAVED:
      return 'saved';
    case DataActionTypes.DATA_SAVE_ERROR:
      return 'error';
    case DataActionTypes.DATA_SAVE_FAILED:
      return 'failed';
  }
  return state;
};

const savingStateByIdReducer: AppReducer<IDataSavingState> = (
  state = {},
  action
) => {
  switch (action.type) {
    case DataActionTypes.DATA_LOADING:
      state = {};
      break;
    case DataActionTypes.DATA_SAVING:
    case DataActionTypes.DATA_SAVED:
    case DataActionTypes.DATA_SAVE_FAILED:
    case DataActionTypes.DATA_SAVE_ERROR:
      const id = action.payload.itemId;
      state = timm.set(state, id, savingStateReducer(state[id], action));
      break;
    case DataActionTypes.DATA_SET_ITEM:
      // if (!action.payload.itemData) {
      state = timm.omit(state, action.payload.itemId);
      // }
      // TODO: think about what else we might want to do in this case
      break;
  }
  return state;
};

const dirtyStateByIdReducer: AppReducer<IDataDirtyState> = (
  state = {},
  action
) => {
  switch (action.type) {
    case DataActionTypes.DATA_LOADING:
      state = {};
      break;
    case DataActionTypes.DATA_SET_ITEM:
      state = timm.set(state, action.payload.itemId, true);
      break;
    case DataActionTypes.DATA_SAVED:
      state = timm.set(state, action.payload.itemId, false);
      break;
  }
  return state;
};

const errorsStateByIdReducer: AppReducer<IDataErrorsState> = (
  state = {},
  action
) => {
  switch (action.type) {
    case DataActionTypes.DATA_LOADING:
      state = {};
      break;
    case DataActionTypes.DATA_SET_ITEM:
      state = timm.omit(state, action.payload.itemId);
      break;
    case DataActionTypes.DATA_SAVE_FAILED:
      state = timm.set(state, action.payload.itemId, action.payload.errors);
      break;
  }
  return state;
};

const collectionReducer = combineReducers({
  byId: byIdReducer,
  ids: idsReducer,
  loading: loadingStateReducer,
  saving: savingStateByIdReducer,
  dirty: dirtyStateByIdReducer,
  errors: errorsStateByIdReducer
});

function empty<T>(): IDataCollection<T & ICollectable> {
  return {
    byId: {},
    ids: [],
    loading: 'unloaded',
    saving: {},
    dirty: {},
    errors: {}
  };
}

export const dataReducer: AppReducer<IDataState> = (state, action) => {
  if (state === undefined) {
    state = {
      combinedCriteriaSets: empty<ICombinedCriteriaSet>(),
      questions: empty<Question>(),
      sections: empty<Section>(),
      responses: empty<QuestionResponse>(),
      oldResponses: empty<OldResponse>(),
      questionResults: empty<IQuestionResults>(),
      lockedQuestionIds: empty<{}>(),
      criteriaSetValidity: empty<ICriteriaSetValidityResult>(),
      responseUpdates: empty<QuestionResponseUpdateStatus>()
    };
  }
  if (isDataAction(action)) {
    const collectionKey = action.payload.collection;
    const newCollection = collectionReducer(state[collectionKey], action);
    state = timm.set(state, collectionKey, newCollection);
  }
  return state;
};
