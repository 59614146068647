import { Action } from '@ngrx/store';
import { QuestionId } from '../../../common/questions/question.models';
import {
  Filter,
  ICriteriaDefineCrunchState
} from './criteria-define-crunch.models';
import { CriteriaRemove } from '../criteria.actions';

export enum CriteriaDefineCrunchActionTypes {
  ADD_FILTER = '[Criteria Define Crunch] Add Filter',
  REMOVE_FILTER = '[Criteria Define Crunch] Remove Filter',
  SET_FILTER_VALUE = '[Criteria Define Crunch] Set Filter Value',
  LOAD_CRITERIA_SET = '[Criteria Define Crunch] Load Criteria Set',
  SET_STATE = '[Criteria Define Crunch] Set State',
  SET_NAME = '[Criteria Define Crunch] Set Name',
  SAVE = '[Criteria Define Crunch] Save',
  CANCEL = '[Criteria Define Crunch] Cancel'
  // SHOW_QUESTION_SELECTOR = '[Criteria Define Crunch] Show Question Selection',
  // SELECT_QUESTION = '[Criteria Define Crunch] Select Question'
}

export class AddFilter implements Action {
  readonly type = CriteriaDefineCrunchActionTypes.ADD_FILTER;

  constructor(public payload: { id: string; questionId: QuestionId }) {}
}

export class RemoveFilter implements Action {
  readonly type = CriteriaDefineCrunchActionTypes.REMOVE_FILTER;

  constructor(public payload: { id: string }) {}
}

export class SetFilterValue implements Action {
  readonly type = CriteriaDefineCrunchActionTypes.SET_FILTER_VALUE;

  constructor(
    public payload: { id: string; field: keyof Filter; value: any }
  ) {}
}

export class LoadCriteriaSet implements Action {
  readonly type = CriteriaDefineCrunchActionTypes.LOAD_CRITERIA_SET;

  constructor(public payload: { setId: string }) {}
}

export class SetState implements Action {
  readonly type = CriteriaDefineCrunchActionTypes.SET_STATE;

  constructor(public payload: { state: ICriteriaDefineCrunchState }) {}
}

export class SetName implements Action {
  readonly type = CriteriaDefineCrunchActionTypes.SET_NAME;
  constructor(public payload: { setId: string; name: string }) {}
}

export class Save implements Action {
  readonly type = CriteriaDefineCrunchActionTypes.SAVE;
}

export class Cancel implements Action {
  readonly type = CriteriaDefineCrunchActionTypes.CANCEL;
}

// export class ShowQuestionSelector implements Action {
//   readonly type = CriteriaDefineCrunchActionTypes.SHOW_QUESTION_SELECTOR;
//   constructor(public payload: { questionId?: QuestionId }) {}
// }

// export class SelectQuestion implements Action {
//   readonly type = CriteriaDefineCrunchActionTypes.SELECT_QUESTION;
//   constructor(public payload: { questionId?: QuestionId }) {}
// }

export type CriteriaDefineCrunchActions =
  | AddFilter
  | RemoveFilter
  | SetFilterValue
  | LoadCriteriaSet
  | SetState
  | SetName
  | Save
  | Cancel;
// | ShowQuestionSelector
// | SelectQuestion;
