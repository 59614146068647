import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppLogger } from './app-logger';
import { Observable } from 'rxjs';

@Injectable()
export class EffectLoggerService {
  constructor(private logger: AppLogger) {}

  handleErrors = <T, U>(
    inner$: (innerSource$: Observable<T>) => Observable<U>
  ) => (source$: Observable<T>): Observable<U> => {
    const result$: Observable<U> = inner$(source$).pipe(
      catchError((err, caught) => {
        this.logger.error(err);
        return result$;
      })
    );
    return result$;
    // return inner$(source$).catch(err => {
    //   this.logger.error(err);
    //   return Observable.empty();
    //  });
    // return source$.mergeMap(value => {
    //   return Observable.of(value)
    //     .let(inner$)
    //     .catch((err) => {
    //       this.logger.error(err);
    //       return Observable.empty();
    //     })
    //     .onErrorResumeNext();
    // });
  };
}
