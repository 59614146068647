import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  AfterViewInit, OnInit
} from '@angular/core';
import {
  MultipleSelectionQuestion,
  HasQuestionId
} from '../../../common/questions/question.models';
import { AnswerDispatcherService } from '../answer-dispatcher.service';
import {
  SelectionResponse,
  SkippedResponse
} from '../../../common/questions/response.model';
import { BaseQuestionComponent } from '../base-question.component';
import { set } from 'timm';
import { IGridSelectOption } from '../../shared/grid-select/grid-select.component';

@Component({
  selector: 'app-multiple-selection-question',
  templateUrl: './multiple-selection-question.component.html',
  styleUrls: ['./multiple-selection-question.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleSelectionQuestionComponent extends BaseQuestionComponent
  implements OnChanges, OnInit {
  @Input() question: MultipleSelectionQuestion & HasQuestionId;
  @Input() response?: SelectionResponse | SkippedResponse;
  @Input() newOptions: string[] = [];

  extra: string;
  @ViewChild('extraElement') extraElement: ElementRef;
  private extraFirstShown = true;

  selections: { [optionId: string]: boolean } = {};

  constructor(private answerDispatcher: AnswerDispatcherService) {
    super();
  }

  ngOnInit(): void {

    if (this.response ) {
        const resAny: any = (JSON.parse(JSON.stringify(this.response)) as any);
        const choices: any[] = [];
      for (let i = 0; i < resAny.choices.length; i++) {
        const element = this.question.options.findIndex((item: any) => item.optionId === resAny.choices[i]);
        if (element !== -1) {
          choices.push(resAny.choices[i]);
        }
      }
      resAny["choices"] = choices;
      this.response = resAny as SelectionResponse | SkippedResponse;
      this.answerDispatcher.answer(this.question, this.response);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selections = {};
    this.extra = '';
    if (this.response && this.response.type === 'selection') {
      this.extra = this.response.extra;
      if (this.response.choices) {
        for (const choice of this.response.choices) {
          this.selections[choice] = true;
        }
      }
    }

    if (this.showExtraBox) {
      if (this.extraFirstShown) {
        this.extraFirstShown = false;
        if (this.extraElement && this.extraElement.nativeElement) {
          const el = this.extraElement.nativeElement;
          setTimeout(() => el.focus(), 0);
        }
      }
    } else {
      this.extraFirstShown = true;
    }
  }

  selectChanged() {
    const choices: string[] = [];
    for (const optionId of Object.keys(this.selections)) {
      if (this.selections[optionId]) {
        choices.push(optionId);
      }
    }
    this.answer(choices);
  }

  onExtraChanged($event: Event) {
    $event.stopPropagation();
    this.selectChanged();
  }

  answer(choices: string[]) {
    const response: SelectionResponse = {
      questionId: this.question.id,
      questionKey: this.question.key,
      type: 'selection',
      choices: choices,
      extra: this.extra,
      updatedAt: new Date()
    };
    this.answerDispatcher.answer(this.question, response);
  }

  get showExtraBox() {
    if (this.response && this.response.type === 'selection') {
      const r = this.response;
      return this.question.options
        .filter(o => r.choices.some(c => o.optionId === c))
        .some(o => !!o.showBox);
    }
    return false;
  }

  get selectOptions(): IGridSelectOption[] {
    return this.question.options.map(o => {
      let icon;
      if (this.newOptions.indexOf(o.optionId) > -1) {
        icon = 'new';
      }
      return { id: o.optionId, text: o.text, icon };
    });
  }

  get selectStyle() {
    return this.question.options.length > 5 ? 'grid' : 'column';
  }

  onSelect(id: string) {
    this.selections = set(this.selections, id, !this.selections[id]);
    this.selectChanged();
  }

  // searchSelectComplete() {
  //   this.selectChanged();
  // }
}
