<div class="top-actions"
     *ngIf="hasUpdates">
  <button class="action primary"
          (click)="moveToFirstNext()">
    Go to first flagged question
  </button>
</div>

<app-modal-embedded *ngIf="doneUpdatingCriticals"
                    (close)="updateDone()"
                    class="thanks">

  <h3>You’re nearly there!</h3>

  <p>Why not check a couple of more responses so that your profile is 100% up-to-date and you can be sure that the businesses
    in
    <a routerLink="/recommended-support">Recommended Connections</a> really can help a startup like yours.
  </p>

  <!-- <p>
      <strong>To ensure even more accuracy, we suggest continuing through to checking over and updating the rest of your responses.</strong>
      Alternatively, you can continue on to
       to see your latest matches.
    </p>
 -->
  <div class="update-more-actions">
    <button class="action"
            (click)="updateDone()">Nope, not right now</button>
    <button class="action primary"
            (click)="updateMore()">YES please</button>
  </div>
</app-modal-embedded>

<app-modal-embedded *ngIf="doneUpdatingRest"
                    (close)="updateDone()"
                    class="thanks">

  <h3>
    You’re done!
  </h3>
  <p>
    Thank you for taking part.
    <br> Now you’re completely up-to-date, why not head over to
    <a routerLink="/recommended-support">‘Recommended Connections’</a> to see what businesses want to help your startup succeed.
  </p>

  <!-- <p>Now that you're all up-to-date, you can head on over to your
    <a routerLink="/recommended-support">Recommended Connections</a> to see your latest matches.
  </p> -->

</app-modal-embedded>

<div class="section"
     *ngFor="let section of state.sections; trackBy: trackBySectionId"
     [ngClass]="{selected: isSectionSelected(section.id)}">

  <div class="section-header"
       (click)="onClickSection(section.id)">

    <app-toggle-icon [expanded]="isSectionSelected(section.id)"></app-toggle-icon>

    <h2>{{ section.title }}</h2>

    <app-question-status [status]="section.needsUpdate ? 'need-update' : undefined"></app-question-status>
  </div>

  <div class="question-list"
       *ngIf="isSectionSelected(section.id)">
    <div class="question"
         *ngFor="let qr of section.questionResponses; trackBy: trackByQuestionId"
         [ngClass]="{'selectable': !editing(qr.question.id), 'editing': editing(qr.question.id)}"
         (click)="onClickQuestion($event, qr.question.id)">

      <div class="question-body"
           [id]="'question-' + qr.question.key">

        <h3 [appRewriteLinks]="qr.question.text"></h3>

        <app-question [state]="qr"
                      [editing]="editing(qr.question.id)">
        </app-question>
      </div>

      <div class="question-actions">
        <app-question-status [status]="qr.status"></app-question-status>

        <div class="floating-action">

          <button *ngIf="editing(qr.question.id)"
                  [disabled]="!qr.isValid"
                  (click)="save($event, qr)"
                  class="action save-button">
            {{ saveButtonText(qr) }}
          </button>

        </div>
      </div>

    </div>
  </div>
</div>
