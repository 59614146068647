import {
  Question,
  TextQuestion,
  NumberQuestion,
  MultipleChoiceQuestion,
  MultipleSelectionQuestion,
  QuestionWithoutId
} from './question.models';
import {
  QuestionResponse,
  ChoiceResponse,
  SelectionResponse,
  ValueResponse
} from './response.model';
import { ILogger } from '../core/logger';

export type ResponseValidationResult =
  | { isValid: true }
  | { isValid: false; message: string };

const IS_VALID: ResponseValidationResult = { isValid: true };

export class ResponseValidatorService {
  constructor(private appLogger: ILogger) {}

  isValid(
    question: Question,
    response?: QuestionResponse
  ): ResponseValidationResult {
    if (!response) {
      return this.genericFailure(question);
    }
    if (response.type === 'skipped') {
      return IS_VALID;
    }
    switch (question.type) {
      case 'T':
      case 'N':
        return this.isValueQuestionValid(question, response);
      case 'MC':
        if (response.type !== 'choice') {
          this.appLogger.error(
            `Got a non-choice (${response.type}) for an MC question "${
              question.key
            }"`
          );
          return this.genericFailure(question);
        }
        if (this.valueIsEmpty(response.choice)) {
          return this.genericFailure(question);
        }
        return this.isOtherValid(question, response);
      case 'MS':
        if (response.type !== 'selection') {
          this.appLogger.error(
            `Got a non-selection (${response.type}) for an MS question "${
              question.key
            }"`
          );
          return this.genericFailure(question);
        }
        if (!response.choices || response.choices.length === 0) {
          return this.genericFailure(question);
        }
        return this.isOtherValid(question, response);
      case 'F':
        return IS_VALID;
    }
  }

  private isValueQuestionValid(
    question: TextQuestion | NumberQuestion,
    response: QuestionResponse
  ): ResponseValidationResult {
    if (response.type === 'skipped') {
      return IS_VALID;
    }
    if (response.type !== 'value') {
      this.appLogger.error(
        `Got a non-value (${response.type}) for a ${question.type} question "${
          question.key
        }"`
      );
      return this.genericFailure(question);
    }
    if (this.valueIsEmpty(response.value)) {
      return {
        isValid: false,
        message: question.validatorMsg || 'Please enter a response.'
      };
    }
    if (question.type === 'N' && !/^[-+.0-9%]+$/.test(response.value)) {
      return {
        isValid: false,
        message: question.validatorMsg || 'Please enter a valid number.'
      };
    }
    if (question.validator) {
      const cleanedValue = response.value.replace('\n', '');
      const isValid = new RegExp(question.validator, 'i').test(cleanedValue);
      if (isValid) {
        return IS_VALID;
      }
      return {
        isValid: false,
        message: question.validatorMsg || 'Please enter a valid response.'
      };
    }
    return IS_VALID;
  }

  private isOtherValid(
    question: MultipleChoiceQuestion | MultipleSelectionQuestion,
    response: ChoiceResponse | SelectionResponse
  ): ResponseValidationResult {
    if (response.type === 'choice') {
      const option = question.options.find(o => o.optionId === response.choice);
      if (!option) {
        this.appLogger.error(
          `Got a response option (${
            response.choice
          }) that does not exist on question ${question.key}.`
        );
        return this.genericFailure(question);
      }
      if (option.showBox) {
        if (this.valueIsEmpty(response.extra)) {
          return {
            isValid: false,
            message: `Please enter in a response for "${option.text}".`
          };
        }
      }
    } else {
      for (const choice of response.choices) {
        const option = question.options.find(o => o.optionId === choice);
        if (!option) {
          this.appLogger.error(
            `Response has a choice (${choice}) that doesn't exist in options for ${
              question.key
            }`
          );
          return this.genericFailure(question);
        }
        if (option.showBox) {
          if (this.valueIsEmpty(response.extra)) {
            return {
              isValid: false,
              message: `Please enter a response for "${option.text}".`
            };
          }
        }
      }

      if (question.type === 'MS' && question.optionValidator) {
        for (const vali of question.optionValidator) {
          const choiceA = response.choices.find(c => vali.a.indexOf(c) > -1);
          const choiceB = response.choices.find(c => vali.b.indexOf(c) > -1);
          if (choiceA && choiceB) {
            const choiceAText = question.options.find(
              o => o.optionId === choiceA
            )!.text;
            const choiceBText = question.options.find(
              o => o.optionId === choiceB
            )!.text;
            return {
              isValid: false,
              message:
                vali.message ||
                `You cannot select "${choiceAText}" with "${choiceBText}".`
            };
          }
        }
      }
    }
    return IS_VALID;
  }

  private valueIsEmpty(value: string) {
    return value === null || value === undefined || value.trim() === '';
  }

  private genericFailure(
    question: QuestionWithoutId
  ): ResponseValidationResult {
    return { isValid: false, message: this.genericFailureMessage(question) };
  }

  private genericFailureMessage(question: QuestionWithoutId): string {
    switch (question.type) {
      case 'T':
      case 'N':
        return 'Please enter a response.';
      case 'MS':
        return 'Please select at least one option.';
      case 'MC':
        return 'Please select an option.';
      case 'F':
        return '';
    }
  }
}
