
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.models';
import { DataSelectorsService } from '../data/data-selectors.service';
import * as rxUtil from '../../rx-util';
import { Observable } from 'rxjs';

@Injectable()
export class CriteriaSetSelectorsService {
  readonly isLoaded$ = this.dataSelectors.isLoaded$(
    'combinedCriteriaSets',
    'questions',
    'sections'
  );

  readonly sets$ = this.store.select(s => s.data.combinedCriteriaSets);
  readonly allSets$ = this.sets$.pipe((rxUtil.allOrdered));
  readonly hasSets$ = this.allSets$.pipe(map(s => s.length > 0));

  constructor(
    private store: Store<AppState>,
    private dataSelectors: DataSelectorsService
  ) {}
}
