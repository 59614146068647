import { Component, OnInit, Output, EventEmitter, isDevMode, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TokenRequestModel, UserSignupModel } from '../../../common/auth/user.models';
import { Store } from "@ngrx/store";
import { AppState } from "../../app.models";
import { Subscription } from "rxjs";

export interface ILoginForm {
  email: string;
}

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.sass']
})
export class LoginFormComponent implements OnInit, OnDestroy {
  @Input() showError = false;
  @Output() formSubmitted = new EventEmitter<ILoginForm>();

  form: FormGroup;
  submitted = false;

  private subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.subscription = this.store.select(s => s.global.loginEmail).subscribe(email => {
      this.form.controls.email.setValue(email);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  save() {
    this.submitted = true;
    if (this.form.valid) {
      const model: ILoginForm = {
        email: this.form.controls.email.value
      };
      this.formSubmitted.emit(model);
    }
  }

  showValidation(fieldName: string): boolean {
    const control = this.form.controls[fieldName];
    return !control.valid && (this.submitted || !control.pristine);
  }
}
