import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../app.models";
import { DataLoadRequested, DataSaveRequested, DataSetItem } from "../data/data.actions";
import { IDataState } from "../data/data.models";
import { ICollectable } from "../../common/core/collection";

@Injectable()
export class DataService {
  constructor(private store: Store<AppState>) {}

  load(collection: keyof IDataState) {
    return new DataLoadRequested({ collection });
  }

  save(collection: keyof IDataState, itemId: string) {
    return new DataSaveRequested({ collection, itemId });
  }

  set(collection: keyof IDataState, itemId: string, itemData: any|null) {
    if (itemData && itemData._id !== itemId) {
      itemData = { ...itemData, _id: itemId };
    }
    return new DataSetItem({ collection, itemId, itemData });
  }

  setAndSave(collection: keyof IDataState, itemId: string, itemData: any|null) {
    return [this.set(collection, itemId, itemData), this.save(collection, itemId)];
  }
}
