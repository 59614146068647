import { of as observableOf, empty as observableEmpty, Observable } from 'rxjs';

import { withLatestFrom, map, mergeAll } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppResponse } from '../../../common/core/core.models';
import { IReportDownloadKey } from '../../../common/reports/reports.models';
import { AppState } from '../../app.models';
import { EffectLoggerService } from '../../effect-logger.service';
import {
  CallApiActionTypes,
  CallApiFailed,
  CallApiLoaded,
  CallApiService
} from '../call-api';
import {
  ProvideEmail,
  ProvideEmailFailed,
  ReportDownloadActionTypes,
  SetDownloadKey
} from './report-download.actions';
import {
  ReportDownloadApiKeys,
  ReportDownloadSaveEmailApiArgs
} from './report-download.api';
import { ReportDownloadService } from './report-download.service';

@Injectable()
export class ReportDownloadEffects {
  @Effect()
  provideEmail$ = this.actions$.pipe(
    ofType(ReportDownloadActionTypes.PROVIDE_EMAIL),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        map((action: ProvideEmail) => {
          return this.callApi.callApi(
            new ReportDownloadSaveEmailApiArgs(action.payload.reportId, {
              email: action.payload.email
            })
          );
          // return Observable.empty<Action>();
        }),
        mergeAll()
      )
    )
  );

  @Effect()
  saveEmailRespnose$ = this.actions$.pipe(
    ofType(CallApiActionTypes.CALL_API_LOADED),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        map((action: CallApiLoaded) => {
          if (action.payload.key !== ReportDownloadApiKeys.SAVE_EMAIL) {
            return observableEmpty();
          }
          const downloadKeyResponse = action.payload
            .response as AppResponse<IReportDownloadKey>;
          if (downloadKeyResponse.success) {
            return observableOf(
              new SetDownloadKey(downloadKeyResponse.data.downloadKey)
            );
          }
          return observableOf(new ProvideEmailFailed());
        }),
        mergeAll()
      )
    )
  );

  @Effect()
  saveEmailResponseFailed$ = this.actions$.pipe(
    ofType(CallApiActionTypes.CALL_API_FAILED),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        map((action: CallApiFailed) => {
          if (action.payload.key === ReportDownloadApiKeys.SAVE_EMAIL) {
            return observableOf(new ProvideEmailFailed());
          }
          return observableEmpty();
        }),
        mergeAll()
      )
    )
  );

  @Effect()
  setDownloadKey$ = this.actions$.pipe(
    ofType(ReportDownloadActionTypes.SET_DOWNLOAD_KEY),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        withLatestFrom(
          this.store.select(s => s.reportDownload.requestedReportId),
          (action: SetDownloadKey, reportId: string) => {
            this.reportDownloadService.requestDownload(reportId);
            return observableEmpty();
          }
        ),
        mergeAll()
      )
    )
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private effectLogger: EffectLoggerService,
    private callApi: CallApiService,
    private reportDownloadService: ReportDownloadService
  ) {}
}
