import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AboutComponent } from './about/about.component';
import { AppErrorHandler } from './app-error-handler';
import { AppInitService } from './app-init.service';
import { AppLogger } from './app-logger';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appMetaReducers, appReducers } from './app.reducer';
import { CompleteComponent } from './complete/complete.component';
import { ContactComponent } from './contact/contact.component';
import { CriteriaModule } from './criteria/criteria.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataModule } from './data/data.module';
import { DevModule } from './dev/dev.module';
import { EffectLoggerService } from './effect-logger.service';
import { HomeModule } from './home/home.module';
import { JobsComponent } from './jobs/jobs.component';
import { OpportunitiesModule } from './opportunities/opportunities.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ReportsModule } from './reports/reports.module';
import { SavingStatusComponent } from './saving-status/saving-status.component';
import { SharedModule } from './shared/shared.module';
import { UsersModule } from './users/users.module';
import { YourOrganisationModule } from './your-organisation/your-organisation.module';
import { TokenService } from './token.service';
import { TokenNotExpiredService } from './token-not-expired.service';
import { ApiInterceptorService } from './api-interceptor';
import { MapModule } from './map/map.module';
import { NgMapsCoreModule } from '@ng-maps/core';

export function jwtOptionsFactory(tokenService: TokenService) {
  return {
    tokenGetter: () => tokenService.token
  };
}

@NgModule({
  declarations: [
    AppComponent,
    SavingStatusComponent,
    ContactComponent,
    PageNotFoundComponent,
    PrivacyPolicyComponent,
    CompleteComponent,
    AboutComponent,
    DashboardComponent,
    JobsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [TokenService]
      }
    }),
    AppRoutingModule,
    SharedModule,
    UsersModule,
    OpportunitiesModule,
    YourOrganisationModule,
    CriteriaModule,
    ReportsModule,
    HomeModule,
    DevModule,
    DataModule,
    StoreModule.forRoot(appReducers, { metaReducers: appMetaReducers }),
    EffectsModule.forRoot([]),
    MapModule,
    NgMapsCoreModule,
    NgMapsGoogleModule,
    // StoreDevtoolsModule.instrument({
    //   maxAge: 25
    // })
    // StoreDevtoolsModule.instrumentOnlyWithExtension({ maxAge: 5 })
  ],
  providers: [
    // AUTH_PROVIDERS
    AppInitService,
    AppLogger,
    EffectLoggerService,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    TokenService,
    TokenNotExpiredService,
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: ApiInterceptorService
    },
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        // apiKey: 'AIzaSyCTxfkcjtouyk9PXIuxfUvPjaZySZLzikI', // new
        apiKey: 'AIzaSyAxjOiHqUuSPUY-DrF5SZAyV-FXJWe06sw', // old
        libraries: ['places']
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
