import { YourOrganisationActions } from './your-organisation/your-organisation.actions';
import { Action } from '@ngrx/store';
import { SharedActions } from './shared/shared.actions';
import { CriteriaActions } from './criteria/criteria.actions';
import { DataActions } from './data/data.actions';
import { QuestionActions } from './data/question.actions';
import { FieldEditActions } from './data/field-edit';
import { GlobalActions } from './global.actions';
import { AsyncActions } from './async';
import { SearchPickerActions } from './shared/search-picker.actions';
import { OpportunitiesActions } from './opportunities/opportunities.actions';
import { ReportDownloadActions } from './shared/report-download/report-download.actions';
import { CriteriaDefineCrunchActions } from './criteria/criteria-define-crunch/criteria-define-crunch.actions';
import { SurveyPageActions } from './your-organisation/survey-page.actions';

export type AppActions =
  | SharedActions
  | YourOrganisationActions
  | CriteriaActions
  | DataActions
  | FieldEditActions
  | QuestionActions
  | OpportunitiesActions
  | AppClear
  | GlobalActions
  | AsyncActions
  | SearchPickerActions
  | ReportDownloadActions
  | CriteriaDefineCrunchActions
  | SurveyPageActions;

export namespace AppActionTypes {
  export const APP_CLEAR: '[App] Clear State' = '[App] Clear State';
}

export class AppClear implements Action {
  type = AppActionTypes.APP_CLEAR;
}
