import {
  empty as observableEmpty,
  from as observableFrom,
  Observable
} from 'rxjs';

import {
  map,
  filter,
  delay,
  debounceTime,
  mergeAll,
  withLatestFrom
} from 'rxjs/operators';
import * as _ from 'lodash';
import * as timm from 'timm';
import * as responseUtil from '../../common/questions/response.util';
import * as questionUtil from '../../common/questions/question.util';

import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app.models';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  CompareQuestionResults,
  QuestionActionTypes,
  QuestionResponseResultMatches,
  QuestionResponseUpdate,
  QuestionResponseValid,
  RetrySave,
  UpdateQuestionDependencies,
  UpdateQuestionVisibility,
  CopyOldResponse
} from './question.actions';
import { QuestionResponse } from '../../common/questions/response.model';
import {
  Question,
  QuestionCondition,
  QuestionId,
  QuestionKey,
  QuestionShowIf
} from '../../common/questions/question.models';
import { ShowExternalUrlService } from './show-external-url.service';
import { QuestionResultsService } from '../your-organisation/question-results.service';
import { AppLogger } from '../app-logger';
import { EffectLoggerService } from '../effect-logger.service';
import { QuestionResponseTypeChecks } from '../../common/questions/response-typechecks.model';
import { GaService } from '../shared/ga.service';
import { DataService } from './data.service';
import {
  DataActionTypes,
  DataLoaded,
  DataSaveError,
  DataSaved
} from './data.actions';
import {
  ResponseValidatorService,
  ResponseValidationResult
} from '../../common/questions/response-validator.service';
import { QuestionVisibilityService } from '../../common/questions/question-visibility.service';
import {
  transformResponsesForVisibility,
  ResponsesForVisibility
} from 'common/questions/question-visibility.util';
import { AppActions } from '../app.actions';

// const IGNORED_RESPONSE_IDS = ['QUserType', 'QUserTypeReturningEmail'];

@Injectable()
export class QuestionEffects {
  @Effect()
  update$ = this.actions$.pipe(
    ofType(QuestionActionTypes.RESPONSE_UPDATE),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        withLatestFrom(
          this.store.select(s => s.data.questions.byId),
          this.store.select(s => s.data.responses.byId),
          this.store.select(s => s.data.questionResults.byId),
          this.store.select(s => s.questions.dependencies),
          (
            action: QuestionResponseUpdate,
            questions,
            responses,
            questionResults,
            allDependencies
          ) => {
            const { questionId, response } = action.payload;
            const itemData = { ...response, _id: questionId };
            const question = questions[questionId]!;
            const result = questionResults[question.key];
            const dependencies = allDependencies[question.id];

            this.handlePopup(question, response, questions, responses);

            const actions = [];

            actions.push(this.data.set('responses', questionId, itemData));
            const validity = this.responseValidator.isValid(question, itemData);
            if (validity.isValid) {
              if (
                question.key === 'QUserType' &&
                itemData.type === 'selection'
              ) {
                for (const choice of itemData.choices) {
                  this.ga.logResponse(question.key, choice);
                }
              } else {
                this.ga.logResponse(question.key);
              }
              actions.push(
                new QuestionResponseValid({ [question.id]: { isValid: true } })
              );
              if (result) {
                const matches = this.questionResults.responseToResultMatches(
                  question,
                  response
                );
                actions.push(
                  new QuestionResponseResultMatches({ [question.key]: matches })
                );
              }
              if (dependencies && dependencies.length > 0) {
                const newResponses = timm.set(responses, questionId, itemData);
                const newResponsesByKey = _(newResponses)
                  .values()
                  .keyBy(r => r.questionKey)
                  .value();
                const visibilityResponses =
                  transformResponsesForVisibility(newResponsesByKey);
                for (const dependencyId of dependencies) {
                  const dependencyQuestion = questions[dependencyId];
                  this.updateDependenciesIfNeeded(
                    question.key,
                    dependencyQuestion,
                    newResponsesByKey,
                    visibilityResponses,
                    actions
                  );
                }
              }
            } else {
              actions.push(
                new QuestionResponseValid({
                  [question.id]: validity
                })
              );
            }

            return observableFrom(actions);
          }
        ),
        mergeAll()
      )
    )
  );

  private dirty$ = this.store.select(s => s.data.responses.dirty);
  private saving$ = this.store.select(s => s.data.responses.saving);

  // TODO: implement batching for multiple updates?
  @Effect()
  persist$ = this.actions$.pipe(
    ofType(QuestionActionTypes.RESPONSE_UPDATE, QuestionActionTypes.RETRY_SAVE),
    debounceTime(1000),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        withLatestFrom(this.dirty$, this.saving$, (action, dirty, saving) => {
          const toPersist = [];
          for (const dirtyKey in dirty) {
            if (dirty[dirtyKey]) {
              if (!saving[dirtyKey] || saving[dirtyKey] !== 'saving') {
                toPersist.push(dirtyKey);
              }
            }
          }
          return toPersist;
        }),
        withLatestFrom(
          this.store.select(s => s.data.responses.byId),
          (toPersist, responses) => {
            return observableFrom(
              toPersist.map(id => {
                const itemData = responses[id];
                return this.data.save('responses', id);
              })
            );
          }
        ),
        mergeAll()
      )
    )
  );

  @Effect()
  loadResponses$ = this.actions$.pipe(
    ofType(DataActionTypes.DATA_LOADED),
    filter((action: DataLoaded) => action.payload.collection === 'responses'),
    // .delay(0)
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        withLatestFrom(
          this.store.select(s => s.data.responses.byId),
          this.store.select(s => s.data.questions.byId),
          this.store.select(s => s.data.questionResults.byId),
          (_action, responses, questions, questionResults) => {
            const validResponses: _.Dictionary<ResponseValidationResult> = {};
            const resultMatches: _.Dictionary<string[]> = {};
            for (const qid of Object.keys(questions)) {
              const question = questions[qid];
              const response = responses[qid];
              const results = questionResults[question.key];
              validResponses[qid] = this.responseValidator.isValid(
                question,
                response
              );
              if (response && results) {
                const matches = this.questionResults.responseToResultMatches(
                  question,
                  response
                );
                resultMatches[qid] = matches;
              }
            }
            const responsesByKey = _(responses)
              .values()
              .keyBy(r => r.questionKey)
              .value();
            const visibilityResponses =
              transformResponsesForVisibility(responsesByKey);
            const { questionsByKey } =
              this.questionVisibility.visibleQuestionsAndResponses(
                _.values(questions),
                visibilityResponses
              );
            const isVisible = _.mapValues(questionsByKey, q => true);
            return observableFrom([
              new QuestionResponseValid(validResponses),
              new UpdateQuestionVisibility(isVisible),
              new QuestionResponseResultMatches(resultMatches)
            ]);
          }
        ),
        mergeAll()
      )
    )
  );

  @Effect()
  loadQuestions$ = this.actions$.pipe(
    ofType(DataActionTypes.DATA_LOADED),
    filter((action: DataLoaded) => action.payload.collection === 'questions'),
    // .delay(0)
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        withLatestFrom(
          this.store.select(s => s.data.questions.byId),
          (_action, questions) => {
            const questionsByKey = _(questions)
              .values()
              .keyBy(q => q.key)
              .value();
            const dependencies: _.Dictionary<QuestionId[]> = {};
            for (const qid of Object.keys(questions)) {
              const question = questions[qid];
              let localDependencies: QuestionId[] = [];
              if (question.showIf) {
                const showIfArray = Array.isArray(question.showIf)
                  ? question.showIf
                  : [question.showIf];
                localDependencies = this.extractDependencies(
                  showIfArray,
                  questionsByKey
                );
              }
              if (question.default) {
                localDependencies = _.uniq(
                  localDependencies.concat(
                    this.extractDependencies([question.default], questionsByKey)
                  )
                );
              }
              for (const localDependency of localDependencies) {
                const existing = dependencies[localDependency];
                if (existing) {
                  existing.push(question.id);
                } else {
                  dependencies[localDependency] = [question.id];
                }
              }
            }
            return new UpdateQuestionDependencies(dependencies);
          }
        )
      )
    )
  );

  @Effect()
  retryOnSaveError$ = this.actions$.pipe(
    ofType(DataActionTypes.DATA_SAVE_ERROR),
    filter(
      (action: DataSaveError) => action.payload.collection === 'responses'
    ),
    debounceTime(1000),
    delay(10000),
    map(() => new RetrySave())
  );

  @Effect()
  onSaved$ = this.actions$.pipe(
    ofType(DataActionTypes.DATA_SAVED),
    filter((action: DataSaved) => action.payload.collection === 'responses'),
    map((action: DataSaved) => {
      return observableEmpty();
    }),
    mergeAll()
  );

  @Effect()
  compareResults$ = this.actions$.pipe(
    ofType(QuestionActionTypes.COMPARE_QUESTION_RESULTS),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        withLatestFrom(
          this.store.select(s => s.data.questions.byId),
          this.store.select(s => s.data.lockedQuestionIds.byId),
          (action: CompareQuestionResults, questions, lockedIds) => {
            const { questionId } = action.payload;
            const question = questions[questionId];
            const locked = lockedIds[question.key];
            if (question.showResultsAfterLockout && !locked) {
              return observableFrom(
                this.data.setAndSave('lockedQuestionIds', question.key, {})
              );
            }
            return observableEmpty();
          }
        ),
        mergeAll()
      )
    )
  );

  @Effect()
  copyOldResponse$ = this.actions$.pipe(
    ofType(QuestionActionTypes.COPY_OLD_RESPONSE),
    withLatestFrom(
      this.store.select(s => s.data.oldResponses.byId),
      this.store.select(s => s.data.questions.byId),
      (action: CopyOldResponse, oldResponsesById, questionsById) => {
        const question = _.find(questionsById, q => q.key === action.payload);
        const oldResponse = oldResponsesById[action.payload];
        return new QuestionResponseUpdate({
          questionId: question!.id,
          response: oldResponse.replacementResponse!
        });
      }
    )
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private showExternalUrl: ShowExternalUrlService,
    private responseValidator: ResponseValidatorService,
    private questionVisibility: QuestionVisibilityService,
    private questionResults: QuestionResultsService,
    private appLogger: AppLogger,
    private effectLogger: EffectLoggerService,
    private ga: GaService,
    private data: DataService
  ) {}

  private updateDependenciesIfNeeded(
    parentQuestionKey: QuestionKey,
    question: Question,
    responsesByKey: _.Dictionary<QuestionResponse>,
    visibilityResponses: ResponsesForVisibility,
    actions: Action[]
  ): void {
    if (
      question.default &&
      questionUtil.showIfContainsQuestionKey(
        question.default,
        parentQuestionKey
      ) &&
      this.questionVisibility.shouldShowOnly(question, visibilityResponses)
    ) {
      const existingResponse = responsesByKey[question.key];
      const value = question.default.value;

      if (
        this.questionVisibility.shouldDefault(question, visibilityResponses)
      ) {
        let newResponse: QuestionResponse | undefined;
        if (value === 'Skipped') {
          newResponse = {
            questionId: question.id,
            questionKey: question.key,
            type: 'skipped',
            updatedAt: new Date()
          };
        } else if (question.type === 'T' || question.type === 'N') {
          newResponse = {
            questionId: question.id,
            questionKey: question.key,
            type: 'value',
            value,
            updatedAt: new Date()
          };
        } else if (question.type === 'MC') {
          newResponse = {
            questionId: question.id,
            questionKey: question.key,
            type: 'choice',
            choice: value,
            extra: '',
            updatedAt: new Date()
          };
        } else if (question.type === 'MS') {
          newResponse = {
            questionId: question.id,
            questionKey: question.key,
            type: 'selection',
            choices: [value],
            extra: '',
            updatedAt: new Date()
          };
        } else {
          this.appLogger.error(`Attempting to update default value
          ${value} for question ${question.key} of type ${question.type}:
            unhandled type`);
        }

        if (
          newResponse &&
          !responseUtil.isEqual(newResponse, existingResponse)
        ) {
          actions.push(
            new QuestionResponseUpdate({
              questionId: question.id,
              response: newResponse
            })
          );
        }
      } else if (existingResponse) {
        const shouldUndoDefaulting =
          (QuestionResponseTypeChecks.isSkipped(existingResponse) &&
            value === 'Skipped') ||
          (QuestionResponseTypeChecks.isValue(existingResponse) &&
            value === existingResponse.value) ||
          (QuestionResponseTypeChecks.isChoice(existingResponse) &&
            value === existingResponse.choice) ||
          (QuestionResponseTypeChecks.isSelection(existingResponse) &&
            existingResponse.choices.length > 0 &&
            value === existingResponse.choices[0]);

        if (shouldUndoDefaulting) {
          if (question.type === 'T' || question.type === 'N') {
            actions.push(
              new QuestionResponseUpdate({
                questionId: question.id,
                response: {
                  questionId: question.id,
                  questionKey: question.key,
                  type: 'value',
                  value: '',
                  updatedAt: new Date()
                }
              })
            );
          } else if (question.type === 'MC') {
            actions.push(
              new QuestionResponseUpdate({
                questionId: question.id,
                response: {
                  questionId: question.id,
                  questionKey: question.key,
                  type: 'choice',
                  choice: '',
                  extra: '',
                  updatedAt: new Date()
                }
              })
            );
          } else if (question.type === 'MS') {
            actions.push(
              new QuestionResponseUpdate({
                questionId: question.id,
                response: {
                  questionId: question.id,
                  questionKey: question.key,
                  type: 'selection',
                  choices: [],
                  extra: '',
                  updatedAt: new Date()
                }
              })
            );
          }
        }
      }
    }
  }

  private extractDependencies(
    showIfs: QuestionShowIf[],
    questionsByKey: _.Dictionary<Question>
  ): QuestionId[] {
    const filtered = _.chain(showIfs)
      .flatMap(showIf => [showIf.any, showIf.all, showIf.notAny, showIf.notAll])
      .filter(this.filterUndefined);
    return filtered
      .flatten()
      .map((c: QuestionCondition) => c.questionId)
      .uniq()
      .map(key => questionsByKey[key].id)
      .value();
  }

  private handlePopup(
    question: Question,
    response: QuestionResponse,
    questions: _.Dictionary<Question>,
    responses: _.Dictionary<QuestionResponse>
  ): void {
    if (!response || response.type !== 'selection') {
      return;
    }
    if (question.type === 'MS') {
      for (const choice of response.choices) {
        const option = question.options.find(o => o.optionId === choice)!;
        if (option.popupURL) {
          const oldResponse = responses[question.id];
          if (!oldResponse) {
            this.showExternalUrl.show(option.popupURL);
          } else if (oldResponse.type === 'selection') {
            if (oldResponse.choices.findIndex(c => c === choice) === -1) {
              this.showExternalUrl.show(option.popupURL);
            }
          }
        }
      }
    }
  }

  private filterUndefined<T>(x: T | undefined): x is T {
    return !!x;
  }
}
