import { withLatestFrom } from 'rxjs/operators';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.models';
import { Observable } from 'rxjs';
import { ICriteriaSet } from '../../../common/criteria/criteria.models';
import { Router } from '@angular/router';
import { CriteriaSetSelectorsService } from '../criteria-set-selectors.service';
import {
  CriteriaRemove,
  CriteriaSetEnabled
} from '../../criteria/criteria.actions';
import * as uuid from 'uuid';
import { generateConnectionInfo } from '../../../common/questions/connection-info-generator';
import * as _ from 'lodash';

export interface ICriteriaRootState {
  sets: ICriteriaSet[];
  setValidity: _.Dictionary<'valid' | 'tooWide' | 'tooNarrow'>;
  initialAdd: string | null;
}

@Component({
  selector: 'app-criteria-root',
  templateUrl: './criteria-root.component.html',
  styleUrls: ['./criteria-root.component.sass']
})
export class CriteriaRootComponent {
  state$: Observable<ICriteriaRootState>;
  selected?: string;

  constructor(
    criteriaSelectors: CriteriaSetSelectorsService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.state$ = criteriaSelectors.allSets$.pipe(
      withLatestFrom(
        this.store.select(s => s.data.responses.byId),
        this.store.select(s => s.data.questions.byId),
        this.store.select(s => s.criteria.setValidity),
        (combinedSets, responsesById, questionsById, setValidity) => {
          const responsesByKey = _(responsesById)
            .values()
            .keyBy(r => r.questionKey)
            .value();
          const questionsByKey = _(questionsById)
            .values()
            .keyBy(q => q.key)
            .value();
          const org = generateConnectionInfo(
            '1234',
            false,
            responsesByKey,
            questionsByKey
          );
          let initialAdd = null;
          if (combinedSets.length === 0) {
            initialAdd = uuid.v4();
          } else if (combinedSets.length === 1) {
            const set = combinedSets[0];
            const validity = setValidity[set.set.id];
            if (!set.set.enabled && validity !== 'valid') {
              initialAdd = set.set.id;
            }
          }
          return {
            sets: combinedSets.map(s => s.set),
            org,
            setValidity,
            initialAdd
          };
        }
      )
    );
  }

  onSetClick(setId: string) {
    if (this.selected === setId) {
      this.selected = undefined;
    } else {
      this.selected = setId;
    }
  }

  onSetEnableClick(setId: string) {
    this.store.dispatch(new CriteriaSetEnabled(setId, true));
  }

  onSetDisableClick(setId: string) {
    this.store.dispatch(new CriteriaSetEnabled(setId, false));
  }

  onSetRemoveClick(setId: string) {
    if (confirm('Are you sure?')) {
      this.store.dispatch(new CriteriaRemove(setId));
    }
  }

  onAddNewCriteria() {
    this.router.navigate(['connect-with-startups', uuid.v4()]);
  }
}
