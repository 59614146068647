import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapPageComponent } from './map-page/map-page.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { SharedModule } from '../shared/shared.module';
import { MapSidebarComponent } from './map-sidebar/map-sidebar.component';
import { MapFilterComponent } from './map-filter/map-filter.component';
import { MapContactComponent } from './map-contact/map-contact.component';
import { MapContactAllComponent } from './map-contact-all/map-contact-all.component';
import { MapCustomReportComponent } from './map-custom-report/map-custom-report.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [MapPageComponent, GoogleMapComponent, MapSidebarComponent, MapFilterComponent, MapContactComponent, MapContactAllComponent, MapCustomReportComponent]
})
export class MapModule {}
