import { empty as observableEmpty, of as observableOf, Observable } from 'rxjs';

import { mergeAll, combineLatest } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../app.models';
import { OrgSelectorsService } from '../users/org-selectors.service';

@Injectable()
export class HomeRedirectService implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private orgSelectors: OrgSelectorsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.store.select(s => s.global.loadState === 'loaded').pipe(
      combineLatest(
        this.store.select(s => s.global.showPlatform),
        this.orgSelectors.userType$,
        (loaded, showPlatform, userType) => {
          if (loaded) {
            if (showPlatform) {
              if (userType.isHelper || userType.isStartup) {
                this.router.navigate(['/dashboard']);
                return observableOf(false);
              }
            }
            return observableOf(true);
          }
          return observableEmpty();
        }
      ),
      mergeAll()
    );
  }
}
