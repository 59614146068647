import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-toggle-icon',
  templateUrl: './toggle-icon.component.html',
  styleUrls: ['./toggle-icon.component.sass']
})
export class ToggleIconComponent implements OnInit {
  @Input() expanded: boolean;

  constructor() {}

  ngOnInit() {}
}
