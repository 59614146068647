import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  AfterViewInit,
  ViewChildren,
  ElementRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.models';
import { Subscription } from 'rxjs';
import { UserService } from '../../users/user.service';
import { SurveySelectorsService } from '../survey-selectors.service';
import { LoginLinkRequested } from '../../global.actions';
import {
  CompareQuestionResults,
  CopyOldResponse
} from '../../data/question.actions';
import {
  OrgSelectorsService,
  IUserType
} from '../../users/org-selectors.service';
import {
  ISurveyState,
  ISurveyStateQuestionResponse
} from '../your-organisation.models';
import { QuestionIds, OptionIds } from '../../../common/questions/constants';
import * as scrollIntoView from 'scroll-into-view';
import { QuestionComponent } from '../question/question.component';
import { ChooseStart } from '../survey-page.actions';

import { IntercomService } from '../../shared/intercom.service';

@Component({
  selector: 'app-survey-page',
  templateUrl: './survey-page.component.html',
  styleUrls: ['./survey-page.component.sass']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(QuestionComponent, { read: ElementRef })
  questionComponents: QueryList<ElementRef>;

  userType: IUserType;
  state: ISurveyState;

  private stateSubscription: Subscription;
  private userTypeSubscription: Subscription;

  public doShowResults = true;
  public doShowSurvey = true;

  constructor(
    private store: Store<AppState>,
    private userService: UserService,
    private surveySelectors: SurveySelectorsService,
    private intercom: IntercomService,
    private orgSelectors: OrgSelectorsService
  ) {

  }

  ngOnInit() {

    this.stateSubscription = this.surveySelectors.state$.subscribe(s => {
      this.state = s;
      if (s  &&
        s.sections.length > 2 &&
        s.sections[2].questionResponses &&
        s.sections[2].questionResponses.length > 0 &&
        s.sections[2].questionResponses[0].response) {
        const nameInfo = JSON.parse(JSON.stringify(s.sections[2].questionResponses[0].response));
        const nameAlphabet = (nameInfo["value"].slice(0, 1)).toLowerCase();
        //this.doShowResults = !(nameAlphabet >= 'a' && nameAlphabet <= 'm');


      }
    });
    this.userTypeSubscription = this.orgSelectors.userType$.subscribe(s => {
      this.userType = s;
    });
    window.addEventListener('beforeunload', this.onBeforeUnload);
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.onBeforeUnload);
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
    if (this.userTypeSubscription) {
      this.userTypeSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.questionComponents.length > 1) {
        const lastQuestion = this.questionComponents.last;
        if (lastQuestion && lastQuestion.nativeElement) {
          const el: HTMLElement = lastQuestion.nativeElement;
          scrollIntoView(
            el,
            {
              time: 500,
              align: {
                top: 0.2,
                topOffset: 20
              } as any
            },
            type => {
              if (type === 'complete') {
                const inputElement = el.querySelector(
                  'input,textarea,.contenteditable'
                ) as HTMLElement | null;
                if (inputElement && inputElement.focus) {
                  inputElement.focus();
                }
              }
            }
          );
        }
      }
    }, 0);
  }

  requestLogin() {
    if (this.state.returningEmail) {
      this.store.dispatch(
        new LoginLinkRequested({
          email: this.state.returningEmail,
          redirectOnSuccess: true
        })
      );
    }
  }

  completeSurvey() {
    this.userService.completeSurvey().subscribe();
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  trackByQuestionId(index: number, item: ISurveyStateQuestionResponse) {
    return item.question.id;
  }

  hideCompare(qr: ISurveyStateQuestionResponse) {
    return qr.isLocked || !qr.isValid || !!qr.errors;
  }

  showResults(qr: ISurveyStateQuestionResponse) {
    if (!this.doShowResults) {
      return false;
    }
    if (qr.response && qr.results) {
      if (
        qr.question.key === QuestionIds.USER_TYPE &&
        qr.response.type === 'selection' &&
        qr.response.choices.indexOf(OptionIds.UserType.RETURNING) > -1
      ) {
        return false;
      }
      return (
        (!qr.question.showResultsAfterLockout || qr.isLocked) &&
        (!!qr.isValid && qr.isValid.isValid) &&
        !qr.errors &&
        qr.results.some(r => r.percentage > 0)
      );
    }
    return false;
  }

  showWhy(qr: ISurveyStateQuestionResponse) {
    return qr.response && qr.isValid;
  }

  onCompareResults(qr: ISurveyStateQuestionResponse) {
    this.store.dispatch(
      new CompareQuestionResults({ questionId: qr.question.id })
    );
  }

  isAlt(section: any) {
    return section.id === 'SLetsBegin' || section.id === 'SSurveyComplete';
  }

  newSurvey() {
    // TODO: only if they haven't completed this years
    const answer = confirm(newSurveyConfirmation);
    if (!answer) {
      return;
    }
    this.userService.logOut();
  }
  onClickPreviousYes() {
    this.store.dispatch(new ChooseStart('yes-previous'));

    (window as any).lintrk('track', { conversion_id: 13317004 });
  }

  onClickPreviousNo() {
    this.store.dispatch(new ChooseStart('no-previous'));

    (window as any).lintrk('track', { conversion_id: 13317004 });
  }

  get showSurvey() {
    return (
      this.state.hasStarted ||
      this.state.hasPreviousResponses ||
      !this.showPrevious ||
      this.state.page.flowState === 'no-previous' ||
      this.state.page.loginContinue
    );
  }

  get showPrevious() {
    return (
      this.state.page.flowState !== 'initial' ||
      (!this.state.hasStarted && !this.state.hasPreviousResponses)
    );
  }

  get showNewSurvey() {
    // return true;
    // return this.state.sections.length > 3;
    return this.state.hasStarted;
  }

  onContactClicked($event: MouseEvent) {
    document.querySelectorAll('a').forEach(a => {if ( a.textContent && a.textContent.trim() === 'Contact us') {a.click(); }});
    return false;
  }
  onClickContact($event: MouseEvent) {
    $event.stopPropagation();
    this.intercom.show();
    return false;
  }

  // TODO: check data saving state
  // TODO: also need CanDeactivate
  private onBeforeUnload = ($event: BeforeUnloadEvent) => {
    if (
      !this.state.isComplete &&
      this.userType &&
      (this.userType.isFuture ||
        this.userType.isHelper ||
        this.userType.isStartup)
    ) {
      const msg = 'You have not yet completed your Startup Muster submission';
      $event.returnValue = msg;
      return msg;
    }
    return;
  }
}

// tslint:disable-next-line:max-line-length
const newSurveyConfirmation = `This will save your existing responses, then start a new blank survey response, are you sure?`;
