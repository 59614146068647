export interface AppResponseBase {
  success: boolean;
}

export interface SuccessfulAppResponse<T> extends AppResponseBase {
  success: true;
  data: T;
}

export interface FailedAppResponse extends AppResponseBase {
  success: false;
  errors: string[];
}

export type AppResponse<T> = SuccessfulAppResponse<T> | FailedAppResponse;

export namespace AppResponse {
  export function failed(errors: string[]): FailedAppResponse {
    return {
      success: false,
      errors: errors
    };
  }

  export function succeded<T>(data: T): SuccessfulAppResponse<T> {
    return {
      success: true,
      data: data
    };
  }
}

export namespace ErrorIds {
  export const CONNECTIONS_DAILY_LIMIT = 'CONNECTIONS_DAILY_LIMIT';
}

export type AppResponseError = keyof typeof ErrorIds;

// export interface Tag<T> {
//   _tag: T;
// }

export declare class Tag<T extends string> {
  private __tag: T;
}
