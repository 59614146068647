
import {map, combineLatest} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.models';
import { AsyncSubject ,  Observable } from 'rxjs';
import {
  UiState,
  UiOperandItem,
  Operand,
  UiOperand,
  UiFilter,
  UiQuestion,
  Filter
} from './criteria-define-crunch.models';
import { createDataFinder, allOrdered } from '../../../rx-util';
import {
  QuestionType,
  Question,
  QuestionId
} from '../../../common/questions/question.models';
import { isQuestionWithOptions } from '../../../common/questions/question.util';
import * as _ from 'lodash';
import { AppLogger } from '../../app-logger';
import {
  CriteriaOperator,
  ICriterion
} from '../../../common/criteria/criteria.models';
import { OperatorType } from '../../../common/criteria/criteria-operator-types.models';
import { operatorsForQuestion } from '../../../common/criteria/criteria-operators-by-question-type.models';
import { ICollection, ICollectable } from '../../../common/core/collection';
import * as uuid from 'uuid';
import { INumberMaskOptions } from '../../your-organisation/number-mask.service';
import { IDataCollection } from '../../data/data.models';
import { CriteriaCrunchValidatorService } from '../criteria-crunch-validator.service';

@Injectable()
export class CriteriaDefineCrunchSelectorsService {
  private allQuestion$ = this.store.select(s => s.data.questions);
  private allSections$ = this.store.select(s => s.data.sections);

  private combinedCriteriaSetsById$ = this.store.select(
    s => s.data.combinedCriteriaSets.byId
  );

  private findQuestion = createDataFinder(this.allQuestion$);
  private findSection = createDataFinder(this.allSections$);

  private allMatchingQuestions$ = this.allQuestion$.pipe((allOrdered),map(
    qs =>
      qs
        .filter(
          q =>
            !q.criteria ||
            !q.criteria.matching ||
            q.criteria.matching !== 'none'
        )
        .filter(q => ignoredQuestionKeys.indexOf(q.key) === -1) // TODO: this needs to live elsewhere
  ),);

  private allMatchingSectionIds$ = this.allMatchingQuestions$.pipe(map(qs =>
    _(qs)
      .flatMap(q => q.sectionId)
      .uniq()
      .value()
  ));

  questionOptions$ = this.allSections$.pipe(
    (allOrdered),
    combineLatest(this.allMatchingSectionIds$, (ss, sids) =>
      ss.filter(s => sids.indexOf(s.id) > -1)
    ),
    combineLatest(this.allMatchingQuestions$, (ss, qs) =>
      ss.map(s => ({
        sectionTitle: s.criteriaTitle || s.title,
        questions: qs.filter(q => q.sectionId === s.id).map(q => ({
          id: q.id,
          text: q.criteria && q.criteria.text ? q.criteria.text : q.text
        }))
      }))
    ),);

  state$: Observable<UiState> = this.store
    .select(s => s.criteriaCrunch).pipe(
    combineLatest(
      this.allQuestion$,
      this.questionOptions$,
      this.store.select(s => s.data.combinedCriteriaSets),
      (state, questions, questionOptions, combinedSets) => {
        if (!state) {
          return {
            setId: uuid.v4(),
            name: '',
            filters: [],
            sections: [],
            canCancel: false,
            isLocallyValid: false
          } as UiState;
        }

        const filters = state.filters.map(f =>
          this.mapFilterToUi(f, questions.byId)
        );

        const sections = questionOptions.map(qo => ({
          title: qo.sectionTitle,
          options: qo.questions
        }));

        const savingState = state.setId
          ? combinedSets.saving[state.setId]
          : undefined;

        const canCancel = combinedSets.ids.length > 0;

        const result: UiState = {
          setId: state.setId || uuid.v4(),
          name: state.name,
          filters,
          sections,
          validity: state.validity,
          savingState,
          canCancel,
          isLocallyValid: false
        };

        const isLocallyValid = this.crunchValidator.isUiValid(result);

        return { ...result, isLocallyValid };
      }
    ));

  constructor(
    private store: Store<AppState>,
    private logger: AppLogger,
    private crunchValidator: CriteriaCrunchValidatorService
  ) {}

  private mapFilterToUi(
    filter: Filter,
    questions: _.Dictionary<Question>
  ): UiFilter {
    const question = questions[filter.questionId];
    if (!question) {
      this.logger.error('unable to find question', filter.questionId);
    }

    const questionText =
      (question.criteria && question.criteria.text) || question.text;
    const uiValidOperators = operatorsForQuestion(question).map(vo => ({
      operator: vo,
      text: operatorsToText[vo]
    }));
    const operator = filter.operatorType || uiValidOperators[0].operator;

    const operand = toUiOperand(operator, question, filter.operand);

    const uiQuestion: UiQuestion = {
      id: filter.questionId,
      text: questionText
    };
    if (question.type === 'N') {
      uiQuestion.insertDollars = question.insertDollars;
      uiQuestion.insertCommas = question.insertCommas;
    }
    const needsMaxAgeMonths = !(
      question.criteria && question.criteria.ignoreFreshness
    );

    let maxAgeMonths = filter.maxAgeMonths;
    if (!maxAgeMonths && needsMaxAgeMonths) {
      maxAgeMonths = 12;
    }

    return {
      id: filter.id,
      question: uiQuestion,
      operator: {
        operator: operator,
        text: operatorsToText[operator]
      },
      validOperators: uiValidOperators,
      operand,
      needsMaxAgeMonths,
      maxAgeMonths
    };
  }
}

function acceptsCustomItems(
  operator: OperatorType,
  question: Question
): boolean {
  if (
    (operator === OperatorType.ALL_OF || operator === OperatorType.ONE_OF) &&
    isQuestionWithOptions(question)
  ) {
    return question.options.some(o => !!o.showBox);
  }
  return operator === OperatorType.CONTAINS;
}

// TODO: move/merge into common/criteria.util
const operatorsToText: { [K in keyof typeof OperatorType]: string } = {
  [OperatorType.ALL_OF]: 'all of',
  [OperatorType.ONE_OF]: 'any of',
  [OperatorType.CONTAINS]: 'contains',
  [OperatorType.BETWEEN]: 'between',
  [OperatorType.PROXIMITY]: 'location'
};

function toUiOperand(
  operatorType: OperatorType,
  question: Question,
  operand?: Operand
): UiOperand {
  if (operand) {
    if (operand.type === 'tags') {
      return {
        ...baseTagsOperand(operatorType, question),
        type: 'tags',
        tags: operand.tags //.map(t => ({ tag: true, label: t })) as any
      };
    }
    return operand;
  }
  switch (operatorType) {
    case OperatorType.ALL_OF:
    case OperatorType.ONE_OF:
    case OperatorType.CONTAINS:
      return {
        ...baseTagsOperand(operatorType, question),
        type: 'tags',
        tags: []
      };
    case OperatorType.BETWEEN:
      return {
        type: 'between',
        from: '',
        to: ''
      };
    case OperatorType.PROXIMITY:
      return {
        type: 'location',
        place: '',
        lng: undefined,
        lat: undefined,
        radius: undefined
      };
  }
}

function baseTagsOperand(operatorType: OperatorType, question: Question) {
  let operandItems = new Array<UiOperandItem>();
  if (isQuestionWithOptions(question)) {
    operandItems = question.options
      .filter(o => !o.showBox)
      .map(o => ({ label: o.text, id: o.optionId }));
  }
  return {
    type: 'tags',
    // tags: [],
    items: operandItems,
    acceptsCustomItems: acceptsCustomItems(operatorType, question),
    addCustomText:
      operatorType === OperatorType.CONTAINS
        ? 'Search for'
        : 'Search Other responses for'
  };
}

const ignoredQuestionKeys = [
  'QTotalReceivedFromPrivateEquityInAustralia',
  'QTotalReceivedFromPrivateEquityOverseas',
  'QTotalReceivedFromPrivateEquityAsConvertibleNoteInAustralia',
  'QTotalReceivedFromPrivateEquityAsConvertibleNoteOverseas',
  'QTotalReceivedFromFamilyAndFriends',
  'QTotalReceivedFromBankLoan',
  'QTotalReceivedFromCreditCard',
  'QTotalReceivedFromWelfarePayments',
  'QTotalReceivedFromFederalGovernmentGrant',
  'QTotalReceivedFromStateGovernmentGrant',
  'QTotalReceivedFromLocalGovernmentGrant',
  'QTotalReceivedFromCrowdFundingOnAustralianPlatform',
  'QTotalReceivedFromCrowdFundingOnOverseasPlatform',
  'QTotalReceivedFromIPO',
  'QTotalReceivedFromRDTaxOffset',
  'QTotalReceivedFromMyOwnCash',
  'QTotalReceivedFromAcceleratorIncubator',
  'QTotalReceivedFromPrizeMoney',
  'QTotalReceivedFromOther',
  'QWhichWillYouNeedInTheNextSixMonths',
  'QNumberOfCurrentEmployeesHolding457Visas',
  'QNumberOfCurrentEmployeesHolding417Visas',
  'QNumberOfCurrentEmployeesHolding462Visas',
  'QNumberOfCurrentEmployeesHoldingVisas500',
  'QNumberOfCurrentEmployeesHoldingVisas485',
  'QNumberOfCurrentEmployeesHoldingVisas189',
  'QNumberOfCurrentEmployeesHoldingOtherVisas'
];
