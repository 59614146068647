import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass']
})
export class ModalComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  onClose() {
    this.router.navigate([{ outlets: { modal: null } }]);
  }
}
