import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  AfterViewChecked
} from '@angular/core';
import {
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { IButtonGroupOption } from '../../shared/button-group/button-group.component';

declare var google: any;

export interface IOpProximityModel {
  place: string;
  lat: number;
  lng: number;
  radius: number;
}

@Component({
  selector: 'app-op-proximity',
  templateUrl: './op-proximity.component.html',
  styleUrls: ['./op-proximity.component.sass']
})
export class OpProximityComponent implements OnInit, OnChanges {
  @ViewChild('locationInput', { static: true })
  locationInputElement: ElementRef;

  radiusOptions: IButtonGroupOption[] = [
    { text: '15', value: '15' },
    { text: '25', value: '25' },
    { text: '50', value: '50' },
    { text: '100', value: '100' },
    { text: '200', value: '200' }
  ];

  @Input()
  lat = 0;
  @Input()
  lng = 0;
  @Input()
  radius = 50;
  @Input()
  place: string | undefined = undefined;

  @Output()
  changeValue = new EventEmitter<IOpProximityModel>();

  constructor(private ngZone: NgZone) {}

  ngOnInit() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.locationInputElement.nativeElement,
      {
        // types: ['geocode']
        // location: [-27.527758, 134.296875],
        // radius: 2318000
      }
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.ngZone.run(() => {
        const value = this.locationInputElement.nativeElement.value;
        const place = autocomplete.getPlace();
        this.place = value;
        // console.log(value, place);
        if (place && place.geometry && place.geometry.location) {
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.changeValue.emit({
            place: this.place!,
            lat: this.lat,
            lng: this.lng,
            radius: this.radius
          });
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.radius) {
      this.radius = 50;
    }
  }

  selectRadius(radius: string) {
    this.radius = parseInt(radius, 10);
  }

  get selectedRadius() {
    return this.radius.toString();
  }

  get radiusMeters() {
    return this.radius * 1000;
  }
}
