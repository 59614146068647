import { OperatorType } from './criteria-operator-types.models';
import {
  Question,
  QuestionCriteriaMatching,
  QuestionType
} from '../questions/question.models';

export const OperatorsByQuestionType: {
  [K in QuestionType]: OperatorType[]
} = {
  T: [OperatorType.CONTAINS],
  N: [OperatorType.BETWEEN],
  MC: [OperatorType.ONE_OF],
  MS: [OperatorType.ONE_OF, OperatorType.ALL_OF],
  F: []
};

export const OperatorsByCriteriaMatch: {
  [K in QuestionCriteriaMatching]: OperatorType[]
} = {
  state: [OperatorType.PROXIMITY, OperatorType.ONE_OF],
  decade: [OperatorType.ONE_OF],
  none: []
};

export function operatorsForQuestion(question: Question): OperatorType[] {
  if (question.criteria && question.criteria.matching) {
    return OperatorsByCriteriaMatch[question.criteria.matching];
  }
  return OperatorsByQuestionType[question.type];
}
