import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import { IQuestionComponentState } from '../question/question.component';
import { ResponseValueService } from '../response-value.service';
import { NumberMaskService } from '../number-mask.service';

@Component({
  selector: 'app-old-response',
  templateUrl: './old-response.component.html',
  styleUrls: ['./old-response.component.sass']
})
export class OldResponseComponent implements OnInit, OnChanges {
  @Input()
  state: IQuestionComponentState;

  isNumberValue = false;
  numberValue = '';

  private numberMask: any;

  constructor(
    private responseValueService: ResponseValueService,
    private numberMaskService: NumberMaskService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.state) {
      this.numberMask = undefined;
      if (this.state.question.type === 'N') {
        this.numberMask = this.numberMaskService.create(this.state.question);
      }
      this.isNumberValue =
        this.state.question.type === 'N' &&
        !!this.state.oldResponse &&
        this.state.oldResponse.options.length === 1;
      this.numberValue = '';
      if (this.isNumberValue) {
        this.numberValue = this.responseValueService.formatValue(
          this.state.question,
          this.state.oldResponse!.options[0].text,
          this.numberMask
        );
      }
    }
  }
}
