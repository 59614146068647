<div class="pageheader">
  <h1>About Startup Muster</h1>
</div>
<div class="pagecontent" style="text-align: left">
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;text-align: left" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">Founded in 2013, Startup Muster's mission is to measure and publish the progress, challenges, and opportunities within the Australian startup ecosystem, in order to demonstrate and accelerate progress.</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;text-align: left" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">Startup Muster ran annual national surveys until 2018, with a brief hiatus until 2023, and is continuing in 2024 thanks to support from CommBank, Vanta, Stone &amp; Chalk, CSIRO, ACS Labs, UNSW, QUT, City of Sydney, City of Parramatta, Baxter IP, Gild Group, Amplitude and Relevance AI.</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;text-align: left" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">Want to spread the word? Please find promotional assets&nbsp;</span></span><a style="text-decoration:none;" target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/1duc2ydfeFbBLveiG4S47EY-xFELpEFte"><span style="background-color:transparent;color:#406ab5;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">here</span></span></a><span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">, and&nbsp;</span></span><span style="background-color:transparent;color:#406ab5;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"><a style="text-decoration:none;" target="_blank" rel="noopener noreferrer" href="javascript:void(0)" (click)="onClickContact($event)">contact us</a></span></span><span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"> if you need a different format of image.</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;text-align: left" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">The 2024 Startup Muster team consists of:</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;text-align: left" dir="ltr">
    <a style="text-decoration:none;" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/murrayhurps/"><span style="background-color:transparent;color:#0078d7;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">Murray Hurps</span></span></a><span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">, Managing Director,&nbsp;</span></span><span style="background-color:transparent;color:#0078d7;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">murray@startupmuster.com</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;text-align: left" dir="ltr">
    <a style="text-decoration:none;" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/heyadamspencer/"><span style="background-color:transparent;color:#0078d7;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">Adam Spencer</span></span></a><span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">, Survey Coordinator,&nbsp;</span></span><span style="background-color:transparent;color:#0078d7;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">adam@startupmuster.com</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;text-align: left" dir="ltr">
    <a style="text-decoration:none;" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/jafernaqvi/"><span style="background-color:transparent;color:#0078d7;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">Muhammad Jafer</span></span></a><span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">, CTO&nbsp;</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;text-align: left" dir="ltr">
    <a style="text-decoration:none;" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/daniellehurps/"><span style="background-color:transparent;color:#0078d7;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">Danielle Hurps</span></span></a><span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">, Creative Director</span></span>
  </p>
  <!--
<div class="pageheader">
  <h1>About Startup Muster</h1>
</div>

<div class="pagecontent">
  <p style="-webkit-text-stroke-width:0px;box-sizing:inherit;color:rgb(68, 84, 106);font-family:&quot;Source Sans Pro&quot;, system-ui, -apple-system, BlinkMacSystemFont, &quot;Avenir Next&quot;, Avenir, &quot;Segoe UI&quot;, &quot;Lucida Grande&quot;, &quot;Helvetica Neue&quot;, Helvetica, &quot;Fira Sans&quot;, Roboto, Noto, &quot;Droid Sans&quot;, Cantarell, Oxygen, Ubuntu, &quot;Franklin Gothic Medium&quot;, &quot;Century Gothic&quot;, &quot;Liberation Sans&quot;, sans-serif;font-size:18px;font-style:normal;font-variant-caps:normal;font-weight:400;letter-spacing:-0.2px;line-height:1.2;margin:0px auto 1.25rem;max-width:50rem;orphans:auto;text-align:start;text-decoration:none;text-indent:0px;text-transform:none;white-space:normal;widows:auto;word-spacing:0px;">
    <br>
    <span style="box-sizing:inherit;letter-spacing:-0.2px;text-align:center;">Founded in 2013, Startup Muster's mission is to measure&nbsp;and publish the progress, challenges, and opportunities within the Australian startup ecosystem, in order to demonstrate and accelerate progress.</span>
  </p>
  <p style="-webkit-text-stroke-width:0px;box-sizing:inherit;color:rgb(68, 84, 106);font-family:&quot;Source Sans Pro&quot;, system-ui, -apple-system, BlinkMacSystemFont, &quot;Avenir Next&quot;, Avenir, &quot;Segoe UI&quot;, &quot;Lucida Grande&quot;, &quot;Helvetica Neue&quot;, Helvetica, &quot;Fira Sans&quot;, Roboto, Noto, &quot;Droid Sans&quot;, Cantarell, Oxygen, Ubuntu, &quot;Franklin Gothic Medium&quot;, &quot;Century Gothic&quot;, &quot;Liberation Sans&quot;, sans-serif;font-size:18px;font-style:normal;font-variant-caps:normal;font-weight:400;letter-spacing:-0.2px;line-height:1.2;margin:0px auto 1.25rem;max-width:50rem;orphans:auto;text-align:start;text-decoration:none;text-indent:0px;text-transform:none;white-space:normal;widows:auto;word-spacing:0px;">
    <span class="gmail-Apple-converted-space" style="box-sizing:inherit;letter-spacing:-0.2px;text-align:center;">Startup Muster ran annual national surveys until 2018, and after a brief hiatus has now returned thanks to support from&nbsp;</span><span style="box-sizing:inherit;letter-spacing:-0.2px;">Atlassian,</span><span class="gmail-Apple-converted-space" style="box-sizing:inherit;letter-spacing:-0.2px;">&nbsp;</span><span style="box-sizing:inherit;letter-spacing:-0.2px;">NSW Government,</span><span class="gmail-Apple-converted-space" style="box-sizing:inherit;letter-spacing:-0.2px;">&nbsp;</span><span style="box-sizing:inherit;letter-spacing:-0.2px;">Microsoft,&nbsp;Antler</span><span class="gmail-Apple-converted-space" style="box-sizing:inherit;letter-spacing:-0.2px;">&nbsp;</span><span style="box-sizing:inherit;letter-spacing:-0.2px;">,&nbsp;The Gild&nbsp;Group and Parramatta City Council.</span>
  </p>
  <p style="-webkit-text-stroke-width:0px;box-sizing:inherit;color:rgb(68, 84, 106);font-family:&quot;Source Sans Pro&quot;, system-ui, -apple-system, BlinkMacSystemFont, &quot;Avenir Next&quot;, Avenir, &quot;Segoe UI&quot;, &quot;Lucida Grande&quot;, &quot;Helvetica Neue&quot;, Helvetica, &quot;Fira Sans&quot;, Roboto, Noto, &quot;Droid Sans&quot;, Cantarell, Oxygen, Ubuntu, &quot;Franklin Gothic Medium&quot;, &quot;Century Gothic&quot;, &quot;Liberation Sans&quot;, sans-serif;font-size:18px;font-style:normal;font-variant-caps:normal;font-weight:400;letter-spacing:-0.2px;line-height:1.2;margin:0px auto 1.25rem;max-width:50rem;orphans:auto;text-align:start;text-decoration:none;text-indent:0px;text-transform:none;white-space:normal;widows:auto;word-spacing:0px;">
    Want to spread the&nbsp;word? Please find promotional assets <a style="color:rgb(0, 120, 215);" target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/1pKE80h2PJy4JL3U1LzrLMKMfrbWCUfTC?usp=share_link" title="https://drive.google.com/drive/folders/1pKE80h2PJy4JL3U1LzrLMKMfrbWCUfTC?usp=share_link">here</a>, and <a href="/contact">contact us</a> if you need a different format of image.
  </p>
  <p style="-webkit-text-stroke-width:0px;box-sizing:inherit;color:rgb(68, 84, 106);font-family:&quot;Source Sans Pro&quot;, system-ui, -apple-system, BlinkMacSystemFont, &quot;Avenir Next&quot;, Avenir, &quot;Segoe UI&quot;, &quot;Lucida Grande&quot;, &quot;Helvetica Neue&quot;, Helvetica, &quot;Fira Sans&quot;, Roboto, Noto, &quot;Droid Sans&quot;, Cantarell, Oxygen, Ubuntu, &quot;Franklin Gothic Medium&quot;, &quot;Century Gothic&quot;, &quot;Liberation Sans&quot;, sans-serif;font-size:18px;font-style:normal;font-variant-caps:normal;font-weight:400;letter-spacing:-0.2px;line-height:1.2;margin:0px auto 1.25rem;max-width:50rem;orphans:auto;text-align:start;text-decoration:none;text-indent:0px;text-transform:none;white-space:normal;widows:auto;word-spacing:0px;">
    <span style="box-sizing:inherit;letter-spacing:-0.2px;">The 2024 Startup Muster team consists of:</span>
  </p>
  <p style="-webkit-text-stroke-width:0px;box-sizing:inherit;color:rgb(68, 84, 106);font-family:&quot;Source Sans Pro&quot;, system-ui, -apple-system, BlinkMacSystemFont, &quot;Avenir Next&quot;, Avenir, &quot;Segoe UI&quot;, &quot;Lucida Grande&quot;, &quot;Helvetica Neue&quot;, Helvetica, &quot;Fira Sans&quot;, Roboto, Noto, &quot;Droid Sans&quot;, Cantarell, Oxygen, Ubuntu, &quot;Franklin Gothic Medium&quot;, &quot;Century Gothic&quot;, &quot;Liberation Sans&quot;, sans-serif;font-size:18px;font-style:normal;font-variant-caps:normal;font-weight:400;letter-spacing:-0.2px;line-height:1.2;margin:0px auto 1.25rem;max-width:50rem;orphans:auto;text-align:start;text-decoration:none;text-indent:0px;text-transform:none;white-space:normal;widows:auto;word-spacing:0px;">
    <a style="box-sizing:inherit;color:rgb(0, 120, 215);text-decoration:none;" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/murrayhurps/" title="https://www.linkedin.com/in/murrayhurps/">Murray Hurps</a>, Managing Director, <a style="color:rgb(0, 120, 215);" href="mailto:murray@startupmuster.com" title="mailto:murray@startupmuster.com">murray@startupmuster.com</a>
  </p>
  <p style="-webkit-text-stroke-width:0px;box-sizing:inherit;color:rgb(68, 84, 106);font-family:&quot;Source Sans Pro&quot;, system-ui, -apple-system, BlinkMacSystemFont, &quot;Avenir Next&quot;, Avenir, &quot;Segoe UI&quot;, &quot;Lucida Grande&quot;, &quot;Helvetica Neue&quot;, Helvetica, &quot;Fira Sans&quot;, Roboto, Noto, &quot;Droid Sans&quot;, Cantarell, Oxygen, Ubuntu, &quot;Franklin Gothic Medium&quot;, &quot;Century Gothic&quot;, &quot;Liberation Sans&quot;, sans-serif;font-size:18px;font-style:normal;font-variant-caps:normal;font-weight:400;letter-spacing:-0.2px;line-height:1.2;margin:0px auto 1.25rem;max-width:50rem;orphans:auto;text-align:start;text-decoration:none;text-indent:0px;text-transform:none;white-space:normal;widows:auto;word-spacing:0px;">
    <a style="box-sizing:inherit;color:rgb(0, 120, 215);text-decoration:none;" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/heyadamspencer/" title="https://www.linkedin.com/in/heyadamspencer/">Adam Spencer</a>, Survey Coordinator, <a style="color:rgb(0, 120, 215);" href="mailto:adam@startupmuster.com" title="mailto:adam@startupmuster.com">adam@startupmuster.com</a>
  </p>
  <p style="-webkit-text-stroke-width:0px;box-sizing:inherit;color:rgb(68, 84, 106);font-family:&quot;Source Sans Pro&quot;, system-ui, -apple-system, BlinkMacSystemFont, &quot;Avenir Next&quot;, Avenir, &quot;Segoe UI&quot;, &quot;Lucida Grande&quot;, &quot;Helvetica Neue&quot;, Helvetica, &quot;Fira Sans&quot;, Roboto, Noto, &quot;Droid Sans&quot;, Cantarell, Oxygen, Ubuntu, &quot;Franklin Gothic Medium&quot;, &quot;Century Gothic&quot;, &quot;Liberation Sans&quot;, sans-serif;font-size:18px;font-style:normal;font-variant-caps:normal;font-weight:400;letter-spacing:-0.2px;line-height:1.2;margin:0px auto 1.25rem;max-width:50rem;orphans:auto;text-align:start;text-decoration:none;text-indent:0px;text-transform:none;white-space:normal;widows:auto;word-spacing:0px;">
    <a style="box-sizing:inherit;color:rgb(0, 120, 215);text-decoration:none;" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/jafernaqvi/" title="https://www.linkedin.com/in/jafernaqvi/">Muhammad Jafer</a>, CTO<span class="gmail-Apple-converted-space">&nbsp;</span>
  </p>
  <p style="-webkit-text-stroke-width:0px;box-sizing:inherit;color:rgb(68, 84, 106);font-family:&quot;Source Sans Pro&quot;, system-ui, -apple-system, BlinkMacSystemFont, &quot;Avenir Next&quot;, Avenir, &quot;Segoe UI&quot;, &quot;Lucida Grande&quot;, &quot;Helvetica Neue&quot;, Helvetica, &quot;Fira Sans&quot;, Roboto, Noto, &quot;Droid Sans&quot;, Cantarell, Oxygen, Ubuntu, &quot;Franklin Gothic Medium&quot;, &quot;Century Gothic&quot;, &quot;Liberation Sans&quot;, sans-serif;font-size:18px;font-style:normal;font-variant-caps:normal;font-weight:400;letter-spacing:-0.2px;line-height:1.2;margin:0px auto 1.25rem;max-width:50rem;orphans:auto;text-align:start;text-decoration:none;text-indent:0px;text-transform:none;white-space:normal;widows:auto;word-spacing:0px;">
    <a style="box-sizing:inherit;color:rgb(0, 120, 215);text-decoration:none;" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/daniellehurps/" title="https://www.linkedin.com/in/daniellehurps/">Danielle Hurps</a>, Creative Director
  </p>

</div>
-->
