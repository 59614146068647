
import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';
import { ICollectable } from './common/core/collection';
import { IDataCollection } from './app/data/data.models';
import * as _ from 'lodash';
import { AppLogger } from './app/app-logger';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { combineLatest, withLatestFrom, filter, map } from 'rxjs/operators';

export function createFinder<T>(
  source$: Observable<T[]>,
  idGetter: (value: T) => string
) {
  return (id$: Observable<string>) =>
    source$.pipe(
      combineLatest(id$, (values, id) => values.find(v => idGetter(v) === id))
    );
}

export function createDataFinder<T extends ICollectable>(
  source$: Observable<IDataCollection<T>>
) {
  return (id$: Observable<string>) =>
    source$.pipe(combineLatest(id$, (collection, id) => collection.byId[id]));
}

export function withLatestFinder<T, TOther>(
  source$: Observable<T>,
  other$: Observable<_.Dictionary<TOther>>,
  idSelector: (value: T) => string
): Observable<[T, TOther]> {
  return source$.pipe(
    withLatestFrom(other$, (sourceValue, otherValue) => {
      return <[T, TOther]>[sourceValue, otherValue[idSelector(sourceValue)]];
    })
  );
}

export type AllObservables<T> = { [P in keyof T]: Observable<T[P]> };

/** Transpose an object of observables, to an observable of objects. */
export function projectOut<T>(obj: AllObservables<T>): Observable<T> {
  const observables = [];
  const keys: (keyof T)[] = [];
  // tslint:disable-next-line:forin
  for (const key in obj) {
    keys.push(key);
    observables.push(obj[key]);
  }
  return observableCombineLatest(observables).pipe(map(values => {
    const result: any = {};
    values.forEach((value, i) => (result[keys[i]] = value));
    return result;
  }));
}

export function filterNull<T>(
  source$: Observable<T | undefined | null>
): Observable<T> {
  return source$.pipe(filter(x => !!x)) as Observable<T>;
}

/** Return all items from an IDataCollection, unordered. */
export function all<T extends ICollectable>(
  collection$: Observable<IDataCollection<T>>
): Observable<T[]> {
  return collection$.pipe(map(c => _.values(c.byId)));
}

/** Return all items from an IDataCollection, in order. */
export function allOrdered<T extends ICollectable>(
  collection$: Observable<IDataCollection<T>>
): Observable<T[]> {
  return collection$.pipe(map(c => c.ids.map(id => c.byId[id])));
}
