import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface IButtonGroupOption {
  text: string;
  value: string;
}

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.sass']
})
export class ButtonGroupComponent implements OnInit {
  @Input()
  options: IButtonGroupOption[];
  @Input()
  selected: string;
  @Input()
  id: string;
  @Output()
  change = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  changeSelected(event: Event, value: string) {
    event.stopPropagation();
    this.change.emit(value);
  }
}
