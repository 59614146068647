import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CallApiActionTypes, CallApiLoaded } from '../shared/call-api';
import { SavingHandlerService } from '../shared/saving-handler.service';
import {
  OpportunitiesActionTypes,
  OpportunitiesCallApiKeys,
  OpportunitiesSetFilter
} from './opportunities.actions';

@Injectable()
export class OpportunitiesEffects {
  @Effect({ dispatch: false })
  redirect$ = this.actions$.pipe(
    ofType(CallApiActionTypes.CALL_API_LOADED),
    tap((action: CallApiLoaded) => {
      if (
        action.payload.key === OpportunitiesCallApiKeys.REQUEST_CONNECTION &&
        action.payload.response.success
      ) {
        this.router.navigate(['/recommended-support']);
      }
    })
  );

  @Effect({ dispatch: false })
  setFilter$ = this.actions$.pipe(
    ofType(OpportunitiesActionTypes.SET_FILTER),
    tap((action: OpportunitiesSetFilter) => {
      if (!action.value) {
        this.router.navigate(['/recommended-support']);
      } else {
        this.router.navigate([
          '/recommended-support',
          { filter: action.value }
        ]);
      }
    })
  );

  constructor(
    private actions$: Actions,
    private savingHandler: SavingHandlerService,
    private router: Router
  ) {}
}
