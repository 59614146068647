
import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-criteria-edit-set',
  templateUrl: './criteria-edit-set.component.html',
  styleUrls: ['./criteria-edit-set.component.sass']
})
export class CriteriaEditSetComponent implements OnInit {
  state$: any;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {
    this.state$ = route.params.pipe(map(params => {
      return {
        setId: params['id']
      };
    }));
  }

  ngOnInit() {}
}
