<div class="modal"
     [ngClass]="{ 'no-pad': noPad }">
  <input class="modal-state"
         id="modal-1"
         type="checkbox"
         checked="checked" />
  <div class="modal-fade-screen"
       (click)="onClose()">
    <div class="modal-inner"
         (click)="onInnerClick($event)">
      <div class="modal-close"
           for="modal-1"
           (click)="onClose()"></div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
