import { take } from 'rxjs/operators';
import { DataLoaderService } from '../shared/data-loader.service';
import { LoadStateCallApiArgs } from './opportunities.actions';
import { Observable } from 'rxjs';
import { CallApiService } from '../shared/call-api';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  Resolve
} from '@angular/router';
import { AppState } from '../app.models';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

@Injectable()
export class OpportunitiesDataLoaderService
  implements CanActivate, Resolve<boolean> {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private loader: DataLoaderService,
    private callApi: CallApiService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.loader
      .load({
        state$: this.store.select(s => s.opportunities.loaded),
        isLoaded: loaded => loaded,
        triggerLoad: () =>
          this.callApi.callApi(new LoadStateCallApiArgs()).subscribe(this.store) // TODO: refactor
      })
      .pipe(take(1));
  }
}
