<input type="text"
       inputmode="numeric"
       [textMask]="mask"
       [name]="question.key"
       [title]="question.text"
       [(ngModel)]="value"
       (input)="answer()"
       (keypress)="onKeypress($event)"
       (focus)="onFocus($event)"
       (blur)="onBlur($event)"
       [placeholder]="isSkipped ? question.skippable : ''">
