<app-grid-select [selections]="selections"
                 [options]="selectOptions"
                 (select)="onSelect($event)"
                 [style]="selectStyle"></app-grid-select>

<input #extraElement class="extra"
       type="text"
       *ngIf="showExtraBox"
       [(ngModel)]="extra"
       (input)="answer($event)">
