import * as Rollbar from 'rollbar';
import code_version from 'version';

Rollbar.init({
  accessToken: '346a76526d1d40e89c0de9ab83ff08a9',
  captureUncaught: true,
  captureUnhandledRejections: true,
  // source_map_enabled: true,
  payload: {
    environment: 'production',
    client: {
      javascript: {
        code_version,
        source_map_enabled: true
      }
    }
  }
});

console.log("boot intercom");

(window as any).Intercom('boot', {
  app_id: 'jceziuaf'
});

export const environment = {
  production: true,
  staging: false,
  dev: false
};
