import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild, OnInit
} from '@angular/core';
import {
  MultipleChoiceQuestion,
  HasQuestionId
} from '../../../common/questions/question.models';
import { AnswerDispatcherService } from '../answer-dispatcher.service';
import {
  ChoiceResponse,
  SkippedResponse
} from '../../../common/questions/response.model';
import { BaseQuestionComponent } from '../base-question.component';
import {
  Selections,
  Option
} from '../../shared/search-select/search-select.component';

@Component({
  selector: 'app-multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls: ['./multiple-choice-question.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleChoiceQuestionComponent extends BaseQuestionComponent
  implements OnChanges, OnInit {
  @Input() question: MultipleChoiceQuestion & HasQuestionId;
  @Input() response?: ChoiceResponse | SkippedResponse;
  @Input() newOptions: string[] = [];

  choice: string;
  extra: string;
  // TODO: refactor extra handling with multiple-selection
  @ViewChild('extraElement') extraElement: ElementRef;
  private extraFirstShown = true;

  selections: Selections = {};
  selectOptions: Option[] = [];
  selectStyle = 'grid';

  constructor(private answerDispatcher: AnswerDispatcherService) {
    super();
  }

  ngOnInit(): void {

    if (this.response) {
      this.answerDispatcher.answer(this.question, this.response);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selections = {};

    if (changes.question) {
      this.selectOptions = this.question.options.map(o => {
        let icon;
        if (this.newOptions.indexOf(o.optionId) > -1) {
          icon = 'new';
        }
        return {
          id: o.optionId,
          text: o.text,
          icon
        };
      });
      this.selectStyle = this.selectOptions.length <= 7 ? 'column' : 'grid';
    }

    this.choice = '';
    this.extra = '';
    if (this.response && this.response.type === 'choice') {
      this.choice = this.response.choice;
      this.extra = this.response.extra;
      this.selections[this.response.choice] = true;
    }

    if (this.showExtraBox) {
      if (this.extraFirstShown) {
        this.extraFirstShown = false;
        if (this.extraElement && this.extraElement.nativeElement) {
          const el = this.extraElement.nativeElement;
          setTimeout(() => el.focus(), 0);
        }
      }
    } else {
      this.extraFirstShown = true;
    }
  }

  onSelect(id: string) {
    this.choice = id;
    this.answer();
  }

  answer($event?: Event) {
    if ($event) {
      $event.stopPropagation();
    }
    const response: ChoiceResponse = {
      questionId: this.question.id,
      questionKey: this.question.key,
      type: 'choice',
      choice: this.choice,
      extra: this.extra,
      updatedAt: new Date()
    };
    this.answerDispatcher.answer(this.question, response);
  }

  select(id: string) {
    this.choice = id;
    this.answer();
  }

  get showAsGroup() {
    return (
      this.question.options.map(o => o.text.length).reduce((p, c) => p + c, 0) <
      30
    );
  }

  get showExtraBox() {
    const currentOption = this.question.options.find(
      o => o.optionId === this.choice
    );
    return currentOption && currentOption.showBox;
  }

  isSelected(id: string) {
    return this.choice === id;
  }
}
