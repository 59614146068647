import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
  NgZone,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import {
  TextQuestion,
  HasQuestionId
} from '../../../common/questions/question.models';
import { AnswerDispatcherService } from '../answer-dispatcher.service';
import {
  ValueResponse,
  SkippedResponse
} from '../../../common/questions/response.model';
import { BaseQuestionComponent } from '../base-question.component';
import { SearchPickerService } from '../../shared/search-picker.service';
import { Subscription } from 'rxjs';
import { MapsAPILoader } from '@ng-maps/core';

// declare var google: any;

@Component({
  selector: 'app-text-question',
  templateUrl: './text-question.component.html',
  styleUrls: ['./text-question.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextQuestionComponent
  extends BaseQuestionComponent
  implements OnChanges, OnInit, OnDestroy {
  @Input()
  question: TextQuestion & HasQuestionId;
  @Input()
  response?: ValueResponse | SkippedResponse;

  @ViewChild('input')
  inputElement: ElementRef;

  value: string;
  mask?: any;
  uiType: 'plain' | 'address' | 'masked' | 'autocomplete' = 'plain';
  pickerKey: string;

  searchResults: string[];

  private isFocused = false;
  private pickerSelectionSubscription: Subscription;
  private pickerSelection: string | null = null;

  constructor(
    private answerDispatcher: AnswerDispatcherService,
    private ngZone: NgZone,
    private detectorRef: ChangeDetectorRef,
    private searchPicker: SearchPickerService,
    private mapsApiLoader: MapsAPILoader
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.question.isAddress) {
      this.initMapsAutocomplete();
    }


    if (this.response) {
      this.answerDispatcher.answer(this.question, this.response);
    }
  }

  ngOnDestroy(): void {
    if (this.pickerSelectionSubscription) {
      this.pickerSelectionSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.response && this.response.type !== 'skipped') {
      this.value = this.response.value;
    } else {
      this.value = '';
    }
    if (changes.question && this.question) {
      if (
        this.question.key ===
        'QEducationalInstitutionForHighestLevelOfEducation'
      ) {
        this.uiType = 'autocomplete';
        this.pickerKey = 'University';
        if (this.pickerSelectionSubscription) {
          this.pickerSelectionSubscription.unsubscribe();
        }
        this.pickerSelectionSubscription = this.searchPicker
          .selection$(this.question.key)
          .subscribe(selection => {
            this.pickerSelection = selection;
          });
      } else if (this.question.inputType === 'twitter') {
        const charRegexp = /\S/;
        this.mask = {
          mask: (rawValue: string = '') => {
            let mask: (string | RegExp)[] = [];
            mask.push('@');
            mask = mask.concat(rawValue.split('').map(c => charRegexp));
            return mask;
          },
          guide: false
        };
        this.uiType = 'masked';
      } else {
        this.mask = undefined;
        this.uiType = this.question.isAddress ? 'address' : 'plain';
      }
    }
  }

  answer() {
    if (
      this.response &&
      this.response.type === 'skipped' &&
      this.value === ''
    ) {
      return;
    }
    const response: ValueResponse = {
      questionId: this.question.id,
      questionKey: this.question.key,
      type: 'value',
      value: this.value,
      updatedAt: new Date()
    };
    this.answerDispatcher.answer(this.question, response);
  }

  completeInput() {
    this.searchPicker.setVisibility(this.question.key, true);
    this.answer();
  }

  completePick(pick: string) {
    this.searchPicker.setVisibility(this.question.key, false);
    this.value = pick;
    this.answer();
  }

  get inputType() {
    if (this.question.inputType === 'twitter') {
      return 'text';
    }
    return this.question.inputType || 'text';
  }

  get isSkipped() {
    return this.response && this.response.type === 'skipped';
  }

  get placeholder(): string {
    if (!this.isFocused && this.isSkipped) {
      return this.question.skippable!;
    }
    return '';
  }

  contentEditableKeyUp($event: KeyboardEvent) {
    switch ($event.keyCode) {
      case 9: // tab
      case 16: // shift
      case 17: // ctl
      case 18: // alt
      case 91: // cmd
      case 93: // cmd
        if (this.value === '') {
          return;
        }
    }
    this.answer();
  }

  inputKeyPress($event: KeyboardEvent) {
    $event.stopPropagation();
    if ($event.keyCode === 13) {
      // enter
      if (this.pickerSelection) {
        this.completePick(this.pickerSelection);
      } else {
        if (this.uiType === 'autocomplete') {
          this.searchPicker.setVisibility(this.question.key, false);
        }
        this.answerDispatcher.nextQuestion(this.question.key);
      }
      return false;
    }
    return;
  }

  inputKeyDown($event: KeyboardEvent) {
    $event.stopPropagation();
    if (this.uiType === 'autocomplete') {
      switch ($event.keyCode) {
        case 40: // down
          this.searchPicker.moveSelection(this.question.key, 'down');
          break;
        case 38: // up
          this.searchPicker.moveSelection(this.question.key, 'up');
          break;
        case 27: // esc
          this.searchPicker.setVisibility(this.question.key, false);
          break;
        default:
          return;
      }
      return false;
    }
    return;
  }

  onFocus($event: FocusEvent) {
    this.isFocused = true;
  }

  onBlur($event: FocusEvent) {
    this.isFocused = false;
    if (this.uiType === 'autocomplete') {
      setTimeout(() => {
        if (this.pickerSelection) {
          this.completePick(this.pickerSelection);
        } else {
          this.searchPicker.setVisibility(this.question.key, false);
        }
      }, 0);
    }
  }

  private initMapsAutocomplete() {
    this.mapsApiLoader.load().then(() => {
      // {south: -44.66792461145285, west: 108.63228125, north: -10.076028944668456, east: 159.25728125}
      const defaultBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(-44.66792461145285, 108.63228125),
        new google.maps.LatLng(-10.076028944668456, 159.25728125)
      );
      const options = {
        bounds: defaultBounds
      };
      const autocomplete = new google.maps.places.Autocomplete(
        this.inputElement.nativeElement,
        options
      );
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        this.ngZone.run(() => {
          const value = this.inputElement.nativeElement.value;
          const place = autocomplete.getPlace();
          // TODO: consider using formatted_address?
          // if (this.value && !this.value.match(/[0-9]{4}/) && place && place.formatted_address) {
          //   this.value = place.formatted_address;
          //   this.answer();
          // }

          if (
            value &&
            !value.match(/[0-9]{4}/) &&
            place &&
            place.address_components
          ) {
            let postcode = '';

            for (let i = 0; i < place.address_components.length; i++) {
              if (place.address_components[i].types) {
                for (
                  let j = 0;
                  j < place.address_components[i].types.length;
                  j++
                ) {
                  if (place.address_components[i].types[j] === 'postal_code') {
                    postcode = place.address_components[i].long_name;
                  }
                }
              }
            }

            if (postcode) {
              this.value = value + ' ' + postcode;
              this.answer();
            }
          }
        });
      });
    });
  }
}
