import { Component, OnInit } from '@angular/core';
import { IntercomService } from '../shared/intercom.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.sass']
})
export class AboutComponent implements OnInit {

  constructor(private intercom: IntercomService, private router: Router) { }

  ngOnInit() {
    const currentPath = this.router.url;
    if (currentPath === '/contact') {
      setTimeout(() => { this.onClickContact(new MouseEvent('click')); }, 1);
    }
  }

  onClickContact($event: MouseEvent) {
    $event.stopPropagation();
    this.intercom.show();
    return false;
  }

}
