<select [name]="question.key + '-month'"
        [(ngModel)]="month"
        (change)="answer()">
  <option value="" disabled></option>
  <option *ngFor="let o of monthOptions" [value]="o.id">{{ o.text }}</option>
</select>

<select [name]="question.key + '-year'"
        [(ngModel)]="year"
        (change)="answer()">
  <option value="" disabled></option>
  <option *ngFor="let y of yearOptions" [value]="y">{{ y }}</option>
</select>
