import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { IConnectionInfo } from '../../../common/opportunities.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.models';
import { Observable } from 'rxjs';
import { extractValue } from '../../../common/questions/response.util';
import { QuestionIds } from '../../../common/questions/constants';
import {
  generateForSupporter,
  ConnectionInfoForSupporter
} from '../../../common/questions/connection-info-generator';
import { Router } from '@angular/router';

export type ConnectWithAction =
  | { type: 'dismiss' }
  | { type: 'connect'; reason: string };

export interface IConnectWithState extends IConnectionInfo {
  ourName?: string;
  ourInfo: ConnectionInfoForSupporter;
  dailyLimitReached: boolean;
}

@Component({
  selector: 'app-connect-with',
  templateUrl: './connect-with.component.html',
  styleUrls: ['./connect-with.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectWithComponent implements OnInit {
  @Input() state: IConnectWithState;
  @Output() action = new EventEmitter<ConnectWithAction>();

  reason = '';
  isValid = {
    reason: true
  };

  constructor() {}

  ngOnInit() {}

  connect() {
    this.isValid.reason = !!this.reason && this.reason.trim().length > 0;
    if (this.isValid.reason) {
      this.action.emit({ type: 'connect', reason: this.reason });
    }
  }

  dismiss() {
    this.action.emit({ type: 'dismiss' });
  }
}
