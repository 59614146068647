import { switchMap, first } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../user.service';
import { AuthGuardService } from '../auth-guard.service';
import { Observable, Subscription, empty } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.models';
import { LoginLinkRequested } from '../../global.actions';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  showError = false;
  emailValue = '';

  constructor(
    private userService: UserService,
    private authGuard: AuthGuardService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    const emailURL = this.route.snapshot.paramMap.get('email');
    if (emailURL) {
      this.processLogin(emailURL);
      return;
    }

    this.subscription = this.route.params
      .pipe(
        first(),
        switchMap(p => {
          const token = p['id'];
          if (token) {
            return this.userService.auth(token);
          }
          return empty();
        })
      )
      .subscribe(success => {
        if (success) {
          this.router.navigate(['/survey']);
        } else {
          this.showError = true;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  requestLogin() {
    if (this.emailValue) {
      this.showError = false;
      this.processLogin(this.emailValue);
    }

  }
  processLogin(email: string) {
      this.store.dispatch(
        new LoginLinkRequested({
          email: email,
          redirectOnSuccess: true
        })
      );
  }
}
