<form [formGroup]="form" novalidate>

  <div>
    <label for="email">Email</label>
    <input type="email" formControlName="email" id="email">
    <small class="validation" *ngIf="showValidation('email')">
      Email is required.
    </small>
  </div>

  <div class="validation" *ngIf="showError">
    Invalid email.
  </div>

  <button class="action primary" type="submit" (click)="save()">Request Login Link</button>
</form>
