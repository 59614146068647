import { Action } from '@ngrx/store';
import { ICollectable } from '../../common/core/collection';
import {
  QuestionId,
  QuestionKey
} from '../../common/questions/question.models';
import { IQuestionsSeen } from '../../common/questions/questions-seen.models';
import { QuestionResponse } from '../../common/questions/response.model';
import { CallApiMethods, ICallApiArgs } from '../shared/call-api';
import { ResponseValidationResult } from 'common/questions/response-validator.service';

export namespace QuestionActionTypes {
  export const QUESTIONS_LOAD: '[Data] Questions Load' =
    '[Data] Questions Load';
  export const QUESTIONS_FAILED: '[Data] Questions Failed' =
    '[Data] Questions Failed';
  export const QUESTIONS_LOADED: '[Data] Questions Loaded' =
    '[Data] Questions Loaded';

  export const RESPONSE_UPDATE: '[Data] Question Response Update' =
    '[Data] Question Response Update';
  export const NEXT_QUESTION: '[Data] Next Question' = '[Data] Next Question';
  export const QUESTION_RESPONSE_VALID: '[Data] Question Response Valid' =
    '[Data] Question Response Valid';
  export const QUESTION_RESPONSE_RESULT_MATCHES: '[Data] Question Response Result Matches' =
    '[Data] Question Response Result Matches';
  export const UPDATE_QUESTION_VISIBILITY: '[Data] Update Question Visibilitiy' =
    '[Data] Update Question Visibilitiy';
  export const UPDATE_QUESTION_DEPENDENCIES: '[Data] Update Question Dependencies' =
    '[Data] Update Question Dependencies';
  export const COMPARE_QUESTION_RESULTS: '[Data] Compare Question Results' =
    '[Data] Compare Question Results';

  export const SHOW_POPUP: '[Data] Question Show Popup' =
    '[Data] Question Show Popup';

  export const RETRY_SAVE: '[Data] Questions Rety Save' =
    '[Data] Questions Rety Save';

  export const COPY_OLD_RESPONSE: '[Data] Copy Old Response' =
    '[Data] Copy Old Response';
}

export type QuestionActions =
  | QuestionResponseUpdate
  | NextQuestion
  | QuestionResponseValid
  | QuestionResponseResultMatches
  | UpdateQuestionVisibility
  | UpdateQuestionDependencies
  | CompareQuestionResults
  | RetrySave
  | CopyOldResponse;

export namespace QuestionCallApiKeys {
  export const LOAD_QUESTIONS: '[Data] Load Questions' =
    '[Data] Load Questions';
  export const LOAD_RESPONSES: '[Data] Load Responses' =
    '[Data] Load Responses';
  export const SAVE_RESPONSE: '[Data] Save Response' = '[Data] Save Response';
  // export const BATCH_SAVE_RESPONSE: '[Data] Batch Save Response' = '[Data] Batch Save Response';
  export const LOAD_ALL_RESULTS: '[Data] Load All Results' =
    '[Data] Load All Results';
  export const LOAD_QUESTION_RESPONSES_LOCKED: '[Data] Load Question Responses Locked' =
    '[Data] Load Question Responses Locked';
  export const LOCK_QUESTION_ID: '[Data] Lock Question Id' =
    '[Data] Lock Question Id';
  export const QUESTIONS_SEEN: '[Data] Questions Seen' =
    '[Data] Questions Seen';
}

export class LoadQuestionsCallApiArgs implements ICallApiArgs {
  key = QuestionCallApiKeys.LOAD_QUESTIONS;
  url = '/questions';
}

export class LoadQuestionResponsesCallApiArgs implements ICallApiArgs {
  key = QuestionCallApiKeys.LOAD_RESPONSES;
  url = '/questions/responses';
}

export class SaveQuestionResponseCallApiArgs implements ICallApiArgs {
  key = QuestionCallApiKeys.SAVE_RESPONSE;
  url: string;
  method = CallApiMethods.Put;
  data: QuestionResponse;

  constructor(questionId: QuestionId, response: QuestionResponse) {
    this.url = `/questions/${questionId}/response`;
    this.data = response;
  }
}

// export class BatchQuestionResponseCallApiArgs implements ICallApiArgs {
//   key = QuestionCallApiKeys.BATCH_SAVE_RESPONSE;
//   url = '/questions/batch';
//   method = CallApiMethods.Post;
//   data: QuestionResponseWithId[];

//   constructor(responses: QuestionResponseWithId[]) {
//     this.data = responses;
//   }
// }

export class QuestionResponseUpdate implements Action {
  type = QuestionActionTypes.RESPONSE_UPDATE;
  constructor(
    public payload: { questionId: QuestionId; response: QuestionResponse }
  ) {}
}

export class NextQuestion implements Action {
  type = QuestionActionTypes.NEXT_QUESTION;
  constructor(public payload: { fromQuestionKey: QuestionKey }) {}
}

export class QuestionResponseValid implements Action {
  type = QuestionActionTypes.QUESTION_RESPONSE_VALID;
  constructor(
    public payload: { [questionId: string]: ResponseValidationResult }
  ) {}
}

export class QuestionResponseResultMatches implements Action {
  type = QuestionActionTypes.QUESTION_RESPONSE_RESULT_MATCHES;
  constructor(public payload: _.Dictionary<string[]>) {}
}

export class UpdateQuestionVisibility implements Action {
  type = QuestionActionTypes.UPDATE_QUESTION_VISIBILITY;
  constructor(public payload: _.Dictionary<boolean>) {}
}

export class UpdateQuestionDependencies implements Action {
  type = QuestionActionTypes.UPDATE_QUESTION_DEPENDENCIES;
  constructor(public payload: _.Dictionary<QuestionId[]>) {}
}

// export class LoadQuestionResultsCallApiArgs implements ICallApiArgs {
//   key = QuestionCallApiKeys.LOAD_QUESTION_RESULTS;
//   url: string;

//   constructor(questionId: string) {
//     this.url = `/results/${questionId}`;
//   }
// }

export class LoadAllResultsCallApiArgs implements ICallApiArgs {
  key = QuestionCallApiKeys.LOAD_ALL_RESULTS;
  url = '/results/all';
}

export class LoadQuestionResponsesLocked implements ICallApiArgs {
  key = QuestionCallApiKeys.LOAD_QUESTION_RESPONSES_LOCKED;
  url = '/questions/locked';
}

export class LockQuestionIdCallApiArgs implements ICallApiArgs {
  key = QuestionCallApiKeys.LOCK_QUESTION_ID;
  method = CallApiMethods.Put;
  url: string;

  constructor(public data: ICollectable) {
    this.url = `/questions/${data._id}/lock`;
  }
}

export class ShowPopup implements Action {
  type = QuestionActionTypes.SHOW_POPUP;
  constructor(public payload: { url: string }) {}
}

export class CompareQuestionResults implements Action {
  type = QuestionActionTypes.COMPARE_QUESTION_RESULTS;
  constructor(public payload: { questionId: QuestionId }) {}
}

export class QuestionsSeenCallApiArgs implements ICallApiArgs {
  key = QuestionCallApiKeys.QUESTIONS_SEEN;
  method = CallApiMethods.Put;
  url = '/questions/seen';
  data: IQuestionsSeen;
  constructor(public payload: IQuestionsSeen) {
    this.data = payload;
  }
}

export class RetrySave implements Action {
  type = QuestionActionTypes.RETRY_SAVE;
}

export class CopyOldResponse implements Action {
  type = QuestionActionTypes.COPY_OLD_RESPONSE;
  constructor(public payload: QuestionKey) {}
}

// export class UpdateQuestionSeen implements Action {

// }
