
import { ISearchPickerState, ISearchPickerUiState } from "../shared/search-picker.models";
import { AppActions } from "../app.actions";
import { combineReducers, Action } from "@ngrx/store";
import { ISearchPickerResult } from "../../common/questions/search-picker.models";
import { SearchPickerActionTypes, SetSelection } from "../shared/search-picker.actions";
import * as timm from 'timm';
import { AppReducer } from "../app.reducer";

export function allResultsByKeyReducer(state: _.Dictionary<ISearchPickerResult[]> = {}, action: AppActions) {
  switch (action.type) {
    case SearchPickerActionTypes.SET_RESULTS:
      return timm.set(state, action.payload.key, action.payload.results);
  }
  return state;
}

export function filteredResultsByQuestionReducer(state: _.Dictionary<ISearchPickerResult[]> = {}, action: AppActions) {
  switch (action.type) {
    case SearchPickerActionTypes.RESULTS_FILTERED:
      return timm.set(state, action.payload.questionId, action.payload.results);
  }
  return state;
}

export function uiStateReducer(state: ISearchPickerUiState = { visible: false }, action: AppActions) {
  switch (action.type) {
    case SearchPickerActionTypes.SET_SELECTION:
      return timm.set(state, 'selection', action.payload.selection);
    case SearchPickerActionTypes.VISIBILITY_TOGGLE:
      return timm.set(state, 'visible', action.payload.visible);
    case SearchPickerActionTypes.RESULTS_FILTERED:
      return timm.set(state, 'selection', undefined);
  }
  return state;
}

export function uiReducer(state: _.Dictionary<ISearchPickerUiState> = {}, action: AppActions) {
  switch (action.type) {
    case SearchPickerActionTypes.SET_SELECTION:
    case SearchPickerActionTypes.VISIBILITY_TOGGLE:
    case SearchPickerActionTypes.RESULTS_FILTERED:
      return timm.set(state, action.payload.questionId, uiStateReducer(state[action.payload.questionId], action));
  }
  return state;
}

const innerSearchPickerReducer = combineReducers({
  allResultsByKey: allResultsByKeyReducer,
  filteredResultsByQuestion: filteredResultsByQuestionReducer,
  ui: uiReducer
});

export function searchPickerReducer(state: any, action: AppActions) {
  return innerSearchPickerReducer(state, action);
}
