import { AppActions } from '../../app.actions';
import {
  CriteriaDefineCrunchActionTypes,
  AddFilter,
  SetFilterValue
} from './criteria-define-crunch.actions';
import * as timm from 'timm';
import * as uuid from 'uuid';
import { QuestionId } from '../../../common/questions/question.models';
import {
  ICriteriaDefineCrunchState,
  Filter
} from './criteria-define-crunch.models';
import { DataActionTypes } from '../../data/data.actions';
import { ICriteriaSetValidityResult } from '../../../common/criteria/criteria.models';

const defaultState: ICriteriaDefineCrunchState = {
  setId: uuid.v4(),
  name: '',
  filters: []
};

export function criteriaDefineCrunchReducer(
  state: ICriteriaDefineCrunchState = defaultState,
  action: AppActions
) {
  switch (action.type) {
    case CriteriaDefineCrunchActionTypes.ADD_FILTER:
      return timm.set(
        state,
        'filters',
        timm.addLast(state.filters, addFilterReducer(action))
      );
    case CriteriaDefineCrunchActionTypes.REMOVE_FILTER:
      return timm.set(
        state,
        'filters',
        timm.removeAt(
          state.filters,
          state.filters.findIndex(f => f.id === action.payload.id)
        )
      );
    case CriteriaDefineCrunchActionTypes.SET_FILTER_VALUE:
      const filterIndex = state.filters.findIndex(
        f => f.id === action.payload.id
      )!;
      const newFilter = setFilterValueReducer(
        state.filters[filterIndex],
        action
      );
      return timm.setIn(state, ['filters', filterIndex], newFilter);
    case CriteriaDefineCrunchActionTypes.SET_STATE:
      return action.payload.state;
    case CriteriaDefineCrunchActionTypes.SET_NAME:
      return timm.set(state, 'name', action.payload.name);
    case CriteriaDefineCrunchActionTypes.CANCEL:
      return defaultState;
    case DataActionTypes.DATA_SAVED:
      if (action.payload.collection === 'combinedCriteriaSets') {
        const result = action.payload.response as ICriteriaSetValidityResult;
        if (result && result.setId === state.setId) {
          state = timm.set(state, 'validity', result.status);
        }
      }
      break;
  }
  return state;
}

export function addFilterReducer(action: AddFilter): Filter {
  return {
    id: action.payload.id,
    questionId: action.payload.questionId,
    operatorType: undefined,
    operand: undefined
  };
}

export function setFilterValueReducer(
  state: Filter,
  action: SetFilterValue
): Filter {
  return timm.set(state, action.payload.field, action.payload.value);
}
