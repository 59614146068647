import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { OptionGroup } from '../nested-select/nested-select.component';
import { Option } from '../search-select/search-select.component';
import * as _ from 'lodash';
import { filter, wrap } from 'fuzzaldrin-plus';

@Component({
  selector: 'app-staged-select-crunch',
  templateUrl: './staged-select-crunch.component.html',
  styleUrls: ['./staged-select-crunch.component.sass']
})
export class StagedSelectCrunchComponent implements OnInit, OnChanges {
  @Input() optionGroups: OptionGroup[];
  @Input() selectedOptionId?: string;
  @Output() selectOptionId = new EventEmitter<string>();

  searchText = '';

  selectedGroup?: OptionGroup;

  filteredOptions?: Option[];

  private allOptions: Option[];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.optionGroups) {
      this.allOptions = _.flatMap(this.optionGroups, og => og.options);
      this.onSearch();
    }
    if (changes.selectedOptionId && this.selectedOptionId) {
      this.selectedGroup = this.optionGroups.find(
        og => og.options.findIndex(o => o.id === this.selectedOptionId) > -1
      );
    }
  }

  onClickGroup(group: OptionGroup) {
    this.selectedGroup = group;
  }

  onSearch() {
    if (this.searchText !== '') {
      this.filteredOptions = filter(this.allOptions, this.searchText, {
        key: 'text'
      });
    } else {
      this.filteredOptions = undefined;
    }
  }

  searchWrapped(value: string) {
    return wrap(value, this.searchText);
  }

  onClickItem(id: string) {
    this.selectOptionId.emit(id);
  }
}
