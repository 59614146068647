import { Action } from '@ngrx/store';
import { IRequestConnection } from '../../common/connections/connections.models';
import { CallApiMethods, ICallApiArgs } from '../shared/call-api';

export namespace OpportunitiesActionTypes {
  export const SET_FILTER: '[Opportunities] Set Filter' =
    '[Opportunities] Set Filter';
}

export namespace OpportunitiesCallApiKeys {
  export const LOAD_STATE: '[Opportunities] Load State' =
    '[Opportunities] Load State';
  export const REQUEST_CONNECTION: '[Opportunities] Request Connection' =
    '[Opportunities] Request Connection';
  export const LEAVE_FEEDBACK: '[Opportunities] Leave Feedback' =
    '[Opportunities] Leave Feedback';
  export const STILL_ENGAGING: '[Opportunities] Still Engaging' =
    '[Opportunities] Still Engaging';
}

export type OpportunitiesActions = OpportunitiesSetFilter;

export class OpportunitiesSetFilter implements Action {
  type = OpportunitiesActionTypes.SET_FILTER;
  constructor(public value: string | null) {}
}

export class LoadStateCallApiArgs implements ICallApiArgs {
  key = OpportunitiesCallApiKeys.LOAD_STATE;
  url = '/opportunities';
}

export class RequestConnectionCallApiArgs implements ICallApiArgs {
  key = OpportunitiesCallApiKeys.REQUEST_CONNECTION;
  url: string;
  method = CallApiMethods.Put;
  dataKey: string;

  constructor(orgId: string, public data: IRequestConnection) {
    this.url = `/opportunities/${orgId}`;
    this.dataKey = orgId;
  }
}

export interface IFeedbackData {
  orgId: string;
  choice: string;
  reason: string;
}

export class LeaveFeedbackCallApiArgs implements ICallApiArgs {
  key = OpportunitiesCallApiKeys.LEAVE_FEEDBACK;
  url: string;
  method = CallApiMethods.Put;
  data: IFeedbackData;

  constructor(private orgId: string, data: IFeedbackData) {
    this.url = `/opportunities/${orgId}/feedback`;
    this.data = data;
  }
}

export class StillEngagingCallApiArgs implements ICallApiArgs {
  key = OpportunitiesCallApiKeys.STILL_ENGAGING;
  url: string;
  method = CallApiMethods.Put;
  data: { orgId: string };

  constructor(private orgId: string) {
    this.url = `/opportunities/${orgId}/still-engaging`;
    this.data = { orgId };
  }
}
