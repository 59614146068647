import { Injectable } from '@angular/core';
import {
  Question,
  MultipleSelectionQuestion,
  MultipleChoiceQuestion
} from '../../common/questions/question.models';
import {
  QuestionResponse,
  SelectionResponse,
  ChoiceResponse
} from '../../common/questions/response.model';
import { conformToMask } from 'angular2-text-mask';

@Injectable()
export class ResponseValueService {
  constructor() {}

  get(
    question: Question,
    response?: QuestionResponse,
    numberMask?: any
  ): string {
    if (response) {
      switch (response.type) {
        case 'skipped':
          if (question.type === 'T' || question.type === 'N') {
            return question.skippable || 'Skipped.';
          }
          return 'Skipped.';
        case 'value':
          return this.formatValue(question, response.value, numberMask);
        case 'selection':
          return this.selectionResponseValue(
            <MultipleSelectionQuestion>question,
            response
          );
        case 'choice':
          return this.choiceResponseValue(
            <MultipleChoiceQuestion>question,
            response
          );
      }
    }
    return 'No response.';
  }

  formatValue(
    question: Question,
    responseValue: string,
    numberMask?: any
  ): string {
    if (question.type === 'N' && numberMask) {
      const value = responseValue.toString();
      const mask = numberMask(value);
      const conformed = conformToMask(value, mask, {});
      return conformed.conformedValue;
    }
    return responseValue;
  }

  private selectionResponseValue(
    question: MultipleSelectionQuestion,
    response: SelectionResponse
  ): string {
    const options = response.choices
      .map(c => question.options.find(o => o.optionId === c))
      .filter(o => !!o);
    const choiceNames = options.map(o => o!.text);
    let result = choiceNames.join(', ');
    if (response.extra && response.extra !== '') {
      result += `: ${response.extra}`;
    }
    return result;
  }

  private choiceResponseValue(
    question: MultipleChoiceQuestion,
    response: ChoiceResponse
  ): string {
    const option = question.options.find(o => response.choice === o.optionId);
    if (!option) {
      return '';
    }
    let result = option.text;
    if (response.extra && response.extra !== '') {
      result += `: ${response.extra}`;
    }
    return result;
  }
}
