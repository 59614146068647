<h1>Privacy Policy</h1>

<p>Startup Muster Pty Ltd (ACN 609 487 103) (<strong>“we”</strong> or <strong>“us”</strong> or <strong>“our”</strong>)  collects information about the Australian startup community through a survey  (“Survey”, which includes annually, periodically and/or continuously collected and updated information about the Australian startup community)  via this website (“Site”). We compile the data collected from the Survey for use in and contribution to reports and statistics in relation to the Australian startup landscape, (“Reports”, which includes all forms of summaries and information derived from the Survey including annual, periodic or statistical summaries and as may otherwise compiled). Visitors and users of this site comprise participants in the Survey,  visitors viewing the site who may also download the Reports and visitors who subscribe to or mail list, (all of whom are referred to in this Privacy Policy as “you or your”).</p>
<p>We are firmly committed to protecting the privacy and confidentiality of your personal information. </p>
<p>The purpose of this Privacy Policy is to inform you about how we process information about you. This Privacy Policy applies to the Site and the Survey.</p>
<p>If you have any questions about this Privacy Policy, please feel free to contact our privacy officer at <a href="mailto:info@startupmuster.com">info@startupmuster.com</a>.</p>

<h2>What Information do we collect</h2>

<p>Should you participate in the Survey the sort of  personal information we collect from you includes your name, age, gender, address, education, employment. </p>
<p>Should you be a visitor to the Website and subscribe to our mailing list the sort of personal information we collect from you includes your email address and name.</p>
<p>As a visitor to the Site, whether or not you subscribe to our mail list or participate in the Survey, we  may use common technologies such as cookies to keep track of your interactions with the Site to improve the quality of its service. We may also collect your Internet Protocol (IP) address to track and aggregate non-personally identifiable information, your referring website addresses, browser type, domain name and access times. </p>

<h2>How Your Information is used by us </h2>

<ol type="a">
<li><strong>Information you provide</strong> – When you participate in the Survey we use it to compile the Reports on the Startup industry in Australia and we make those Reports freely available to download on this Site. The data and information in the Reports does not include any personal information. Startup Muster's Survey has become the industry benchmark for survey information on Australia’s Startup ecosystem. For this reason we share your survey answers with government and other research agencies and statutory authorities for the purposes of assisting in policy development, research or statistical analysis. Whist this does include some Personal Information it is only used for the purposes of assisting in policy development, research or statistical analysis.</li>
<li><strong>Cookies</strong> – When you visit the Site, we may send one or more cookies to your computer or device to improve the quality of our service, including for storing user preferences, improving search results, improving features of the Site and tracking user trends.</li>
<li><strong>Log information</strong> – When you access the Site, our servers may automatically record certain information. These server logs may include information such as your web request, your interaction with a service, IP address, browser type, browser language, the date and time of your request and one or more cookies that may uniquely identify your browser.</li>
<li><strong>Communications</strong> – When you join our mailing list or send email or other communications to us, we may retain those communications in order to process your inquiries, manage your subscription, send you newsletters and relevant information or  respond to your requests and improve our services. We may use your email address to communicate with you about our services and provide administrative information about our Site.</li>
<li><strong>Third Party Applications</strong> – From time to time, we may be use third party applications such as for non personally identifying Site analytics.</li>
<li><strong>Other sites</strong> – This Privacy Policy applies to the Site only. We do not exercise control over the sites displayed as search results, sites that include our applications, products or services, or links from within our various Sites. These other sites may place their own cookies or other files on your computer or device, collect data or solicit personal information from you.</li>
</ol>

<p>In addition to the above, we may use the information we collect to provide, maintain, protect, and improve the Site.</p>
<p>We store and process personal information collected via the Survey and the Site on our servers in Australia, the United States of America and Singapore, and you consent to your personal information being stored and processed and in those locations.</p>
<p>Any public commentary by our employees, officers or founders in relation to any startup will not be based on the identifying information provided by you.</p>


<h2>Your Choice to Opt Out</h2>
<p>Other than as expressly stated in this Privacy Policy, we will not provide your identifying information to any third party. We do not provide a benefit, service or advantage to collect personal information nor do we  disclose personal information for a benefit, service or advantage.</p>
<p>Where we are required to use your identifying information in a manner different from the purpose for which it was collected, we will seek your written consent prior to such use.</p>
<p>If you would like to opt-out from having us use your information in connection with the Site, please let us know as soon as possible by sending an email to <a href="mailto:info@startupmuster.com">info@startupmuster.com</a> identifying the information you would like us to stop use of.</p>
<p>When you elect to opt-out, we will endeavour to have your previous submissions removed from any future reports compiled by us, or datasets shared. However, where information collected from you has already been shared such as with government and other research agencies and statutory authorities and has been compiled and published in the form of a report, you will no longer have recourse to opting out of such information being used.</p>

<h2>Information sharing and onward transfer</h2>
<p>We only share personal information in the following limited circumstances:</p>

<ol type="a">
<li><strong>Connection Platform</strong>: When you complete the Survey you consent to receiving a list of supporters whose target criteria matches your startup. As a supporter on the connection platform you will be given the option to define target criteria and appear as a potential supporter to other survey participants, showing them your identity. Matching survey participants can then opt to engage with supporters, in which case a preview of their information will be shared with supporters at their election. The information includes their email addresses and the startups will see a preview of what information is being sent and will provide approval prior to the information being sent. Once contact has been made the parties don’t engage further through the connection platform they deal directly with each other. No representation, agreement, warranty promise or assurance of any  nature is made by or through Startup Muster or any participant using Startup Muster. Startup Muster has no responsibility or liability whatsoever regarding the use of the connection platform nor for any matter event, loss claim or liability whatsoever between platform partners and other participants of the connection platform.  The connection platform is entirely free and Startup Muster derives no benefit, service or advantage from its operation.</li>
<li>When we have your consent. We will usually require opt-in consent for the sharing of any identifying personal information.</li>
<li>When we provide such information to our subsidiaries, affiliated companies or other trusted business or persons for the purpose of processing personal information on our behalf. We require that these parties agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.</li>

<li>
  When we have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary to:
  <ol type="i">
    <li>satisfy any applicable law, regulation, legal process or enforceable governmental request;</li>
    <li>enforce our Terms of Service, including investigation of potential violations of those Terms;</li>
    <li>detect, prevent, or otherwise address fraud, security or technical issues; or</li>
    <li>protect against harm to the rights, our property or safety, our users or the public as required or permitted by law.</li>
  </ol>
</li>

<li>Where your personal information has been aggregated in the form of statistics or de-identified datasets and where you cannot be individually identified.</li>
<li>
  If we become involved in a merger, acquisition, or any form of sale of some or all of our assets, we will ensure that:

  <ol type="i">
    <li>the confidentiality of any personal information is maintained pursuant to this privacy policy; and</li>

    <li>your permission is obtained before any new use of your identifying information.</li>
  </ol>

</li>
</ol>

<h2>Information security</h2>

<p>We do not provide any guarantee or warranty that:</p>

<ol type="a">
  <li>our internal systems will be free of third party interception; and</li>
  <li>the servers of any third party service providers engaged by us will be secure.</li>
</ol>
<p>Your use or access to our Site is at your own risk.</p>
<p>We take appropriate security measures to protect against unauthorised access to or unauthorised alteration, disclosure or destruction of data. These include internal reviews of the security of our computers and our data collection, storage and processing practices and security measures.</p>
<p>We restrict access to personal information to our employees, contractors and agents who need to know that information in order to process it on our behalf.</p>

<h2>Accessing and updating personal information</h2>

<p>You have the right to request access to the personal information that the we hold about you and to request its correction. We may charge you a reasonable fee for access to your personal information.</p>
<p>We ask individual users to identify themselves and the information requested to be accessed, corrected or removed before processing such requests, and we may decline to process requests that are unreasonably repetitive or systematic, require disproportionate technical effort, jeopardise the privacy of others, or would be extremely impractical, or for which access is not otherwise required. Because of the way we maintain certain services in connection with the Site and our Survey, after we delete your information, residual copies may take a period of time before they are deleted from our active servers and may remain in the our backup systems.</p>

<h2>Complaints</h2>

<p>If you believe we have not met our obligations to you under the Privacy Act please do not hesitate to advise us and we will investigate your complaint and respond to any legitimate complaint as soon as practicable and in any event within 30 days. If you are not satisfied with the outcome of or our response to your complaint, you may refer your complaint to the Office of the Australian Information Commissioner.</p>

<h2>Changes to this Privacy Policy</h2>
<p>Please note that we may amend this Privacy Policy from time to time. We will post any Privacy Policy changes on this page and it is your sole obligation to check our most current Privacy Policy prior to accessing or using the Site.</p>

<h1>Terms and Conditions</h1>

<h2>License to use Site</h2>
<p>By our publishing this Site on the world wide web and communicating its contents to the public you have permission to view, navigate and use the Site by means of your web browser but that use is subject to the these terms and conditions to which you have agreed by accessing this Site.</p>

<h2>Intellectual Property Rights</h2>
<p>All the content of this site, including text, images, code, downloadable content and our logo are our Intellectual Property. You are not permitted to copy, download or republish any of our Intellectual Property other than:</p>

<ol type="a">
  <li>to access and navigate this Site via your web browser;</li>
  <li>to download our Survey report(s) and use it solely in accordance with the Creative Commons Attribution Non-Commercial No Derivatives 4.0 International License http://creativecommons.org/licenses/by-nc-nd/4.0/</li>
  <li>to print out a hard copy of our Site for your non commercial personal use; and</li>
  <li>for any legitimate fair dealing in accordance with the Copyright Act (C/W)</li>
</ol>

<h2>Liability</h2>
<p>To the full extent permitted by law, we exclude all liability arising from your access to and use of this Site and downloading the Survey report(s) in respect of loss of data, denial of service, virus, interruption of business or any consequential or incidental damages.</p>
<p>We make no representations, warranties or terms (whether express or implied) other than those expressly set out in these terms and conditions.</p>

<h2>Jurisdiction</h2>
<p>These terms are governed by the laws of New South Wales and you agree to submit to the jurisdiction of the courts of New South Wales.</p>

