import { IReportDownloadEmail } from '../../../common/reports/reports.models';
import { CallApiMethods, ICallApiArgs } from '../../shared/call-api';

export namespace ReportDownloadApiKeys {
  export const SAVE_EMAIL: '[Report Download] SAVE_EMAIL' =
    '[Report Download] SAVE_EMAIL';
}

export class ReportDownloadSaveEmailApiArgs implements ICallApiArgs {
  key = ReportDownloadApiKeys.SAVE_EMAIL;
  url: string;
  method = CallApiMethods.Post;
  data: IReportDownloadEmail;
  // dataKey?: any;
  constructor(reportId: string, public payload: IReportDownloadEmail) {
    this.data = payload;
    this.url = `/report-download/${reportId}/emails`;
  }
}
