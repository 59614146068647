import { ActionReducer } from '@ngrx/store';
import { SharedActionTypes, SharedActions } from './shared.actions';
import { IAsyncState, SharedState } from './shared.models';
import { updateIn, set } from 'timm';
import { setIn } from 'timm';
import { merge } from 'timm';

export function defaultAsyncState(): IAsyncState {
  return {
    inProgress: false,
    didFail: false,
    inFlight: 0
  };
}

const DEFAULT_STATE: SharedState = {
  saving: defaultAsyncState(),
  loading: defaultAsyncState()
};

function asyncStart(state: IAsyncState): IAsyncState {
  if (!state.inProgress) {
    return { inProgress: true, didFail: false, inFlight: 1 };
  }
  return set(state, 'inFlight', state.inFlight + 1);
}

function asyncDone(state: IAsyncState): IAsyncState {
  state = updateIn(state, ['inFlight'], c => c - 1);
  if (state.inFlight === 0) {
    state = setIn(state, ['inProgress'], false);
  }
  return state;
}

function asyncFailed(state: IAsyncState, errors?: string[]): IAsyncState {
  state = asyncDone(state);
  return merge(state, { didFail: true, errors: errors });
}

export function sharedReducer(state: SharedState = DEFAULT_STATE, action: SharedActions): SharedState {
  switch (action.type) {
    case SharedActionTypes.SAVING_START:
      return set(state, 'saving', asyncStart(state.saving));

    case SharedActionTypes.SAVING_DONE:
      return set(state, 'saving', asyncDone(state.saving));

    case SharedActionTypes.SAVING_FAILED:
      return set(state, 'saving', asyncFailed(state.saving, action.payload));

    case SharedActionTypes.LOADING_START:
      return set(state, 'loading', asyncStart(state.loading));

    case SharedActionTypes.LOADING_DONE:
      return set(state, 'loading', asyncDone(state.loading));

    case SharedActionTypes.LOADING_FAILED:
      return set(state, 'loading', asyncFailed(state.loading, action.payload));
  }

  return state;
}
