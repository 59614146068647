import {
  QuestionResponse,
  SkippedResponse,
  OldResponse
} from 'common/questions/response.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Question, QuestionType } from 'common/questions/question.models';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NumberMaskService } from '../number-mask.service';
import { Router } from '@angular/router';
import { UserService } from '../../users/user.service';
import { ResponseValueService } from '../response-value.service';
import { AnswerDispatcherService } from '../answer-dispatcher.service';
import { ResponseValidationResult } from 'common/questions/response-validator.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.models';
import { ISurveyStateQuestionResponse } from '../your-organisation.models';
import { CopyOldResponse } from '../../data/question.actions';

export type QuestionChangeEditState = 'edit' | 'cancel' | 'finish';

export type QuestionComponentType = QuestionType | 'QFinal' | 'TYearMonth';

export interface IQuestionComponentState {
  question: Question;
  response?: QuestionResponse;
  oldResponse?: OldResponse;
  status?: 'saving' | 'saved' | 'failed' | 'error' | 'need-update';
  errors?: string[];
  isDirty?: boolean;
  isLocked?: boolean;
  isValid?: ResponseValidationResult;
}

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionComponent implements OnChanges {
  @Input() state: IQuestionComponentState;
  @Input() editing = true;
  @Input() hideResponse = false;

  @Output() editStateChange = new EventEmitter<QuestionChangeEditState>();

  helperText: SafeHtml | null;

  questionType: QuestionComponentType;

  private numberMask: any;
  private rawHelperText?: string;

  constructor(
    private numberMaskService: NumberMaskService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private userService: UserService,
    private responseValueService: ResponseValueService,
    private answerDispatcher: AnswerDispatcherService,
    private store: Store<AppState>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.numberMask = null;
    if (this.state && this.state.question.type === 'N') {
      this.numberMask = this.numberMaskService.create(this.state.question);
    }
    if (changes.state) {
      if (this.state.question.key === 'QFinal') {
        this.questionType = 'QFinal';
      } else if (this.state.question.uiType === 'year-month') {
        this.questionType = 'TYearMonth';
      } else {
        this.questionType = this.state.question.type;
      }

      if (this.rawHelperText !== this.state.question.helperText) {
        this.rawHelperText = this.state.question.helperText;
        this.helperText = null;
        if (this.rawHelperText) {
          this.helperText = this.domSanitizer.bypassSecurityTrustHtml(
            this.rawHelperText
          );
        }
      }
    }
  }

  get responseValue(): string {
    return this.responseValueService.get(
      this.state.question,
      this.state.response,
      this.numberMask
    );
  }

  get showSaving() {
    return this.savingFail;
  }

  get savingSuccess() {
    return this.state.status === 'saved';
  }

  get savingFail() {
    return this.state.status === 'failed' || this.state.status === 'error';
  }

  // completeSurvey() {
  //   this.userService.completeSurvey().subscribe();
  // }

  skip() {
    const response: SkippedResponse = {
      questionId: this.state.question.id,
      questionKey: this.state.question.key,
      type: 'skipped',
      updatedAt: new Date()
    };
    this.answerDispatcher.answer(this.state.question, response);
  }

  transferOldResponse(qr: ISurveyStateQuestionResponse) {
    this.store.dispatch(new CopyOldResponse(qr.question.key));
  }

  get showOldResponse() {
    return (
      !!this.state.oldResponse &&
      !!this.state.oldResponse.options &&
      this.state.oldResponse.options.length > 0
    );
  }
}
