import { moveItem } from '../../timm-extra';
import { AppActions } from '../app.actions';
import {
  // LoadStateLoaded,
  OpportunitiesActionTypes,
  OpportunitiesCallApiKeys
  // RequestConnectionLoaded
} from './opportunities.actions';
import { IOpportunitiesState } from './opportunities.models';
import * as _ from 'lodash';
import { merge } from 'timm';
import { mergeIn } from 'timm';
import { set, removeAt, setIn } from 'timm';
import { CallApiActionTypes } from '../shared/call-api';
import { IOpportunities, IConnectionInfo } from '../../common/opportunities.model';
import { AppResponse, ErrorIds } from '../../common/core/core.models';
import * as moment from 'moment';
import Dictionary = _.Dictionary;

const defaultState: IOpportunitiesState = {
  loaded: false,
  connections: {
    connections: [],
    opportunities: []
  },
  connectionsById: {},
  connectionDetailsByConnectionId: {},
  filterConnectionsBy: null,
  dailyLimitReached: null
};

export function opportunitiesReducer(
  state: IOpportunitiesState = defaultState,
  action: AppActions
): IOpportunitiesState {
  switch (action.type) {
    case CallApiActionTypes.CALL_API_LOADED:
      switch (action.payload.key) {
        case OpportunitiesCallApiKeys.LOAD_STATE:
          return loadStateLoadedReduer(state, action.payload.response);
        case OpportunitiesCallApiKeys.REQUEST_CONNECTION:
          return requestConnectionLoadedReducer(
            state,
            action.payload.dataKey,
            action.payload.response
          );
        case OpportunitiesCallApiKeys.LEAVE_FEEDBACK:
          return leaveFeedbackReducer(state, action.payload.data.orgId);
        case OpportunitiesCallApiKeys.STILL_ENGAGING:
          return leaveFeedbackReducer(state, action.payload.data.orgId);
      }
      break;
  }
  return state;
}

function loadStateLoadedReduer(
  state: IOpportunitiesState,
  data: IOpportunities
): IOpportunitiesState {
  const allConnections = data.connections.concat(data.opportunities);
  const connectionsById = _.keyBy(allConnections, c => c.orgId);
  return merge(state, { connections: data, connectionsById, loaded: true });
}

function requestConnectionLoadedReducer(
  state: IOpportunitiesState,
  orgId: string,
  response: AppResponse<{}>
): IOpportunitiesState {
  if (response.success) {
    const index = state.connections.opportunities.findIndex(
      o => o.orgId === orgId
    );
    return setIn(
      state,
      ['connections', 'opportunities', index, 'isConnected'],
      true
    );
  }
  if (response.errors.indexOf(ErrorIds.CONNECTIONS_DAILY_LIMIT) > -1) {
    return set(state, 'dailyLimitReached', moment());
  }
  return state;
}

function leaveFeedbackReducer(
  state: IOpportunitiesState,
  orgId: string
): IOpportunitiesState {
  const index = state.connections.connections.findIndex(c => c.orgId === orgId);
  const newConnections = removeAt(state.connections.connections, index);
  const newState = setIn(state, ['connections', 'connections'], newConnections);
  return newState;
}
