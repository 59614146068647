import { ChoiceResponse, QuestionResponse, SelectionResponse, SkippedResponse, ValueResponse } from './response.model';

export namespace QuestionResponseTypeChecks {
  export function isSkipped(response: QuestionResponse): response is SkippedResponse {
    return response.type === 'skipped';
  }

  export function isValue(response: QuestionResponse): response is ValueResponse {
    return response.type === 'value';
  }

  export function isSelection(response: QuestionResponse): response is SelectionResponse {
    return response.type === 'selection';
  }

  export function isChoice(response: QuestionResponse): response is ChoiceResponse {
    return response.type === 'choice';
  }
}
