import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  AfterViewInit,
  ViewChildren,
  ElementRef
} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {TokenService} from "../../token.service";
import {TokenNotExpiredService} from "../../token-not-expired.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../app.models";
import {OrgSelectorsService} from "../../users/org-selectors.service";
import {AppLogger} from "../../app-logger";
import {IntercomService} from "../../shared/intercom.service";
import {GaService} from "../../shared/ga.service";
import {Observable} from "rxjs";
import {IAuthLinkValidationResponse} from "../../../common/auth/auth-links.models";
import {map} from "rxjs/operators";
import {GlobalSetLoginEmail} from "../../global.actions";
import {IInitialState} from "../../../common/initial-state.models";


@Component({
  selector: 'app-survey-offline',
  templateUrl: './survey-offline.component.html',
  styleUrls: ['./survey-offline.component.sass']
})
export class SurveyOfflineComponent implements OnInit, OnDestroy, AfterViewInit {

  email = '';

  loading = false;
  showSuccesMsg = false;
  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    private tokenNotExpiredService: TokenNotExpiredService,
    private store: Store<AppState>,
    private orgSelectors: OrgSelectorsService,
    private appLogger: AppLogger,
    private intercom: IntercomService,
    private ga: GaService
  ) {

  }

  postEmail() {
    const payload = {email: this.email};
    return this.http.post('/subscribe/report/email', payload).toPromise();
  }
  async saveEmail() {
    this.loading = true;
    await this.postEmail();
    this.loading = false;
    this.showSuccesMsg = true;

  }
  inputKeyPress($event: KeyboardEvent) {
    $event.stopPropagation();
    if ($event.keyCode === 13) {
      this.saveEmail();
      return false;
    }
    return;
  }


  get isEmailValidish() {
    return this.email && this.email.match(/@.+\..+/);
  }
}
