import {
  Directive,
  ElementRef,
  OnChanges,
  Input,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appRewriteLinks]'
})
export class RewriteLinksDirective implements OnChanges {
  // tslint:disable-next-line:no-input-rename
  @Input('appRewriteLinks') text: string;

  constructor(private el: ElementRef, private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    const el = this.el.nativeElement;
    if (
      changes.text &&
      changes.text.currentValue !== changes.text.previousValue &&
      el
    ) {
      const originalLength = this.text.length;
      const processedText = this.text.replace(
        'onclick="togglePrivacyPolicy(event)"',
        ''
      );
      el.innerHTML = processedText;
      if (originalLength > processedText.length) {
        // we found the privacy policy link, hook it up
        const anchors = el.querySelectorAll('a[href]');
        // anchors is not a real array, so we need to use an old style for loop
        for (
          let anchorsIndex = 0;
          anchorsIndex < anchors.length;
          anchorsIndex++
        ) {
          const anchor = anchors[anchorsIndex] as HTMLAnchorElement;
          anchor.addEventListener('click', $event => {
            $event.stopPropagation();
            this.router.navigate([
              { outlets: { modal: 'modal/privacy-policy' } }
            ]);
            return false;
          });
        }
      }
    }
  }
}
