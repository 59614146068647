import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevComponent } from './dev.component';
import { LoadingTestComponent } from './loading-test/loading-test.component';
import { SharedModule } from '../shared/shared.module';
import { YourOrganisationModule } from '../your-organisation/your-organisation.module';
import { SearchSelectTestComponent } from './search-select-test/search-select-test.component';
import { MockTargetsComponent } from './mock-targets/mock-targets.component';
import { MockDefineComponent } from './mock-define/mock-define.component';
import { CriteriaModule } from '../criteria/criteria.module';
import { OpportunitiesModule } from '../opportunities/opportunities.module';
import { ValueListTestComponent } from './value-list-test/value-list-test.component';
import { ProxTestComponent } from './prox-test/prox-test.component';
import { StylesComponent } from './styles/styles.component';
import { QuestionViewComponent } from './question-view/question-view.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    YourOrganisationModule,
    CriteriaModule,
    OpportunitiesModule
  ],
  declarations: [
    DevComponent,
    LoadingTestComponent,
    SearchSelectTestComponent,
    MockTargetsComponent,
    MockDefineComponent,
    ValueListTestComponent,
    ProxTestComponent,
    StylesComponent,
    QuestionViewComponent
  ]
})
export class DevModule {}
