
import {scan, combineLatest, map, distinctUntilChanged, shareReplay} from 'rxjs/operators';
import * as _ from 'lodash';
import * as rxUtil from '../../rx-util';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.models';
import { IQuestionComponentState } from './question/question.component';
import { Question, QuestionKey } from '../../common/questions/question.models';
import { QuestionResultsService } from './question-results.service';
import { Observable } from 'rxjs';
import { ResponseValidationResult } from '../../common/questions/response-validator.service';
import { QuestionVisibilityService } from '../../common/questions/question-visibility.service';
import { ISurveyStateSection } from './your-organisation.models';
import { transformResponsesForVisibility } from 'common/questions/question-visibility.util';

@Injectable()
export class QuestionSelectorsService {
  private orderedQuestions$ = this.store
    .select(s => s.data.questions).pipe(
    (rxUtil.allOrdered),
    shareReplay(1),);
  readonly responses$ = this.store.select(s => s.data.responses.byId);
  readonly questionsByKey$ = this.store
    .select(s => s.data.questions.byId).pipe(
    map(questionsById =>
      _(questionsById)
        .values()
        .keyBy(q => q.key)
        .value()
    ),
    shareReplay(1),);

  private oldResponses$ = this.store.select(s => s.data.oldResponses.byId);
  private responsesSaving$ = this.store.select(s => s.data.responses.saving);
  private responsesDirty$ = this.store.select(s => s.data.responses.dirty);
  private responsesErrors$ = this.store.select(s => s.data.responses.errors);
  private sections$ = this.store.select(s => s.data.sections);
  private results$ = this.store.select(s => s.data.questionResults.byId);
  private isResponseValid$ = this.store
    .select(s => s.questions.isResponseValid).pipe(
    shareReplay(1));
  private resultMatches$ = this.store.select(s => s.questions.resultMatches);
  private lockedQuestionIds$ = this.store.select(
    s => s.data.lockedQuestionIds.byId
  );

  private orderedSections$ = this.sections$.pipe((rxUtil.allOrdered));

  private sectionSortedQuestions$ = this.orderedQuestions$.pipe(combineLatest(
    this.orderedSections$,
    (questions, sections) => {
      // TODO: refactor, figure out more optimal way of resolving the section/question order problem
      const questionsBySectionId = _.groupBy(questions, q => q.sectionId);
      return _.flatMap(sections, s => questionsBySectionId[s.id] || []);
    }
  ));

  private visibleQuestions$ = this.orderedQuestions$.pipe(
    combineLatest(this.responses$, (questions, responses) => {
      const responsesByKey = _(responses)
        .values()
        .keyBy(r => r.questionKey)
        .value();
      const visibilityResponses = transformResponsesForVisibility(
        responsesByKey
      );
      const {
        questionsByKey
      } = this.questionVisibility.visibleQuestionsAndResponses(
        questions,
        visibilityResponses
      );
      return questionsByKey;
    }),
    shareReplay(1),);

  progressPercentage$ = this.visibleQuestions$.pipe(
    map(qs => _.size(qs)),
    combineLatest(
      this.isResponseValid$,
      (visibleQuestionCount, isResponseValid) => {
        let validResponses = 0;
        for (const key of Object.keys(isResponseValid)) {
          if (isResponseValid[key].isValid) {
            validResponses++;
          }
        }
        return (validResponses / visibleQuestionCount) * 100;
      }
    ),
    scan((prev, next) => (next > prev ? next : prev), 0),
    distinctUntilChanged(),);

  visibleQuestionsResponses$(limitVisible: boolean) {
    return this.visibleQuestions$.pipe(
      combineLatest(
        this.sectionSortedQuestions$,
        this.isResponseValid$,
        (visibleQuestions, sortedQuestions, isResponseValid) => {
          // we lose sorting in the visibility check, so we're filtering our correctly sorted questions instead
          const whileFn = this.hasValidResponsePlusOneUnlessReturning(
            isResponseValid
          );
          return _(sortedQuestions)
            .filter(q => !!visibleQuestions[q.key])
            .takeWhile(q => {
              return !limitVisible || whileFn(q);
            })
            .value();
        }
      ),
      combineLatest(
        this.responses$,
        this.results$,
        this.resultMatches$,
        this.lockedQuestionIds$,
        this.isResponseValid$,
        (
          questions,
          responses,
          allResults,
          resultMatches,
          lockedQuestionIds,
          isResponseValid
        ) => {
          return questions.map(q => {
            // if we have results to show for this question, pull those in and pull in where the current response fits
            let results;
            const thisResults = allResults[q.key];
            if (thisResults) {
              results = this.questionResults.mapResults(
                q,
                thisResults,
                resultMatches[q.key] || []
              );
            }
            let response =  responses[q.id] ;


            if (response === null || response === undefined) {
              Object.keys(responses).forEach((key) => {
                if (responses[key].questionKey === q.key) {
                  response = responses[key];
                }
              });

            }

            return <IQuestionComponentState>{
              question: q,
              response: response,
              results,
              isLocked: !!lockedQuestionIds[q.key],
              isValid: isResponseValid[q.id]
            };
          });
        }
      ),
      combineLatest(
        this.responsesSaving$,
        this.responsesDirty$,
        this.responsesErrors$,
        this.oldResponses$,
        (questionStates, respSaving, respDirty, respErrors, oldResponses) => {
          return questionStates.map(qs => {
            // pull in saving/error state
            const id = qs.question.id;
            const dirty = respDirty[id];
            const status = respSaving[id];
            const errors = respErrors[id];
            const oldResponse = oldResponses[qs.question.key];
            return { ...qs, status, isDirty: dirty, errors, oldResponse };
            // return qs;
          });
        }
      ),);
  }

  nestedSections$(limitVisible: boolean): Observable<ISurveyStateSection[]> {
    return this.visibleQuestionsResponses$(limitVisible).pipe(
      map(qrs => _.groupBy(qrs, qr => qr.question.sectionId)),
      combineLatest(this.orderedSections$, (qrs, sections) => {
        return sections
          .map(s => {
            return {
              id: s.id,
              title: s.title,
              questionResponses: qrs[s.id] || []
            };
          })
          .filter(s => s.questionResponses.length > 0);
      }),);
  }

  selectQuestionByKey$(key: QuestionKey): Observable<Question | null> {
    return this.questionsByKey$.pipe(map(
      questionsByKey => questionsByKey[key] || null
    ));
  }

  constructor(
    private store: Store<AppState>,
    private questionVisibility: QuestionVisibilityService,
    private questionResults: QuestionResultsService
  ) {}

  private hasValidResponsePlusOneUnlessReturning(
    isResponseValid: _.Dictionary<ResponseValidationResult>
  ) {
    let stop = false;
    return (question: Question) => {
      const result = !stop;
      // returning email is a special case, we want to halt there.
      if (
        !isResponseValid[question.id] ||
        !isResponseValid[question.id].isValid ||
        question.key === 'QUserTypeReturningEmail'
      ) {
        stop = true;
      }
      return result;
    };
  }
}
