import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { AppRoutingModule } from '../app-routing.module';
import { CriteriaDataLoaderService } from './criteria-data-loader.service';
import { MiniEditorComponent } from './mini-editor/mini-editor.component';
import { IdealFeedbackComponent } from './ideal-feedback/ideal-feedback.component';
import { CriteriaRootComponent } from './criteria-root/criteria-root.component';
import { CriteriaOperatorSelectComponent } from './criteria-operator-select/criteria-operator-select.component';
import { CriteriaSetSelectorsService } from './criteria-set-selectors.service';
// import { CriteriaSelectorsService } from './criteria-selectors.service';
// import { CriteriaEditCriterionSelectorsService } from './criteria-edit-criterion/criteria-edit-criterion-selectors.service';
import { OpportunitiesModule } from '../opportunities/opportunities.module';
import { CriteriaEditSetComponent } from './criteria-edit-set/criteria-edit-set.component';
import { CriteriaSetEffects } from './effects/criteria-set.effects';
// import { CriteriaCriterionEffects } from './effects/criteria-criterion.effects';
import { CriteriaSetDetailComponent } from './criteria-set-detail/criteria-set-detail.component';
import { OpProximityComponent } from './op-proximity/op-proximity.component';
import { NgMapsCoreModule } from '@ng-maps/core';
import { CriteriaDefineCrunchComponent } from './criteria-define-crunch/criteria-define-crunch.component';
import { CriteriaDefineCrunchSelectorsService } from './criteria-define-crunch/criteria-define-crunch-selectors.service';
import { CriteriaDefineCrunchEffects } from './criteria-define-crunch/criteria-define-crunch.effects';
import { CrunchFilterComponent } from './crunch-filter/crunch-filter.component';
import { CriteriaCrunchValidatorService } from './criteria-crunch-validator.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([
      CriteriaSetEffects,
      // CriteriaCriterionEffects,
      CriteriaDefineCrunchEffects
    ]),
    OpportunitiesModule,
    NgMapsCoreModule
  ],
  declarations: [
    CriteriaRootComponent,
    MiniEditorComponent,
    IdealFeedbackComponent,
    CriteriaOperatorSelectComponent,
    CriteriaEditSetComponent,
    CriteriaSetDetailComponent,
    OpProximityComponent,
    CriteriaDefineCrunchComponent,
    CrunchFilterComponent
  ],
  providers: [
    CriteriaDataLoaderService,
    CriteriaSetSelectorsService,
    // CriteriaSelectorsService,
    // CriteriaEditCriterionSelectorsService,
    CriteriaDefineCrunchSelectorsService,
    CriteriaCrunchValidatorService
  ],
  exports: [OpProximityComponent, CriteriaDefineCrunchComponent]
})
export class CriteriaModule {}
