
import { Action } from "@ngrx/store";
import { ISearchPickerResult } from "../../common/questions/search-picker.models";
import { ICallApiArgs } from "../shared/call-api";

export namespace SearchPickerActionTypes {
  export const UPDATE_SEARCH: '[Search Picker] Update Search' = '[Search Picker] Update Search';
  export const RESULTS_FILTERED: '[Search Picker] Results Filtered' = '[Search Picker] Results Filtered';
  export const SET_RESULTS: '[Search Picker] Set Results' = '[Search Picker] Set Results';
  export const VISIBILITY_TOGGLE: '[Search Picker] Visibility Toggle' = '[Search Picker] Visibility Toggle';
  export const MOVE_SELECTION: '[Search Picker] Move Selection' = '[Search Picker] Move Selection';
  export const SET_SELECTION: '[Search Picker] Set Selection' = '[Search Picker] Set Selection';
}

export type SearchPickerActions =
  UpdateSearch | ResultsFiltered | SetResults
  | MoveSelection | VisibilityToggle | SetSelection;

export class UpdateSearch implements Action {
  type = SearchPickerActionTypes.UPDATE_SEARCH;
  constructor(public payload: { questionId: string, key: string, value: string }) {}
}

export class ResultsFiltered implements Action {
  type = SearchPickerActionTypes.RESULTS_FILTERED;
  constructor(public payload: { questionId: string, results: ISearchPickerResult[] }) {}
}

export class SetResults implements Action {
  type = SearchPickerActionTypes.SET_RESULTS;
  constructor(public payload: { key: string, results: ISearchPickerResult[] }) {}
}

export namespace SearchPickerCallApiKeys {
  export const FETCH_RESULTS: '[Search Picker] Fetch Results' = '[Search Picker] Fetch Results';
}

export class FetchResultsCallApiArgs implements ICallApiArgs {
  key = SearchPickerCallApiKeys.FETCH_RESULTS;
  url: string;

  constructor(public dataKey: string) {
    this.url = `/search-picks/${dataKey}`;
  }
}

export class VisibilityToggle implements Action {
  type = SearchPickerActionTypes.VISIBILITY_TOGGLE;
  constructor(public payload: { questionId: string,  visible: boolean }) {}
}

export class MoveSelection implements Action {
  type = SearchPickerActionTypes.MOVE_SELECTION;
  constructor(public payload: { questionId: string, direction: 'up'|'down' }) {}
}

export class SetSelection implements Action {
  type = SearchPickerActionTypes.SET_SELECTION;
  constructor(public payload: { questionId: string, selection?: string }) {}
}
