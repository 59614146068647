import {
  CancelEditQuestion,
  EditQuestion,
  SelectSection
} from '../your-organisation.actions';
import { IAnswersViewState } from '../your-organisation.models';
import { Observable } from 'rxjs';
import { AppState } from '../../app.models';
import { Store } from '@ngrx/store';
import { Component } from '@angular/core';
import { AnswersViewSelectorsService } from '../answers-view-selectors.service';

@Component({
  selector: 'app-your-organisation',
  templateUrl: './your-organisation.component.html',
  styleUrls: ['./your-organisation.component.sass']
})
export class YourOrganisationComponent {
  state$: Observable<IAnswersViewState>;

  constructor(
    private store: Store<AppState>,
    private answersViewSelectors: AnswersViewSelectorsService
  ) {
    this.state$ = answersViewSelectors.state$;
  }

  onEditQuestion(questionId: string) {
    this.store.dispatch(new EditQuestion(questionId));
  }

  onCancelEditQuestion() {
    this.store.dispatch(new CancelEditQuestion());
  }

  onSelectSection(sectionId: string) {
    this.store.dispatch(new SelectSection({ sectionId }));
  }
}
