
import {tap, map, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.models';
import { GlobalLoadRequested } from '../global.actions';

const redirectUrl = '/login';

@Injectable()
export class AuthGuardService implements CanActivate {
  private originalUrl: string | null = null;

  constructor(
    private user: UserService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store
      .select(s => s.global).pipe(
      filter(g => g.loadState === 'loaded'),
      map(g => g.showPlatform),
      tap(result => {
        if (!result) {
          this.router.navigate([redirectUrl]);
        }
      }),);
  }

  navigateToOriginalOrRoot() {
    let url = '/';
    if (this.originalUrl) {
      if (this.originalUrl !== redirectUrl) {
        url = this.originalUrl;
      }
      this.originalUrl = null;
    }
    this.router.navigate([url]);
  }
}
