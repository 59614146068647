import { Injectable } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export interface INumberMaskOptions {
  insertDollars?: boolean;
  insertCommas?: boolean;
}

@Injectable()
export class NumberMaskService {
  constructor() {}

  create(inOptions: INumberMaskOptions) {
    const options = {
      prefix: inOptions.insertDollars ? '$' : '',
      includeThousandsSeparator: !!inOptions.insertCommas
    };
    return createNumberMask(options);
  }
}
