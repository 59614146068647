import { Action } from '@ngrx/store';
import {
  ICombinedCriteriaSet,
  ICriteriaSet,
  ICriterion
} from '../../common/criteria/criteria.models';
import {
  CriteriaIdealFeedbackStates,
  DefiningCriteriaStates,
  IEditingCriterion
} from '../criteria/criteria.models';
import { CallApiMethods, ICallApiArgs } from '../shared/call-api';

export namespace CriteriaActionTypes {
  export const INIT: '[Criteria] Init' = '[Criteria] Init';
  export const SET_STATE: '[Criteria] Set State' = '[Criteria] Set State';
  export const SET_NAME: '[Criteria] Set Name' = '[Criteria] Set Name';
  export const DONE_ADDING: '[Criteria] Done Adding' = '[Criteria] Done Adding';
  export const SAVE: '[Criteria] Save' = '[Criteria] Save';
  export const SET_ENABLED: '[Criteria] Set Enabled' = '[Criteria] Set Enabled';
  export const REMOVE: '[Criteria] Remove' = '[Criteria] Remove';

  export const CRITERION_SET_VALUE: '[Criteria] Criterion Set Value' =
    '[Criteria] Criterion Set Value';
  export const CRITERION_ADD: '[Criteria] Criterion Add' =
    '[Criteria] Criterion Add';
  export const CRITERION_REMOVE: '[Criteria] Criterion Remove' =
    '[Criteria] Criterion Remove';
  export const CRITERION_FOCUS: '[Criteria] Criterion Focus' =
    '[Criteria] Criterion Focus';
  export const CRITERION_SAVE: '[Criteria] Criterion Save' =
    '[Criteria] Criterion Save';
  export const SET_EDITING_CRITERION: '[Criteria] Set Editing Criterion' =
    '[Criteria] Set Editing Criterion';
  // export const CRITERION_UPDATE_VALIDITY: '[Criteria] Criterion Update Validity' =
  //   '[Criteria] Criterion Update Validity';

  export const CRITERIA_UPDATE_IDEAL_FEEDBACK_STATE: '[Criteria] Update Ideal Feedback State' =
    '[Criteria] Update Ideal Feedback State';
}

export type CriteriaActions =
  | CriteriaInit
  | CriteriaSetName
  | CriteriaDoneAdding
  | CriteriaSetState
  | CriteriaRemove
  | CriteriaCriterionSetValue
  | CriteriaCriterionAdd
  | CriteriaCriterionRemove
  | CriteriaCriterionFocus
  // | CriteriaCriterionUpdateValidity
  | CriteriaCriterionSave
  | CriteriaUpdateIdealFeedbackState
  | CriteriaSetEditingCriterion
  | CriteriaSetEnabled;

export class CriteriaInit implements Action {
  type = CriteriaActionTypes.INIT;
  constructor(public setId: string) {}
}

export class CriteriaSetState implements Action {
  type = CriteriaActionTypes.SET_STATE;
  constructor(public setId: string, public state: DefiningCriteriaStates) {}
}

export class CriteriaSetName implements Action {
  type = CriteriaActionTypes.SET_NAME;
  constructor(public setId: string, public name: string) {}
}

export class CriteriaSave implements Action {
  type = CriteriaActionTypes.SAVE;
  constructor(public setId: string) {}
}

export class CriteriaRemove implements Action {
  type = CriteriaActionTypes.REMOVE;
  constructor(public setId: string) {}
}

export class CriteriaSetEnabled implements Action {
  type = CriteriaActionTypes.SET_ENABLED;
  constructor(public setId: string, public enabledState: boolean) {}
}

export class CriteriaCriterionSetValue implements Action {
  type = CriteriaActionTypes.CRITERION_SET_VALUE;
  constructor(
    public setId: string,
    public id: string,
    public key:
      | 'questionId'
      | 'maxAgeMonths'
      | 'operatorType'
      | 'operatorValue',
    public value: any
  ) {}
}

export class CriteriaCriterionAdd implements Action {
  type = CriteriaActionTypes.CRITERION_ADD;
  constructor(public setId: string) {}
}

export class CriteriaCriterionRemove implements Action {
  type = CriteriaActionTypes.CRITERION_REMOVE;
  constructor(public setId: string, public id: string) {}
}

export class CriteriaCriterionFocus implements Action {
  type = CriteriaActionTypes.CRITERION_FOCUS;
  constructor(public setId: string, public id: string | null) {}
}

export class CriteriaCriterionSave implements Action {
  type = CriteriaActionTypes.CRITERION_SAVE;
  constructor(public setId: string, public id: string) {}
}

export class CriteriaDoneAdding implements Action {
  type = CriteriaActionTypes.DONE_ADDING;
  constructor(public setId: string) {}
}

export class CriteriaSetEditingCriterion implements Action {
  type = CriteriaActionTypes.SET_EDITING_CRITERION;
  constructor(public criterion: IEditingCriterion | null) {}
}

// export class CriteriaCriterionUpdateValidity implements Action {
//   type = CriteriaActionTypes.CRITERION_UPDATE_VALIDITY;
//   constructor(public id: string, public isValid: boolean) {}
// }

export namespace CriteriaCallApiKeys {
  export const LOAD_CRITERIA_SETS: '[Criteria] Load Criteria Sets' =
    '[Criteria] Load Criteria Sets';
  export const SAVE_CRITERION: '[Criteria] Save Criterion' =
    '[Criteria] Save Criterion';
  export const DELETE_CRITERION: '[Criteria] Delete Criterion' =
    '[Criteria] Delete Criterion';
  export const SAVE_CRITERIA_SET: '[Criteria] Save Criteria Set' =
    '[Criteria] Save Criteria Set';
  export const DELETE_CRITERIA_SET: '[Criteria] Delete Criteria Set' =
    '[Criteria] Delete Criteria Set';
  export const SAVE_COMBINED_CRITERIA_SET: '[Criteria] Save Combined Criteria Set' =
    '[Criteria] Save Combined Criteria Set';
  export const DELETE_COMBINED_CRITERIA_SET: '[Criteria] Delete Combined Criteria Set' =
    '[Criteria] Delete Combined Criteria Set';
  export const LOAD_COMBINED_CRITERIA_SET: '[Criteria] Load Combined Criteria Set' =
    '[Criteria] Load Combined Criteria Set';
}

export class LoadCriteriaSetsCallApiArgs implements ICallApiArgs {
  key = CriteriaCallApiKeys.LOAD_CRITERIA_SETS;
  url = '/criteria-sets';
}

export class SaveCriterionCallApiArgs implements ICallApiArgs {
  key = CriteriaCallApiKeys.SAVE_CRITERION;
  url: string;
  method = CallApiMethods.Put;
  data: ICriterion;

  constructor(data: ICriterion) {
    this.url = `/criteria-sets/${data.criteriaSetId}/criteria/${data.id}`;
    this.data = data;
  }
}

export class SaveCombinedCriteriaSetCallApiArgs implements ICallApiArgs {
  key = CriteriaCallApiKeys.SAVE_COMBINED_CRITERIA_SET;
  url: string;
  method = CallApiMethods.Put;
  data: ICombinedCriteriaSet;

  constructor(data: ICombinedCriteriaSet) {
    this.url = `/criteria-sets/${data.set.id}/combined`;
    this.data = data;
  }
}

export class DeleteCombinedCriteriaSetCallApiArgs implements ICallApiArgs {
  key = CriteriaCallApiKeys.DELETE_COMBINED_CRITERIA_SET;
  url: string;
  method = CallApiMethods.Delete;

  constructor(setId: string) {
    this.url = `/criteria-sets/${setId}`;
  }
}

export class LoadCombinedCriteriaSetCallApiArgs implements ICallApiArgs {
  key = CriteriaCallApiKeys.LOAD_COMBINED_CRITERIA_SET;
  url = '/criteria-sets';
}

export class RemoveCriterionCallApiArgs implements ICallApiArgs {
  key = CriteriaCallApiKeys.DELETE_CRITERION;
  url: string;
  method = CallApiMethods.Delete;

  constructor(public id: string) {
    this.url = `/criteria/${id}`;
  }
}

export class SaveCriteriaSetCallApiArgs implements ICallApiArgs {
  key = CriteriaCallApiKeys.SAVE_CRITERIA_SET;
  url: string;
  method = CallApiMethods.Put;
  data: ICriteriaSet;
  dataKey: string;

  constructor(data: ICriteriaSet) {
    this.url = `/criteria-sets/${data.id}`;
    this.data = data;
    this.dataKey = data.id;
  }
}

export class RemoveCriteriaSetCallApiArgs implements ICallApiArgs {
  key = CriteriaCallApiKeys.DELETE_CRITERIA_SET;
  url: string;
  method = CallApiMethods.Delete;
  data: any;

  constructor(id: string) {
    this.url = `/criteria-sets/${id}`;
    this.data = { id };
  }
}

export class CriteriaUpdateIdealFeedbackState implements Action {
  type = CriteriaActionTypes.CRITERIA_UPDATE_IDEAL_FEEDBACK_STATE;
  constructor(public payload: CriteriaIdealFeedbackStates) {}
}
