<div class="pageheader">
  <h1>Login</h1>
</div>

<div class="pagecontent">
  <div class="wait" [appShow]="!showError">
    Logging In... <i class="fa fa-refresh fa-spin fa-fw"></i>
  </div>
  <div class="error" [appShow]="showError">
    <p>
      Sorry, we were unable to log you back in. Your login link may have expired, or has been used already.
    </p>
    <p>Please enter your email to receive a new link to login or resume your survey with:</p>
    <form>
      <input type="text" [(ngModel)]="emailValue" name="email">
      <button (click)="requestLogin()">Request new link</button>
    </form>
  </div>
</div>

