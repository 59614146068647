<div class="pageheader">
  <h1>The most comprehensive data on
    <br> the Australian startup ecosystem</h1>
</div>

<div class="pagecontent">

  <div class="report-2018"
       (click)="downloadReport()">
    <div class="corner-ribbon-host">
      <div class="corner-ribbon">JUST RELEASED!</div>
      <img src="/assets/images/reports/2018.jpg"
           width="400"
           alt="Startup Muster 2018 Report"
           class="page-title">
    </div>
  </div>

  <p class="left">
    The 2018 Startup Muster report is the fifth annual report from Startup Muster, highlighting the progress,
    challenges and opportunities in the Australian startup ecosystem.
  </p>

  <button class="action primary read-report"
          (click)="downloadReport()">Read the report</button>

  <hr class="clearfix">

  <p>
    If you are a startup founder, future startup founder, or a startup supporter, please help us measure and represent
    what’s happening in the community by contributing your data below.</p>
  <p>
    While you answer our questions, Startup Muster will show you how you compare to other organisations. Founders will
    also receive emails with recommendations of startup support organisations and events that are relevant to them,
    based on the criteria supporters have given us.
  </p>
  <p>
    These are statistics for the startup community, by the startup community.
  </p>

  <div class="quotes">
    <div class="quote quote-left">
      <div class="quote-image">
        <img src="/assets/images/quotes/small_EmilyRichAndAlanJones.jpg">
      </div>
      <blockquote>
        &ldquo;It’s a Herculean task to research the changing dynamics of something as fast-changing
        as Australia’s tech startup industry. Yet it’s essential to develop a clearer
        picture of where we are world-class and where we might need further support
        and development to foster the economic impact of the innovation economy.
        Startup Muster is our primary source of research at M8 Ventures when it comes
        to cross-checking the changes we observe at the coal face, with what is happening
        in the rest of the industry.&rdquo;
        <footer>
          <cite>&mdash; Alan Jones and Emily Rich, M8 Ventures</cite>
        </footer>
      </blockquote>
    </div>

    <div class="quote quote-right">
      <div class="quote-image">
        <img src="/assets/images/quotes/small_AndyLamb.jpg">
      </div>
      <blockquote>
        &ldquo;Startup Muster are a fantastic organisation that keeps there finger on the
        pulse of startup businesses (both successful and not so much) to help raise
        the profile of these new businesses in the Australian and international market.
        It’s something that I recommend all startup and innovative businesses to
        get involved with each year. This aids to our industry raise its profile
        and speak with a consistent voice.&rdquo;
        <footer>
          <cite>&mdash; Andy Lamb, Innovation Studios</cite>
        </footer>
      </blockquote>
    </div>

    <div class="quote quote-left">
      <div class="quote-image">
        <img src="/assets/images/quotes/small_AndreaMyles.jpg">
      </div>
      <blockquote>
        &ldquo;Startup Muster lets us know what we've got and what we don't, hence paving
        the way forward becomes much clearer.  Startup Muster is definitive source
        on the ecosystem and we should not take Monica and her team for granted.
        Without them, all we are is hot air.&rdquo;
        <footer>
          <cite>&mdash; Andrea Myles, CAMP</cite>
        </footer>
      </blockquote>
    </div>

    <div class="quote quote-right">
      <div class="quote-image">
        <img src="/assets/images/quotes/small_AlexVenardos.jpg">
      </div>
      <blockquote>
        &ldquo;AustCyber's mission is to help grow a vibrant cyber security ecosystem in
        Australia - to achieve this, we need to be connected with founders and supporters
        Australia wide. Startup Muster and the quality data sets they produce are
        highly valuable to help us further our mission.&rdquo;
        <footer>
          <cite>&mdash; Alex Venardos, AustCyber</cite>
        </footer>
      </blockquote>
    </div>

    <div class="quote quote-left">
      <div class="quote-image">
        <img src="/assets/images/quotes/small_BenChong.jpg">
      </div>
      <blockquote>
        &ldquo;Startup Muster is a wonderful initiative. By surveying the entire Australian
        startup community, from future founders and founders to supporters and investors,
        it builds a clear picture about our local growing tech scene. It helps us
        direct efforts, whether to high performing founders and their companies,
        or other sections of the community that need more support. I recommend anyone
        involved in the community actively participate in the program.&rdquo;
        <footer>
          <cite>&mdash; Ben Chong, Right Click Capital</cite>
        </footer>
      </blockquote>
    </div>

  </div>

  <div class="bottom-actions">
    <h2>Ready?</h2>

    <p>
      <button class="action primary"
              [routerLink]="'/survey'">Take the survey!</button>
    </p>
  </div>

</div>