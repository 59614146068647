import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  HostListener
} from '@angular/core';
import { IConnectionInfo } from '../../../common/opportunities.model';
import { IConnectionsStateConnectionInfo } from '../connections/connections.component';
// import uniqolor from 'uniqolor';
import * as UrlParse from 'url-parse';

@Component({
  selector: 'app-org-line',
  templateUrl: './org-line.component.html',
  styleUrls: ['./org-line.component.sass']
})
export class OrgLineComponent implements OnInit {
  @Input() org: IConnectionsStateConnectionInfo;
  @Input() viewOnly: boolean;

  expanded = true;

  constructor() {}

  ngOnInit() {
    if (this.viewOnly) {
      this.expanded = true;
    }
  }

  @HostListener('click')
  onClickOrgLine($event: MouseEvent) {
    // $event.stopPropagation();
    this.expanded = !this.expanded;
  }

  cleanUrl(url: string) {
    return url.match(/https?:\/\//) ? url : `http://${url}`;
  }

  // color(text: string) {
  //   return uniqolor(text, {
  //     saturation: [80, 90],
  //     lightness: [80, 90]
  //     // differencePoint: 250
  //   }).color;
  // }

  get logoHost() {
    if (this.org.url) {
      const cleanedUrl = this.cleanUrl(this.org.url);
      try {
        const parsed = new UrlParse(cleanedUrl);
        const hostname = parsed.hostname;
        // const hostname = new URL(this.org.url).hostname;
        if (hostname) {
          return hostname;
        }
      } catch {}
    }
    return this.org.url!;
  }
}
