
import {empty as observableEmpty, of as observableOf,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';

export interface IDataLoadOptions<T> {
  state$: Observable<T>;
  isLoaded: (state: T) => boolean;
  triggerLoad: () => void;
}

@Injectable()
export class DataLoaderService {
  constructor() {}

  load<T>(options: IDataLoadOptions<T>): Observable<boolean> {
    let loadRequested = false;
    return options.state$.pipe(mergeMap(state => {
      if (options.isLoaded(state)) {
        return observableOf(true);
      }
      if (!loadRequested) {
        options.triggerLoad();
        loadRequested = true;
      }
      return observableEmpty();
    }));
  }
}
