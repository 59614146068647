import { ICriteriaState, IEditingCriterion } from '../criteria.models';
import { set, removeAt, addLast, omit, merge } from 'timm';
import { CriteriaOperatorSelectChange } from '../criteria-operator-select/criteria-operator-select.component';
import {
  CriteriaOperator,
  ICriterion
} from '../../../common/criteria/criteria.models';
import { OperatorType } from '../../../common/criteria/criteria-operator-types.models';
import { CriteriaCriterionSetValue } from '../criteria.actions';

export function criterionUpdate(
  state: IEditingCriterion,
  action: CriteriaCriterionSetValue
): IEditingCriterion {
  switch (action.key) {
    case 'questionId':
      state = set(state, 'questionId', action.value);
      state = omit(state, 'operator');
      if (!state.maxAgeMonths) {
        state.maxAgeMonths = 12;
      }
      break;
    case 'maxAgeMonths':
      state = set(state, 'maxAgeMonths', parseInt(action.value, 10));
      break;
    case 'operatorType':
      state = set(state, 'operator', { type: action.value });
      break;
    case 'operatorValue':
      const change = action.value as CriteriaOperatorSelectChange;
      state = set(
        state,
        'operator',
        criterionOperatorUpdate(state.operator!, change)
      );
      break;
  }
  return state;
}

function criterionOperatorUpdate(
  state: Partial<CriteriaOperator>,
  change: CriteriaOperatorSelectChange
): Partial<CriteriaOperator> {
  if (!state) {
    throw new Error(
      'no operator on criterion while attempting to change operator values'
    );
  }
  if (!state.type) {
    throw new Error(
      'no operator type on criterion when attempting to change operator values'
    );
  }

  const valuePath = 'value';

  switch (state.type) {
    // case OperatorTypes.OTHER_CONTAINS:
    //   if (change.type === 'value') {
    //     return set(state, valuePath, change.value);
    //   }
    //   break;
    case OperatorType.ALL_OF:
    case OperatorType.ONE_OF:
      if (change.type === 'choice') {
        const optionIdsPath = 'optionIds';
        if (!state.optionIds) {
          return set(state, optionIdsPath, [change.value]);
        }
        const optionIndex = state.optionIds.indexOf(change.value);
        if (optionIndex >= 0) {
          return set(
            state,
            optionIdsPath,
            removeAt(state.optionIds, optionIndex)
          );
        }
        return set(
          state,
          optionIdsPath,
          addLast(state.optionIds, change.value)
        );
      }
      if (
        change.type === 'choice-other' &&
        state.type === OperatorType.ONE_OF
      ) {
        // return set(state, 'optionIds', change.values);
        return set(state, 'otherContains', change.values);
      }
      break;
    case OperatorType.BETWEEN:
      if (change.type === 'between') {
        const prop = change.between === 'from' ? 'start' : 'end';
        const value = change.value ? parseFloat(change.value) : undefined;
        return set(state, prop, value);
      }
      break;
    case OperatorType.CONTAINS:
      if (change.type === 'value') {
        return set(state, valuePath, change.value);
      }
      break;
    case OperatorType.PROXIMITY:
      if (change.type === 'proximity') {
        return merge(state, change.value);
      }
      break;
  }

  throw new Error(
    `got invalid change type ${change.type} for operator type ${state.type}`
  );
}
