import {
  CriteriaOperator,
  CriteriaSetValidity
} from '../../common/criteria/criteria.models';
import Dictionary = _.Dictionary;
import { Option } from '../shared/search-select/search-select.component';
import { Question } from '../../common/questions/question.models';
import { OperatorType } from '../../common/criteria/criteria-operator-types.models';

export interface ICriteriaState {
  idealFeedbackState: CriteriaIdealFeedbackStates;
  editingCriterion: IEditingCriterion | null;
  definingCriteria: IDefiningCriteria | null;
  setValidity: _.Dictionary<CriteriaSetValidity>;
}

export type CriteriaIdealFeedbackStates = 'empty' | 'filling' | 'done';

export interface IEditingCriterion {
  id: string;
  questionId?: string;
  operator?: Partial<CriteriaOperator>;
  maxAgeMonths?: number;
  validState: IValidState;
}

export interface IValidState {
  isValid: boolean;
  message?: string;
}

export namespace ValidState {
  export function invalid(message?: string): IValidState {
    return { isValid: false, message };
  }

  export function valid(message?: string): IValidState {
    return { isValid: true, message };
  }
}

export interface IDefiningCriteria {
  setId: string;
  state: DefiningCriteriaStates;
}

export type DefiningCriteriaStates =
  | 'editing'
  | 'valid'
  | 'tooWide'
  | 'tooNarrow'
  | 'completing'
  | 'saving';
