import { QuestionResponse } from './response.model';
import { QuestionCondition } from './question.models';
import { parseOptionId, stripSubOption } from './question.util';

export class QuestionVisibilityConditionService {
  conditionIsMet(
    responsesById: _.Dictionary<QuestionResponse>,
    condition: QuestionCondition
  ): boolean {
    const response = responsesById[condition.questionId];
    if (response) {
      switch (response.type) {
        case 'choice':
          const choice = stripSubOption(response.choice);
          return condition.value === choice;
        case 'value':
          if (condition.operator) {
            switch (condition.operator) {
              case 'gte':
                return (
                  parseFloat(response.value) >= parseFloat(condition.value)
                );
              case 'lte':
                return (
                  parseFloat(response.value) <= parseFloat(condition.value)
                );
            }
          }
          return condition.value === response.value;
        case 'selection':
          return response.choices
            .map(stripSubOption)
            .some(c => c === condition.value);
        case 'skipped':
          return condition.value === 'Skipped';
      }
    }
    return false;
  }
}
