import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-requested',
  templateUrl: './login-requested.component.html',
  styleUrls: ['./login-requested.component.sass']
})
export class LoginRequestedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
