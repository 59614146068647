import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

export const TOKEN_KEY = 'id_token';
export const IS_TEMPORARY = 'is_temporary';

@Injectable()
export class TokenService {
  // Implementation notes:
  // Apparently incognito Safari will throw exceptions on localStorage methods
  // Sometimes a fairly recent chrome on android can be missing localStorage completely

  private backupStorage: _.Dictionary<string> | null = null;

  clear() {
    try {
      if (window.localStorage) {
        localStorage.removeItem(IS_TEMPORARY);
        localStorage.removeItem(TOKEN_KEY);
        return;
      }
    } catch (err) {
      if (!this.isSafariPrivateError(err)) {
        throw err;
      }
    }

    this.backupStorage = {};
  }

  store(token: string) {
    try {
      if (window.localStorage) {
        localStorage.setItem(TOKEN_KEY, token);
        return;
      }
    } catch (err) {
      // Safari in private mode just throws exceptions on local storage
      if (!this.isSafariPrivateError(err)) {
        throw err;
      }
    }

    this.initBackupStorage();
    this.backupStorage![TOKEN_KEY] = token;
  }

  get token(): string | null {
    if (this.backupStorage) {
      return this.backupStorage[TOKEN_KEY];
    }

    try {
      if (window.localStorage) {
        return localStorage.getItem(TOKEN_KEY);
      }
    } catch (err) {
      if (!this.isSafariPrivateError(err)) {
        throw err;
      }
    }

    return null;
  }

  get isTemporary() {
    let stringVal = null;

    if (this.backupStorage) {
      stringVal = this.backupStorage[IS_TEMPORARY];
    } else if (window.localStorage) {
      try {
        stringVal = localStorage.getItem(IS_TEMPORARY);
      } catch (err) {
        if (!this.isSafariPrivateError(err)) {
          throw err;
        }
      }
    }

    if (stringVal !== null && stringVal !== undefined) {
      return JSON.parse(stringVal);
    }

    return true;
  }

  set isTemporary(value: boolean) {
    try {
      if (window.localStorage) {
        localStorage.setItem(IS_TEMPORARY, JSON.stringify(value));
        return;
      }
    } catch (err) {
      if (!this.isSafariPrivateError(err)) {
        throw err;
      }
    }

    this.initBackupStorage();
    this.backupStorage![IS_TEMPORARY] = JSON.stringify(value);
  }

  private isSafariPrivateError(err: any) {
    return err.name && err.name === 'QuotaExceededError';
  }

  private initBackupStorage() {
    if (!this.backupStorage) {
      this.backupStorage = {};
    }
  }
}
