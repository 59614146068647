<app-grid-select [selections]="selections"
                 [options]="selectOptions"
                 (select)="onSelect($event)" [style]="selectStyle"></app-grid-select>

<input #extraElement
       type="text"
       class="extra"
       *ngIf="showExtraBox"
       [(ngModel)]="extra"
       (input)="onExtraChanged($event)">
