import {
  mergeMap,
  withLatestFrom,
  filter,
  scan,
  debounceTime
} from 'rxjs/operators';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.models';
import { Effect } from '@ngrx/effects';
import { QuestionSelectorsService } from '../your-organisation/question-selectors.service';
import { QuestionsSeenCallApiArgs } from '../data/question.actions';
import { CallApiService } from '../shared/call-api';
import { EffectLoggerService } from '../effect-logger.service';
import { IQuestionComponentState } from '../your-organisation/question/question.component';
import { QuestionId } from '../../common/questions/question.models';
import { QuestionIds } from '../../common/questions/constants';

interface ISeenTrackerState {
  added: QuestionId[];
  removed: QuestionId[];
  state: QuestionId[];
}

@Injectable()
export class QuestionSeenEffects {
  // TODO: come up with something more robust
  @Effect({ dispatch: false })
  seenTracker$ = this.questionSelectors.visibleQuestionsResponses$(true).pipe(
    debounceTime(1000),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        scan(
          (
            acc: ISeenTrackerState,
            questionsResponses: IQuestionComponentState[]
          ) => {
            const visibleIds = questionsResponses.map(qr => qr.question.id);
            const removedIds = _.difference(acc.state, visibleIds);
            const addedIds = _.difference(visibleIds, acc.state);
            return {
              added: addedIds,
              removed: removedIds,
              state: visibleIds
            };
          },
          {
            added: [],
            removed: [],
            state: []
          }
        ),
        filter(s => s.added.length > 0 || s.removed.length > 0),
        withLatestFrom(this.store.select(s => s.data.questions.byId)),
        filter(([s, questionsById]) => {
          if (s.added.length === 1 && s.removed.length === 0) {
            return questionsById[s.added[0]].key !== QuestionIds.USER_TYPE;
          }
          return true;
        }),
        mergeMap(([s, ..._rest]) => {
          return this.callApi.callApi(
            new QuestionsSeenCallApiArgs({
              added: s.added,
              removed: s.removed
            })
          ) as any;
        })
      )
    )
  );

  constructor(
    private store: Store<AppState>,
    private questionSelectors: QuestionSelectorsService,
    private callApi: CallApiService,
    private effectLogger: EffectLoggerService
  ) {}
}
