import {
  of as observableOf,
  from as observableFrom,
  empty as observableEmpty,
  Observable
} from 'rxjs';

import { mergeMap, mergeAll, map, withLatestFrom, merge } from 'rxjs/operators';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app.models';
import {
  DataActionTypes,
  DataLoaded,
  DataLoadFailed,
  DataLoading,
  DataLoadRequested,
  DataSaved,
  DataSaveError,
  DataSaveFailed,
  DataSaveRequested,
  DataSaving,
  DataSetItem,
  DataUpdateRelation
} from '../data/data.actions';
import { IDataState, DataCollectionKey } from '../data/data.models';
import {
  CallApiActionTypes,
  ICallApiArgs,
  CallApiService
} from '../shared/call-api';
import { AppLogger } from '../app-logger';
import { AppActions } from '../app.actions';
import { DataConfig } from '../data/data.config';
import { AppResponse } from '../../common/core/core.models';
import { EffectLoggerService } from '../effect-logger.service';

interface IHandleCallApiData {
  args: ICallApiArgs;
  callAction: AppActions;
  successAction: (response: any) => AppActions;
  failAction: (errors: string[]) => AppActions;
  errorAction: AppActions;
}

@Injectable()
export class DataEffects {
  private data$ = this.store.select(s => s.data);

  @Effect()
  loadRequested$() {
    return this.actions$.pipe(
      ofType(DataActionTypes.DATA_LOAD_REQUESTED),
      this.effectLogger.handleErrors(source$ => {
        return source$.pipe(
          withLatestFrom(this.data$, this.loadRequested),
          mergeAll()
        );
      })
    );
  }

  @Effect()
  saveRequested$() {
    return this.actions$.pipe(
      ofType(DataActionTypes.DATA_SAVE_REQUESTED),
      this.effectLogger.handleErrors(source$ =>
        source$.pipe(withLatestFrom(this.data$, this.saveRequested), mergeAll())
      )
    );
  }

  @Effect()
  updateRelationships$() {
    return this.actions$.pipe(
      ofType(DataActionTypes.DATA_SET_ITEM),
      this.effectLogger.handleErrors(source$ =>
        source$.pipe(
          withLatestFrom(
            this.store.select(s => s.data),
            this.updateRelationships
          ),
          mergeAll()
        )
      )
    );
  }

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private logger: AppLogger,
    private config: DataConfig,
    private effectLogger: EffectLoggerService,
    private callApi: CallApiService
  ) {}

  private loadRequested = (action: DataLoadRequested, data: IDataState) => {
    const collectionKey = action.payload.collection;
    const config = this.config.configuration[collectionKey];
    if (config) {
      const loadingState = data[collectionKey].loading;

      if (loadingState !== 'loading') {
        const request = this.callApi.callApi(config.loadArgs).pipe(
          mergeMap(apiAction => {
            let result: Action[];
            const keys = this.selectKeysByCallApiKey(apiAction.payload.key);
            if (apiAction.type === CallApiActionTypes.CALL_API_LOADED) {
              result = keys.map(key => {
                const newData = this.config.configuration[key].loadMapResponse(
                  apiAction.payload.response
                );
                return new DataLoaded({
                  collection: key,
                  data: newData
                });
              });
            } else {
              result = keys.map(key => new DataLoadFailed({ collection: key }));
            }
            return observableFrom(result);
          })
        );
        return observableOf(
          new DataLoading({ collection: collectionKey })
        ).pipe(merge(request));
      }
    } else {
      throw new Error(
        `Load requested on collection ${collectionKey} with missing config or missing loadArgs`
      );
    }
    return observableEmpty();
  };

  private saveRequested = (action: DataSaveRequested, data: IDataState) => {
    const collectionKey = action.payload.collection;
    const config = this.config.configuration[collectionKey];
    const collection = data[collectionKey];
    const itemId = action.payload.itemId;
    const itemStatus = collection.saving[itemId];
    const itemData = collection.byId[itemId];

    if (!config.saveArgs) {
      throw new Error('Attempting to save for collection with missing config');
    }

    if (!itemStatus || itemStatus !== 'saving') {
      let args: ICallApiArgs;
      if (itemData) {
        args = config.saveArgs(itemId, itemData);
      } else {
        if (!config.deleteArgs) {
          throw new Error(
            'Attempting to delete for collection with missing config'
          );
        }
        args = config.deleteArgs(itemId);
      }
      const itemPayload = { collection: collectionKey, itemId };
      return this.handleCallApiActions({
        args,
        callAction: new DataSaving(itemPayload),
        successAction: response => new DataSaved({ ...itemPayload, response }),
        failAction: errors => new DataSaveFailed({ ...itemPayload, errors }),
        errorAction: new DataSaveError(itemPayload)
      });
    } else {
      this.logger.warn('Attempted to save item that has failed or is saving');
    }
    return observableEmpty();
  };

  private updateRelationships = (action: DataSetItem, data: IDataState) => {
    const { collection, itemData, itemId } = action.payload;

    const actions = this.config.keys
      .filter(otherCollection => otherCollection !== collection)
      .map(otherCollection => {
        return {
          otherCollection,
          config: this.config.configuration[otherCollection]
        };
      })
      .filter(({ config }) => config.hasMany && collection in config.hasMany)
      .map(({ otherCollection, config }) => {
        const hasMany = config.hasMany![collection];

        const parentId = itemData ? (<any>itemData)[hasMany.foreignKey] : null;

        const parent: any = parentId
          ? data[otherCollection].byId[parentId]
          : null;
        const oldParents: any[] = _.values(data[otherCollection].byId).filter(
          (p: any) => p[hasMany.arrayKey].indexOf(itemId) > -1
        );

        if (parent && oldParents.length === 1 && oldParents[0] === parent) {
          return null;
        }

        const basePayload = {
          collection: otherCollection,
          idArrayKey: hasMany.arrayKey,
          childId: itemId
        };
        for (const oldParent of oldParents) {
          if (oldParent !== parent) {
            return new DataUpdateRelation({
              ...basePayload,
              parentId: oldParent.id,
              childAction: 'removing'
            });
          }
        }
        if (parent) {
          return new DataUpdateRelation({
            ...basePayload,
            parentId,
            childAction: 'adding'
          });
        }
        return null;
      })
      .filter(a => !!a) as Action[];
    return observableFrom(actions);
  };

  private selectKeysByCallApiKey(callApiKey: string): DataCollectionKey[] {
    return (<DataCollectionKey[]>Object.keys(this.config.configuration)).filter(
      key => {
        const config = this.config.configuration[key];
        return config.loadArgs.key === callApiKey;
      }
    );
  }

  private handleCallApiActions(data: IHandleCallApiData): Observable<Action> {
    const call$ = this.callApi.callApi(data.args).pipe(
      map(action => {
        if (action.type === CallApiActionTypes.CALL_API_LOADED) {
          const response = action.payload.response as AppResponse<{}>;
          if (response.success) {
            return data.successAction(response.data);
          }
          return data.failAction(response.errors);
        } else {
          return data.errorAction;
        }
      })
    );

    return observableOf(data.callAction).pipe(merge(call$));
  }
}
