import { LoadingTestComponent } from './dev/loading-test/loading-test.component';
import { SearchSelectTestComponent } from './dev/search-select-test/search-select-test.component';
import { ReportsComponent } from './reports/reports.component';
import { ConnectWithPageComponent } from './opportunities/connect-with-page/connect-with-page.component';
import { ConnectionsPageComponent } from './opportunities/connections-page/connections-page.component';
import { OpportunitiesDataLoaderService } from './opportunities/opportunities-data-loader.service';
import { OpportunitiesComponent } from './opportunities/opportunities.component';
import { AuthGuardService } from './users/auth-guard.service';
import { LoginComponent } from './users/login/login.component';
import { YourOrganisationComponent } from './your-organisation/your-organisation/your-organisation.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CriteriaDataLoaderService } from './criteria/criteria-data-loader.service';
import { environment } from '../environments/environment';
import { LoginRequestedComponent } from './users/login-requested/login-requested.component';
import { LoginDirectComponent } from './users/login-direct/login-direct.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { LeaveFeedbackComponent } from './opportunities/leave-feedback/leave-feedback.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ModalComponent } from './shared/modal/modal.component';
import { ConfirmCancelComponent } from './shared/confirm-cancel/confirm-cancel.component';
import { AppInitService } from './app-init.service';
import { AboutComponent } from './about/about.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MockTargetsComponent } from './dev/mock-targets/mock-targets.component';
import { MockDefineComponent } from './dev/mock-define/mock-define.component';
import { CriteriaRootComponent } from './criteria/criteria-root/criteria-root.component';
import { CriteriaEditSetComponent } from './criteria/criteria-edit-set/criteria-edit-set.component';
import { ValueListTestComponent } from './dev/value-list-test/value-list-test.component';
import { ProxTestComponent } from './dev/prox-test/prox-test.component';
import { ReportDownloadComponent } from './shared/report-download/report-download.component';
import { StylesComponent } from './dev/styles/styles.component';
import { SurveyUpdateComponent } from './your-organisation/survey-update/survey-update.component';
import { QuestionViewComponent } from './dev/question-view/question-view.component';
import { LoginUpdateComponent } from './users/login-update/login-update.component';
import { MapPageComponent } from './map/map-page/map-page.component';

const routes: Routes = [
  {
    path: 'login',
    children: [
      { path: '', component: LoginDirectComponent },
      { path: 'requested', component: LoginRequestedComponent },
      { path: 'update', component: LoginUpdateComponent },
      { path: ':id', component: LoginComponent },
    ]
  },
  {
    path: 'auto/login',
    children: [
      { path: ':email', component: LoginComponent },
    ]
  },
  {
    path: '',
    canActivate: [AppInitService],
    children: [
      {
        path: '',
        // component: HomeComponent
        component: SurveyUpdateComponent
      }
    ]
  },
  {
    path: '',
    canActivate: [AppInitService],
    data: { lightweight: true },
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'reports',
        component: ReportsComponent
      },
      { path: 'about', component: AboutComponent },
      { path: 'contact', component: AboutComponent },

      /* Disable map for now
      {
        path: 'map',
        component: MapPageComponent
      }

       */
    ]
  },
  {
    path: '',
    canActivate: [AppInitService],
    children: [
      {
        path: 'survey',
        component: SurveyUpdateComponent
      },
      {
        path: '',
        canActivate: [AuthGuardService],
        children: [
          {
            path: 'dashboard',
            component: DashboardComponent
          },
          {
            path: 'recommended-support',
            component: OpportunitiesComponent,
            resolve: [OpportunitiesDataLoaderService],
            children: [
              { path: '', component: ConnectionsPageComponent },
              { path: 'connect-with/:id', component: ConnectWithPageComponent },
              {
                path: 'leave-feedback/:id',
                component: LeaveFeedbackComponent
              },
              {
                path: 'leave-feedback/:id/:preselect',
                component: LeaveFeedbackComponent
              }
            ]
          },
          {
            path: 'your-organisation',
            component: YourOrganisationComponent
          },
          {
            path: 'connect-with-startups',
            resolve: [CriteriaDataLoaderService],
            children: [
              {
                path: '',
                component: CriteriaRootComponent
              },
              {
                path: ':id',
                component: CriteriaEditSetComponent
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'modal',
    component: ModalComponent,
    outlet: 'modal',
    children: [
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'confirm-cancel', component: ConfirmCancelComponent },
      { path: 'contact', component: ContactComponent }
    ]
  },
  {
    path: 'report-download/:id',
    component: ReportDownloadComponent,
    outlet: 'modal'
  },
  { path: '**', component: PageNotFoundComponent }
];

if (environment.dev) {
  routes.unshift({
    path: 'dev',
    canActivate: [],
    children: [
      { path: 'loading', component: LoadingTestComponent },
      { path: 'search-select', component: SearchSelectTestComponent },
      { path: 'mock-targets', component: MockTargetsComponent },
      { path: 'mock-define', component: MockDefineComponent },
      { path: 'value-list', component: ValueListTestComponent },
      { path: 'prox-test', component: ProxTestComponent },
      { path: 'styles', component: StylesComponent },
      { path: 'question/:key', component: QuestionViewComponent }
    ]
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {}
