
import {combineLatest, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.models';
import { Observable } from 'rxjs';
import { SelectionResponse } from '../../common/questions/response.model';
import * as _ from 'lodash';
import { QuestionIds } from 'common/questions/constants';

export interface IUserType {
  isHelper: boolean;
  isStartup: boolean;
  isFuture: boolean;
}

const helperTypes = ['AUserTypeHelper', 'AUserTypeLargeHelper'];
const founderTypes = ['AUserTypeFounder'];
const futureTypes = ['AUserTypeFuture'];

@Injectable()
export class OrgSelectorsService {
  private responses$ = this.store.select(s => s.data.responses.byId);
  private oldResponses$ = this.store.select(s => s.data.oldResponses.byId);

  userType$: Observable<IUserType> = this.responses$.pipe(
    map(rs =>
      _(rs)
        .filter(r => r.questionKey === QuestionIds.USER_TYPE)
        .first()
    ),
    combineLatest(this.oldResponses$, (userTypeResponse, oldResponses) => {
      if (userTypeResponse) {
        return userTypeResponse;
      }
      const oldResponse = oldResponses[QuestionIds.USER_TYPE];
      return oldResponse && oldResponse.replacementResponse;
    }),
    map(r => {
      let isHelper = false;
      let isStartup = false;
      let isFuture = false;
      if (r && r.type === 'selection') {
        isHelper = this.choicesContains(r, helperTypes);
        isStartup = this.choicesContains(r, founderTypes);
        isFuture = this.choicesContains(r, futureTypes);
      }
      return { isHelper, isStartup, isFuture };
    }),);

  constructor(private store: Store<AppState>) {}

  private choicesContains(
    response: SelectionResponse,
    contains: string[]
  ): boolean {
    return response.choices.some(c => contains.indexOf(c) > -1);
  }
}
