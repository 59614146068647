import {
  IYourOrganisationQuestion,
  IYourOrganisationSection,
  IYourOrganisationState
} from '../../common/your-organisation.models';
import { QuestionVisibilityConditionService } from '../../common/questions/question-visibility-condition.service';
import { QuestionResponse } from '../../common/questions/response.model';
import { QuestionVisibilityService } from '../../common/questions/question-visibility.service';
import { CancelEditQuestion, EditQuestion } from './your-organisation.actions';
import { IYourOrganisationUiState } from './your-organisation.models';
import { merge, set, setIn, removeAt, addLast } from 'timm';
import { AppActions } from '../app.actions';
import {
  YourOrganisationActionTypes,
  YourOrganisationCallApiKeys,
  SelectSection
} from '../your-organisation/your-organisation.actions';
import * as _ from 'lodash';
import { CallApiActionTypes } from '../shared/call-api';

const DEFAULT_STATE: IYourOrganisationUiState = {
  currentUpdateLevel: 'critical',
  selectedSectionIds: []
};

export function yourOrganisationReducer(
  state: IYourOrganisationUiState = DEFAULT_STATE,
  action: AppActions
): IYourOrganisationUiState {
  switch (action.type) {
    case YourOrganisationActionTypes.EDIT_QUESTION:
      return editQuestionReducer(state, action);
    case YourOrganisationActionTypes.CANCEL_EDIT_QUESTION:
      return cancelEditQuestionReducer(state, action);
    case YourOrganisationActionTypes.SELECT_SECTION:
      return set(
        state,
        'selectedSectionIds',
        selectSectionReducer(state.selectedSectionIds, action)
      );
    case YourOrganisationActionTypes.SET_UPDATE_LEVEL:
      return set(state, 'currentUpdateLevel', action.payload.updateLevel);
  }

  return state;
}

export function editQuestionReducer(
  state: IYourOrganisationUiState,
  action: EditQuestion
): IYourOrganisationUiState {
  return set(state, 'editingQuestionId', action.payload);
}

export function cancelEditQuestionReducer(
  state: IYourOrganisationUiState,
  action: CancelEditQuestion
): IYourOrganisationUiState {
  return set(state, 'editingQuestionId', undefined);
}

export function selectSectionReducer(
  selectedSectionIds: string[],
  action: SelectSection
): string[] {
  const sectionIndex = selectedSectionIds.indexOf(action.payload.sectionId);
  if (sectionIndex > -1) {
    return removeAt(selectedSectionIds, sectionIndex);
  }
  return addLast(selectedSectionIds, action.payload.sectionId);
}
