<ng-container [ngSwitch]="status">

  <i *ngSwitchCase="'saved'"
     class="status-saved fa fa-lg fa-check"
     aria-hidden="true"></i>

  <i *ngSwitchCase="'error'"
     class="status-error fa fa-lg fa-refresh fa-spin"
     aria-hidden="true"></i>

  <i *ngSwitchCase="'saving'"
     class="status-saving fa fa-lg fa-refresh fa-spin"
     aria-hidden="true"></i>

  <i *ngSwitchCase="'need-update'"
     class="status-need-update fa fa-lg fa-exclamation-triangle"
     aria-hidden="true"></i>

</ng-container>
