import {
  Directive,
  OnChanges,
  Input,
  SimpleChanges,
  HostBinding,
  ElementRef,
  AfterViewChecked,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[appShow]'
})
export class ShowDirective implements OnChanges {
  @HostBinding('class.hidden')
  hidden: boolean;

  // tslint:disable-next-line:no-input-rename
  @Input('appShow')
  show: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.hidden = !this.show;
  }
}
