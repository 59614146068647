import { ErrorHandler, Injectable } from '@angular/core';
import { AppLogger } from './app-logger';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private logger: AppLogger) {}

  handleError(error: any): void {
    if (!window.rollbar || !window.rollbar.error) {
      console.error('Unable to report error to rollbar!');
      console.error(error);
    }
    try {
      this.logger.error(error);
    } catch (rollbar_err) {
      console.error('rollbar error: ', rollbar_err);
      throw error;
    }
  }
}
