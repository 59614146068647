<div class="pageheader">
  <h1>Recommended Connections</h1>
</div>

<div class="pagecontent">

  <p>
    Based on your profile, we’ve found companies, organisations and supporters that are a great match for you to connect with.
  </p>

  <p>
    The most relevant matches appear first. By reviewing and reaching out to your recommended connections, you can access opportunities
    that will help you grow and succeed.
  </p>

  <div *ngIf="hasConnections"
       class="section">
    <h3>Awaiting feedback</h3>

    <ul class="feedback-list">
      <li *ngFor="let org of state.connections">
        <strong>{{ org.name }}</strong>
        <a [routerLink]="['leave-feedback', org.orgId]">Leave feedback</a>
      </li>
    </ul>
  </div>

  <hr>

  <div *ngIf="hasOpportunities || filterSupporterType"
       class="section">

    <h3>New opportunities</h3>

    <div class="filter">
      <app-filter-select [options]="filterSupporterTypeOptions"
                         [selected]="filterSupporterType"
                         [name]="'Filter by supporter type'"
                         (select)="onSelectFilterSupporterType($event)">
      </app-filter-select>
    </div>

    <app-org-line [org]="org"
                  *ngFor="let org of state.opportunities">
    </app-org-line>

  </div>

  <div *ngIf="noResults"
       class="component">
    <p>
      No results. Please
      <a [routerLink]="['/your-organisation']">update your organisation</a> if you have not done so recently.
    </p>
  </div>

</div>
