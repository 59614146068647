<div class="pageheader">
  <h1>Your organisation</h1>
</div>

<div class="pagecontent">

  <p class="be-sure">
    Be sure to update the questions with orange flags
    <i class="fa fa-exclamation-triangle"></i>, so that you can unlock new opportunities with businesses that can assist you.
  </p>

  <!-- <p>Unlock new opportunities to engage with businesses that can assist you by updating your information here.</p> -->

  <app-answers-view [state]="state$ | async"
                    (editQuestion)="onEditQuestion($event)"
                    (cancelEditQuestion)="onCancelEditQuestion()"
                    (selectSection)="onSelectSection($event)">
  </app-answers-view>
</div>
