
import {combineLatest} from 'rxjs/operators';
import { IOpportunities } from '../../../common/opportunities.model';
import { Component, OnInit } from '@angular/core';
import { AppState } from '../../app.models';
import { Store } from '@ngrx/store';
import { LoadStateCallApiArgs } from '../opportunities.actions';
import {
  IConnectionsCancelPending,
  IConnectionsState,
  IConnectionsStateConnectionInfo
} from '../connections/connections.component';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-connections-page',
  templateUrl: './connections-page.component.html',
  styleUrls: ['./connections-page.component.sass']
})
export class ConnectionsPageComponent implements OnInit {
  connections$: Observable<IConnectionsState>;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {
    this.connections$ = store
      .select(s => s.opportunities).pipe(
      combineLatest(route.params, (o, params) => {
        let opportunities = o.connections.opportunities;
        if (params.filter) {
          opportunities = opportunities.filter(
            c => c.helperType === params.filter
          );
        }
        const totalOpportunities = opportunities.length;
        let count = 0;
        // TODO: come up with a more pleasing curve
        opportunities = opportunities.map(c => {
          count++;
          const rank = Math.min(1 - count / totalOpportunities, 1.0);
          return {
            rank,
            ...c
          };
        });
        return {
          connections: o.connections.connections,
          opportunities: opportunities as IConnectionsStateConnectionInfo[]
        };
      }));
  }

  ngOnInit() {}
}
