<div class="pageheader">
  <h1>Login</h1>
</div>

<div class="pagecontent">
  <p>You are currently logged out, to login again request a login link below by providing
    your email address below:</p>

  <app-login-form (formSubmitted)="login($event)"></app-login-form>
</div>
