<button class="filter-question"
        (click)="onClickEditQuestion()">{{ filter.question.text }}
</button>

<div class="actions-group">
  <div class="actions-row">
    <ng-select class="filter-operator"
               [items]="filter.validOperators"
               bindLabel="text"
               bindValue="operator"
               [clearable]="false"
               [searchable]="false"
               [ngModel]="filter.operator.operator"
               (ngModelChange)="onChangeOperator($event)"></ng-select>

    <ng-container [ngSwitch]="filter.operand.type">

      <ng-select #tagsSelect
                 *ngSwitchCase="'tags'"
                 class="filter-options"
                 [items]="filter.operand.items"
                 [multiple]="true"
                 [addTag]="filter.operand.acceptsCustomItems"
                 [addTagText]="filter.operand.addCustomText"
                 [ngModel]="filter.operand.tags"
                 (ngModelChange)="onChangeTags($event)"></ng-select>

      <!-- <ng-select *ngSwitchCase="'text'"
     class="filter-options"
     [items]="[]"
     [multiple]="true"
     [addTag]="true"
     [ngModel]="filter.operand.tags"
     (ngModelChange)="onChangeFilterOperands(filter, $event)"></ng-select> -->

      <div *ngSwitchCase="'between'"
           class="filter-between">
        <input #betweenInput
               [textMask]="mask"
               type="text"
               placeholder="&gt; or equal to"
               [ngModel]="filter.operand.from"
               (ngModelChange)="onChangeBetweenFrom($event)">
        <p>to</p>
        <input type="text"
               [textMask]="mask"
               placeholder="&lt; or equal to"
               [ngModel]="filter.operand.to"
               (ngModelChange)="onChangeBetweenTo($event)">
      </div>

      <div *ngSwitchCase="'location'"
           class="filter-location">
        <app-op-proximity [place]="filter.operand.place"
                          [lat]="filter.operand.lat"
                          [lng]="filter.operand.lng"
                          [radius]="filter.operand.radius"
                          (changeValue)="onChangeProximity($event)"></app-op-proximity>
      </div>

    </ng-container>
  </div>

  <div class="sub-row"
       *ngIf="filter.needsMaxAgeMonths">

    <label class="filter-max-age-label">Maximum age of response:</label>

    <ng-select class="filter-max-age"
               [items]="maxAgeItems"
               bindLabel="text"
               bindValue="value"
               [clearable]="false"
               [searchable]="false"
               [ngModel]="filter.maxAgeMonths"
               (ngModelChange)="onChangeMaxAgeMonths($event)"></ng-select>
  </div>
</div>

<a href="javascript:void(0)"
   class="filter-remove"
   (click)="onClickRemove()">
  <i class="fa fa-times"
     aria-hidden="true"></i>
</a>
