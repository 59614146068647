<header>
  <div class="burger" (click)="toggleMenu()">
    <i class="fa fa-bars" [ngClass]="{ 'fa-rotate-90': mobileMenuShowing }"></i>
  </div>

  <div class="logo">
    <a routerLink="/">
      <img width="300" src="../assets/images/logo-white.svg" alt="logo" />
    </a>
  </div>

  <nav [ngClass]="{ 'show-mobile': mobileMenuShowing }">
    <!--
      <a id="jobs-link"
      routerLink="/jobs"
      routerLinkActive="active">We're hiring!</a>
    -->
    <a  routerLink="/about" routerLinkActive="active">About us</a>
    <!--
    <a *ngIf="showNav" routerLink="/dashboard" routerLinkActive="active"
      >Dashboard</a
    >
    -->
    <a href="javascript:void(0)" (click)="onClickRoute('')">Reports</a>
    <!--
      <a routerLink="/map"
      routerLinkActive="active">Map</a>
    -->
    <a href="javascript:void(0)" (click)="onClickContact($event)">Contact us</a>
    <!--
    <a
      *ngIf="!showNav"
      class="auth"
      routerLink="/login"
      routerLinkActive="active"
      >Login</a
    >
    -->
    <!--
    <a *ngIf="showNav" class="auth" (click)="logOut()">Log out</a>
    -->
    <!--
      <span class="login-email"
       *ngIf="showNav">({{ loginEmail }})</span>
    -->
  </nav>
</header>

<div class="content">
  <div [appShow]="!loading"><router-outlet></router-outlet></div>
  <div class="loading" [appShow]="loading">
    <div class="pageheader"></div>
    <div class="pagecontent">
      <h2>Loading&hellip;</h2>
      <i class="fa fa-4x fa-spin fa-refresh" aria-hidden="true"></i>
    </div>
  </div>
</div>

<footer>
  <div class="logo">
    <img width="120" src="../assets/images/logo-white.svg" alt="logo" />
    <div class="subtitle">
      &nbsp;
      <!-- The largest survey of the Australian startup ecosystem -->
    </div>
    <hr />
  </div>

  <div class="links">
    <div class="local">
      <a routerLink="/about" routerLinkActive="active">About us</a> <a href="javascript:void(0)" (click)="onClickRoute('')">Reports</a>
      <a href="javascript:void(0)" (click)="onClickContact($event)"
        >Contact us</a
      >
    </div>
    <!--
    <div class="social">
      <a href="https://www.linkedin.com/company-beta/6379544/" target="_blank">
        <i class="fa fa-2x fa-linkedin" aria-hidden="true"></i>LinkedIn</a
      >
      <a href="https://www.facebook.com/StartupMuster/" target="_blank">
        <i class="fa fa-2x fa-facebook" aria-hidden="true"></i>Facebook</a
      >
      <a href="https://twitter.com/startupmuster" target="_blank">
        <i class="fa fa-2x fa-twitter" aria-hidden="true"></i>Twitter</a
      >
    </div>
    -->
  </div>

  <div class="copyright">
    Copyright 2024 © Startup Muster. All rights reserved.
  </div>
</footer>

<router-outlet name="modal"></router-outlet>
