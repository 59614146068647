
import {combineLatest} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { AppState } from '../../app.models';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IConnectionInfo } from '../../../common/opportunities.model';



import { RequestConnectionCallApiArgs } from '../opportunities.actions';
import {
  ConnectWithAction,
  IConnectWithState
} from '../connect-with/connect-with.component';
import { CallApiService } from '../../shared/call-api';
import { extractValue } from '../../../common/questions/response.util';
import { QuestionIds } from '../../../common/questions/constants';
import { generateForSupporter } from '../../../common/questions/connection-info-generator';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-connect-with-page',
  templateUrl: './connect-with-page.component.html',
  styleUrls: ['./connect-with-page.component.sass']
})
export class ConnectWithPageComponent implements OnInit {
  state$: Observable<IConnectWithState>;
  private orgId: string;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private callApi: CallApiService
  ) {
    const questionsById$ = store.select(s => s.data.questions.byId);
    const responsesById$ = store.select(s => s.data.responses.byId);
    const dailyLimitReached$ = store.select(
      s => s.opportunities.dailyLimitReached
    );
    this.state$ = store
      .select(s => s.opportunities.connectionsById).pipe(
      combineLatest(
        route.params,
        questionsById$,
        responsesById$,
        dailyLimitReached$,
        (
          connections,
          params,
          questionsById,
          responsesById,
          dailyLimitReachedTs
        ) => {
          const responsesByKey = _(responsesById)
            .values()
            .keyBy(r => r.questionKey)
            .value();
          this.orgId = params['id'];
          const connectionInfo = connections[this.orgId];
          const ourName =
            extractValue(responsesByKey[QuestionIds.ORGANISATION_NAME]) ||
            extractValue(responsesByKey[QuestionIds.FULL_NAME]);
          const ourInfo = generateForSupporter(responsesByKey);
          const dailyLimitReached =
            !!dailyLimitReachedTs &&
            moment().diff(dailyLimitReachedTs, 'hours') < 8;
          return { ...connectionInfo, ourName, ourInfo, dailyLimitReached };
        }
      ));
  }

  ngOnInit() {}

  onAction(action: ConnectWithAction) {
    switch (action.type) {
      case 'connect':
        // TODO: refactor the effect for this to do this instead
        this.callApi
          .callApi(
            new RequestConnectionCallApiArgs(this.orgId, {
              reason: action.reason
            })
          )
          .subscribe(this.store);
        // this.router.navigateByUrl('/');
        break;
      case 'dismiss':
        this.router.navigateByUrl('/recommended-support');
        break;
    }
  }
}
