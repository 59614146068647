import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportDownloadService } from '../shared/report-download/report-download.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.sass']
})
export class ReportsComponent implements OnInit {
  constructor(private reportDownloadService: ReportDownloadService) {  parent.location.href = "https://app.startupmuster.com/reports";}

  ngOnInit() {

  }

  downloadReport(reportId: string) {
    this.reportDownloadService.requestDownload(reportId);
    // this.router.navigate([
    //   {
    //     outlets: { modal: ['report-download', reportId] }
    //   }
    // ]);
  }
}
