import { combineLatest } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { OptionIds, QuestionIds } from '../../common/questions/constants';
import { Question, QuestionKey } from '../../common/questions/question.models';
import { AppState } from '../app.models';
import { DataSelectorsService } from '../data/data-selectors.service';
import { FragmentRouterService } from '../shared/fragment-router.service';
import { ReportDownloadService } from '../shared/report-download/report-download.service';
import { IUserType, OrgSelectorsService } from '../users/org-selectors.service';
import { AnswerDispatcherService } from '../your-organisation/answer-dispatcher.service';
import { QuestionSelectorsService } from '../your-organisation/question-selectors.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit, OnDestroy {
  userType: IUserType;
  isLoading = true;
  surveyCompleted = false;

  private userTypeQuestion: Question;

  private subscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private dataSelectors: DataSelectorsService,
    private orgSelectors: OrgSelectorsService,
    private questionSelectors: QuestionSelectorsService,
    private answerDispatcher: AnswerDispatcherService,
    private fragmentRouter: FragmentRouterService,
    private downloadService: ReportDownloadService
  ) {}

  ngOnInit() {
    const loading$ = this.dataSelectors.isLoaded$(
      'questions',
      'sections',
      'questionResults'
    );
    this.subscription = loading$
      .pipe(
        combineLatest(
          this.store.select(s => s.global.showPlatform),
          this.orgSelectors.userType$,
          this.questionSelectors.selectQuestionByKey$(
            QuestionIds.USER_TYPE as QuestionKey
          )
        )
      )
      .subscribe(([_loaded, surveyCompleted, userType, userTypeQuestion]) => {
        this.isLoading = false;
        this.surveyCompleted = surveyCompleted;
        this.userType = userType;
        this.userTypeQuestion = userTypeQuestion!;
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  beginSurvey() {
    this.fragmentRouter.navigate('start-survey');
  }

  alreadyCompleted() {
    this.answerDispatcher.answer(this.userTypeQuestion, {
      type: 'selection',
      choices: [OptionIds.UserType.RETURNING],
      extra: '',
      questionId: this.userTypeQuestion.id,
      questionKey: this.userTypeQuestion.key,
      updatedAt: new Date()
    });
    this.fragmentRouter.navigate('start-survey');
  }

  downloadReport() {
    this.downloadService.requestDownload('2018');
    // window.open('/reports/Startup-Muster-2017-Report.pdf', '_blank');
  }
}
