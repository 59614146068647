import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { ISurveyStateQuestionResponse } from "../your-organisation.models";

@Component({
  selector: 'app-compare-button',
  templateUrl: './compare-button.component.html',
  styleUrls: ['./compare-button.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompareButtonComponent implements OnInit, OnChanges {
  @Input() state: ISurveyStateQuestionResponse;

  @Output() compare = new EventEmitter<{}>();

  // confirm = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.confirm = false;
  }

  // clickConfirm() {
  //   this.confirm = true;
  // }

  clickCompare() {
    this.compare.emit({});
  }

}
