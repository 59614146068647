import * as _ from 'lodash';

import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { IQuestionResults } from "../../../common/questions/question-results.models";
import { QuestionResponse } from "../../../common/questions/response.model";
import { ISurveyStateResult } from "../your-organisation.models";

@Component({
  selector: 'app-question-results',
  templateUrl: './question-results.component.html',
  styleUrls: ['./question-results.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionResultsComponent implements OnInit, OnChanges {
  @Input() results: ISurveyStateResult[];

  filteredResults: ISurveyStateResult[] = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.results) {
      this.filteredResults = [];
      let counter = 5;
      for (const result of this.results) {
        if (counter > 0)  {
          this.filteredResults.push(result);
          counter--;
        } else {
          if (result.selected) {
            this.filteredResults.push(result);
          }
        }
      }
    }
  }


  trackByText(index: number, item: ISurveyStateResult) {
    return item.text;
  }

}
