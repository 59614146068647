
import {of as observableOf,  Observable } from 'rxjs';

import {merge, catchError, map, first} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppResponse } from '../../common/core/core.models';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../app.models';
import {
  SharedActionTypes,
  SavingStart,
  SavingDone,
  SavingFailed
} from './shared.actions';
import { AppActions } from '../app.actions';

@Injectable()
export class SavingHandlerService {
  constructor(private store: Store<AppState>) {}

  handle(savingRequest: Observable<AppResponse<any>>) {
    this.store.dispatch({ type: SharedActionTypes.SAVING_START });
    savingRequest.pipe(first()).subscribe(
      ar => {
        if (ar.success === true) {
          this.store.dispatch({ type: SharedActionTypes.SAVING_DONE });
        } else {
          this.store.dispatch({
            type: SharedActionTypes.SAVING_FAILED,
            payload: ar.errors
          });
        }
      },
      err => this.store.dispatch({ type: SharedActionTypes.SAVING_FAILED }) // TODO: what sort of error do we show?
    );
  }

  handle$(savingRequest: Observable<AppResponse<any>>): Observable<AppActions> {
    return savingRequest.pipe(
      first(),
      map(appResponse => {
        if (appResponse.success === true) {
          return new SavingDone();
        } else {
          return new SavingFailed(appResponse.errors);
        }
      }),
      catchError(err => observableOf(new SavingFailed())),
      merge(observableOf(new SavingStart())),);
  }
}
