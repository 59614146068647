import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class FragmentRouterService {
  constructor(private router: Router, private route: ActivatedRoute) {}

  navigate(fragment: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      fragment: fragment
    });
  }
}
