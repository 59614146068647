import {
  IOptions,
  ISkippable,
  QuestionShowIf,
  QuestionKey,
  QuestionWithoutId
} from './question.models';

export function isQuestionWithOptions(
  question: QuestionWithoutId
): question is QuestionWithoutId & IOptions {
  return question.type === 'MS' || question.type === 'MC';
}

export function isQuestionSkippable(
  question: QuestionWithoutId
): question is QuestionWithoutId & ISkippable {
  return question.type === 'T' || question.type === 'N';
}

export function showIfContainsQuestionKey(
  showIf: QuestionShowIf,
  key: QuestionKey
): boolean {
  if (showIf.all && showIf.all.some(x => x.questionId === key)) return true;
  if (showIf.any && showIf.any.some(x => x.questionId === key)) return true;
  if (showIf.notAll && showIf.notAll.some(x => x.questionId === key))
    return true;
  if (showIf.notAny && showIf.notAny.some(x => x.questionId === key))
    return true;
  return false;
}

export interface ParsedOptionId {
  optionId: string;
  sub: string | null;
}

export function parseOptionId(optionId: string): ParsedOptionId {
  if (optionId[optionId.length - 1] !== ']') {
    // fast path
    return { optionId, sub: null };
  }
  const matchArray = optionId.match(/^(\w+)(\[(\w+)\])?$/);
  if (matchArray === null) {
    throw new Error(
      `Attempted to parse optionId with invalid format: ${optionId}`
    );
  }
  const mainOptionId = matchArray[1];
  const subOptionId = matchArray[3] || null;
  return { optionId: mainOptionId, sub: subOptionId };
}

export function stripSubOption(optionId: string): string {
  if (optionId[optionId.length - 1] !== ']') {
    // fast path
    return optionId;
  }
  const matchArray = optionId.match(/^(\w+)(\[(\w+)\])?$/);
  if (matchArray === null) {
    throw new Error(
      `Attempted to parse optionId with invalid format: ${optionId}`
    );
  }
  return matchArray[1];
}

export function optionHasSubId(optionId: string): boolean {
  return optionId.match(/^\w+\[\w+\]$/) !== null;
}

// export function asQuestionKey<T extends string>(
//   inString: T
// ): T & Tag<'QuestionKey'> {
//   return inString as T & Tag<'QuestionKey'>;
// }

// export function asQuestionId<T extends string>(
//   inString: T
// ): T & Tag<'QuestionId'> {
//   return inString as T & Tag<'QuestionId'>;
// }
