import { QuestionResponse } from './response.model';
import { QuestionIds, OptionIds } from './constants';
import * as _ from 'lodash';
import { parseOptionId } from './question.util';
import { Tag } from '../core/core.models';

export type ResponsesForVisibility = _.Dictionary<QuestionResponse> &
  Tag<'ResponsesForVisibility'>;

export function transformResponsesForVisibility(
  responsesByKey: _.Dictionary<QuestionResponse>
): ResponsesForVisibility {
  // Currently we're special-casing QUserType only
  // there is no "fast path" as user type is our first question
  return _.mapValues(responsesByKey, r => {
    if (r.questionKey === QuestionIds.USER_TYPE && r.type === 'selection') {
      // We drop the sub-options from our choices, and remove duplicates.
      // Additionally, if a suboption is selected WITH helper, then we drop the
      // whole option and pretend they're just a helper.
      const isHelper = r.choices.indexOf(OptionIds.UserType.HELPER) > -1;
      const choices = r.choices.reduce((result, c) => {
        const parsed = parseOptionId(c);
        if (
          (!parsed.sub || !isHelper) &&
          result.indexOf(parsed.optionId) === -1
        ) {
          result.push(parsed.optionId);
        }
        return result;
      }, new Array<string>());
      return {
        ...r,
        choices
      };
    }
    return r;
  }) as ResponsesForVisibility;
}
