import { AppActions } from '../app.actions';
import { ISurveyPageState } from './survey-page.models';
import { SurveyPageActionTypes } from './survey-page.actions';
import * as timm from 'timm';

const defaultState: ISurveyPageState = {
  flowState: 'initial',
  // flowState: 'yes-previous',
  loginContinue: false
};

export function surveyPageReducer(
  state: ISurveyPageState = defaultState,
  action: AppActions
) {
  switch (action.type) {
    case SurveyPageActionTypes.CHOOSE_START:
      return timm.merge(state, {
        flowState: action.payload,
        loginContinue: false
      });
    case SurveyPageActionTypes.CONTINUE_PAST_LOGIN:
      return timm.set(state, 'loginContinue', true);
  }
  return state;
}
