<div class="org">

  <div class="header">
    <div class="logo">
      <img *ngIf="org.url"
           [src]="'//clearbit-logo-core-ng.startupmuster.com/' + logoHost + '?size=100'">
    </div>
    <div class="title">
      <strong>
        {{ org.name }}
      </strong>
      <br>
      <span class="helper-type"
            *ngIf="org.helperType">{{ org.helperType }}</span>
    </div>
    <div class="actions">
      <a *ngIf="!viewOnly && !org.isConnected"
         [routerLink]="['connect-with', org.orgId]">Connect with</a>
      <span *ngIf="!viewOnly && org.isConnected"
            class="connected">
        Connected
      </span>
      <!-- <div *ngIf="!org.isConnected && (org.rank === 0 || !!org.rank)"
           class="progress-bar-indication">
        <span class="meter"
              [ngStyle]="{ 'width.%': org.rank * 100.0 }">
        </span>
      </div> -->
    </div>
  </div>

  <div class="body"
       [appShow]="expanded">
    <div *ngIf="org.url">
      <a href="{{ cleanUrl(org.url) }}"
         target="_blank">{{ org.url }}</a>
    </div>
    <div *ngIf="org.description"
         class="description">{{ org.description }}</div>
    <!-- <a *ngIf="!viewOnly && !org.isConnected"
       [routerLink]="['connect-with', org.orgId]">See more</a> -->
  </div>

</div>
