<div class="pageheader"><h1>Survey 2018</h1></div>

<div class="pagecontent">
  <ng-container *ngIf="loginState !== 'success'">
    <p>
      <strong
        >Please enter the email address you used to complete the previous
        survey:</strong
      >
    </p>
    <form>
      <input type="email" [(ngModel)]="emailValue" name="email" />
      <button (click)="login()">Continue</button>
    </form>

    <p *ngIf="loginState === 'not-found'">
      No survey response found. Please note this only works for surveys completed in 2023 or later. Please check the address entered, or start a new survey below.
    </p>

    <h3>or</h3>

    <p><button [routerLink]="'/survey'">Back to the survey</button></p>
  </ng-container>

  <ng-container *ngIf="loginState === 'success'">
    <p>
      Great, we've located your survey response from a previous entry, and
      <strong>emailed you a link to login with</strong> that will allow you to
      view and bring across your responses from the last survey easily.
    </p>
  </ng-container>
</div>
