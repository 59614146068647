import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.models';
import { Observable } from 'rxjs';
// import { CriteriaDefineSelectorsService } from '../criteria-define/criteria-define-selectors.service';

@Component({
  selector: 'app-criteria-set-detail',
  templateUrl: './criteria-set-detail.component.html',
  styleUrls: ['./criteria-set-detail.component.sass']
})
export class CriteriaSetDetailComponent implements OnInit {
  @Input() id: string;

  state$: Observable<{ criteria: any }>;

  constructor(
    private store: Store<AppState> // private selectors: CriteriaDefineSelectorsService
  ) {
    // this.state$ = store
    //   .select(s => s.data.combinedCriteriaSets.byId)
    //   .combineLatest(combinedSets => {
    //     const set = combinedSets[this.id];
    //     return {
    //       set: set.set,
    //       criteria: set.criteria
    //     };
    //   });
  }

  ngOnInit() {
    // this.state$ = this.selectors.criteriaDefineState$(this.id);
  }
}
