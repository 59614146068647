import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

export interface IGridSelectOption {
  id: string;
  text: string;
  icon?: string;
}

export interface IGridSelectSelections {
  [value: string]: boolean;
}

@Component({
  selector: 'app-grid-select',
  templateUrl: './grid-select.component.html',
  styleUrls: ['./grid-select.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridSelectComponent implements OnInit {
  @Input()
  options: IGridSelectOption[];
  @Input()
  selections?: IGridSelectSelections;
  @Input()
  style: 'grid' | 'alt-grid' | 'column' | 'small' = 'grid';

  @Output()
  select = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  onSelect(id: string) {
    this.select.emit(id);
  }

  isSelected(id: string) {
    return this.selections && this.selections[id];
  }

  trackByOptionId(index: number, item: IGridSelectOption) {
    return item.id;
  }
}
