
import {combineLatest} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IConnectionInfo } from '../../../common/opportunities.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.models';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LeaveFeedbackCallApiArgs } from '../../opportunities/opportunities.actions';
import { CallApiService } from '../../shared/call-api';
import { StillEngagingCallApiArgs } from '../opportunities.actions';

@Component({
  selector: 'app-leave-feedback',
  templateUrl: './leave-feedback.component.html',
  styleUrls: ['./leave-feedback.component.sass']
})
export class LeaveFeedbackComponent implements OnInit, OnDestroy {
  loaded = false;
  connection: IConnectionInfo;
  orgId: string;
  reason: string;
  preselect?: string;
  isValid = {
    choice: true,
    reason: true
  };

  readonly options = [{ id: 'yes', text: 'Yes' }, { id: 'no', text: 'No' }];

  selectedOption: string;

  get selections() {
    const result: any = {};
    if (this.selectedOption) {
      result[this.selectedOption] = true;
    } else if (this.preselect) {
      result[this.preselect] = true;
    }
    return result;
  }

  private subscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private callApi: CallApiService
  ) {}

  ngOnInit() {
    this.subscription = this.store
      .select(s => s.opportunities.connectionsById).pipe(
      combineLatest(this.route.params, (connections, params) => {
        this.orgId = params['id'];
        this.preselect = params['preselect'];
        if (this.preselect === 'still-engaging') {
          this.callApi
            .callApi(new StillEngagingCallApiArgs(this.orgId))
            .subscribe(this.store);
          this.router.navigateByUrl('/recommended-support');
          return;
        }
        this.connection = connections[this.orgId];
        if (this.connection) {
          this.loaded = true;
        }
      }))
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  dismiss() {
    this.router.navigateByUrl('/recommended-support');
  }

  leaveFeedback() {
    const choice = this.selectedOption || this.preselect!;
    this.isValid.choice = !!choice && choice.trim().length > 0;
    this.isValid.reason = !!this.reason && this.reason.trim().length > 0;
    if (!this.isValid.choice || !this.isValid.reason) {
      return;
    }
    const data = {
      orgId: this.orgId,
      choice,
      reason: this.reason
    };
    // TODO: refactor
    this.callApi
      .callApi(new LeaveFeedbackCallApiArgs(this.orgId, data))
      .subscribe(this.store);
    this.router.navigateByUrl('/recommended-support');
  }

  onSelectOption(option: string) {
    this.selectedOption = option;
  }
}
