import { from as observableFrom, Observable } from 'rxjs';

import { mergeAll, map, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState } from '../../app.models';
import {
  CriteriaActionTypes,
  CriteriaCriterionAdd,
  CriteriaCriterionFocus,
  CriteriaCriterionRemove,
  CriteriaCriterionSave,
  CriteriaCriterionSetValue,
  CriteriaDoneAdding,
  CriteriaRemove,
  CriteriaSave,
  CriteriaSetEditingCriterion,
  CriteriaSetEnabled,
  CriteriaSetName,
  CriteriaSetState
} from '../criteria.actions';
import { DataService } from '../../data/data.service';
import { EffectLoggerService } from '../../effect-logger.service';
import {
  ICriteriaSet,
  ICriterion
} from '../../../common/criteria/criteria.models';
import { IEditingCriterion } from '../criteria.models';
import { Injectable } from '@angular/core';
import {
  OperatorsByCriteriaMatch,
  OperatorsByQuestionType
} from '../../../common/criteria/criteria-operators-by-question-type.models';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as timm from 'timm';

@Injectable()
export class CriteriaSetEffects {
  private criteriaSetsDirty$ = this.store.select(
    s => s.data.combinedCriteriaSets.dirty
  );
  // private criteriaDirty$ = this.store.select(s => s.data.criteria.dirty);

  @Effect()
  remove$() {
    return this.actions$.pipe(
      ofType(CriteriaActionTypes.REMOVE),
      map((action: CriteriaRemove) => {
        return observableFrom(
          this.data.setAndSave('combinedCriteriaSets', action.setId, null)
        );
      }),
      mergeAll()
    );
  }

  // @Effect()
  // criteriaDoneAdding$() {
  //   return this.actions$
  //     .ofType(CriteriaActionTypes.DONE_ADDING)
  //     .map(
  //       (action: CriteriaDoneAdding) =>
  //         new CriteriaSetState(action.setId, 'completing')
  //     );
  // }

  // @Effect()
  // criteriaSaveAndComplete$() {
  //   return this.actions$
  //     .ofType(CriteriaActionTypes.SAVE)
  //     .map((action: CriteriaSave) => {
  //       this.router.navigateByUrl('/connect-with-startups');
  //       return Observable.of(new CriteriaSetEnabled(action.setId, true));
  //     })
  //     .mergeAll();
  // }

  @Effect()
  criteriaSetEnabled$() {
    return this.actions$.pipe(
      ofType(CriteriaActionTypes.SET_ENABLED),
      withLatestFrom(
        this.store.select(s => s.data.combinedCriteriaSets.byId),
        (action: CriteriaSetEnabled, setsById) => {
          const cSet = setsById[action.setId];
          return observableFrom([
            this.data.set(
              'combinedCriteriaSets',
              action.setId,
              timm.setIn(cSet, ['set', 'enabled'], action.enabledState)
            ),
            this.data.save('combinedCriteriaSets', action.setId)
          ]);
        }
      ),
      mergeAll()
    );
  }

  // @Effect()
  // criteriaSetName$() {
  //   return this.actions$
  //     .ofType(CriteriaActionTypes.SET_NAME)
  //     .withLatestFrom(
  //       this.store.select(s => s.data.criteriaSets.byId),
  //       (action: CriteriaSetName, setsById) => {
  //         const set = setsById[action.setId];
  //         return this.data.set('criteriaSets', action.setId, {
  //           ...set,
  //           label: action.name
  //         });
  //       }
  //     );
  // }

  // @Effect()
  // persist$() {
  //   return this.actions$
  //     .ofType(CriteriaActionTypes.SAVE, CriteriaActionTypes.CRITERION_SAVE)
  //     .let(
  //       this.effectLogger.handleErrors(source$ =>
  //         source$
  //           .withLatestFrom(
  //             this.criteriaDirty$,
  //             this.criteriaSetsDirty$,
  //             (
  //               action: CriteriaSave | CriteriaCriterionSave,
  //               criteriaDirty,
  //               criteriaSetsDirty
  //             ) => {
  //               const setActions = this.getToPersist(criteriaSetsDirty).map(
  //                 id => this.data.save('criteriaSets', id)
  //               );
  //               const criteriaActions = this.getToPersist(criteriaDirty).map(
  //                 id => this.data.save('criteria', id)
  //               );
  //               return Observable.from(setActions.concat(criteriaActions));
  //             }
  //           )
  //           .mergeAll()
  //       )
  //     );
  // }

  private getToPersist(dirty: _.Dictionary<boolean>) {
    const toPersist = [];
    for (const dirtyKey in dirty) {
      if (dirty[dirtyKey]) {
        toPersist.push(dirtyKey);
      }
    }
    return toPersist;
  }

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private effectLogger: EffectLoggerService,
    private data: DataService,
    private router: Router
  ) {}
}
