
import {combineLatest} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, LoginLinkState } from '../../app.models';
import { LoginLinkRequested } from '../../global.actions';
import { ContinuePastLogin } from '../../your-organisation/survey-page.actions';

@Component({
  selector: 'app-login-inline',
  templateUrl: './login-inline.component.html',
  styleUrls: ['./login-inline.component.sass']
})
export class LoginInlineComponent implements OnInit, OnDestroy {
  private stateSubscription: Subscription;

  loginState = 'initial' as 'initial' | 'success' | 'not-found'  | 'loading';
  emailValue?: string;
  loginLinkState: LoginLinkState;
  continueState = false;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.loginState = 'initial';
    this.stateSubscription = this.store
      .select(s => s.global.loginLink).pipe(
      combineLatest(this.store.select(s => s.surveyPage.loginContinue)))
      .subscribe(([loginLink, continueState]) => {
        this.continueState = continueState;
        this.loginLinkState = loginLink;
        //this.loginState = 'initial';
        if (loginLink.receivedResponse) {
          if (loginLink.success) {
            this.loginState = 'success';
          } else {
            this.loginState = 'not-found';
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  login() {
    if (this.emailValue) {

      this.loginState = 'loading';
      this.store.dispatch(
        new LoginLinkRequested({
          email: this.emailValue,
          redirectOnSuccess: false
        })
      );
    }
  }

  onClickContinue() {
    this.store.dispatch(new ContinuePastLogin());
  }
}
