
<div *ngIf="doShowSurvey; then block1 else block2"></div>

<ng-template #block1>
  <!-- Content for block1 -->
<!--


<div style="display: flex;border-bottom: solid 3px rgba(60, 72, 94, 0.2);justify-content: space-between;">
  <div style="margin: 10px;font-size: 30px;">
    <h2 class="head" style="padding-bottom: 0px; margin-bottom: 0px;border-bottom: 0px">&nbsp;</h2></div>
  <div style="font-size: 30px;position: relative;">
    <button class="action primary read-more" style="position: absolute;right: 0" [routerLink]="'/about'">
      Find out more about Startup Muster...
    </button>
  </div>
</div>
<br/>
<br/>
--->


<div>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">The Startup Muster team has never been more grateful for the time you spend here.</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">If you are running a startup in Australia, or supporting startups in Australia</span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"> (educators, investors, accelerators, incubators, coworking spaces, service providers, government, precincts and any other organisations supporting startups),&nbsp;</span><span style="font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"><strong>please contribute your data</strong></span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"> below to the largest survey of Australia's startup ecosystem.</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"><strong>Why? Because:</strong></span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">1.&nbsp;</span><span style="font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"><strong>It will show the great things in Australia's startup ecosystem</strong></span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">, the areas that need improvement, and your opinions on critical policy issues. Our national report will then be freely published in late November 2024 (giving us some more review time this year), and your responses shared directly with relevant parts of Government (if you give permission for that).</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">2.&nbsp;</span><span style="font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"><strong>We've combined questions this year from 30 different Australian startup support organisations and government departments</strong></span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">, avoiding 30 new surveys. These are questions from the ecosystem, for the ecosystem, and we've also worked to trim 27 questions from our 2023 survey that didn't yield data that was able to be used.</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">3.&nbsp;</span><span style="font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"><strong>You'll contribute to a long legacy of Australian ecosystem data</strong></span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">. Many of these questions date back to our first survey in 2013, with a similar collection approach, providing an unprecedented picture of how this sector has evolved.</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">3.&nbsp;</span><span style="font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"><strong>You'll see comparison charts</strong></span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"> that show how your answers compare to other survey participants so far this year.</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">4.&nbsp;</span><span style="font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"><strong>You'll receive special offers and discounts</strong></span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"> on completion from our partners.</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">5.&nbsp;</span><span style="font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"><strong>There's never been a more challenging year for Startup Muster</strong></span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">, and&nbsp;</span><span style="-webkit-text-decoration-skip:none;font-style:normal;font-variant:normal;font-weight:400;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre-wrap;"><u>we need your help</u></span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"> to make sure this work can continue.</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">This survey will take a while, but you can stop any time and come back to resume later. We're confident you can complete the survey in much less time than a typical policy consultation session.</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"><strong>Please join us in thanking our 2024 supporters</strong></span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">: CommBank, Vanta, Stone &amp; Chalk, CSIRO, ACS Labs, UNSW, QUT, City of Sydney, City of Parramatta, Baxter IP, Gild Group, Amplitude and Relevance AI.</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;" dir="ltr">
    <span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"><strong>Want to help promote?</strong></span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"> Please find promotional assets&nbsp;</span></span><a style="text-decoration:none;" target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/1duc2ydfeFbBLveiG4S47EY-xFELpEFte"><span style="background-color:transparent;color:#406ab5;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">here</span></span></a><span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">. Promotion to under-represented startup communities is especially appreciated. Contact us&nbsp;</span></span><span style="background-color:transparent;color:#406ab5;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"><a style="text-decoration:none;" target="_blank" rel="noopener noreferrer" href="javascript:void(0)" (click)="onClickContact($event)">here</a></span></span><span style="background-color:transparent;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;"> if you need assets that aren’t in the folder.</span></span>
  </p>
  <p style="line-height:1.68;margin-bottom:15pt;margin-top:0pt;" dir="ltr">
    <span style="background-color:#fdfdfd;color:#44546a;font-family:Roboto,sans-serif;font-size:13.5pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">Thank you for supporting Startup Muster’s mission to measure and publish the progress, challenges, and opportunities within the Australian startup ecosystem, in order to demonstrate and accelerate progress.</span></span>
  </p>
  <p>
    &nbsp;
  </p>
  <br/>
</div>
<div *ngIf="showNewSurvey" class="new-survey">
  <button class="action" (click)="newSurvey()">
    Save and start new survey submission
  </button>
</div>

<ng-container *ngIf="showPrevious">
  <p>
    <strong>Did you complete (or start) a Startup Muster survey previously? This year or in a past year.</strong
    >
  </p>

  <p class="start-actions">
    <button
      class="previous"
      [ngClass]="{ selected: state.page.flowState === 'yes-previous' }"
      (click)="onClickPreviousYes()"
    >
      Yes
    </button>

    <button
      class="previous"
      [ngClass]="{ selected: state.page.flowState === 'no-previous' }"
      (click)="onClickPreviousNo()"
    >
      No
    </button>
  </p>

  <ng-container *ngIf="state.page.flowState === 'yes-previous'">
    <p>
      <strong
        >You can complete the survey faster and easier by simply updating your
        previous responses.</strong
      >
    </p>

    <app-login-inline></app-login-inline>

    <!--
      <button class="action previous"
              [routerLink]="'/login/update'">

      </button>
    -->
  </ng-container>
</ng-container>

<!--
  <div class="progress">
    <div class="progress-label">Progress:</div>

    <app-progress [percentage]="state.progressPercentage"></app-progress>
  </div>
-->

<div
  class="section"
  [appShow]="showSurvey"
  [ngClass]="{ alt: isAlt(section) }"
  *ngFor="let section of state.sections; trackBy: trackById"
>
  <h2>{{ section.title }}</h2>

  <div
    class="question component"
    *ngFor="let qr of section.questionResponses; trackBy: trackByQuestionId"
  >
    <app-question-status [status]="qr.status"></app-question-status>

    <h3 [appRewriteLinks]="qr.question.text"></h3>

    <app-question [state]="qr"> </app-question>

    <app-compare-button
      *ngIf="qr.question.showResultsAfterLockout"
      [appShow]="!hideCompare(qr)"
      [state]="qr"
      (compare)="onCompareResults(qr)"
    ></app-compare-button>

    <app-question-results
      *ngIf="showResults(qr)"
      [results]="qr.results"
    ></app-question-results>

    <div class="why" *ngIf="qr.question.whyText" [appShow]="showWhy(qr)" [innerHTML]="qr.question.whyText"></div>

    <div
      *ngIf="qr.question.key === 'QUserTypeReturningEmail'"
      [appShow]="state.isReturning"
    >
      <p class="error" [appShow]="state.loginLinkRequestFailed">
        No survey response found. Please note this only works for surveys completed in 2023 or later. Please check the address entered, or start a new survey below.
      </p>

      <button (click)="requestLogin()">Request Login Link</button>
    </div>

    <div *ngIf="qr.question.key === 'QFinal'" class="final">
      <div *ngIf="userType.isFuture || userType.isStartup">
        <!--
          <p>Thank you for taking part in the Startup Muster 2017 Survey!</p>
        -->
        <p>
          Your contribution means that Australia will have representative and
          useful statistics about the Australian startup community.
        </p>
      </div>
      <!--
                <p>Thank you for taking part in the Startup Muster 2017 Survey.</p>

      <div class="helper" *ngIf="userType.isHelper">

        <p>
          We also now provide an option for your support to be shown to startups
          using targeting criteria.
        </p>
        <button class="action" (click)="completeSurvey()">
          Enter my targeting criteria so I can be seen by startups
        </button>
      </div>
      -->
      <!--
      <div class="startup" *ngIf="userType.isStartup && !userType.isHelper">
        <button class="action" (click)="completeSurvey()">
          Connect with helpers
        </button>
      </div>
      -->
      <div>
        <p>To fill out another survey, please select the following option:</p>

        <button class="action" (click)="newSurvey()">
          Fill out another survey
        </button>
      </div>
    </div>
  </div>
</div>
</ng-template>

<ng-template #block2>
  <app-survey-offline></app-survey-offline>

</ng-template>
