import {
  throwError as observableThrowError,
  of as observableOf,
  Observable
} from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { AppLogger } from '../app-logger';
import { AppState } from '../app.models';

export namespace CallApiActionTypes {
  export const CALL_API: '[Shared] Call API' = '[Shared] Call API';
  export const CALL_API_LOADED: '[Shared] Call API Loaded' =
    '[Shared] Call API Loaded';
  export const CALL_API_FAILED: '[Shared] Call API Failed' =
    '[Shared] Call API Failed';
}

@Injectable()
export class CallApiService {
  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private logger: AppLogger
  ) {}

  callApi(args: ICallApiArgs): Observable<CallApiLoaded | CallApiFailed> {
    const method = args.method ? args.method : 'GET';
    const extraSep = args.url.startsWith('/') ? '' : '/';
    // const headers = new HttpHeaders({ 'content-type': 'application/json' });

    // body: JSON.stringify(args.data),
    // const request = new HttpRequest(
    //   method,
    //   `${extraSep}${args.url}`,
    //   args.data
    // );

    const payload = { key: args.key, data: args.data, dataKey: args.dataKey };

    return this.http
      .request(method, `${extraSep}${args.url}`, {
        body: args.data
      })
      .pipe(
        map(response => {
          if (args.mapResponse) {
            response = args.mapResponse(response);
          }
          return new CallApiLoaded({ ...payload, response });
        }),
        catchError(err => {
          if (typeof Response !== 'undefined' && err instanceof Response) {
            this.logger.warn(err);
            return observableOf(new CallApiFailed(payload));
          }
          this.logger.error(err);
          return observableThrowError(err);
        })
      );
  }
}

export interface ICallApiPayload {
  key: string;
  data?: any;
  dataKey?: any;
}

export enum CallApiMethods {
  Delete = 'DELETE',
  Get = 'GET',
  Head = 'HEAD',
  Jsonp = 'JSONP',
  Options = 'OPTIONS',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH'
}

export interface ICallApiArgs extends ICallApiPayload {
  key: string;
  url: string;
  method?: CallApiMethods;
  data?: any;
  mapResponse?(response: any): any;
}

export interface ICallApiResponse extends ICallApiPayload {
  response: any;
}

export class CallApiLoaded implements Action {
  type = CallApiActionTypes.CALL_API_LOADED;
  constructor(public payload: ICallApiResponse) {}
}

export class CallApiFailed implements Action {
  type = CallApiActionTypes.CALL_API_FAILED;
  constructor(public payload: ICallApiPayload) {}
}

export type CallApiActions = CallApiLoaded | CallApiFailed;
