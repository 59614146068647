import { withLatestFrom, map, retryWhen, tap, mergeMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, timer, throwError } from 'rxjs';
import * as uuid from 'uuid';
import { IAuthLinkValidationResponse } from '../../common/auth/auth-links.models';
import { IInitialState } from '../../common/initial-state.models';
import { AppLogger } from '../app-logger';
import { AppClear } from '../app.actions';
import { AppState } from '../app.models';
import { DataLoaded } from '../data/data.actions';
import { IDataState } from '../data/data.models';
import {
  GlobalLoadRequested,
  GlobalSetLoginEmail,
  GlobalSetShowPlatform,
  GlobalSetHasPreviousResponses
} from '../global.actions';
import { IntercomService } from '../shared/intercom.service';
import { OrgSelectorsService } from '../users/org-selectors.service';
import { TokenService } from '../token.service';
import { TokenNotExpiredService } from '../token-not-expired.service';
import { GaService } from 'app/shared/ga.service';

@Injectable()
export class UserService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    private tokenNotExpiredService: TokenNotExpiredService,
    private store: Store<AppState>,
    private orgSelectors: OrgSelectorsService,
    private appLogger: AppLogger,
    private intercom: IntercomService,
    private ga: GaService
  ) {
    const token = this.tokenService.token;
    if (token) {
      this.appLogger.setSession(token);
    }
  }

  isLoggedIn(): boolean {
    return this.tokenNotExpiredService.notExpired();
  }

  completeSurvey() {
    return this.http.put('/questions/survey-complete', {}).pipe(
      withLatestFrom(this.orgSelectors.userType$, (_response, userType) => {
        this.store.dispatch(new GlobalSetShowPlatform(true));
        if (userType.isHelper) {
          this.router.navigate(['/connect-with-startups']);
        } else {
          this.router.navigate(['/recommended-support']);
        }
      })
    );
  }

  auth(linkToken: string): Observable<boolean> {
    return this.http
      .post<IAuthLinkValidationResponse>('/auth/login', { linkToken })
      .pipe(
        map(response => {
          if (response.email) {
            this.store.dispatch(new GlobalSetLoginEmail(response.email));
            this.ga.setSurveyResumption(response.email);
          }
          switch (response.status) {
            case 'ok':
              const state: IInitialState = response.initialState!;
              this.handleInitialState(state);
              return true;
            // case 'expired':
            // case 'used':
          }
          return false;
        })
      );
  }

  loadInitialState(lightweight: boolean): Observable<boolean> {
    const reqId = uuid.v4();
    const headers = new HttpHeaders({ 'content-type': 'application/json' });
    const urlParams = new URLSearchParams(window.location.search);
    const partnerID = urlParams.get('from');
    let body: any = { reqId, lightweight };
    if (partnerID) {
      body = { reqId, lightweight, partnerID };
    }
    return this.http.post<IInitialState>('/init', body, { headers }).pipe(
      retryWhen(errors => {
        return errors.pipe(
          mergeMap((error, i) => {
            if (i > 3) {
              return throwError(error);
            }
            i++;
            return timer(1000);
          })
        );
      }),
      map(state => {
        if (state.reqId !== reqId) {
          this.appLogger.error(`reqId doesn't match the one sent in request.`);
          return false;
        } else {
          this.handleInitialState(state);
        }
        return true;
      })
    );
  }

  logOut() {
    this.tokenService.clear();
    this.store.dispatch(new AppClear());
    this.router.navigate(['/survey']);
    this.store.dispatch(new GlobalLoadRequested());
  }

  private handleInitialState(state: IInitialState) {
    this.tokenService.store(state.authToken);
    this.store.dispatch(new GlobalSetShowPlatform(!!state.showPlatform));
    this.store.dispatch(
      new GlobalSetHasPreviousResponses(
        !!state.data.oldResponses && state.data.oldResponses.length > 0
      )
    );
    if (state.email) {
      this.store.dispatch(new GlobalSetLoginEmail(state.email));
    }

    this.appLogger.setSession(state.authToken);
    this.appLogger.setPerson(state.userId, state.email);
    this.intercom.update(state.userId, state.email);

    const {
      sections,
      questions,
      responses,
      oldResponses,
      questionResults,
      lockedQuestionIds,
      responseUpdates
    } = state.data;
    this.loadIf('sections', sections, s => s.id);
    this.loadIf('questions', questions, q => q.id);
    this.loadIf('responses', responses, r => r.questionId);
    this.loadIf('oldResponses', oldResponses, r => r.questionKey);
    this.loadIf('questionResults', questionResults, r => r.questionId);
    this.loadIf('responseUpdates', responseUpdates, r => r.questionId);
    if (lockedQuestionIds) {
      this.store.dispatch(
        new DataLoaded({
          collection: 'lockedQuestionIds',
          data: lockedQuestionIds.map(id => ({ _id: id }))
        })
      );
    }
  }

  private loadIf<T>(
    collection: keyof IDataState,
    data: T[] | undefined,
    idMap: (item: T) => string
  ) {
    if (data && data.map) {
      this.store.dispatch(
        new DataLoaded({
          collection,
          data: data.map(item => ({ ...(item as Object), _id: idMap(item) }))
        })
      );
    }
  }
}
