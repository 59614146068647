<ng-template [ngIf]="loaded">
  <div class="pageheader">
    <h1><span>Leave feedback for &hellip;</span></h1>
  </div>


  <div class="pagecontent">
    <p><em>{{ connection.description }}</em></p>

    <p><strong>Would you recommend {{ connection.name }} to organisations similar to yours?</strong></p>

    <div class="spacing">
      <app-grid-select [options]="options"
                       [selections]="selections"
                       id="feedback-feeling"
                       class="control"
                       (select)="onSelectOption($event)"></app-grid-select>
    </div>

    <p class="validation"
       [appShow]="!isValid.choice">
      Please select either Yes or No.
    </p>

    <p><strong>What is your reason for this recommendation?</strong></p>

    <textarea name="reason"
              id="reason"
              cols="10"
              rows="3"
              [(ngModel)]="reason"></textarea>

    <p class="validation"
       [appShow]="!isValid.reason">
      Please enter in a reason for your recommendation.
    </p>

    <div class="actions">
      <button (click)="dismiss()"
              class="action">Dismiss</button>
      <button (click)="leaveFeedback()"
              class="action primary">Leave Feedback</button>
    </div>
  </div>

</ng-template>
