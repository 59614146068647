<div class="pageheader">
  <h1>Dashboard</h1>
</div>

<div class="copy">
  <p>Your dashboard lets you update your data, connect with the community and browse through our reports.</p>
</div>

<div class="icons">

  <a routerLink="/your-organisation"
     class="profile">
    <svg xmlns="http://www.w3.org/2000/svg"
         width="73"
         height="47"
         viewBox="0 0 73 47">
      <g fill-rule="evenodd">
        <path d="M17.351 18.686a9.343 9.343 0 1 1 0-18.686 9.343 9.343 0 0 1 0 18.686zM35.236 39.37c0 9.73-35.236 9.73-35.236 0s7.888-17.618 17.618-17.618c9.73 0 17.618 7.888 17.618 17.618z"
        />
        <rect width="32"
              height="5"
              x="41"
              y="6"
              rx="2.5" />
        <rect width="32"
              height="5"
              x="41"
              y="17"
              rx="2.5" />
        <rect width="32"
              height="5"
              x="41"
              y="28"
              rx="2.5" />
      </g>
    </svg>

    <div class="title">Update Your Organisation</div>

    <div class="subtext">Ensure the quality of your connections and data by keeping us up to date.</div>
  </a>

  <a routerLink="/recommended-support"
     *ngIf="userType.isStartup">
    <svg xmlns="http://www.w3.org/2000/svg"
         width="48"
         height="49"
         viewBox="0 0 48 49">
      <g fill-rule="evenodd"
         transform="translate(0 1)">
        <path d="M23 0h2v47h-2z" />
        <path d="M40.234 6l1.414 1.414L8.414 40.648 7 39.234z" />
        <path d="M6 7.414L7.414 6l33.234 33.234-1.414 1.414z" />
        <path d="M47 23v2H0v-2z" />
        <circle cx="24"
                cy="24"
                r="6"
                stroke="#5080C2"
                stroke-width="2" />
        <circle cx="24"
                cy="3"
                r="3"
                stroke="#5080C2"
                stroke-width="2" />
        <circle cx="24"
                cy="44"
                r="3"
                stroke="#5080C2"
                stroke-width="2" />
        <circle cx="44"
                cy="24"
                r="3"
                stroke="#5080C2"
                stroke-width="2" />
        <circle cx="38"
                cy="9"
                r="3"
                stroke="#5080C2"
                stroke-width="2" />
        <circle cx="10"
                cy="38"
                r="3"
                stroke="#5080C2"
                stroke-width="2" />
        <circle cx="4"
                cy="24"
                r="3"
                stroke="#5080C2"
                stroke-width="2" />
        <circle cx="38"
                cy="38"
                r="3"
                stroke="#5080C2"
                stroke-width="2" />
        <circle cx="10"
                cy="9"
                r="3"
                stroke="#5080C2"
                stroke-width="2" />
      </g>
    </svg>

    <div class="title">View Recommended Connections</div>

    <div class="subtext">Discover recommended connections based on your needs and priorities.</div>
  </a>

  <a routerLink="/connect-with-startups"
     *ngIf="userType.isHelper">
    <svg xmlns="http://www.w3.org/2000/svg"
         width="31"
         height="47"
         viewBox="0 0 31 47">
      <g fill-rule="evenodd">
        <path d="M15.075 46.21c8.326 0 15.076-6.55 15.076-14.631 0-2.787-6.75-6.651-15.076-6.651C6.75 24.928 0 23.498 0 31.578c0 8.082 6.75 14.633 15.075 14.633z"
        />
        <path d="M16.85 20.396h-4.435v.887a.887.887 0 0 1-1.773 0v-.887H6.208v.887a.887.887 0 0 1-1.774 0v-.887h-2.66v7.095h17.735v-7.095h-.886v.887a.887.887 0 0 1-1.774 0v-.887z"
        />
        <rect width="4.39"
              height="23.057"
              y="10.642"
              rx="2.195" />
        <rect width="4.39"
              height="23.057"
              x="6.252"
              y="3.547"
              rx="2.195" />
        <rect width="4.39"
              height="26.604"
              x="12.415"
              rx="2.195" />
        <rect width="4.39"
              height="28.377"
              x="18.623"
              y="1.774"
              rx="2.195" />
        <rect width="5.286"
              height="18.623"
              x="24.864"
              y="15.962"
              rx="2.643" />
      </g>
    </svg>

    <div class="title">Be Found By Startups</div>

    <div class="subtext">Create and save custom opportunity profiles to be discovered by startups and supporters.</div>
  </a>

  <a routerLink="/reports">
    <svg xmlns="http://www.w3.org/2000/svg"
         width="49"
         height="48"
         viewBox="0 0 49 48">
      <g fill-rule="evenodd"
         transform="translate(1 1)">
        <g stroke="#5080C2"
           stroke-linecap="round"
           stroke-linejoin="round"
           stroke-width="2">
          <path fill="none"
                d="M1.989 42.933l6.524-15.366M8.513 27.567l8.156 11.298M16.669 38.865l8.88-18.528M25.549 20.337l8.88 9.49M34.43 29.827l9.604-26.664"
          />
        </g>
        <circle cx="1.808"
                cy="43.385"
                r="1.808"
                fill="#FFF"
                stroke="#5080C2"
                stroke-width="2" />
        <circle cx="9.038"
                cy="28.923"
                r="1.808"
                fill="#FFF"
                stroke="#5080C2"
                stroke-width="2" />
        <circle cx="25.308"
                cy="20.788"
                r="1.808"
                fill="#FFF"
                stroke="#5080C2"
                stroke-width="2" />
        <circle cx="44.288"
                cy="1.808"
                r="1.808"
                fill="#FFF"
                stroke="#5080C2"
                stroke-width="2" />
        <path d="M7.231 35.25h3.615V47H7.231zM13.106 41.577h3.615V47h-3.615zM18.981 38.865h3.615V47h-3.615zM24.856 28.923h3.615V47h-3.615zM30.731 32.538h3.615V47h-3.615zM36.606 37.058h3.615V47h-3.615zM42.481 22.596h3.615V47h-3.615z"
        />
      </g>
    </svg>

    <div class="title">Access Reports</div>

    <div class="subtext">View current and past Startup Muster reports and data.</div>
  </a>

</div>
