import { Action } from '@ngrx/store';

export namespace GlobalActionTypes {
  export const LOAD_REQUESTED: '[Global] Load Requested' =
    '[Global] Load Requested';
  export const LOADING: '[Global] Loading' = '[Global] Loading';
  export const LOADED: '[Global] Loaded' = '[Global] Loaded';
  export const NOT_LOADED: '[Global] Not Loaded' = '[Global] Not Loaded';
  export const LOGIN_LINK_REQUESTED: '[Global] Login Link Requested' =
    '[Global] Login Link Requested';
  export const LOGIN_LINK_RESULT: '[Global] Login Link Success' =
    '[Global] Login Link Success';
  export const LOGIN_LINK_RESET: '[Global] Login Link Reset' =
    '[Global] Login Link Reset';
  export const SET_LOGIN_EMAIL: '[Global] Set Login Email' =
    '[Global] Set Login Email';
  export const SET_SHOW_PLATFORM: '[Global] Set Show Platform' =
    '[Global] Set Show Platform';
  export const SET_HAS_PREVIOUS_RESPONSES: '[Global] Set Has Previous Responses' =
    '[Global] Set Has Previous Responses';
}

export type GlobalActions =
  | GlobalLoadRequested
  | GlobalLoading
  | GlobalLoaded
  | GlobalNotLoaded
  | GlobalSetShowPlatform
  | GlobalSetHasPreviousResponses
  | LoginLinkRequested
  | LoginLinkResult
  | GlobalSetLoginEmail
  | LoginLinkReset;

export class GlobalLoadRequested implements Action {
  type = GlobalActionTypes.LOAD_REQUESTED;
  constructor(public lightweightLoad: boolean = false) {}
}

export class GlobalLoaded implements Action {
  type = GlobalActionTypes.LOADED;
  constructor(public lightweight: boolean) {}
}

export class GlobalLoading implements Action {
  type = GlobalActionTypes.LOADING;
}

export class GlobalNotLoaded implements Action {
  type = GlobalActionTypes.NOT_LOADED;
}

export class GlobalSetShowPlatform implements Action {
  type = GlobalActionTypes.SET_SHOW_PLATFORM;
  constructor(public payload: boolean) {}
}

export class GlobalSetHasPreviousResponses implements Action {
  type = GlobalActionTypes.SET_HAS_PREVIOUS_RESPONSES;
  constructor(public payload: boolean) {}
}

export class LoginLinkRequested implements Action {
  type = GlobalActionTypes.LOGIN_LINK_REQUESTED;
  constructor(
    public payload: {
      email: string;
      redirectOnSuccess: boolean;
    }
  ) {}
}

export class LoginLinkResult implements Action {
  type = GlobalActionTypes.LOGIN_LINK_RESULT;
  constructor(
    public payload: { success: boolean; redirectOnSuccess: boolean }
  ) {}
}

export class LoginLinkReset implements Action {
  type = GlobalActionTypes.LOGIN_LINK_RESET;
}

export class GlobalSetLoginEmail implements Action {
  type = GlobalActionTypes.SET_LOGIN_EMAIL;
  constructor(public payload: string) {}
}
