import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  OrgSelectorsService,
  IUserType
} from '../users/org-selectors.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit, OnDestroy {
  userType: IUserType;

  private userTypeSubscription: Subscription;

  constructor(orgSelectors: OrgSelectorsService) {
    orgSelectors.userType$.subscribe(t => {
      this.userType = t;
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    if (this.userTypeSubscription) {
      this.userTypeSubscription.unsubscribe();
    }
  }
}
