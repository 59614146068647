import { combineReducers } from '@ngrx/store';
import { AppReducer } from '../app.reducer';
import { QuestionActionTypes } from '../data/question.actions';
import * as timm from 'timm';
import { DataActionTypes } from '../data/data.actions';
import { AppActions } from '../app.actions';
import { QuestionId } from '../../common/questions/question.models';
import { ResponseValidationResult } from 'common/questions/response-validator.service';

export interface IQuestionsState {
  isResponseValid: _.Dictionary<ResponseValidationResult>;
  resultMatches: _.Dictionary<string[]>;
  isVisible: _.Dictionary<boolean>;
  dependencies: _.Dictionary<QuestionId[]>;
}

function clearOnLoad<T>(reducer: AppReducer<T>, clearValue: T): AppReducer<T> {
  return (state, action) => {
    if (
      action.type === DataActionTypes.DATA_LOAD_REQUESTED &&
      action.payload.collection === 'responses'
    ) {
      return clearValue;
    }
    return reducer(state, action);
  };
}

export const isResponseValidReducer: AppReducer<
  _.Dictionary<ResponseValidationResult>
> = (state = {}, action) => {
  switch (action.type) {
    case QuestionActionTypes.QUESTION_RESPONSE_VALID:
      return timm.merge(state, action.payload);
  }
  return state;
};

export const resultMatchesReducer: AppReducer<_.Dictionary<string[]>> = (
  state = {},
  action
) => {
  switch (action.type) {
    case QuestionActionTypes.QUESTION_RESPONSE_RESULT_MATCHES:
      return timm.merge(state, action.payload);
  }
  return state;
};

export const isVisibleReducer: AppReducer<_.Dictionary<boolean>> = (
  state = {},
  action
) => {
  switch (action.type) {
    case QuestionActionTypes.UPDATE_QUESTION_VISIBILITY:
      return timm.merge(state, action.payload);
  }
  return state;
};

export const dependenciesReducer: AppReducer<_.Dictionary<QuestionId[]>> = (
  state = {},
  action
) => {
  switch (action.type) {
    case QuestionActionTypes.UPDATE_QUESTION_DEPENDENCIES:
      return timm.merge(state, action.payload);
  }
  return state;
};

const innerQuestionsReducer = combineReducers({
  isResponseValid: clearOnLoad(isResponseValidReducer, {}),
  resultMatches: clearOnLoad(resultMatchesReducer, {}),
  isVisible: clearOnLoad(isVisibleReducer, {}),
  dependencies: clearOnLoad(dependenciesReducer, {})
});

export function questionsReducer(state: any, action: AppActions) {
  return innerQuestionsReducer(state, action);
}
