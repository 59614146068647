import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as uuid from 'uuid';
import { OperatorType } from '../../../common/criteria/criteria-operator-types.models';
import { QuestionId } from '../../../common/questions/question.models';
import { AppState } from '../../app.models';
import { IOpProximityModel } from '../op-proximity/op-proximity.component';
import { CriteriaDefineCrunchSelectorsService } from './criteria-define-crunch-selectors.service';
import {
  AddFilter,
  LoadCriteriaSet,
  RemoveFilter,
  Save,
  SetFilterValue,
  SetName,
  Cancel
} from './criteria-define-crunch.actions';
import { UiFilter, UiState } from './criteria-define-crunch.models';

interface QuestionSelect {
  showing: boolean;
  filterId?: string;
  questionId?: QuestionId;
}

@Component({
  selector: 'app-criteria-define-crunch',
  templateUrl: './criteria-define-crunch.component.html',
  styleUrls: ['./criteria-define-crunch.component.sass']
})
export class CriteriaDefineCrunchComponent
  implements OnInit, OnDestroy, OnChanges {
  // @ViewChildren('.filter') filters: QueryList<ElementRef>;

  @Input() setId: string | null;

  state: UiState;

  questionSelect: QuestionSelect = { showing: false };

  private stateSubscription: Subscription;

  constructor(
    private selectors: CriteriaDefineCrunchSelectorsService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.stateSubscription = this.selectors.state$.subscribe(value => {
      this.state = value;
    });
  }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.setId && this.setId) {
      this.store.dispatch(new LoadCriteriaSet({ setId: this.setId }));
    }
  }

  onClickAdd() {
    this.questionSelect = {
      showing: true
    };
  }

  onClickSave() {
    this.store.dispatch(new Save());
  }

  onClickCancel() {
    this.store.dispatch(new Cancel());
  }

  onEditQuestion(filter: UiFilter) {
    this.questionSelect = {
      filterId: filter.id,
      questionId: filter.question.id,
      showing: true
    };
  }

  onCloseEdit() {
    this.questionSelect = { showing: false };
  }

  onSelectQuestionId(questionId: QuestionId) {
    if (!this.questionSelect.filterId) {
      this.store.dispatch(
        new AddFilter({ id: uuid.v4(), questionId: questionId })
      );
    } else {
      this.store.dispatch(
        new SetFilterValue({
          id: this.questionSelect.filterId,
          field: 'questionId',
          value: questionId
        })
      );
    }
    this.questionSelect = { showing: false };
  }

  onChangeFilterOperator(filter: UiFilter, operator: OperatorType) {
    this.store.dispatch(
      new SetFilterValue({
        id: filter.id,
        field: 'operatorType',
        value: operator
      })
    );
  }

  onChangeFilterTags(filter: UiFilter, tags: any) {
    this.store.dispatch(
      new SetFilterValue({
        id: filter.id,
        field: 'operand',
        value: {
          type: 'tags',
          tags
        }
      })
    );
  }

  onChangeBetweenFrom(filter: UiFilter, from: string) {
    this.store.dispatch(
      new SetFilterValue({
        id: filter.id,
        field: 'operand',
        value: {
          type: 'between',
          from,
          to: (filter.operand.type === 'between' && filter.operand.to) || ''
        }
      })
    );
  }

  onChangeBetweenTo(filter: UiFilter, to: string) {
    this.store.dispatch(
      new SetFilterValue({
        id: filter.id,
        field: 'operand',
        value: {
          type: 'between',
          from:
            (filter.operand.type === 'between' && filter.operand.from) || '',
          to
        }
      })
    );
  }

  onChangeFilterProximity(filter: UiFilter, event: IOpProximityModel) {
    this.store.dispatch(
      new SetFilterValue({
        id: filter.id,
        field: 'operand',
        value: {
          type: 'location',
          place: event.place,
          lat: event.lat,
          lng: event.lng,
          radius: event.radius
        }
      })
    );
  }

  onChangeFilterMaxAgeMonths(filter: UiFilter, maxAgeMonths: number) {
    this.store.dispatch(
      new SetFilterValue({
        id: filter.id,
        field: 'maxAgeMonths',
        value: maxAgeMonths
      })
    );
  }

  filterTrackBy(index: number, filter: UiFilter) {
    return filter.id;
  }

  onChangeProfileName($event: Event) {
    const newName = ($event.target as HTMLInputElement).value || '';
    this.store.dispatch(
      new SetName({ setId: this.state.setId, name: newName })
    );
  }

  onRemove(filter: UiFilter) {
    this.store.dispatch(new RemoveFilter({ id: filter.id }));
    return false;
  }

  get saveDisabled() {
    return !this.state.isLocallyValid;
  }

  get showCancelButton() {
    return this.state.canCancel;
  }
}
