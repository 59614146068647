
  <p style="-webkit-text-stroke-width:0px;box-sizing:inherit;color:rgb(68, 84, 106);font-family:'Source Sans Pro', system-ui, -apple-system, BlinkMacSystemFont, 'Avenir Next', Avenir, 'Segoe UI', 'Lucida Grande', 'Helvetica Neue', Helvetica, 'Fira Sans', Roboto, Noto, 'Droid Sans', Cantarell, Oxygen, Ubuntu, 'Franklin Gothic Medium', 'Century Gothic', 'Liberation Sans', sans-serif;font-size:18px;font-style:normal;font-variant-caps:normal;font-weight:400;letter-spacing:-0.2px;line-height:1.2;margin:0;max-width:50rem;text-align:justify;text-decoration:none;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;">
    The Startup Muster 2024 survey has now closed. Our report will be released on October 30th 2024, and we can’t wait to share how Australia’s startup ecosystem has changed since our 2018 report.
  </p>
  <br/>
  <p style="-webkit-text-stroke-width:0px;box-sizing:inherit;color:rgb(68, 84, 106);font-family:'Source Sans Pro', system-ui, -apple-system, BlinkMacSystemFont, 'Avenir Next', Avenir, 'Segoe UI', 'Lucida Grande', 'Helvetica Neue', Helvetica, 'Fira Sans', Roboto, Noto, 'Droid Sans', Cantarell, Oxygen, Ubuntu, 'Franklin Gothic Medium', 'Century Gothic', 'Liberation Sans', sans-serif;font-size:18px;font-style:normal;font-variant-caps:normal;font-weight:400;letter-spacing:-0.2px;line-height:1.2;margin:0;max-width:50rem;text-align:justify;text-decoration:none;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;">
    Thank you to <strong>Atlassian</strong>, <strong>NSW Government</strong>, <strong>Microsoft</strong>,<strong>Antler</strong>,<strong>Parramatta City Council</strong> and <strong>The Gild Group</strong> for making the 2024 survey possible.
  </p>
  <br/>
  <p style="-webkit-text-stroke-width:0px;box-sizing:inherit;color:rgb(68, 84, 106);font-family:'Source Sans Pro', system-ui, -apple-system, BlinkMacSystemFont, 'Avenir Next', Avenir, 'Segoe UI', 'Lucida Grande', 'Helvetica Neue', Helvetica, 'Fira Sans', Roboto, Noto, 'Droid Sans', Cantarell, Oxygen, Ubuntu, 'Franklin Gothic Medium', 'Century Gothic', 'Liberation Sans', sans-serif;font-size:18px;font-style:normal;font-variant-caps:normal;font-weight:400;letter-spacing:-0.2px;line-height:1.2;margin:0;max-width:50rem;text-align:justify;text-decoration:none;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;" [appShow]="!loading && showSuccesMsg">Thank you, we’ll be in touch when our next report is released.</p>

  <p style="-webkit-text-stroke-width:0px;box-sizing:inherit;color:rgb(68, 84, 106);font-family:'Source Sans Pro', system-ui, -apple-system, BlinkMacSystemFont, 'Avenir Next', Avenir, 'Segoe UI', 'Lucida Grande', 'Helvetica Neue', Helvetica, 'Fira Sans', Roboto, Noto, 'Droid Sans', Cantarell, Oxygen, Ubuntu, 'Franklin Gothic Medium', 'Century Gothic', 'Liberation Sans', sans-serif;font-size:18px;font-style:normal;font-variant-caps:normal;font-weight:400;letter-spacing:-0.2px;line-height:1.2;margin:0;max-width:50rem;text-align:justify;text-decoration:none;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;" [appShow]="!loading && !showSuccesMsg">
    To be notified when the report is published, please enter your email address below:
  </p>
  <br/>
  <div  class="action-line" style=" max-width:500px"
  >
    <div class="loading" [appShow]="loading">
      <div class="pagecontent">
        <h2>Loading&hellip;</h2>
        <i class="fa fa-4x fa-spin fa-refresh" aria-hidden="true"></i>
      </div>
    </div>
    <div [appShow]="!loading && !showSuccesMsg">
      <input type="text"
             (keypress)="inputKeyPress($event)"
             [(ngModel)]="email">
      <button class="action primary" (click)="saveEmail()"
              [disabled]="!isEmailValidish">
        Subscribe
      </button>
    </div>
  </div>
