import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-cancel',
  templateUrl: './confirm-cancel.component.html',
  styleUrls: ['./confirm-cancel.component.sass']
})
export class ConfirmCancelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  confirm() {

  }

  cancel() {

  }

}
