
import {combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import {map, filter, distinct} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from "@ngrx/store";
import { AppState } from "../app.models";
import { DataCollectionKey } from "./data.models";

@Injectable()
export class DataSelectorsService {

  isLoaded$(...collectionKeys: DataCollectionKey[]): Observable<boolean> {
    const loadingStates = collectionKeys.map(key => this.store.select(s => s.data[key].loading));
    return observableCombineLatest(loadingStates).pipe(
      distinct(),
      filter(values => values.every(v => v === 'loaded')),
      map(x => !!x),);
  }

  constructor(private store: Store<AppState>) { }

}
