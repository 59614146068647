import { IConnectionInfo } from '../opportunities.model';
import { QuestionResponse } from './response.model';
import { extractValue } from './response.util';
import { QuestionIds } from './constants';
import { Question } from './question.models';

export function generateConnectionInfo(
  orgId: string,
  isConnected: boolean,
  responsesByKey: _.Dictionary<QuestionResponse>,
  questionsByKey: _.Dictionary<Question>
): IConnectionInfo {
  return {
    orgId,
    name:
      extractValue(responsesByKey[QuestionIds.ORGANISATION_NAME]) ||
      extractValue(responsesByKey[QuestionIds.FULL_NAME]) ||
      '',
    description: extractValue(
      responsesByKey[QuestionIds.DESCRIPTION_OF_YOUR_PRODUCT_OR_SERVICE]
    ),
    helperType: extractValue(
      responsesByKey[QuestionIds.KIND_OF_HELPER],
      questionsByKey[QuestionIds.KIND_OF_HELPER]
    ),
    url: extractValue(responsesByKey[QuestionIds.ORGANISATION_URL]),
    isConnected
  };
}

const forSupporterPairs = [
  ['Organisation Name', QuestionIds.ORGANISATION_NAME],
  ['Description', QuestionIds.DESCRIPTION_OF_YOUR_PRODUCT_OR_SERVICE],
  ['Founder', QuestionIds.FULL_NAME],
  ['Founder Email', QuestionIds.PERSONAL_EMAIL],
  // ['Organisation Generic Email', QuestionIds.ORGANISATION_EMAIL],
  ['URL', QuestionIds.ORGANISATION_URL]
];

export type ConnectionInfoForSupporter = { title: string; value?: string }[];

export function generateForSupporter(
  responsesByKey: _.Dictionary<QuestionResponse>
): ConnectionInfoForSupporter {
  return forSupporterPairs.map(([title, qid]) => ({
    title,
    value: extractValue(responsesByKey[qid])
  }));
}
