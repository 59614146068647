<div class="search">
  <input type="text"
         placeholder="Search&hellip;"
         [(ngModel)]="searchText"
         (input)="onSearch()">
</div>

<div class="content"
     *ngIf="!filteredOptions; else elseContent">

  <div class="groups">
    <div class="group-title"
         *ngFor="let g of optionGroups"
         [ngClass]="{ selected: g === selectedGroup }"
         (click)="onClickGroup(g)">
      <div class="text">
        {{ g.title }}
      </div>
      <div class="icon">
        <i class="fa fa-angle-right"
           aria-hidden="true"></i>
      </div>
    </div>
  </div>

  <div class="group-items">
    <ng-container *ngIf="selectedGroup">
      <div class="group-item"
           *ngFor="let o of selectedGroup!.options"
           [ngClass]="{ selected: o.id === selectedOptionId }"
           (click)="onClickItem(o.id)">
        {{ o.text }}
      </div>
    </ng-container>
  </div>

</div>

<ng-template #elseContent>
  <div class="group-items">
    <div class="group-item"
         *ngFor="let o of filteredOptions"
         [innerHTML]="searchWrapped(o.text)"
         (click)="onClickItem(o.id)">
    </div>
  </div>
</ng-template>
