import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ElementRef,
  ViewChild,
  AfterViewInit,
  AfterViewChecked
} from '@angular/core';
import {
  SearchPickerService,
  ISearchPickerViewState
} from '../../shared/search-picker.service';
import { ISearchPickerResult } from '../../../common/questions/search-picker.models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-picker',
  templateUrl: './search-picker.component.html',
  styleUrls: ['./search-picker.component.sass']
})
export class SearchPickerComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  @Input() questionId: string;
  @Input() key: string;
  @Input() value: string;

  @Output() pick = new EventEmitter<string>();

  @ViewChild('ul', { static: true }) list: ElementRef;

  // results: ISearchPickerResult[];
  state: ISearchPickerViewState = { results: [], visible: false };

  private stateSubscription: Subscription;

  constructor(private searchPickerService: SearchPickerService) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.dispose();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.searchPickerService.updateSearch(
        this.questionId,
        this.key,
        this.value
      );
    }

    if (changes.questionId) {
      this.dispose();
      this.stateSubscription = this.searchPickerService
        .getState$(this.questionId)
        .subscribe(newState => {
          if (
            newState.selection !== this.state.selection &&
            newState.selection
          ) {
          }
          this.state = newState;
        });
    }
  }

  ngAfterViewChecked(): void {
    if (this.list && this.list.nativeElement) {
      const el = this.list.nativeElement as HTMLUListElement;
      const selected = el.getElementsByClassName('selected');
      if (selected.length > 0) {
        const selectedEl = selected[0] as HTMLLIElement;
        const relativePosition = selectedEl.offsetTop - el.scrollTop;
        if (relativePosition < 0 || relativePosition > el.clientHeight) {
          selectedEl.scrollIntoView();
        }
      }
    }
  }

  onClickResult(result: ISearchPickerResult) {
    this.pick.emit(result.value);
  }

  private dispose() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  isSelected(result: ISearchPickerResult) {
    return this.state.selection === result.value;
  }

  trackByValue(index: number, result: ISearchPickerResult) {
    return result.value;
  }
}
