import { combineReducers } from '@ngrx/store';
import { AppReducer } from '../../app.reducer';
import {
  ICriteriaState,
  CriteriaIdealFeedbackStates,
  IEditingCriterion,
  IDefiningCriteria,
  IValidState,
  ValidState
} from '../../criteria/criteria.models';
import {
  CriteriaActionTypes,
  CriteriaCallApiKeys
} from '../../criteria/criteria.actions';
import { AppActions } from '../../app.actions';
import * as uuid from 'uuid';
import * as timm from 'timm';
import { OperatorType } from '../../../common/criteria/criteria-operator-types.models';
import {
  CriteriaSetValidity,
  ICriteriaSetValidityResult
} from '../../../common/criteria/criteria.models';
import { DataActionTypes } from '../../data/data.actions';
import { CallApiActionTypes } from '../../shared/call-api';
import { criterionUpdate } from './criterion-update.reducer';

export const idealFeedbackStateReducer: AppReducer<
  CriteriaIdealFeedbackStates
> = (state = 'empty', action) => {
  switch (action.type) {
    case CriteriaActionTypes.CRITERIA_UPDATE_IDEAL_FEEDBACK_STATE:
      return action.payload;
  }
  return state;
};

const innerCriteriaStateReducer: AppReducer<ICriteriaState> = combineReducers({
  idealFeedbackState: idealFeedbackStateReducer,
  editingCriterion: editingCriterionReducer,
  definingCriteria: definingCriteriaReducer,
  setValidity: setValidityReducer
});

export function criteriaStateReducer(state: any, action: AppActions) {
  return innerCriteriaStateReducer(state, action);
}

export function editingCriterionReducer(
  state: IEditingCriterion | null = null,
  action: AppActions
) {
  switch (action.type) {
    case CriteriaActionTypes.CRITERION_SET_VALUE:
      if (!state) {
        state = { id: action.id, validState: ValidState.invalid() };
      }
      state = criterionUpdate(state, action);
      state = validateCriterion(state);
      break;
    case CriteriaActionTypes.SET_EDITING_CRITERION:
      state = action.criterion;
      break;
    case CriteriaActionTypes.CRITERION_ADD:
      state = {
        id: uuid.v4(),
        validState: ValidState.invalid()
      };
      break;
  }
  return state;
}

export function definingCriteriaReducer(
  state: IDefiningCriteria | null = null,
  action: AppActions
): IDefiningCriteria | null {
  switch (action.type) {
    case CriteriaActionTypes.INIT:
      return { setId: action.setId, state: 'editing' };
    case CriteriaActionTypes.SET_STATE:
      return { setId: action.setId, state: action.state };
  }
  return state;
}

function validateCriterion(state: IEditingCriterion): IEditingCriterion {
  return timm.set(state, 'validState', criterionIsValid(state));
}

function criterionIsValid(criterion: IEditingCriterion): IValidState {
  if (criterion.questionId && criterion.operator) {
    switch (criterion.operator.type) {
      // case OperatorType.OTHER_CONTAINS:
      case OperatorType.CONTAINS:
        return { isValid: !!criterion.operator.value };
      case OperatorType.BETWEEN:
        if (!!criterion.operator.start || !!criterion.operator.end) {
          if (
            !!criterion.operator.start &&
            !!criterion.operator.end &&
            criterion.operator.start > criterion.operator.end
          ) {
            return ValidState.invalid(
              'Your starting value is currently larger than your ending value, \
              please check that you have entered your values correctly. The larger \
              value should go on the right side.'
            );
          }
          return ValidState.valid();
        }
        return ValidState.invalid();
      case OperatorType.ALL_OF:
      case OperatorType.ONE_OF:
        return {
          isValid:
            !!criterion.operator.optionIds &&
            criterion.operator.optionIds.length > 0
        };
      case OperatorType.PROXIMITY:
        return {
          isValid:
            !!criterion.operator.lat &&
            !!criterion.operator.lng &&
            !!criterion.operator.place &&
            !!criterion.operator.radius
        };
    }
  }
  return { isValid: false };
}

export function setValidityReducer(
  state: _.Dictionary<CriteriaSetValidity> = {},
  action: AppActions
) {
  switch (action.type) {
    case DataActionTypes.DATA_SAVED:
      if (action.payload.collection === 'combinedCriteriaSets') {
        if (action.payload.response) {
          const result = action.payload.response as ICriteriaSetValidityResult;
          state = timm.set(state, result.setId, result.status);
        }
      }
      break;
    case DataActionTypes.DATA_LOADED:
      // debugger;
      if (action.payload.collection === 'criteriaSetValidity') {
        if (action.payload.data) {
          const results = action.payload.data as ICriteriaSetValidityResult[];
          const newState: _.Dictionary<CriteriaSetValidity> = {};
          for (const r of results) {
            newState[r.setId] = r.status;
          }
          state = newState;
        }
      }
      break;
  }
  return state;
}
