import { Component, OnInit } from '@angular/core';
import { ILoginForm } from '../login-form/login-form.component';
import { UserService } from '../user.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.models';
import { LoginLinkRequested } from '../../global.actions';

@Component({
  selector: 'app-login-direct',
  templateUrl: './login-direct.component.html',
  styleUrls: ['./login-direct.component.sass']
})
export class LoginDirectComponent implements OnInit {
  constructor(
    private userService: UserService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {}

  login(model: ILoginForm) {
    // this.userService.requestLoginLink(model.email).subscribe();
    this.store.dispatch(
      new LoginLinkRequested({ email: model.email, redirectOnSuccess: true })
    );
  }
}
