import { withLatestFrom } from 'rxjs/operators';
import { IQuestionComponentState } from '../question/question.component';
import { IYourOrganisationSection } from '../../../common/your-organisation.models';
import {
  IAnswersViewState,
  ISurveyStateQuestionResponse,
  IAnswersViewSection
} from '../your-organisation.models';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { OnChanges, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { AnswerDispatcherService } from '../answer-dispatcher.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.models';
import { SetUpdateLevel } from '../your-organisation.actions';
import {
  QuestionKey,
  QuestionId
} from '../../../common/questions/question.models';
import { Subscription, ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-answers-view',
  templateUrl: './answers-view.component.html',
  styleUrls: ['./answers-view.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnswersViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() state: IAnswersViewState;
  @Output() editQuestion = new EventEmitter<string>();
  @Output() cancelEditQuestion = new EventEmitter<{}>();
  @Output() selectSection = new EventEmitter<string>();

  private allQuestions$ = new ReplaySubject<
    ISurveyStateQuestionResponse[] | null
  >();
  private fragmentSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private answerDispatcher: AnswerDispatcherService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.fragmentSubscription = this.route.fragment
      .pipe(withLatestFrom(this.allQuestions$))
      .subscribe(([fragment, allQuestions]) => {
        if (fragment && allQuestions && this.state) {
          const match = fragment.match(/question-(\w+)/);
          if (match && match.length > 1) {
            const questionKey = match[1];
            const qr = allQuestions.find(q => q.question.key === questionKey);
            if (qr) {
              if (this.state.editingQuestionId === qr.question.id) {
                return;
              }
              if (!this.isSectionSelected(qr.question.sectionId)) {
                this.selectSection.emit(qr.question.sectionId);
              }
              this.editQuestion.emit(qr.question.id);
            }
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.fragmentSubscription) {
      this.fragmentSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.state) {
      if (this.state) {
        this.allQuestions$.next(
          _.flatMap(this.state.sections, s => s.questionResponses)
        );
      } else {
        this.allQuestions$.next(null);
      }
    }
  }

  save($event: Event, qr: ISurveyStateQuestionResponse) {
    $event.stopPropagation();
    this.answerDispatcher.answer(qr.question, Object.assign({}, qr.response));
    this.cancelEditQuestion.emit();
    this.router.navigate([], {
      relativeTo: this.route,
      fragment: ''
    });
    if (this.state.questionsNeedingUpdates.length > 0) {
      this.moveToFirstNext();
    }
    return false;
  }

  trackBySectionId(index: number, section: IYourOrganisationSection) {
    return section.sectionId;
  }

  trackByQuestionId(index: number, question: IQuestionComponentState) {
    return question.question.id;
  }

  editing(questionId: string) {
    return this.state.editingQuestionId === questionId;
  }

  onClickQuestion($event: Event, questionId: string) {
    if (this.state.editingQuestionId !== questionId) {
      this.editQuestion.emit(questionId);
    } else {
      $event.preventDefault();
    }
  }

  onClickSection(sectionId: string) {
    this.cancelEditQuestion.emit({});
    this.selectSection.emit(sectionId);
  }

  get hasUpdates() {
    return this.state.questionsNeedingUpdates.length > 0;
  }

  isSectionSelected(sectionId: string) {
    return this.state.selectedSectionIds.indexOf(sectionId) > -1;
  }

  moveToFirstNext() {
    if (this.state.questionsNeedingUpdates.length > 0) {
      if (
        this.state.questionsNeedingUpdates[0].question.id ===
        this.state.editingQuestionId
      ) {
        if (this.state.questionsNeedingUpdates.length > 1) {
          this.navToQuestion(this.state.questionsNeedingUpdates[1]);
        }
      } else {
        this.navToQuestion(this.state.questionsNeedingUpdates[0]);
      }
    }
  }

  updateMore() {
    this.store.dispatch(new SetUpdateLevel({ updateLevel: 'warning' }));
    setTimeout(() => {
      this.moveToFirstNext();
    }, 100);
  }

  updateDone() {
    this.store.dispatch(new SetUpdateLevel({}));
  }

  get doneUpdatingCriticals() {
    return (
      this.state.currentUpdateLevel === 'critical' &&
      this.state.questionsNeedingUpdates.length === 0 &&
      !this.state.editingQuestionId
    );
  }

  get doneUpdatingRest() {
    return (
      this.state.currentUpdateLevel === 'warning' &&
      this.state.questionsNeedingUpdates.length === 0 &&
      !this.state.editingQuestionId
    );
  }

  saveButtonText(questionResponse: ISurveyStateQuestionResponse) {
    if (questionResponse.status === 'need-update') {
      return 'Confirm';
    }
    if (this.state.questionsNeedingUpdates.length === 0) {
      return 'Done';
    }
    return 'Next';
  }

  private navToQuestion(questionResponse: ISurveyStateQuestionResponse) {
    if (!this.isSectionSelected(questionResponse.question.sectionId)) {
      this.selectSection.emit(questionResponse.question.sectionId);
    }
    this.editQuestion.emit(questionResponse.question.id);
  }
}
