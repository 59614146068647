import { Action } from '@ngrx/store';
import { IDataState } from './data.models';
import { AppActions } from '../app.actions';
import { ICollectable } from '../../common/core/collection';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';

export namespace DataActionTypes {
  export const DATA_LOAD_REQUESTED: '[Data] Load Requested' =
    '[Data] Load Requested';
  export const DATA_LOADING: '[Data] Loading' = '[Data] Loading';
  export const DATA_LOADED: '[Data] Loaded' = '[Data] Loaded';
  export const DATA_LOAD_FAILED: '[Data] Load Failed' = '[Data] Load Failed';

  export const DATA_SAVE_REQUESTED: '[Data] Save Requested' =
    '[Data] Save Requested';
  export const DATA_SAVING: '[Data] Saving' = '[Data] Saving';
  export const DATA_SAVED: '[Data] Saved' = '[Data] Saved';
  export const DATA_SAVE_FAILED: '[Data] Save Failed' = '[Data] Save Failed';
  export const DATA_SAVE_ERROR: '[Data] Save Error' = '[Data] Save Error';
  export const DATA_SAVE_CANCELLED: '[Data] Save Cancelled' =
    '[Data] Save Cancelled';
  export const DATA_SAVE_RETRIED: '[Data] Save Retried' = '[Data] Save Retried';

  export const DATA_SET_ITEM: '[Data] Set Item' = '[Data] Set Item';
  export const DATA_UPDATE_RELATION: '[Data] Update Relation' =
    '[Data] Update Relation';
}

const dataActionTypeStrings: string[] = <any>_.values(DataActionTypes);

export type DataActions =
  | DataLoadRequested
  | DataLoading
  | DataLoaded
  | DataLoadFailed
  | DataSaveRequested
  | DataSaving
  | DataSaved
  | DataSaveFailed
  | DataSaveError
  | DataSetItem
  | DataUpdateRelation;

export interface IDataPayload {
  collection: keyof IDataState;
}

export function isDataAction(action: AppActions): action is DataActions {
  return dataActionTypeStrings.indexOf(action.type) > -1;
}

export interface IDataLoadedPayload extends IDataPayload {
  data: any;
}

export class DataLoadRequested implements Action {
  type = DataActionTypes.DATA_LOAD_REQUESTED;
  constructor(public payload: IDataPayload) {}
}

export class DataLoading implements Action {
  type = DataActionTypes.DATA_LOADING;
  constructor(public payload: IDataPayload) {}
}

export class DataLoaded implements Action {
  type = DataActionTypes.DATA_LOADED;
  constructor(public payload: IDataLoadedPayload) {}
}

export class DataLoadFailed implements Action {
  type = DataActionTypes.DATA_LOAD_FAILED;
  constructor(public payload: IDataPayload) {}
}

export interface IDataItemPayload extends IDataPayload {
  itemId: string;
}

export interface IDataSetPayload extends IDataItemPayload {
  itemData: ICollectable | null;
}

export interface IDataSaveFailedPayload extends IDataItemPayload {
  errors: string[];
}

export interface IDataItemPayloadWithResponse extends IDataItemPayload {
  response: any;
}

export class DataSaveRequested implements Action {
  type = DataActionTypes.DATA_SAVE_REQUESTED;
  constructor(public payload: IDataItemPayload) {}
}

export class DataSaving implements Action {
  type = DataActionTypes.DATA_SAVING;
  constructor(public payload: IDataItemPayload) {}
}

export class DataSaved implements Action {
  type = DataActionTypes.DATA_SAVED;
  constructor(public payload: IDataItemPayloadWithResponse) {}
}

export class DataSaveFailed implements Action {
  type = DataActionTypes.DATA_SAVE_FAILED;
  constructor(public payload: IDataSaveFailedPayload) {}
}

export class DataSaveError implements Action {
  type = DataActionTypes.DATA_SAVE_ERROR;
  constructor(public payload: IDataItemPayload) {}
}

export class DataSaveCancelled implements Action {
  type = DataActionTypes.DATA_SAVE_CANCELLED;
  constructor(public payload: IDataItemPayload) {}
}

export class DataSaveRetried implements Action {
  type = DataActionTypes.DATA_SAVE_RETRIED;
  constructor(public payload: IDataItemPayload) {}
}

export class DataSetItem implements Action {
  type = DataActionTypes.DATA_SET_ITEM;
  constructor(public payload: IDataSetPayload) {}
}

export interface IDataUpdateRelationPayload extends IDataPayload {
  parentId: string;
  idArrayKey: string;
  childId: string;
  childAction: 'adding' | 'removing';
}

export class DataUpdateRelation implements Action {
  type = DataActionTypes.DATA_UPDATE_RELATION;
  constructor(public payload: IDataUpdateRelationPayload) {}
}
