import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  AfterViewChecked,
  ElementRef
} from '@angular/core';
import { UiFilter } from '../criteria-define-crunch/criteria-define-crunch.models';
import { OperatorType } from '../../../common/criteria/criteria-operator-types.models';
import { NgSelectComponent } from '@ng-select/ng-select';
import { IOpProximityModel } from '../op-proximity/op-proximity.component';
import { NumberMaskService } from '../../your-organisation/number-mask.service';

@Component({
  selector: 'app-crunch-filter',
  templateUrl: './crunch-filter.component.html',
  styleUrls: ['./crunch-filter.component.sass']
})
export class CrunchFilterComponent
  implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild('tagsSelect')
  selectComponent: NgSelectComponent;
  @ViewChild('betweenInput')
  betweenInputRef: ElementRef;

  @Input()
  filter: UiFilter;

  @Output()
  editQuestion = new EventEmitter<{}>();
  @Output()
  remove = new EventEmitter<{}>();

  @Output()
  changeOperator = new EventEmitter<OperatorType>();
  @Output()
  changeTags = new EventEmitter<any>();
  @Output()
  changeBetweenFrom = new EventEmitter<string>();
  @Output()
  changeBetweenTo = new EventEmitter<string>();
  @Output()
  changeProximity = new EventEmitter<IOpProximityModel>();
  @Output()
  changeMaxAgeMonths = new EventEmitter<number>();

  // private shouldFocus = false;

  maxAgeItems = [
    { text: 'no limit', value: 0 },
    { text: '3 months', value: 3 },
    { text: '6 months', value: 6 },
    { text: '12 months', value: 12 },
    { text: '18 months', value: 18 },
    { text: '24 months', value: 24 }
  ];

  constructor(private numberMask: NumberMaskService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    const filterChange = changes.filter;
    if (filterChange) {
      // Focus search input on new filter, or whenever our questionId changes.
      const prev = filterChange.previousValue;
      const curr = filterChange.currentValue;
      if (
        filterChange.isFirstChange() ||
        prev.question.id !== curr.question.id ||
        prev.operator.operator !== curr.operator.operator
      ) {
        setTimeout(() => {
          if (this.selectComponent) {
            // this.selectComponent.open();
            this.selectComponent.focus();
          } else if (
            this.betweenInputRef &&
            this.betweenInputRef.nativeElement
          ) {
            this.betweenInputRef.nativeElement.focus();
          }
        });
      }
    }
  }

  ngAfterViewChecked(): void {}

  onClickEditQuestion() {
    this.editQuestion.emit({});
  }

  onChangeOperator(operator: OperatorType) {
    this.changeOperator.emit(operator);
  }

  onChangeTags(tags: any) {
    this.changeTags.emit(tags);
  }

  onChangeBetweenFrom(from: string) {
    const cleanFrom = from.replace(/\D+/g, '');
    this.changeBetweenFrom.emit(cleanFrom);
  }

  onChangeBetweenTo(to: string) {
    const cleanTo = to.replace(/\D+/g, '');
    this.changeBetweenTo.emit(cleanTo);
  }

  // onInputBetweenFrom($event: Event) {
  //   const from = ($event.target as HTMLInputElement).value || '';
  //   this.changeBetweenFrom.emit(from);
  // }

  // onInputBetweenTo($event: Event) {
  //   const to = ($event.target as HTMLInputElement).value || '';
  //   this.changeBetweenTo.emit(to);
  // }

  onChangeProximity(event: IOpProximityModel) {
    this.changeProximity.emit(event);
  }

  onChangeMaxAgeMonths(maxAgeMonths: number) {
    this.changeMaxAgeMonths.emit(maxAgeMonths);
  }

  onClickRemove() {
    this.remove.emit({});
  }

  get mask() {
    return { mask: this.numberMask.create(this.filter.question) };
  }
}
