import {
  from as observableFrom,
  empty as observableEmpty,
  Observable
} from 'rxjs';

import { tap, withLatestFrom, filter, mergeAll, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { AppState } from '../../app.models';
import { Store, Action } from '@ngrx/store';
import { DataActionTypes, DataSaved } from '../../data/data.actions';
import {
  CriteriaDefineCrunchActionTypes,
  LoadCriteriaSet,
  SetState
} from './criteria-define-crunch.actions';
import { Filter, Operand, Tag } from './criteria-define-crunch.models';
import {
  CriteriaOperator,
  ICriterion,
  ICriteriaSet,
  ICombinedCriteriaSet,
  ICriteriaSetValidityResult
} from '../../../common/criteria/criteria.models';
import { OperatorType } from '../../../common/criteria/criteria-operator-types.models';
import { Question, IOptions } from '../../../common/questions/question.models';
import { DataService } from '../../data/data.service';
import * as _ from 'lodash';
import { EffectLoggerService } from '../../effect-logger.service';
import { DataSelectorsService } from '../../data/data-selectors.service';
import * as timm from 'timm';
import { Router } from '@angular/router';
import { operatorsForQuestion } from '../../../common/criteria/criteria-operators-by-question-type.models';
import { CriteriaCrunchValidatorService } from '../criteria-crunch-validator.service';

@Injectable()
export class CriteriaDefineCrunchEffects {
  private combinedCriteriaSets$ = this.store.select(
    s => s.data.combinedCriteriaSets
  );
  private questions$ = this.store.select(s => s.data.questions);
  private crunchState$ = this.store.select(s => s.criteriaCrunch);

  @Effect()
  loadCriteriaSet$ = this.actions$.pipe(
    ofType(CriteriaDefineCrunchActionTypes.LOAD_CRITERIA_SET),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        withLatestFrom(
          this.combinedCriteriaSets$,
          this.questions$,
          (action: LoadCriteriaSet, allCSets, allQuestions) =>
            setCrunchState(
              action.payload.setId,
              allCSets.byId,
              allQuestions.byId
            )
        )
      )
    )
  );

  @Effect()
  dataLoaded$ = this.actions$.pipe(
    ofType(DataActionTypes.DATA_LOADED),
    withLatestFrom(
      this.crunchState$,
      (action, crunchState) => crunchState.setId
    ),
    filter(setId => !!setId),
    withLatestFrom(this.dataSelectors.isLoaded$('combinedCriteriaSets')),
    filter(([, isLoaded]) => isLoaded),
    withLatestFrom(
      this.combinedCriteriaSets$,
      this.questions$,
      ([setId], allCSets, allQuestions) => {
        return setCrunchState(setId!, allCSets.byId, allQuestions.byId);
      }
    )
  );

  @Effect()
  save$ = this.actions$.pipe(
    ofType(CriteriaDefineCrunchActionTypes.SAVE),
    withLatestFrom(
      this.crunchState$,
      this.questions$,
      (action, crunchState, allQuestions) => {
        const setId = crunchState.setId;
        if (!setId) {
          throw new Error(`Attempted Save with no setId`);
        }

        crunchState = timm.set(
          crunchState,
          'filters',
          crunchState.filters.map(f => {
            if (!f.operatorType) {
              const question = allQuestions.byId[f.questionId];
              if (question) {
                const operators = operatorsForQuestion(question);
                if (operators && operators.length > 0) {
                  return { ...f, operatorType: operators[0] };
                }
              }
            }
            return f;
          })
        );

        if (!this.crunchValidator.isValid(crunchState)) {
          return observableEmpty(); // TODO: ui validation failed?
        }

        const criteria: ICriterion[] = crunchState.filters.map(f => ({
          id: f.id,
          maxAgeMonths: 12,
          questionId: f.questionId,
          criteriaSetId: setId!,
          operator: fromOperand(f.operatorType!, f.operand!)
        }));

        const set: ICriteriaSet = {
          id: setId,
          label: crunchState.name || '',
          enabled: true,
          operator: 'all',
          criteriaIds: criteria.map(c => c.id)
        };

        const cSet: ICombinedCriteriaSet = {
          set,
          criteria
        };

        // this.router.navigateByUrl('/connect-with-startups#list');

        return observableFrom(
          this.data.setAndSave('combinedCriteriaSets', setId, cSet)
        );
      }
    ),
    mergeAll()
  );

  @Effect()
  saved$ = this.actions$.pipe(
    ofType(DataActionTypes.DATA_SAVED),
    map((action: DataSaved) => {
      if (action.payload.collection === 'combinedCriteriaSets') {
        const result = action.payload.response as ICriteriaSetValidityResult;
        if (result.status === 'valid') {
          this.router.navigateByUrl('/connect-with-startups#list');
        } else {
          this.router.navigate(['/connect-with-startups', result.setId]);
        }
        return observableFrom([
          this.data.set('combinedCriteriaSets', result.setId, result)
        ]);
      }
      return observableEmpty();
    }),
    mergeAll()
  );

  @Effect({ dispatch: false })
  cancel$ = this.actions$.pipe(
    ofType(CriteriaDefineCrunchActionTypes.CANCEL),
    tap(() => {
      this.router.navigateByUrl('/connect-with-startups#list');
    })
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private data: DataService,
    private effectLogger: EffectLoggerService,
    private dataSelectors: DataSelectorsService,
    private router: Router,
    private crunchValidator: CriteriaCrunchValidatorService
  ) {}
}

function toOperand(operator: CriteriaOperator, question: Question): Operand {
  switch (operator.type) {
    case OperatorType.ALL_OF:
    case OperatorType.ONE_OF:
      const questionWithOptions = question as Question & IOptions;
      if (!questionWithOptions.options) {
        throw new Error(
          `Expected question ${question.id} to have options for ALL_OF or ONE_OF`
        );
      }
      let tags: Tag[] = operator.optionIds.map(id => {
        const option = questionWithOptions.options.find(o => o.optionId === id);
        return {
          id,
          label: option ? option.text : id
        };
      });
      if (operator.type === OperatorType.ONE_OF && operator.otherContains) {
        tags = tags.concat(operator.otherContains.map(oc => ({ label: oc })));
      }
      return {
        type: 'tags',
        tags
      };
    case OperatorType.BETWEEN:
      return {
        type: 'between',
        from: operator.start ? `${operator.start}` : '',
        to: operator.end ? `${operator.end}` : ''
      };
    case OperatorType.CONTAINS:
      return {
        type: 'tags',
        tags: operator.value.map(v => ({ label: v }))
      };
    case OperatorType.PROXIMITY:
      return {
        type: 'location',
        place: operator.place,
        lat: operator.lat,
        lng: operator.lng,
        radius: operator.radius
      };
  }
}

function fromOperand(
  operatorType: OperatorType,
  operand: Operand
): CriteriaOperator {
  switch (operatorType) {
    case OperatorType.ALL_OF:
      if (operand.type === 'tags') {
        return {
          type: OperatorType.ALL_OF,
          optionIds: operand.tags.map(t => t.id!)
        };
      }
      break;
    case OperatorType.ONE_OF:
      if (operand.type === 'tags') {
        const [tagsWithId, tagsWithoutId] = _.partition(
          operand.tags,
          t => !!t.id
        );
        return {
          type: OperatorType.ONE_OF,
          optionIds: tagsWithId.map(t => t.id!),
          otherContains: tagsWithoutId.map(t => t.label)
        };
      }
      break;
    case OperatorType.CONTAINS:
      if (operand.type === 'tags') {
        return {
          type: OperatorType.CONTAINS,
          value: operand.tags.map(t => (t.label ? t.label : (t as any)))
        };
      }
      break;
    case OperatorType.BETWEEN:
      if (operand.type === 'between') {
        const start = parseInt(operand.from, 10);
        const end = parseInt(operand.to, 10);
        return {
          type: OperatorType.BETWEEN,
          start: isNaN(start) ? undefined : start,
          end: isNaN(end) ? undefined : end
        };
      }
      break;
    case OperatorType.PROXIMITY:
      if (operand.type === 'location') {
        return {
          type: OperatorType.PROXIMITY,
          place: operand.place,
          lat: operand.lat!,
          lng: operand.lng!,
          radius: operand.radius!
        };
      }
      break;
  }
  throw new Error(`blah`);
}

function setCrunchState(
  setId: string,
  csetsById: _.Dictionary<ICombinedCriteriaSet>,
  questionsById: _.Dictionary<Question>
): SetState {
  const cset = csetsById[setId];
  if (!cset) {
    let newCounter = 1;
    for (const cSet of _.values(csetsById)) {
      if (!cSet.set.label) {
        continue;
      }
      const match = cSet.set.label.match(/Opportunity profile #(\d+)/);
      if (match && match.length >= 2) {
        const count = parseInt(match[1], 10);
        newCounter = Math.max(count + 1, newCounter);
      }
    }

    return new SetState({
      state: {
        setId: setId,
        name: `Opportunity profile #${newCounter}`,
        filters: []
      }
    });
  }

  const filters: Filter[] = cset.criteria.map(c => {
    const question = questionsById[c.questionId];
    const operand = toOperand(c.operator, question);
    return {
      id: c.id,
      questionId: c.questionId,
      operatorType: c.operator.type,
      operand
    };
  });

  return new SetState({
    state: {
      setId: cset.set.id,
      name: cset.set.label || '',
      filters
    }
  });
}
