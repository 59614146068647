import {
  of as observableOf,
  empty as observableEmpty,
  from as observableFrom
} from 'rxjs';

import { mergeMap, withLatestFrom, mergeAll, merge } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../app.models';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  SearchPickerActionTypes,
  UpdateSearch,
  ResultsFiltered,
  FetchResultsCallApiArgs,
  SetResults,
  SetSelection,
  MoveSelection
} from '../shared/search-picker.actions';
import { EffectLoggerService } from '../effect-logger.service';
import { CallApiActionTypes, CallApiService } from '../shared/call-api';
import { ISearchPickerResult } from '../../common/questions/search-picker.models';
import { Injectable } from '@angular/core';
import { filter } from 'fuzzaldrin-plus';
import { ISearchPickerUiState } from '../shared/search-picker.models';

@Injectable()
export class SearchPickerEffects {
  @Effect()
  updateSearch$ = this.actions$.pipe(
    ofType(SearchPickerActionTypes.UPDATE_SEARCH),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        withLatestFrom(
          this.store.select(s => s.searchPicker.allResultsByKey),
          (action: UpdateSearch, allResultsByKey) => {
            const { key, questionId, value } = action.payload;
            const results = allResultsByKey[key];

            if (results === undefined) {
              const call$ = this.callApi
                .callApi(new FetchResultsCallApiArgs(key))
                .pipe(
                  mergeMap(fetchAction => {
                    if (
                      fetchAction.type === CallApiActionTypes.CALL_API_LOADED
                    ) {
                      const freshResults = fetchAction.payload.response;
                      const setResults = new SetResults({
                        key,
                        results: freshResults
                      });
                      const filtered = this.filterResults(
                        questionId,
                        value,
                        freshResults
                      );
                      return observableFrom([setResults, filtered]);
                    }
                    return observableEmpty();
                  })
                );

              const setResultsToEmpty = observableOf(
                new SetResults({ key, results: [] })
              );
              return setResultsToEmpty.pipe(merge(call$));
            }

            return observableOf(this.filterResults(questionId, value, results));
          }
        ),
        mergeAll()
      )
    )
  );

  @Effect()
  moveSelection$ = this.actions$.pipe(
    ofType(SearchPickerActionTypes.MOVE_SELECTION),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        withLatestFrom(
          this.store.select(s => s.searchPicker.filteredResultsByQuestion),
          this.store.select(s => s.searchPicker.ui),
          (
            action: MoveSelection,
            results: _.Dictionary<ISearchPickerResult[]>,
            uiStates: _.Dictionary<ISearchPickerUiState>
          ) => {
            const { questionId, direction } = action.payload;
            const currentResults = results[questionId];
            const uiState = uiStates[questionId];
            if (currentResults && currentResults.length > 1) {
              if (uiState && uiState.selection) {
                const currentIndex = currentResults.findIndex(
                  cr => cr.value === uiState.selection
                );
                if (direction === 'up' && currentIndex > 0) {
                  return observableOf(
                    new SetSelection({
                      questionId,
                      selection: currentResults[currentIndex - 1].value
                    })
                  );
                } else if (
                  direction === 'down' &&
                  currentIndex < currentResults.length - 1
                ) {
                  return observableOf(
                    new SetSelection({
                      questionId,
                      selection: currentResults[currentIndex + 1].value
                    })
                  );
                }
              } else if (direction === 'down') {
                return observableOf(
                  new SetSelection({
                    questionId,
                    selection: currentResults[0].value
                  })
                );
              }
            }
            return observableEmpty();
          }
        ),
        mergeAll()
      )
    )
  );

  private filterResults(
    questionId: string,
    value: string,
    results: ISearchPickerResult[]
  ) {
    const filteredResults = filter(results, value, { key: 'description' });
    return new ResultsFiltered({ questionId, results: filteredResults });
  }

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private effectLogger: EffectLoggerService,
    private callApi: CallApiService
  ) {}
}
