import { combineLatest } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from '../../app.models';
import { CallApiService } from '../../shared/call-api';
import { ProvideEmail } from './report-download.actions';
import { IReportDownloadState } from './report-download.models';
import { GaService } from '../ga.service';

@Injectable()
export class ReportDownloadService {
  private emailProvided = false;
  private loginEmail?: string;
  private subscription: Subscription;
  private state: IReportDownloadState;

  constructor(
    private callApi: CallApiService,
    private store: Store<AppState>,
    private router: Router,
    private ga: GaService
  ) {
    this.subscription = store
      .select(s => s.global.loginEmail)
      .pipe(combineLatest(store.select(s => s.reportDownload)))
      .subscribe(([loginEmail, state]) => {
        this.loginEmail = loginEmail;
        this.state = state;
        this.emailProvided = !!state.downloadKey;
      });
  }

  requestDownload(reportId: string) {
    this.router.navigate([
      {
        outlets: { modal: ['report-download', reportId] }
      }
    ]);

    if (this.emailProvided) {
      this.download(reportId);
    } else if (this.loginEmail && this.loginEmail.match(/@/)) {
      // this.download(reportId);
      this.provideEmail(reportId, this.loginEmail);
    }
  }

  provideEmail(reportId: string, email: string) {
    this.store.dispatch(new ProvideEmail({ email, reportId }));
  }

  private download(reportId: string) {
    this.ga.logReportDownload(reportId);
    const reportUrl = this.reportUrl(reportId);
    const downloadWindow = window.open(reportUrl, '_blank');
    if (downloadWindow === null || typeof downloadWindow === 'undefined') {
      window.location.href = reportUrl;
    }
  }

  private reportUrl(reportId: string) {
    switch (reportId) {
      case '2015':
      case '2016':
      case '2017':
      case '2018':
        if (!this.state || !this.state.downloadKey) {
          throw new Error(
            `Missing download key on attempted download for report ${reportId}`
          );
        }
        return `/reports/Startup-Muster-${reportId}-Report.pdf?key=${
          this.state.downloadKey
        }`;
      default:
        throw new Error(`Invalid report id ${reportId}`);
    }
  }
}
