import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { TextMaskModule } from 'angular2-text-mask';
import { AppRoutingModule } from '../app-routing.module';
import { DataModule } from '../data/data.module';
import {
  FragmentPolyfillModule,
  FragmentTargetDirective
} from '../fragment.polyfill.module';
import { GaService } from '../shared/ga.service';
import { IntercomService } from '../shared/intercom.service';
import { SearchPickerEffects } from '../shared/search-picker.effects';
import { SearchPickerService } from '../shared/search-picker.service';
import { ButtonSelectComponent } from './button-select/button-select.component';
import { CallApiService } from './call-api';
import { ConfirmCancelComponent } from './confirm-cancel/confirm-cancel.component';
import { DataLoaderService } from './data-loader.service';
import { DropDownComponent } from './drop-down/drop-down.component';
import { FancyHComponent } from './fancy-h/fancy-h.component';
import { FilterSelectComponent } from './filter-select/filter-select.component';
import { FragmentRouterService } from './fragment-router.service';
import { GridSelectComponent } from './grid-select/grid-select.component';
import { LoadingComponent } from './loading/loading.component';
import { ModalEmbeddedComponent } from './modal-embedded/modal-embedded.component';
import { ModalComponent } from './modal/modal.component';
import { NestedMenuComponent } from './nested-menu/nested-menu.component';
import { NestedSelectComponent } from './nested-select/nested-select.component';
import { ReportDownloadEffects } from './report-download/report-download.effects';
import { RewriteLinksDirective } from './rewrite-links.directive';
import { SavingHandlerService } from './saving-handler.service';
import { SearchPickerComponent } from './search-picker/search-picker.component';
import { SearchSelectComponent } from './search-select/search-select.component';
import { ShowDirective } from './show.directive';
import { StagedSelectCrunchComponent } from './staged-select-crunch/staged-select-crunch.component';
import { SwitchComponent } from './switch/switch.component';
import { ToggleIconComponent } from './toggle-icon/toggle-icon.component';
import { ValueListComponent } from './value-list/value-list.component';
import { ContenteditableModelDirective } from './contenteditable-model.directive';
import { ProgressComponent } from './progress/progress.component';
import { PreserveScrollDirective } from './preserve-scroll.directive';
import { ButtonGroupComponent } from './button-group/button-group.component';

@NgModule({
  imports: [
    FragmentPolyfillModule.forRoot({ smooth: true }),
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DataModule,
    TextMaskModule,
    EffectsModule.forFeature([SearchPickerEffects, ReportDownloadEffects]),
    NgSelectModule,
  ],
  declarations: [
    LoadingComponent,
    SearchSelectComponent,
    NestedMenuComponent,
    GridSelectComponent,
    FancyHComponent,
    ModalComponent,
    SwitchComponent,
    ConfirmCancelComponent,
    ShowDirective,
    SearchPickerComponent,
    RewriteLinksDirective,
    DropDownComponent,
    FilterSelectComponent,
    ValueListComponent,
    ToggleIconComponent,
    ModalEmbeddedComponent,
    NestedSelectComponent,
    StagedSelectCrunchComponent,
    ButtonSelectComponent,
    ContenteditableModelDirective,
    ProgressComponent,
    PreserveScrollDirective,
    ButtonGroupComponent
  ],
  providers: [
    SavingHandlerService,
    CallApiService,
    DataLoaderService,
    SearchPickerService,
    IntercomService,
    GaService,
    FragmentRouterService
  ],
  exports: [
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingComponent,
    SearchSelectComponent,
    NestedMenuComponent,
    GridSelectComponent,
    FancyHComponent,
    TextMaskModule,
    ModalComponent,
    ModalEmbeddedComponent,
    SwitchComponent,
    ShowDirective,
    SearchPickerComponent,
    RewriteLinksDirective,
    DropDownComponent,
    FilterSelectComponent,
    ValueListComponent,
    ToggleIconComponent,
    FragmentTargetDirective,
    NestedSelectComponent,
    StagedSelectCrunchComponent,
    ButtonSelectComponent,
    NgSelectComponent,
    ContenteditableModelDirective,
    ProgressComponent,
    PreserveScrollDirective,
    ButtonGroupComponent
  ]
})
export class SharedModule {}
