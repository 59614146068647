import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { DataEffects } from './data.effects';
import { DataConfig } from './data.config';
import { QuestionEffects } from './question.effects';
import { DataSelectorsService } from './data-selectors.service';
import { ShowExternalUrlService } from './show-external-url.service';
import { QuestionSeenEffects } from "./question-seen.effects";
import { QuestionNextEffects } from "./question-next.effects";
import { DataService } from "./data.service";

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      DataEffects,
      QuestionEffects,
      QuestionSeenEffects,
      QuestionNextEffects
    ])
  ],
  declarations: [],
  providers: [
    DataConfig,
    DataSelectorsService,
    ShowExternalUrlService,
    DataService
  ]
})
export class DataModule {
}
