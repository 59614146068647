import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenService } from './token.service';

@Injectable()
export class TokenNotExpiredService {
  constructor(
    private jwt: JwtHelperService,
    private tokenService: TokenService
  ) {}

  notExpired(): boolean {
    try {
      const token = this.tokenService.token;
      if (!this.jwt.getTokenExpirationDate()) {
        return true;
      }
      return !this.jwt.isTokenExpired(token!);
    } catch (err) {
      this.tokenService.clear();
    }
    return false;
  }
}
