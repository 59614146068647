import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { QuestionKey } from '../../common/questions/question.models';

export interface IGaSendPayload {
  hitType: 'event';
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  eventValue?: number;
}

@Injectable()
export class GaService {
  private responseThrottleMap: _.Dictionary<moment.Moment> = {};

  constructor() {}

  setSurveyResumption(email: string) {
    const gtag = this.gtag;
    if (gtag) {
      gtag('config', 'G-NCDYXM0456', {
        'page_title' : "/login"
      });
      gtag('event', 'survey_resumption', {
        'email': email
      });

    }

  }

  setPage(page: string) {
    const gtag = this.gtag;
    if (gtag) {
      gtag('config', 'G-NCDYXM0456', {
        'page_title' : page
      });

      const urlParams = new URLSearchParams(window.location.search);
      const fromParam = urlParams.get('from');
      if (fromParam) {
        gtag('event', 'partnerID', {
          'partnerID': fromParam
        });
      }
    }
  }

  pageview() {
    const gtag = this.gtag;
    if (gtag) {
      gtag('config', 'G-NCDYXM0456', {
      send_page_view: true
      });
    }
  }

  logResponse(questionKey: QuestionKey, value?: string) {
    if (!value) {
      const throttle = this.responseThrottleMap[questionKey];
      if (throttle) {
        if (moment().diff(throttle, 'minute') < 1) {
          return;
        }
      }
      this.responseThrottleMap[questionKey] = moment();
    }
    this.send({
      hitType: 'event',
      eventCategory: 'response',
      eventAction: questionKey,
      eventLabel: value
    });
  }

  logNewCriteriaSet() {
    this.send({
      hitType: 'event',
      eventCategory: 'criteriaSet',
      eventAction: 'new'
    });
  }

  logCriteriaQuestion(questionKey: QuestionKey) {
    this.send({
      hitType: 'event',
      eventCategory: 'criteriaQuestion',
      eventAction: questionKey
    });
  }

  logReportDownload(reportId: string) {
    this.send({
      hitType: 'event',
      eventCategory: 'reportDownload',
      eventAction: reportId
    });
  }
  send(payload: IGaSendPayload) {
    const gtag = this.gtag;
    if (gtag) {
      gtag(payload.hitType, payload.eventAction, {
        'event_category': payload.eventCategory,
      });
    }
  }

  private get gtag() {
    return (window as any).gtag;
  }
}
