import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-modal-embedded',
  templateUrl: './modal-embedded.component.html',
  styleUrls: ['./modal-embedded.component.sass']
})
export class ModalEmbeddedComponent implements OnInit {
  @Input() noPad = false;
  @Output() close = new EventEmitter<{}>();

  constructor() {}

  ngOnInit() {}

  onInnerClick($event: Event) {
    $event.stopPropagation();
  }

  onClose() {
    this.close.emit({});
  }
}
