import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './user.service';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { AuthGuardService } from './auth-guard.service';
import { LoginRequestedComponent } from './login-requested/login-requested.component';
import { LoginDirectComponent } from './login-direct/login-direct.component';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from '../users/user.effects';
import { OrgSelectorsService } from '../users/org-selectors.service';
import { LoginUpdateComponent } from './login-update/login-update.component';
import { LoginInlineComponent } from './login-inline/login-inline.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([UserEffects])
  ],
  declarations: [
    LoginComponent,
    LoginFormComponent,
    LoginRequestedComponent,
    LoginDirectComponent,
    LoginUpdateComponent,
    LoginInlineComponent
  ],
  providers: [UserService, AuthGuardService, OrgSelectorsService],
  exports: [LoginComponent, LoginInlineComponent]
})
export class UsersModule {}
