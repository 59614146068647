<div class="button-group">
  <label *ngFor="let option of options">
    <input type="radio"
           [name]="id"
           [value]="option.value"
           [checked]="selected === option.value"
           (change)="changeSelected($event, option.value)">
    <span class="button-group-item">{{ option.text }}</span>
  </label>
</div>
