import { Injectable } from '@angular/core';
import {
  ICriteriaDefineCrunchState,
  Filter,
  UiState,
  UiFilter
} from './criteria-define-crunch/criteria-define-crunch.models';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.models';

@Injectable()
export class CriteriaCrunchValidatorService {
  isValid(state: ICriteriaDefineCrunchState): boolean {
    return (
      !!state.name &&
      state.filters &&
      state.filters.length > 0 &&
      state.filters.every(isFilterComplete)
    );
  }

  isUiValid(state: UiState) {
    return (
      !!state.name &&
      state.filters &&
      state.filters.length > 0 &&
      state.filters.every(isUiFilterComplete)
    );
  }
}

function isFilterComplete(filter: Filter): filter is Required<Filter> {
  const op = filter.operand;
  if (!filter.operatorType || !op) {
    return false;
  }

  switch (op.type) {
    case 'location':
      return (
        op.radius !== undefined && op.lat !== undefined && op.lng !== undefined
      );
    case 'tags':
      return !!op.tags && op.tags.length > 0;
    case 'between':
      return !!op.from || !!op.to;
  }
}

function isUiFilterComplete(filter: UiFilter): boolean {
  const op = filter.operand;
  if (!filter.operator || !op) {
    return false;
  }

  switch (op.type) {
    case 'location':
      return (
        op.radius !== undefined && op.lat !== undefined && op.lng !== undefined
      );
    case 'tags':
      return !!op.tags && op.tags.length > 0;
    case 'between':
      return !!op.from || !!op.to;
  }
}
