import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { ILogger } from '../common/core/logger';

@Injectable()
export class AppLogger implements ILogger {
  setSession(sessionId: string) {
    window.rollbar.configure({
      payload: {
        sessionId
      }
    });
  }

  setPerson(id?: string, email?: string) {
    window.rollbar.configure({
      payload: {
        person: {
          id,
          email
        }
      }
    });
  }

  setContext(context: string) {
    window.rollbar.configure({
      payload: {
        context
      }
    });
  }

  error(...args: any[]) {
    if (environment.dev) {
      console.error.apply(console, args);
    } else {
      window.rollbar.error(...args);
    }
  }

  log(...args: any[]) {
    if (environment.dev) {
      console.log.apply(console, args);
    } else {
      window.rollbar.log(...args);
    }
  }

  debug(...args: any[]) {
    if (environment.dev) {
      console.debug.apply(console, args);
    } else {
      window.rollbar.debug(...args);
    }
  }

  info(...args: any[]) {
    if (environment.dev) {
      console.info.apply(console, args);
    } else {
      window.rollbar.info(...args);
    }
  }

  warn(...args: any[]) {
    if (environment.dev) {
      console.warn.apply(console, args);
    } else {
      window.rollbar.warn(...args);
    }
  }
}
