export namespace QuestionIds {
  export const PERSONAL_EMAIL = 'QPersonalEmail';
  export const FULL_NAME = 'QFullName';
  export const ORGANISATION_EMAIL = 'QOrganisationEmail';

  export const ORGANISATION_NAME = 'QOrganisationName';
  export const ORGANISATION_URL = 'QOrganisationURL';
  export const PRIMARY_OFFICE_ADDRESS = 'QPrimaryOfficeAddress';
  // export const ORG_FACEBOOK_URL = 'QOrganisationFacebookURL';
  export const ORG_INSTAGRAM_HANDLE = 'QOrganisationInstagramHandle';
  export const ORG_TWITTER_HANDLE = 'QOrganisationTwitterHandle';

  export const DESCRIPTION_OF_YOUR_PRODUCT_OR_SERVICE =
    'QDescriptionOfYourProductOrService';

  export const SHARING_OPTIONS = 'QSharingOptions';
  export const FINAL = 'QFinal';

  export const KIND_OF_HELPER = 'QKindOfHelper';

  export const USER_TYPE = 'QUserType';
}

export namespace OptionIds {
  export namespace UserType {
    export const RETURNING = 'AUserTypeReturning';
    export const HELPER = 'AUserTypeHelper';
  }
}
