import { empty as observableEmpty, of as observableOf } from 'rxjs';

import { mergeAll, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../app.models';
import { EffectLoggerService } from '../effect-logger.service';
import { LoginLinkRequested } from '../global.actions';
import { NextQuestion, QuestionActionTypes } from '../data/question.actions';
import { CancelEditQuestion } from '../your-organisation/your-organisation.actions';

const RETURNING_EMAIL_ID = 'QUserTypeReturningEmail';

@Injectable()
export class QuestionNextEffects {
  @Effect()
  nextQuestion$ = this.actions$.pipe(
    ofType(QuestionActionTypes.NEXT_QUESTION),
    this.effectLogger.handleErrors(source$ =>
      source$.pipe(
        withLatestFrom(
          this.store.select(s => s.data.responses.byId),
          this.store.select(s => s.yourOrganisation.editingQuestionId),
          (action: NextQuestion, responses, editingQuestionId) => {
            const { fromQuestionKey } = action.payload;
            if (fromQuestionKey === RETURNING_EMAIL_ID) {
              const response = responses[RETURNING_EMAIL_ID];
              if (response && response.type === 'value') {
                return observableOf(
                  new LoginLinkRequested({
                    email: response.value,
                    redirectOnSuccess: true
                  })
                );
              }
            } else if (editingQuestionId) {
              return observableOf(new CancelEditQuestion());
            }
            return observableEmpty();
          }
        ),
        mergeAll()
      )
    )
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private effectLogger: EffectLoggerService
  ) {}
}
