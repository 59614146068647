import { Action } from '@ngrx/store';

export namespace SurveyPageActionTypes {
  export const CHOOSE_START: '[Survey Page] Choose Start' =
    '[Survey Page] Choose Start';
  export const CONTINUE_PAST_LOGIN: '[Survey Page] Continue Past Login' =
    '[Survey Page] Continue Past Login';
}

export type SurveyPageActions = ChooseStart | ContinuePastLogin;

export class ChooseStart implements Action {
  type = SurveyPageActionTypes.CHOOSE_START;
  constructor(public payload: 'yes-previous' | 'no-previous') {}
}

export class ContinuePastLogin implements Action {
  type = SurveyPageActionTypes.CONTINUE_PAST_LOGIN;
}
