import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges, OnInit
} from '@angular/core';
import {
  NumberQuestion,
  HasQuestionId
} from '../../../common/questions/question.models';
import { AnswerDispatcherService } from '../answer-dispatcher.service';
import {
  ValueResponse,
  SkippedResponse
} from '../../../common/questions/response.model';
import { BaseQuestionComponent } from '../base-question.component';
import { NumberMaskService } from '../number-mask.service';

@Component({
  selector: 'app-number-question',
  templateUrl: './number-question.component.html',
  styleUrls: ['./number-question.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberQuestionComponent extends BaseQuestionComponent
  implements OnChanges, OnInit {
  @Input() question: NumberQuestion & HasQuestionId;
  @Input() response?: ValueResponse | SkippedResponse;

  value: string;

  constructor(
    private answerDispatcher: AnswerDispatcherService,
    private numberMask: NumberMaskService
  ) {
    super();
  }

  ngOnInit(): void {

    if (this.response) {
      this.answerDispatcher.answer(this.question, this.response);
    }
  }

  ngOnChanges(): void {
    if (this.response) {
      if (this.response.type === 'value') {
        this.value = this.response.value;
      } else {
        this.value = '';
        // this.value = this.question.skippable!;
      }
    } else {
      this.value = '';
    }
  }

  answer() {
    if (
      this.response &&
      this.response.type === 'skipped' &&
      this.value === ''
    ) {
      return;
    }
    const cleanValue = this.value.replace(/\D+/g, '');
    if (
      cleanValue === '' &&
      (!this.response ||
        (this.response.type === 'value' && this.response.value === ''))
    ) {
      return;
    }
    const response: ValueResponse = {
      questionId: this.question.id,
      questionKey: this.question.key,
      type: 'value',
      value: cleanValue,
      updatedAt: new Date()
    };
    this.answerDispatcher.answer(this.question, response);
  }

  skip() {
    const response: SkippedResponse = {
      questionId: this.question.id,
      questionKey: this.question.key,
      type: 'skipped',
      updatedAt: new Date()
    };
    this.answerDispatcher.answer(this.question, response);
  }

  get mask() {
    // if (this.isSkipped) {
    //   return { mask: this.question.skippable!.split('') };
    // }
    return { mask: this.numberMask.create(this.question) };
  }

  get isSkipped() {
    return this.response && this.response.type === 'skipped';
  }

  onFocus($event: FocusEvent) {
    if (this.isSkipped) {
      $event.stopPropagation();
      this.value = '';
      return false;
    }
    return;
  }

  onBlur($event: FocusEvent) {
    if (this.isSkipped) {
      $event.stopPropagation();
      this.value = this.question.skippable!;
      return false;
    }
    return;
  }

  onKeypress($event: KeyboardEvent) {
    if ($event.key && $event.key.match(/[A-Za-z]+/)) {
      this.answerDispatcher.setValidity(this.question, {
        isValid: false,
        message: 'Please use only digits (0-9) for this field.'
      });
    }
  }
}
