import { AppReducer } from './app.reducer';
import { IGlobalState } from './app.models';
import { GlobalActionTypes } from './global.actions';
import { set, setIn } from 'timm';
import { QuestionActionTypes } from './data/question.actions';
import { AppActions } from './app.actions';

const defaultLoginLinkState = { receivedResponse: false, success: true };

const defaultState: IGlobalState = {
  loadState: 'unloaded',
  isLightweight: true,
  showPlatform: false,
  loginLink: defaultLoginLinkState,
  loginEmail: '',
  hasPreviousResponses: false
};

export function globalReducer(state = defaultState, action: AppActions) {
  switch (action.type) {
    case GlobalActionTypes.LOADED:
      state = set(state, 'loadState', 'loaded');
      return set(state, 'isLightweight', action.lightweight);
    case GlobalActionTypes.LOADING:
      return set(state, 'loadState', 'loading');
    case GlobalActionTypes.NOT_LOADED:
      return set(state, 'loadState', 'unloaded');
    case GlobalActionTypes.SET_SHOW_PLATFORM:
      return set(state, 'showPlatform', action.payload);
    case GlobalActionTypes.SET_HAS_PREVIOUS_RESPONSES:
      return set(state, 'hasPreviousResponses', action.payload);
    case QuestionActionTypes.RESPONSE_UPDATE:
      if (
        action.payload.questionId === 'QUserTypeReturningEmail' &&
        state.loginLink.receivedResponse
      ) {
        return set(state, 'loginLink', {
          receivedResponse: false,
          success: true
        });
      }
      break;
    case GlobalActionTypes.LOGIN_LINK_REQUESTED:
      return set(state, 'loginLink', {
        receivedResponse: false,
        success: true
      });
    case GlobalActionTypes.LOGIN_LINK_RESULT:
      return set(state, 'loginLink', {
        receivedResponse: true,
        success: action.payload.success
      });
    case GlobalActionTypes.LOGIN_LINK_RESET:
      return set(state, 'loginLink', defaultLoginLinkState);
    case GlobalActionTypes.SET_LOGIN_EMAIL:
      return set(state, 'loginEmail', action.payload);
  }
  return state;
}
