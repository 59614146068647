import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { BaseQuestionComponent } from '../base-question.component';
import {
  Question,
  TextQuestion,
  HasQuestionId
} from '../../../common/questions/question.models';
import {
  ValueResponse,
  SkippedResponse
} from '../../../common/questions/response.model';
import { AnswerDispatcherService } from '../answer-dispatcher.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-year-month-question',
  templateUrl: './year-month-question.component.html',
  styleUrls: ['./year-month-question.component.sass']
})
export class YearMonthQuestionComponent extends BaseQuestionComponent
  implements OnInit, OnChanges {
  @Input() question: TextQuestion & HasQuestionId;
  @Input() response: ValueResponse | SkippedResponse;

  year = '';
  month = '';

  monthOptions = moment
    .monthsShort()
    .map((m, index) => ({ id: index + 1, text: m }));
  yearOptions = _.range(moment().year(), 1900, -1).map(y => y.toString());

  constructor(private answerDispatcher: AnswerDispatcherService) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.response) {
      this.year = '';
      this.month = '';
      if (this.response && this.response.type === 'value') {
        const matchResult = this.response.value.match(/(\d*)-(\d*)/);
        if (matchResult) {
          const [_all, year, month] = matchResult;
          this.year = year;
          this.month = month;
        }
      }
    }
  }

  answer() {
    const value = `${this.year}-${this.month}`;
    const response: ValueResponse = {
      questionId: this.question.id,
      questionKey: this.question.key,
      type: 'value',
      value,
      updatedAt: new Date()
    };
    this.answerDispatcher.answer(this.question, response);
  }
}
