<ng-container *ngIf="loginState !== 'success'">
  <p>
    Please enter the email address you used to complete the previous survey:
  </p>

  <form>
    <input type="email" [(ngModel)]="emailValue" name="email" />
    <button (click)="login()">
      <i *ngIf="loginState === 'loading'" class="fa fa-2x fa-spin fa-refresh" aria-hidden="true"></i>
      <span *ngIf="loginState !== 'loading'">Search for previous entry</span>
    </button>



  </form>

  <p *ngIf="loginState === 'not-found'">
    No survey response found. Please note this only works for surveys completed in 2023 or later. Please check the address entered, or start a new survey below.
  </p>

  <h3>or</h3>

  <p class="continue-action-container">
    <button
      class="continue-action"
      [ngClass]="{ selected: continueState }"
      (click)="onClickContinue()"
    >
      Continue with survey
    </button>
  </p>
</ng-container>

<ng-container *ngIf="loginState === 'success'">
  <p>
    Great, we've located your survey response from last year, and
    <strong>emailed you a link to login with</strong> that will allow you to
    view and bring across your responses from your previous survey entry easily.
  </p>
</ng-container>
