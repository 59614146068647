import { of as observableOf, Observable, Subscription } from 'rxjs';

import {
  merge,
  map,
  combineLatest,
  filter,
  switchMap,
  tap,
  delay
} from 'rxjs/operators';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import * as UrlParse from 'url-parse';
import { AppLogger } from './app-logger';
import { AppState, IGlobalState } from './app.models';
import { DataSavingState, IDataCollection } from './data/data.models';
import { GaService } from './shared/ga.service';
import { IntercomService } from './shared/intercom.service';
import { IUserType, OrgSelectorsService } from './users/org-selectors.service';
import { UserService } from './users/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy {
  saving$: Observable<DataSavingState[]>;
  mobileMenuShowing = false;
  userType: IUserType;

  loading = false;

  private stateSubscription: Subscription;
  private routerEventsSubscription: Subscription;
  // private previousUrl = '';
  private previousUrlPath = '';
  private global: IGlobalState;

  constructor(
    private users: UserService,
    private store: Store<AppState>,
    private orgSelectors: OrgSelectorsService,
    private router: Router,
    private renderer: Renderer2,
    private logger: AppLogger,
    private intercom: IntercomService,
    private ga: GaService
  ) {
    this.saving$ = this.store.select(s => s.data).pipe(
      map(d => {
        return _(d)
          .values()
          .flatMap((collection: IDataCollection<any>) => {
            return _.values(collection.saving);
          })
          .uniq()
          .value();
      })
    );
    this.stateSubscription = this.orgSelectors.userType$
      .pipe(combineLatest(this.store.select(s => s.global)))
      .subscribe(([userType, global]) => {
        this.userType = userType;
        this.global = global;
      });
  }

  ngOnInit(): void {
    const loading$ = this.router.events.pipe(
      filter(
        e =>
          e instanceof NavigationStart ||
          e instanceof NavigationEnd ||
          e instanceof NavigationCancel ||
          e instanceof NavigationError
      ),
      switchMap(e => {
        if (e instanceof NavigationStart) {
          return observableOf(true).pipe(delay(300));
        }
        return observableOf(false);
      }),
      tap(loading => {
        this.loading = loading;
      })
    );

    this.routerEventsSubscription = this.router.events
      .pipe(
        tap(e => {
          if (e instanceof NavigationEnd) {
            const parsedUrl = new UrlParse(e.url, {});
            // console.log(e.url);
            // console.dir(parsedUrl);

            this.logger.setContext(e.url);

            if (this.previousUrlPath !== parsedUrl.pathname) {
              this.ga.setPage(e.url);
              this.ga.pageview();
              this.intercom.update();
              if (
                !parsedUrl.pathname.match(/modal:modal/) &&
                !this.previousUrlPath.match(/modal:modal/)
              ) {
                this.renderer.setProperty(document.body, 'scrollTop', 0);
              }
              this.previousUrlPath = parsedUrl.pathname;
            }
          }
        }),
        merge(loading$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  // TODO: we should get notified of use.r login state changing,
  // and then we can cache this value instead of doing a lookup
  get isLoggedIn() {
    return this.users.isLoggedIn();
  }

  get showNav() {
    return (
      this.global.showPlatform &&
      // (this.userType.isHelper || this.userType.isStartup) &&
      this.users.isLoggedIn()
    );
  }

  get loginEmail() {
    return (this.global.showPlatform && this.global.loginEmail) || '';
  }

  logOut() {
    this.users.logOut();
  }

  toggleMenu() {
    this.mobileMenuShowing = !this.mobileMenuShowing;
  }

  onClickContact($event: MouseEvent) {
    $event.stopPropagation();
    this.intercom.show();
    return false;
  }

  onClickRoute(route: string) {
    parent.location.href = "https://app.startupmuster.com" + route;
  }
}
