import * as _ from 'lodash';
import {
  QuestionResponse,
  ValueResponse,
  ChoiceResponse,
  SelectionResponse
} from './response.model';
import { Question, IOptions, MultipleChoiceQuestion } from './question.models';
import { QuestionResponseTypeChecks } from './response-typechecks.model';

export function isEqual(a: QuestionResponse, b: QuestionResponse): boolean {
  if (a === b) return true;
  if (!a || !b) return false;
  if (a.type !== b.type) return false;

  switch (a.type) {
    case 'value':
      return a.value === (<ValueResponse>b).value;
    case 'choice':
      return (
        a.choice === (<ChoiceResponse>b).choice &&
        a.extra === (<ChoiceResponse>b).extra
      );
    case 'selection':
      return (
        (a.choices === (<SelectionResponse>b).choices ||
          _.isEqual(a.choices, (<SelectionResponse>b).choices)) &&
        a.extra === (<SelectionResponse>b).extra
      );
    case 'skipped':
      return true;
  }
}

export function extractValueBlind(
  response: QuestionResponse
): string | string[] | undefined {
  switch (response.type) {
    case 'value':
      return response.value;
    case 'choice':
      return response.choice;
    case 'selection':
      return response.choices;
    case 'skipped':
      return response.reason;
  }
}

export function extractValue(
  response?: QuestionResponse,
  question?: Question
): string | undefined {
  if (!response) {
    return undefined;
  }

  if (QuestionResponseTypeChecks.isValue(response)) {
    return response.value;
  }

  if (question) {
    if (QuestionResponseTypeChecks.isChoice(response)) {
      const choice = response.choice;
      const option = (question as MultipleChoiceQuestion).options.find(
        o => o.optionId === choice
      );
      if (option) {
        if (option.showBox) {
          return response.extra;
        }
        return option.text;
      }
    }
  }

  return undefined;
}

export function extractValues(
  questionsByKey: _.Dictionary<Question>,
  response?: QuestionResponse
): string[] | undefined {
  if (!response) {
    return undefined;
  }

  if (QuestionResponseTypeChecks.isValue(response)) {
    return [response.value];
  }

  if (QuestionResponseTypeChecks.isChoice(response)) {
    const question = questionsByKey[response.questionKey] as IOptions;
    if (!question.options) {
      // debug(
      //   `No options found for question ${response.questionId}, but we have a choice response.`
      // );
      return undefined;
    }
    const option = question.options.find(o => o.optionId === response.choice);
    if (!option) {
      // debug(
      //   `No option found for question ${response.questionId} and optionId ${response.choice}.`
      // );
      return undefined;
    }
    if (option.showBox) {
      return [response.extra];
    }
    return [option.text];
  }

  if (QuestionResponseTypeChecks.isSelection(response)) {
    return response.choices;
  }

  return undefined;
}
