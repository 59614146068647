<ng-container *ngIf="state.oldResponse">

  <ul *ngIf="!isNumberValue">
    <li *ngFor="let o of state.oldResponse!.options"
        [ngClass]="{ strikethrough: o.isDeleted }">{{ o.text }}</li>
  </ul>

  <div class="numberValue"
       *ngIf="isNumberValue">
    {{ numberValue }}
  </div>

</ng-container>
