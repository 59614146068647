import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { combineLatest } from 'rxjs/operators';
import { AppState } from '../../app.models';
import { ReportDownloadService } from '../../shared/report-download/report-download.service';
import { IReportDownloadState } from './report-download.models';

@Component({
  selector: 'app-report-download',
  templateUrl: './report-download.component.html',
  styleUrls: ['./report-download.component.sass']
  // encapsulation: ViewEncapsulation.None
})
export class ReportDownloadComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private reportId: string;

  email = '';
  state: IReportDownloadState;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private reportDownloadService: ReportDownloadService
  ) {
    this.subscription = store
      .select(s => s.reportDownload)
      .pipe(
        combineLatest(route.params, (state, params) => {
          this.state = state;
          this.reportId = params['id'];
        })
      )
      .subscribe();
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onInnerClick($event: Event) {
    $event.stopPropagation();
  }

  close() {
    this.router.navigate([{ outlets: { modal: null } }]);
  }

  saveEmail() {
    this.reportDownloadService.provideEmail(this.reportId, this.email);
  }

  inputKeyPress($event: KeyboardEvent) {
    $event.stopPropagation();
    if ($event.keyCode === 13) {
      this.saveEmail();
      return false;
    }
    return;
  }

  get isEmailValidish() {
    return this.email && this.email.match(/@.+\..+/);
  }

  get showSponsors() {
    return this.reportId === '2018';
  }
}
