import { delay, map, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  YourOrganisationActionTypes,
  EditQuestion
} from './your-organisation.actions';
import { AppState } from '../app.models';
import { Store } from '@ngrx/store';
import { Question, QuestionKey } from '../../common/questions/question.models';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class YourOrganisationEffects {
  @Effect({ dispatch: false })
  onEditQuestion$ = this.actions$.pipe(
    ofType(YourOrganisationActionTypes.EDIT_QUESTION),
    delay(0),
    withLatestFrom(
      this.store.select(s => s.data.questions.byId),
      (action: EditQuestion, questions: _.Dictionary<Question>) => {
        const question = questions[action.payload];
        this.setQuestionFragment(question.key);
      }
    )
  );

  @Effect({ dispatch: false })
  onCancelEditQuestion$ = this.actions$.pipe(
    ofType(YourOrganisationActionTypes.CANCEL_EDIT_QUESTION),
    map(action => {
      this.setQuestionFragment(null);
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  private setQuestionFragment(questionKey: QuestionKey | null) {
    let fragment = '';
    if (questionKey) {
      fragment = 'question-' + questionKey;
    }
    this.router.navigate([], {
      relativeTo: this.route,
      fragment
    });
  }
}
