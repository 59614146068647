import { Injectable } from '@angular/core';

@Injectable()
export class ShowExternalUrlService {

  constructor() { }

  show(url: string) {
    const n = 550, r = 500, i = screen.height, s = screen.width, o = Math.round(s / 2 - n / 2);
    let u = 0;
    if (i > r) {
      u = Math.round(i / 2 - r / 2);
    }
    window.open(url, undefined, "scrollbars=yes,resizable=yes,toolbar=no,location=no,width=" + n + ",height=" + r + ",left=" + o + ",top=" + u);
  }

}
